<template>
  <div>
    <AlterFormLayout
      modalId="institutional-page-show-modal"
      :formTitle="this.dateToString"
      cancelButtonText="Fechar"
      submitButtonText="Baixar organograma em PDF"
      :onSubmitEvent="downloadPdf"
      :disableValidation="true"
      :landscape="true"
    >
      <template #content>
        <pdf-viewer
          :landscape="true"
          :getDocumentHandler="getDocumentHandler"
        />
      </template>
    </AlterFormLayout>
  </div>
</template>

<script>
import PdfViewer from "../../../../components/pdf-viewer/PdfViewer.vue";
import AlterFormLayout from "../../../../layouts/AlterFormLayout.vue";
import { downloadBase64File } from "../../../../services/pdf-download/pdf-download-services";
import { anchorBase64Download } from "../../../../utils/anchor-base64-download/anchor-base64-download";
import { dateFormatMonthToStringAndYearCurrent } from "../../../../utils/date/date-utils";

export default {
  data() {
    return {
      downloadedFile: null,
      fileName: null,
      fileUrl: null,
      month: null,
      year: null,
      dateToString: "",
    };
  },
  components: {
    AlterFormLayout,
    PdfViewer,
  },
  methods: {
    onMessageDate() {
      return `Organograma UGF - Atualizado em ${dateFormatMonthToStringAndYearCurrent(
        this.month - 1,
        this.year
      )}`;
    },
    downloadPdf() {
      if (!this.downloadedFile) return;
      anchorBase64Download(
        this.$store.getters.isApp,
        "application/pdf",
        this.fileName,
        this.downloadedFile
      );
    },
    async getDocumentHandler() {
      const download = await this.downloadFile();
      this.downloadedFile = download;
      const result = atob(download);
      return result;
    },
    async downloadFile() {
      try {
        const yearCurrent = new Date().getFullYear().toString();
        const response = await downloadBase64File(
          this.$store.getters.getUserName,
          "ORGANIZATIONAL_CHART",
          yearCurrent
        );

        this.fileUrl = response.fileUrl;
        this.fileName = response.fileName;
        this.month = response.month;
        this.year = response.year;

        this.dateToString = this.onMessageDate();

        return response.document;
      } catch (error) {
        console.error("Error on function downloadFile:", error);
      }
    },
  },
};
</script>
