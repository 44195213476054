import axios from "../http-client/axios";
import { OPETH_URL } from "../urls/base-urls";

export async function downloadBase64File(crm, type, ano, mes) {
  let fildMonth = "";
  if (mes) fildMonth = `&month=${mes}`;
  const resp = await axios({
    url: `${OPETH_URL}/download/?crm=${crm}&documentType=${type}&year=${ano}${fildMonth}`,
    method: "get",
    data: {},
    headers: { "content-type": "application/json" },
  });
  return resp.data;
}

export async function downloadFilePanelEconomic(sizeMonth, extensionFile) {
  const resp = await axios({
    url: `${OPETH_URL}/download/panel-economic?sizeMonth=${sizeMonth}&extensionFile=${extensionFile}`,
    method: "get",
    data: {},
    headers: { "content-type": "application/json" },
  });
  return resp.data;
}