<template>
  <div class="custom-dropdown">
    <div
      class="content dropdown-title"
      :class="$props.active ? 'dropdown-title--dark' : ''"
      @click="selectNavItemClick($props.route, $event)"
    >
      {{ $props.title }}
      <div class="content">
        <div v-if="items.length" class="custom-dropdown--item-container">
          <div v-for="(item, index) in items" :key="index">
            <div
              v-show="onCheckPreview(item.visible)"
              data-is-dropdown-item="true"
              class="dropdown-item"
              :class="verifyIfIsDisabled(item) && 'isDisabled'"
              @click="onClickHandler(item)"
            >
              {{ item.label }}
              <div :class="getClassNew(item.featureName)"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="isTitleNew()" class="dropdown-new"></div>
    <div v-if="items.length" class="arrow-up"></div>
    <div v-if="items.length" class="arrow-down"></div>
  </div>
</template>

<!-- eslint-disable no-unused-vars -->
<script>
import { getNewByName } from "../../utils/news/news-utils";

export default {
  props: ["title", "items", "route", "active"],
  methods: {
    onCheckPreview(value) {
      return value == null || value == undefined ? true : value;
    },
    isTitleNew() {
      return this.$props.items.some((i) => {
        if (getNewByName(i.featureName)) return true;
        return false;
      });
    },
    getClassNew(featureName) {
      const currentNew = getNewByName(featureName);
      if (currentNew) {
        return "dropdown-new";
      }
      return;
    },
    verifyIfIsDisabled(item) {
      return item.isDisabled?.();
    },
    onClickHandler(item) {
      try {
        item.action && item.action.call(item);
      } catch (error) {
        console.error(
          "Error on onClickHandler: trying to execute functionHanlder",
          error
        );
      }
    },
    selectNavItemClick(item, e) {
      if (e?.target?.dataset?.isDropdownItem) return;
      this.$router.push({ path: item, replace: true });
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  padding-top: 2rem;
}

.arrow-up {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid var(--dark-0);
  display: none;
  margin-left: 0.5rem;
}

.custom-dropdown {
  position: relative;
  display: flex;
  align-items: center;

  &--item-container {
    display: none;
    position: absolute;
    padding: 2rem;
    flex-direction: column;
    background: white;
    transition: all 0.5s ease-in-out;
    white-space: nowrap;
    z-index: 1;
  }
  transition: all 0.5s ease-in-out;
  &:hover &--item-container {
    transition: all 0.5s ease-in-out;
    display: block;
    color: var(--dark-0);
  }
  &:hover .arrow-up {
    display: initial;
  }
  &:hover .arrow-down {
    display: none;
  }
}

.dropdown-title {
  color: var(--grey-1);
  &--dark {
    color: var(--dark-0);
  }
  &:hover {
    color: var(--dark-0);
  }
}

.dropdown-item {
  display: flex;
  align-items: center;
  &:hover {
    color: var(--primary);
    background-color: white;
  }
}

.arrow-down {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid var(--grey-1);
  margin-left: 0.5rem;
}

.arrow-down,
.arrow-up,
.custom-dropdown--item-container {
  @media screen and (max-width: 816px) {
    display: none;

    &:hover {
      display: none;
    }
  }
}

.custom-dropdown {
  &:hover {
    .arrow-down,
    .arrow-up {
      @media screen and (max-width: 816px) {
        display: none;
      }
    }
  }

  &:hover &--item-container {
    @media screen and (max-width: 816px) {
      display: none;
    }
  }
}
.isDisabled {
  pointer-events: none;
  cursor: not-allowed;
  opacity: 0.5;
  text-decoration: none;
}
.dropdown-new {
  margin-bottom: 14px;
  border-radius: 5px;
  top: 28px;
  left: 1082px;
  width: 10px;
  height: 10px;
  background: #b1d34b 0% 0% no-repeat padding-box;
  opacity: 1;
}
</style>
