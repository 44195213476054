const initializeJsonFromLocalStorage = (key) => {
    try {
      let token = localStorage.getItem(key);
      if (token && token != "undefined") {
        return JSON.parse(token);
      }
      return "";
    } catch (error) {
      console.error(`Error on initialize ${key}: `, error);
      return "";
    }
};
  
export const initializeFromLocalStorage = (key) => {
    try {
      let token = localStorage.getItem(key);
      if (token) {
        return token;
      }
      return "";
    } catch (error) {
      console.error(`Error on initialize ${key}: `, error);
      return "";
    }
  };

  export default initializeJsonFromLocalStorage;
