<template>
  <div>
    <FormLayout
      :tabs="this.tabs"
      :cardInfo="this.cardInfo"
      :leftAction="this.leftAction"
      :leftLinkNavigation="this.leftLinkNavigation"
    ></FormLayout>
  </div>
</template>

<script>
import Vue from "vue";
import FormLayout from "../../../layouts/FormLayout.vue";
import RoutesPaths from "../../../router/router-structure/routes-paths";
import { ServiceType } from "../../../static-data/ServiceType";

Vue.component("other-solicitation-view", () =>
  import("../components/visualization/OtherSolicitationView.vue")
);

export default {
  name: "",
  components: {
    FormLayout,
  },
  data: function() {
    return {
      currentTabIndex: 0,
      leftAction: {
        text: "Outras Solicitações",
        onClick: this.leftActionClickHandler,
      },
      leftLinkNavigation: this.leftHistoryNavigation,
      cardInfo: {
        headerIcon: "far fa-address-card",
        icon: "email-send-outline.svg",
        title: "Outras Solicitações",
        description:
          "Aqui você pode protocolar suas solicitações para a área do cooperado.",
      },
      tabs: [
        {
          title: "",
          formComponent: "other-solicitation-view",
          serviceType: ServiceType.GENERAL_REQUESTS,
        },
      ],
    };
  },
  methods: {
    onChangeTabIndex(newValue) {
      this.currentTabIndex = newValue;
    },
    leftActionClickHandler() {
      this.$router.push({
        path: RoutesPaths.home.rootName(),
        replace: true,
      });
    },
    leftHistoryNavigation() {
      this.$router.push({
        path: RoutesPaths.requestHistory.rootName(),
        params: {
          serviceType: ServiceType.GENERAL_REQUESTS,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
