<template>
  <div class="page-container">
    <div>
      <section class="page-header">
        <h2 class="font-weight-bold">
          Declarações e benefícios
        </h2>
      </section>

      <div class="separator-line"></div>
      <section class="sub-header">
        <p>
          Solicite documentos, declarações e benefícios.
        </p>
      </section>
    </div>
    <section>
      <div class="card-container">
        <CustomInfoCard
          class="deck deck-margin"
          v-for="card in getCards"
          :key="card.id"
          :onCardClick="() => onClickCard(card.route)"
          :title="card.header"
          :description="card.description"
          :icon="card.icon"
        />
      </div>
    </section>
  </div>
</template>

<script>
import CustomInfoCard from "../../../components/custom-info-card/CustomInfoCard.vue";
import RoutesPaths from "../../../router/router-structure/routes-paths";

export default {
  components: {
    CustomInfoCard,
  },
  computed: {
    getCards: () => [
      {
        id: 0,
        icon: "medal-outline.svg",
        header: "Benemérito",
        description: "Solicite o benefício pelos seus serviços prestados.",
        route: RoutesPaths.documents.benefactor(),
      },
      {
        id: 1,
        icon: "clipboard-text-outline.svg",
        header: "Afastamento",
        description: "Justifique o motivo e solicite o benefício.",
        route: RoutesPaths.documents.absence(),
      },
      {
        id: 2,
        icon: "auxilio_temporario.svg",
        header: "Auxílio Temporário",
        description:
          "Para os casos de afastamento de doença ou licença maternidade.",
        route: RoutesPaths.documents.temporaryAssistance(),
      },
      {
        id: 3,
        icon: "funeral_assistence.svg",
        header: "Auxílio Funeral",
        description:
          "Benefício concedido aos sucessores legais do médico cooperado em caso de falecimento.",
        route: RoutesPaths.documents.funeralAssistance(),
      },
      {
        id: 4,
        icon: "plano_saude.svg",
        header: "Plano de Saúde (PLAC)",
        description: "Plano de saúde dos médicos Cooperados (PLAC).",
        route: RoutesPaths.documents.healthPlan(),
      },
      {
        id: 5,
        icon: "file-document-edit-outline.svg",
        header: "Declaração de Médico Cooperado",
        description: "Acesse a sua declaração com apenas um clique.",
        route: RoutesPaths.documents.cooperativeDeclaration(),
      },
      {
        id: 6,
        icon: "file-money.svg",
        header: "Informe de Rendimentos",
        description:
          "Acesse o seu informe para a declaração de imposto de renda.",
        route: RoutesPaths.documents.reportIncome(),
      },
      {
        id: 7,
        icon: "file-document-outline.svg",
        header: "Extrato de Cota",
        description: "Acesse o seu extrato de cota de forma fácil e rápida.",
        route: RoutesPaths.documents.quotaStatement(),
      },
      {
        id: 8,
        icon: "sprout-outline.svg",
        header: "Fundo de Sustentabilidade",
        description: "Acesse o seu extrato de fundo de Sustentabilidade.",
        route: RoutesPaths.documents.sustainabilityFund(),
      },
      {
        id: 9,
        icon: "text-box-outline.svg",
        header: "Solicitação de Declarações",
        description: "Solicite declaração de INSS, ISS e LTCAT/PPP.",
        route: RoutesPaths.documents.declaration(),
      },
    ],
  },
  methods: {
    onClickCard(route) {
      this.$router.push(route);
    },
  },
};
</script>

<style lang="scss" scoped>
.deck-margin {
  margin: 2rem;
}

.page-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.page-header {
  text-align: center;
}

.sub-header {
  max-width: 83rem;
  margin: 0 auto;
  margin-top: 1.5rem;
  text-align: center;
}

.card-container {
  max-width: 130rem;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  @include media-breakpoint-up(xs) {
    justify-content: center;
  }

  @include media-breakpoint-up(md) {
    justify-content: center;
  }
}
</style>
