export function validateField(name) {
  let value = this.form[name];
  let validation = !!value;
  this.validations[name] = validation;
  if (!this.formSubmited) return null;
  return !!value;
}

export function requiredFieldsFilled() {
  return Object.values(this.validations).every(
    (fieldValue) => fieldValue == true
  );
}
export function validateFieldFile(name) {
  let value = this.form[name];
  const validation = !!value && value.length > 0;
  this.validations[name] = validation;
  if (!this.formSubmited) return null;
  return validation;
}

export function validateCheckBox(name) {
  this.validations[name] = true;
  if (!this.formSubmited) return null;
  return true;
}

export function validateCnpj(name) {
  let value = this.form[name];
  let validation = cnpj.isValid(value);
  this.validations[name] = validation;
  if (!this.formSubmited) return null;
  return validation;
}

export function validateCpf(name) {
  let value = this.form[name];
  let validation = cpf.isValid(value);
  this.validations[name] = validation;
  if (!this.formSubmited) return null;
  return validation;
}

export function validateFieldPhone(name) {
  let value = this.form[name];
  let validation = true;
  validation = !!value;
  validation = validation && (value.length == 11 || value.length == 10);

  this.validations[name] = validation;
  if (!this.formSubmited) return null;

  return validation;
}

export function validateFiedlCep(name) {
  let value = this.form[name];
  let validation = true;
  validation = !!value;
  validation = validation && value.length == 8;

  this.validations[name] = validation;
  if (!this.formSubmited) return null;

  return validation;
}

export function validateFieldPassword(name) {
  let value = this.form[name];
  const validation = !!value && value.length > 7;
  this.validations[name] = validation;
  if (!this.formSubmited) return null;
  return validation;
}

export function validateFieldSamePassword(name) {
  const validation =
    this.form.password == this.form.passwordConfirm;
  this.validations[name] = validation;
  if (!this.formSubmited) return null;

  return validation;
}

export function validateFieldEmail(name) {
  let value = this.form[name];
  var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  let validation = re.test(value);
  this.validations[name] = validation;
  if (!this.formSubmited) return null;
  return validation;
}
export function validateFieldEndDate(name, startedName, mandatory = true) {
  let value = this.form[name];
  let validation = !!value;
    if (!mandatory && !value) {
      return true;
    }
    if (!value) {
      validation = false;
      this.endDateErrorMsg = "Data de término deve ser informada.";
    } else if (value) {
      const settedEndDate = new Date(value);
      const settedStartDate = new Date(this.form[startedName]);
      if (settedEndDate < settedStartDate) {
        validation = false;
        this.endDateErrorMsg =
          "A data de término deve ser posterior a data de início.";
      }
    } 
  
  this.validations[name] = validation;
  if (!this.formSubmited) return null;
  return validation;
}
export function validateFieldStartDate(name, onlyFutureDate) {
  let value = this.form[name];
  let validation = !!value;
  if (value) {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    let settedStartDate = value
    if (isValidDate(value)) {
      settedStartDate = formatStringHyphenToDate(value);
    } 
    if (onlyFutureDate && today > settedStartDate) {
      validation = false;
      this.startDateErrorMsg =
        "Data de início não pode ser anterior a data atual.";
    }
  } else {
    validation = false;
    this.startDateErrorMsg = "Data de início deve ser informada.";
  }
  this.validations[name] = validation;
  if (!this.formSubmited) return null;
  return validation;
}


import { cnpj, cpf } from "cpf-cnpj-validator";
import {
  formatStringHyphenToDate,
  isValidDate
} from "../date/date-utils";