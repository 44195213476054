<template>
  <div>
    <b-navbar
      class="shadow-sm px-5"
      toggleable="md"
      type="light"
      variant="light"
    >
      <slot name="leftItems"></slot>
      <slot name="rightItems"></slot>
    </b-navbar>
  </div>
</template>
<script>
export default {};
</script>

<style lang="scss" scoped></style>
