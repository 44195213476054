import store from "../../store";
import { anchorBase64Download } from "../../utils/anchor-base64-download/anchor-base64-download";
import axios from "../http-client/axios";
import { GORIJA_URL } from "../urls/base-urls";

export async function downloadInvoice(Numero, CodigoCliente) {
  await axios
    .get(
      `${GORIJA_URL}/invoice-health-plan?number=${Numero}&doctorCard=${CodigoCliente}`
    )
    .then((res) => {
      anchorBase64Download(
        store.getters.isApp,
        "application/pdf",
        "file" + res.data.extension,
        res.data.fileBase64
      );
    });
}

export async function getListRequested(crm) {
  const resp = await axios.get(`${GORIJA_URL}/health-plan?crm=${crm}`);
  return resp.data;
}
