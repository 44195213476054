import initializeJsonFromLocalStorage from "../../../utils/storage/initialize-storage-utils";
import getStatus from "../../../services/status/status";
import VerifyStatusError from "../../../errors/VerifyStatusError.js";
const setStatusStore = (status) => {
  try {
    localStorage.setItem("status", JSON.stringify(status));
  } catch (error) {
    console.error("error on setStatusStore", error);
    return false;
  }
};

const initializeStatusStore = () => {
  try {
    const status = initializeJsonFromLocalStorage("status");
    return status;
  } catch (error) {
    console.error("error on initializeStatusStore", error);
    return false;
  }
};

const statusUser = {
  state: {
    status: initializeStatusStore(),
  },
  mutations: {
    setDateStatus(state, value) {
      state.status = value;
    },
  },
  actions: {
    async verifyStatus({ commit, rootGetters }) {
      try {
        if (rootGetters.getUserName) {
          const { status } = await getStatus(rootGetters.getUserName);
          await setStatusStore(status);
          commit("setDateStatus", status);
          return status;
        }
        return "";
      } catch (error) {
        await commit("setDateStatus", false);
        await setStatusStore(null);
        console.error("Error on verifyStatus", error);

        throw new VerifyStatusError(
          "Não foi possível consultar status do usuário."
        );
      }
    },
    setDateStatus({ commit }, status) {
      commit("setDateStatus", status);
    },
    async clearDateStatus({ commit }) {
      commit("setDateStatus", false);
      await setStatusStore(null);
    },
  },
  getters: {
    status: (state) => state.status,
  },
};

export default statusUser;
