<template>
  <b-modal id="success-modal">
    <b-container>
      <template #modal-header></template>

      <b-row class="my-3">
        <div class="success-container">
          <h2>{{ this.$props.title }}</h2>
        </div>
      </b-row>
      <slot name="body"></slot>
    </b-container>
    <template #modal-footer>
      <b-button
        @click="$bvModal.hide('success-modal')"
        class="ml-4"
        variant="primary"
      >
        {{ $props.buttonText ? $props.buttonText : "Ok" }}
      </b-button>
    </template>
  </b-modal>
</template>

<script>
export default {
  props: ["description", "buttonText", "subdescription", "protocol", "title"],
};
</script>

<style lang="scss" scoped></style>
