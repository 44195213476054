<template>
  <div>
    <b-form-group label="CRM" label-for="crm">
      <b-form-input
        placeHolder="Informe seu CRM"
        id="crm"
        type="number"
        required
        v-model="form.crm"
        :state="validateField('crm') && this.msgErroInvalid == null"
        class="p-4 pr-5"
      />
      <div
        class="invalid"
        v-show="this.msgErroInvalid != null && !this.loading"
      >
        <i class="fas fa-exclamation-circle invalid"></i>
        {{ this.msgErroInvalid }}
      </div>
    </b-form-group>
    <div class="div-buttons">
      <b-button
        @click="onCancelar()"
        class="button mr-4"
        variant="outline-primary"
        id="cancelar"
        >Cancelar
      </b-button>
      <b-button
        :disabled="isDisabled"
        @click="onContinuar()"
        class="button "
        variant="primary"
        id="continuar"
        >Continuar
        <i class="fas fa-spinner fa-spin icon" v-show="this.loading"></i
      ></b-button>
    </div>
  </div>
</template>
<script>
import RoutesPaths from "../../../../router/router-structure/routes-paths";
import { passwordRecoveryEmail } from "../../../../services/password-recovery/change-password-service";
import {
  validateField,
  validateFieldEmail,
  requiredFieldsFilled,
} from "../../../../utils/validate/validate.js";
export default {
  data() {
    return {
      formSubmited: false,
      validations: {},
      loading: false,
      msgErroInvalid: null,
      isDisabled: false,
      validateField,
      validateFieldEmail,
      requiredFieldsFilled,
      form: {
        crm: null,
      },
    };
  },
  methods: {
    onCancelar() {
      this.$router.push(RoutesPaths.login.rootName());
    },
    async onContinuar() {
      try {
        if (this.onSubmit()) {
          this.loading = true;
          this.setDisabled(true);
          const listaEmail = await passwordRecoveryEmail(this.form.crm);

          this.$emit("setLista", this.setFormat(listaEmail));
          this.$emit("setCRM", this.form.crm);
          this.loading = false;
          this.setDisabled(false);
        }
      } catch (error) {
        this.loading = false;
        this.setDisabled(false);
        if (error.response && error.response.status == 400) {
          this.msgErroInvalid =
            "O CRM fornecido não foi encontrado em nossa base de dados. Por favor, verifique se o número está correto e tente novamente. ";
        }
      }
    },
    onSubmit() {
      this.formSubmited = true;
      if (this.requiredFieldsFilled()) {
        this.msgErroInvalid = null;
        return true;
      }
    },
    setDisabled(value) {
      this.isDisabled = value;
    },
    setFormat(listaEmail) {
      const listEmailFormat = listaEmail.map((emailObj) => ({
        text: emailObj.email,
        value: emailObj.id,
      }));

      return listEmailFormat;
    },
  },
};
</script>

<style lang="scss" scoped>
.button {
  margin-top: 14rem;
  width: 12rem;
  height: 4rem;
}
.div-buttons {
  position: absolute;
  bottom: 0;
  margin-bottom: 2rem;
}

.invalid {
  margin-top: 1rem;
  color: #dc3545;
  margin-top: 0.8rem;
}
</style>
