import Vue from "vue";

export const toastWrapper = (
  title,
  message,
  variant = "primary",
  delay = 5000,
  appendToast = false,
  toaster
) => ({
  methods: {
    makeToast() {
      this.$bvToast.toast(message, {
        toaster: toaster,
        title: title,
        autoHideDelay: delay,
        appendToast: appendToast,
        variant: variant,
      });
    },
  },
});

export const createToast = function(
  title,
  message,
  variant = "danger",
  delay = 5000,
  appendToast = false,
  toaster = "b-toaster-bottom-center"
) {
  const vm = new Vue(
    toastWrapper(title, message, variant, delay, appendToast, toaster)
  );
  vm.makeToast();
  setTimeout(() => {
    vm.$destroy();
  }, delay + 1000);
};
