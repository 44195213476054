export function mapperToResponse(values) {
  const items = [];
  values.forEach((element) => {
    items.push({ item: element });
  });
  return items;
}

export function mapperToCardsResponse(values) {
  const items = [];
  values.forEach((element) => {
    items.push({ item: element.CARTAO });
  });
  return items;
}
