import axios from "../http-client/axios";
import ServiceRequestType from "../../static-data/ServiceRequestType";
import requestBuilder from "../request-builder/request-builder";
import AlterType from "../../static-data/TypeRequestedFlow";
import { GORIJA_URL, METALICA_URL } from "../urls/base-urls";
import FormRequestAction from "../../static-data/FormRequestAction";
import { returnDateFormat } from "../../utils/date/date-utils";
import store from "../../store";
import { getMaritalDescription } from "../../static-data/MaritalState";

export async function getReviewGlassesAPI(crm, month, year) {
  const resp = await axios.get(
    `${GORIJA_URL}/feature-gloss?crm=${crm}&month=${month}&year=${year}`
  );

  return resp.data;
}

export async function sendRequestAndGetProtocol(attachments) {
  
    const resp = await axios.post(
      `${GORIJA_URL}/update-file-data`, requestBuilderFiles(attachments)
    );
    return resp.data;
}

export async function sendRequestAndGetProtocolNew(attachments) {
  const data = store.getters.getUserInformation;

  const resp = await axios.post(
    `${METALICA_URL}/v2/integration-flow`,
    requestBuilder(
      mapperToAbsenceRequest(data, FormRequestAction.update),
      AlterType.update,
      attachments,
      ServiceRequestType.FEATURE_GLOSS_SEND
    )
  );
  return resp.data;
}

function mapperToAbsenceRequest(data) {
  return {
    "Ação da solicitação": data.name,
    Nome: data.name,
    "DATA DE NASCIMENTO": returnDateFormat(data.birthdate),
    RG: data.rg,
    CPF: data.cpf,
    "ESTADO CIVIL": getMaritalDescription(data.maritalStatus),
    ESCOLARIDADE: data.schooling,
    CRM: data.crm,
    "PIS/NIT": data.pis,
  };
}

export async function getCompetences() {
  const resp = await axios.get(`${GORIJA_URL}/current-competency`);
  return resp.data;
}

function requestBuilderFiles(
  attachments = [],
) {  
  let formRequest = new FormData();
  if (attachments.length > 0) { 
    attachments.forEach(file=> formRequest.append("files", file));
  }
  return formRequest
}
