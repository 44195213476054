<template>
  <div>
    <custom-accordion class="mb-3" :collapseId="index">
      <template slot="header">
        <div class="p3 color-primary upperCase pl-3">
          {{ $props.header }}
        </div>
      </template>
      <template slot="content">
        <hr class="accordion-hr" />
        <div class="content-style">
          <span v-html="$props.content"></span>
        </div>
      </template>
    </custom-accordion>
  </div>
</template>

<script>
import CustomAccordion from "../../../components/custom-accordion/CustomAccordion.vue";

export default {
  props: ["header", "content", "index"],
  components: { CustomAccordion },
  name: "FaqList",
};
</script>

<style lang="scss">
div.content-style {
  font-size: multiplier-default-font-size(2);
  a {
    font-size: multiplier-default-font-size(2);
  }
}
</style>
