<template>
  <div class="card-container">
    <b-card no-body class="custom-card--size-control " border-variant="light">
      <b-card-body>
        <b-card-text>
          <h4>
            {{ $props.title }}
          </h4>
          <b-row class="mt-3 p2">
            <b-col class="middle-card--begin"></b-col>
            <b-col class="middle-card--end">
              <h2 class="custom-value-color">
                <CustomMaskLabel
                  :initialValue="this.receivable"
                  :options="numberOptions"
                  :cssClass="color"
                />
              </h2>
            </b-col>
          </b-row>
          <b-row v-show="this.$props.isShowLink">
            <b-col></b-col>
            <b-col class="custom-value">
              <a
                @click="exibirDetalhes()"
                class="custom-card--pointer custom-value-color"
              >
                Exibir detalhes
              </a>
            </b-col>
          </b-row>
        </b-card-text>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import CustomMaskLabel from "../../../../components/forms/custom-mask-label/CustomMaskLabel.vue";

const NumberOptions = {
  numeral: true,
  numeralDecimalMark: ",",
  delimiter: ".",
  prefix: "R$ ",
  onValueChanged: InvalidEventHandler,
};

const InvalidEventHandler = () => {
  console.warn("Invalid onChange handler");
};
export default {
  name: "custom-middle-card",
  props: ["title", "amountReceivable", "onDetailClick", "isShowLink"],
  components: {
    CustomMaskLabel,
  },
  data() {
    return {
      color: "color-green-2",
      numberOptions: {
        ...NumberOptions,
        onValueChanged: this.receivable,
      },
      receivable: null,
    };
  },
  methods: {
    exibirDetalhes() {
      this.$props.onDetailClick();
    },
  },
  mounted() {
    this.receivable = this.$props.amountReceivable
      ? this.$props.amountReceivable
      : "000,00";
  },
};
</script>

<style scoped lang="scss">
div.card {
  border-radius: 1rem;
}

.custom-table {
  width: 100%;
}

.custom-value {
  text-align: right;
}
.custom-value-color {
  color: var(--green-2);
}
.custom-card {
  &--pointer {
    cursor: pointer;
  }

  &--size-control {
    height: 9.5rem;
    width: 30rem;
  }
}
</style>
