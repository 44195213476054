export const IntegrationJMJStatus = [
  { title: 'Pendente \n', src: "pending-error-clock-alert-outline.svg", enum: 'PENDING', error: true },

  { title: 'Em andamento \n', src: "running-error-clock-alert-outline.svg", enum: 'RUNNING', error: true },

  { title: 'Pendente \n', src: "pending-clock-alert-outline.svg", enum: 'PENDING', error: false },

  { title: 'Em andamento \n', src: "running-clock-alert-outline.svg", enum: 'RUNNING', error: false },
   
  { title: 'Cancelado \n', src: "canceled-clock-alert-outline.svg", enum: 'CANCELED', error: false },
   
  { title: 'Finalizado \n', src: "finished-clock-alert-outline.svg", enum: 'FINISHED', error: false },
   { title: 'Finalizado \n', src: "finished-clock-alert-outline.svg", enum: 'DEFAULT', error: false },
];



