<template>
  <div class="analytics">
    <div class="page-container">
      <section class="page-header">
        <h2 class="font-weight-bold">
          Painel Econômico
        </h2>
        <div class="separator-line"></div>
        <p class="p3 text-principal">
          Com o objetivo de consolidar os compromissos firmados e aproximar os
          médicos cooperados da realidade atual de sua cooperativa, por meio de
          uma gestão participativa e democrática, passa a ser disponibilizado
          neste espaço o Painel Econômico. O documento apresenta os principais
          resultados financeiros e administrativos da cooperativa, reunindo
          alguns indicadores detalhados e o comparativo dos cenários do ano
          anterior e vigente. Abaixo está disponível o Painel Econômico
          atualizado, assim como os painéis dos meses anteriores.
        </p>
      </section>
      <section class="tab-sub-header"></section>

      <div class="buttons-container">
        <div class="links">
          <div
            v-b-modal:panel-economic-modal
            class="links--internal links--internal--primary"
          >
            <div class="img-panel-economic">
              <img src="../../../assets/images/panel-economic-update.svg" />
            </div>
            <div class="text-panel-economic">
              <h5>
                Painel econômico
                <span class="font-weight-bold">atualizado</span>
              </h5>
              <p>
                Clique aqui para ter acesso ao demonstrativo do Painel Econômico
                atualizado
              </p>
            </div>
          </div>

          <PanelEconomicModal />

          <div @click="onClickHandlerZip()" class="links--internal">
            <div class="img-panel-economic">
              <img src="../../../assets/images/panel_economic.svg" />
            </div>
            <div class="text-panel-economic">
              <h5 class="">
                Painel econômico
                <span class="font-weight-bold">meses anteriores</span>
              </h5>
              <p>
                Clique aqui para baixar o demonstrativo do Painel Econômico dos
                últimos três meses
              </p>
            </div>
          </div>
        </div>
        <div class="panel-economic--img"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { createToast } from "../../../components/toast/toast";
import { downloadFilePanelEconomic } from "../../../services/pdf-download/pdf-download-services";
import { ExtensionFile } from "../../../static-data/ExtensionFile";
import { anchorBase64Download } from "../../../utils/anchor-base64-download/anchor-base64-download";
import PanelEconomicModal from "./modal/PanelEconomicModal.vue";
export default {
  data() {
    return {
      msgErroInvalid: null,
      loading: false,
    };
  },
  methods: {
    onClickHandlerZip() {
      if (this.loading) {
        return;
      }
      this.getDownloadZip();
    },
    onClickHandler() {
      if (this.loading) {
        return;
      }
      this.getDownloadZip();
    },
    async getDownloadZip() {
      try {
        this.msgErroInvalid = null;
        this.loading = true;
        const response = await downloadFilePanelEconomic(3, ExtensionFile.ZIP);

        if (!response) {
          this.msgErroInvalid = "Não existe documentos para serem baixados.";
          createToast(
            "Informação",
            "Não existe documentos para serem baixados.",
            "info"
          );
          this.loading = false;
          return;
        }

        anchorBase64Download(
          this.$store.getters.isApp,
          "application/zip",
          "painel-economico-documentos.zip",
          response.document
        );

        this.loading = false;
      } catch (error) {
        console.error("getDownload", error);
        this.loading = false;
        createToast("Atenção", "Ocorreu um erro ao baixar arquivo.");
      }
    },
  },
  components: { PanelEconomicModal },
};
</script>

<style scoped lang="scss">
.buttons-container {
  margin-top: 2rem;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;

  @include media-breakpoint-down(xs) {
    flex-direction: column;
    flex-grow: 1;
  }

  @include media-breakpoint-down(sm) {
    flex-direction: column;
    flex-grow: 1;
  }
}

.page-container {
  display: flex;
  flex-direction: column;
  width: 70%;
}

.analytics {
  display: flex;
  justify-content: center;
  background-color: var(--background);
  height: 100%;
}

.page-header {
  text-align: center;
  display: flex;
  display: flex;
  flex-direction: column;
  justify-content: center;

  div {
    margin-top: 1rem;
  }

  p {
    margin-top: 1.5rem;
  }
}

.text-principal {
  color: var(--green-2);
}

.img-panel-economic {
  img {
    padding-top: 35%;
    padding-left: 12%;
  }
  width: 17%;
  justify-content: center;
}

.text-panel-economic {
  width: 80%;
}

.links {
  margin: 2rem 0;
  width: 32rem;
  min-width: 32rem;

  &--internal {
    h5 {
      margin-top: 5%;

      span {
        color: var(--green-2);
      }
    }

    &--primary {
      margin-bottom: 2.5rem;
    }

    display: flex;
    flex-direction: row;
    cursor: pointer;
    border-radius: 1.5rem;

    background-color: white;
    width: 100%;
    text-align: left;
    height: 7.5rem;
  }
}

.panel-economic {
  &--img {
    margin-top: 1rem;

    min-height: 20rem;
    min-width: 32rem;

    background-image: url("../../../assets/images/ilus_analitica.svg");
    background-repeat: no-repeat;
    background-position: 85% 50%;
    background-size: 34rem 20rem;

    @include media-breakpoint-down(xs) {
      margin: 0 auto;
      margin-top: 1rem;
    }

    @include media-breakpoint-down(sm) {
      margin: 0 auto;
      margin-top: 1rem;
    }
  }

  &--options {
    display: flex;
    flex-direction: row;

    cursor: pointer;
    border-radius: 1.5rem;

    margin-top: 7.5rem;
    width: 32rem;
    max-width: 32rem;
    margin-left: 15%;
  }
}
.links--internal:hover {
  border: 0.1rem solid #0a5f55 !important;
}
</style>
