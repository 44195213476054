<template>
  <div>
    <b-card no-body class="large-card--control-size" border-variant="light">
      <b-card-body class="large-card--body">
        <b-card-text class="large-card--size m-4">
          <img
            class="large-card--icon-size "
            :src="resolve_img_url($props.icon)"
          />
          <h4>Valor a receber</h4>
          <h1>
            <CustomMaskLabel
              :initialValue="this.receivable"
              :options="numberOptions"
              :cssClass="classGreen"
            />
          </h1>
          <b-row class="mt-4 p2">
            <b-col class="large-card--begin">Valor bruto</b-col>
            <b-col class="large-card--end">
              <CustomMaskLabel
                :initialValue="this.gross"
                :options="numberOptions"
                :cssClass="colorGray"
            /></b-col>
          </b-row>
          <b-row class="mt-3 p2" v-if="$props.invoiceValue">
            <b-col class="large-card--begin">Valor NF</b-col>
            <b-col class="large-card--end">
              <CustomMaskLabel
                :initialValue="this.invoice"
                :options="numberOptions"
                :cssClass="colorGray"
              />
            </b-col>
          </b-row>
          <b-row class="mt-3 p2">
            <b-col class="large-card--begin"
              >Descontos
              <i
                data-toggle="tooltip"
                data-placement="top"
                title="Veja detalhes dos descontos no PDF da analítica detalhada"
                class="fas fa-info-circle color-primary p3"
              ></i>
            </b-col>
            <b-col class="large-card--end">
              <CustomMaskLabel
                :initialValue="this.discountValue"
                :options="numberOptions"
                :cssClass="colorGray"
            /></b-col>
          </b-row>
        </b-card-text>
      </b-card-body>
    </b-card>
  </div>
</template>
<script>
import resolve_img_url from "../../../../utils/image/image-utils";
import CustomMaskLabel from "../../../../components/forms/custom-mask-label/CustomMaskLabel.vue";

const NumberOptions = {
  numeral: true,
  numeralDecimalMark: ",",
  delimiter: ".",
  prefix: "R$ ",
  onValueChanged: InvalidEventHandler,
};

const InvalidEventHandler = () => {
  console.warn("Invalid onChange handler");
};

export default {
  name: "custom-large-card",
  props: ["icon", "amountReceivable", "grossValue", "invoiceValue", "discount"],
  components: {
    CustomMaskLabel,
  },
  data() {
    return {
      classGreen: "color-green-2 text-center",
      colorGray: "color-gray-2",
      gross: null,
      receivable: null,
      invoice: null,
      discountValue: null,
      numberOptions: {
        ...NumberOptions,
        onValueChanged: this.onChangePhone,
      },
    };
  },
  mounted() {
    this.gross = this.$props.grossValue ? this.$props.grossValue : "0,00";
    this.receivable = this.$props.amountReceivable
      ? this.$props.amountReceivable
      : "0,00";
    this.invoice = this.$props.invoiceValue ? this.$props.invoiceValue : "0,00";
    this.discountValue = this.$props.discount ? this.$props.discount : "0,00";
  },
  methods: {
    resolve_img_url: resolve_img_url,
  },
};
</script>
<style scoped lang="scss">
div.card {
  border-radius: 2rem;
}

.large-card {
  &--control-size {
    height: 31rem;
    width: 30rem;
  }

  &--size {
    width: 27rem;
  }

  &--icon-size {
    height: 10rem;
    width: 12.5rem;
  }

  &--body {
    display: flex;
    text-align: center;
  }

  &--begin {
    color: var(--grey-1);
    text-align: left;
  }

  &--end {
    text-align: right;
  }
}
</style>
