<template>
  <div class="cooperative">
    <SimpleLayout
      viewComponent="CooperativeDeclarationTab"
      :cardInfo="this.cardInfo"
      :leftAction="this.leftAction"
    />
  </div>
</template>

<script>
import Vue from "vue";
import SimpleLayout from "../../../layouts/SimpleLayout.vue";
import RoutesPaths from "../../../router/router-structure/routes-paths";

Vue.component("CooperativeDeclarationTab", () =>
  import("../components/visualization/CooperativeDeclarationTab.vue")
);

export default {
  components: {
    SimpleLayout,
  },
  data: function() {
    return {
      leftAction: {
        text: "Declarações",
        onClick: this.leftActionClickHandler,
      },
      cardInfo: {
        icon: "file-document-outline.svg",
        title: "Declaração de Médico Cooperado",
        description: "Acesse a sua declaração com apenas um clique.",
      },
    };
  },
  methods: {
    leftActionClickHandler() {
      this.$router.push({
        path: RoutesPaths.declarations.rootName(),
        replace: true,
      });
    },
  },
};
</script>

<style lang="scss">
.cooperative {
  .simple-layout--content {
    width: 100%;
  }
}
</style>
