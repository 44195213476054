<template>
  <div class="declaration">
    <SimpleLayout
      viewComponent="DeclarationLTCATPP"
      :cardInfo="this.cardInfo"
      :leftAction="this.leftAction"
      :leftLinkNavigation="this.leftLinkNavigation"
    />
  </div>
</template>

<script>
import Vue from "vue";
import SimpleLayout from "../../../layouts/SimpleLayout.vue";
import RoutesPaths from "../../../router/router-structure/routes-paths";
import { ServiceType } from "../../../static-data/ServiceType";

Vue.component("DeclarationLTCATPP", () =>
  import("../components/visualization/DeclarationLTCATPPInformations.vue")
);

export default {
  components: {
    SimpleLayout,
  },
  data: function() {
    return {
      leftAction: {
        text: "Declarações",
        onClick: this.leftActionClickHandler,
      },
      leftLinkNavigation: this.leftHistoryNavigation,
      cardInfo: {
        headerIcon: "far fa-address-card",
        icon: "file-document-outline.svg",
        title: "Declaração LTCAT/PPP",
        description:
          "Solicite o LTCAT (Laudo Técnico das Condições do Ambiente de Trabalho) ou PPP (Perfil Profissiográfico Previdenciário).",
      },
    };
  },
  methods: {
    leftActionClickHandler() {
      this.$router.push({
        path: RoutesPaths.declarations.rootName(),
        replace: true,
      });
    },
    leftHistoryNavigation() {
      this.$router.push({
        path: RoutesPaths.requestHistory.rootName(),
        params: {
          serviceType: ServiceType.Declaration,
        },
      });
    },
  },
};
</script>

<style lang="scss">
.declaration {
  .simple-layout--content {
    width: 100%;
  }
}
</style>
