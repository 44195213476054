export function base64toFile(base64String, fileName) {
  
  var binaryString = window.atob(base64String);
  var bytes = new Uint8Array(binaryString.length);

  for (var i = 0; i < binaryString.length; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  
  var blob = new Blob([bytes]);
  
  var file = new File([blob], fileName);

  return file;
}

