<template>
  <b-form-group class="password-tips">
    <span class="font-rule">
      A senha deve conter:
    </span>
    <ul>
      <li class="font-rule" :class="{ valid: haveCapitalLetters }">
        <i class="fas fa-check-circle" v-show="haveCapitalLetters"></i>
        <i class="fas fa-times-circle" v-show="!haveCapitalLetters"></i>
        Pelo menos uma letra maiúscula
      </li>
      <li class="font-rule" :class="{ valid: hasLowercaseLetters }">
        <i class="fas fa-check-circle" v-show="hasLowercaseLetters"></i>
        <i class="fas fa-times-circle" v-show="!hasLowercaseLetters"></i>
        Pelo menos uma letra minúscula
      </li>
      <li class="font-rule" :class="{ valid: haveNumbers }">
        <i class="fas fa-check-circle" v-show="haveNumbers"></i>
        <i class="fas fa-times-circle" v-show="!haveNumbers"></i>
        Pelo menos um número
      </li>
      <li class="font-rule" :class="{ valid: hasSpecialCharacters }">
        <i class="fas fa-check-circle" v-show="hasSpecialCharacters"></i>
        <i class="fas fa-times-circle" v-show="!hasSpecialCharacters"></i>
        Algum desses caracteres <b>#?!@$%^&*-</b>
      </li>
      <li class="font-rule" :class="{ valid: hasAtLeastEightCharacters }">
        <i class="fas fa-check-circle" v-show="hasAtLeastEightCharacters"></i>
        <i class="fas fa-times-circle" v-show="!hasAtLeastEightCharacters"></i>
        No mínimo 12 caracteres
      </li>
      <li class="font-rule" :class="{ valid: hasValidPasswordConfirm }">
        <i class="fas fa-check-circle" v-show="hasValidPasswordConfirm"></i>
        <i class="fas fa-times-circle" v-show="!hasValidPasswordConfirm"></i>
        Confirmar a senha
      </li>
    </ul>
  </b-form-group>
</template>

<script>
export default {
  name: "rule-password",
  props: ["password", "passwordConfirm"],
  data() {
    return {
      hasLowercaseLetters: false,
      haveCapitalLetters: false,
      haveNumbers: false,
      hasSpecialCharacters: false,
      hasAtLeastEightCharacters: false,
      hasValidPasswordConfirm: false,
    };
  },
  watch: {
    password: {
      handler() {
        this.validatePasswordRules();
      },
    },
    passwordConfirm: {
      handler() {
        this.validatePasswordRules();
      },
    },
  },
  methods: {
    validatePasswordRules() {
      const uppercaseRegex = /[A-Z]/;
      const lowercaseRegex = /[a-z]/;
      const digitRegex = /[0-9]/;
      const specialCharRegex = /[#?!@$%^&*-]/;

      this.haveCapitalLetters = uppercaseRegex.test(this.$props.password);
      this.hasLowercaseLetters = lowercaseRegex.test(this.$props.password);
      this.haveNumbers = digitRegex.test(this.$props.password);
      this.hasSpecialCharacters = specialCharRegex.test(this.$props.password);
      this.hasAtLeastEightCharacters = this.$props.password.length >= 12;
      this.hasValidPasswordConfirm =
        this.$props.password == this.$props.passwordConfirm;
      this.$emit("onValidPassword", this.isValidPassword());
    },
    isValidPassword() {
      return (
        this.haveCapitalLetters &&
        this.hasLowercaseLetters &&
        this.haveNumbers &&
        this.hasSpecialCharacters &&
        this.hasAtLeastEightCharacters &&
        this.hasValidPasswordConfirm
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.password-tips {
  color: #707070;
  .valid {
    color: var(--primary);
  }
}
.font-rule {
  font-size: 14px;
}
ul {
  padding-left: 0;
  list-style-type: none;
  margin-bottom: 40px;
}
</style>
