<template>
  <div class="dropdown-container">
    <button
      type="button"
      class="flex dropdown-style"
      @click.stop.prevent="toggle()"
    >
      <div class="actions-dropdown--header">
        <div v-if="!noIcon" class="user-border">
          <img :src="userImage" v-if="userImage" />
          <img src="@/assets/icons/user.svg" v-else />
        </div>
        <div class="text-user camelCase">{{ text }}</div>
        <i v-show="!isOpen" class="fas fa-angle-down text-primary p1 ml-3" />
        <i v-show="isOpen" class="fas fa-angle-up text-primary p1 ml-3" />
      </div>
    </button>

    <Transition
      enter-active-class="transition ease-out duration-100"
      enter-class="transform opacity-0 scale-95"
      enter-to-class="transform opacity-100 scale-100"
      leave-active-class="transition ease-in duration-75"
      leave-class="transform opacity-100 scale-100"
      leave-to-class="transform opacity-0 scale-95"
    >
      <div class="dropdown-group" v-show="isOpen">
        <slot />
      </div>
    </Transition>
  </div>
</template>

<script>
export default {
  name: "Dropdown",

  props: {
    text: {
      type: String,
      default: "Dropdown",
    },

    noIcon: {
      type: Boolean,
      default: false,
    },
    userImage: {
      type: String,
      default: null,
    },
  },

  provide() {
    return {
      dropdown: this,
    };
  },

  data() {
    return {
      isOpen: false,
    };
  },

  watch: {
    isOpen(value) {
      if (value) {
        this.$root.$emit("dropdown::open", this);
      }
    },
  },

  created() {
    this.$root.$on("dropdown::open", this.rootCloseListener);
  },

  mounted() {
    document.addEventListener("click", this.clickOutListener);
  },

  beforeDestroy() {
    document.removeEventListener("click", this.clickOutListener);
  },

  methods: {
    toggle() {
      this.isOpen = !this.isOpen;
    },

    close() {
      this.isOpen = false;
    },

    clickOutListener(evt) {
      if (!this.$el.contains(evt.target)) {
        this.close();
      }
    },

    rootCloseListener(vm) {
      if (vm !== this) {
        this.close();
      }
    },
  },
};
</script>
<style lang="scss">
.actions-dropdown {
  &--header {
    display: flex;
    color: var(--dark-1);
    justify-content: center;
    align-content: center;
    align-items: center;
  }
}

.dropdown-style {
  background: transparent;
  border: none;
  padding: 1rem;
}

.dropdown-group {
  width: 25rem;
  background: var(--background-1) 0% 0% no-repeat padding-box;
  box-shadow: var(--box-shadow-1);
  border: 1px solid var(--light-5);
  border-radius: 8px;
  padding: 1rem;
  z-index: 1;

  @include media-breakpoint-up(xs) {
    position: inherit;
    margin-top: 2rem;
  }

  @include media-breakpoint-up(md) {
    position: absolute;
    right: 0;
    top: 5.7rem;
    margin-right: 3rem;
  }

  @media screen and (max-width: 1066px) and (min-width: 768px) {
    position: absolute;
    right: 0;
    top: 4.7rem;
    margin-right: 2rem;
  }
}

.dropdown-itens {
  background: white;
  border: none;
  padding: 1rem;
  text-align: left;

  :hover {
    color: var(--primary-hover);
  }
}

.dropdown-container {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
}
.user-border {
  display: flex;
  height: 3.5rem;
  width: 3.5rem;
  margin-right: 1rem;
  justify-content: center;
  align-content: center;
  align-items: center;
  position: relative;

  img {
    height: 2.5rem;
    width: 2.5rem;
    border-radius: 60%;
  }

  @media screen and (max-width: 1066px) and (min-width: 768px) {
    margin-right: 0.4rem;
  }
}

.text-user {
  @include header4;

  @media screen and (max-width: 1066px) and (min-width: 844px) {
    @include header5;
  }

  @media screen and (max-width: 844px) and (min-width: 768px) {
    @include header6;
  }
}
</style>
