var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',{staticClass:"date-picker-custom",class:this.$props.required && this.$props.state != null
      ? 'img-visible'
      : 'img-invisible'},[_c('div'),_c('div',{},[_c('b-form-datepicker',{attrs:{"id":_vm.datePickerId,"locale":"pt-BR","label-help":"","placeholder":"dd/MM/aaaa","size":"lg","date-format-options":{
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      }},on:{"context":_vm.onContext},model:{value:(_vm.value),callback:function ($$v) {_vm.value=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"value"}})],1),_c('div',[_c('img',{staticClass:"d-flex align-items-center h-100 custom-icon-remove",attrs:{"src":require("@/assets/icons/close-circle.svg")},on:{"click":function($event){return _vm.removeValue()}}})]),(
      this.$props.required && this.$props.state && this.$props.state != null
    )?_c('div',{staticClass:"pl-2"},[_c('div',{staticClass:"d-flex align-items-center h-100"},[_c('img',{staticClass:"img-custom",attrs:{"src":require("@/assets/images/check.svg")}})])]):_vm._e(),(
      this.$props.required && !this.$props.state && this.$props.state != null
    )?_c('div',{staticClass:"pl-2"},[_c('div',{staticClass:"d-flex align-items-center h-100"},[_c('img',{staticClass:"img-alert",attrs:{"src":require("../../assets/images/MDI-alert-circle-outline.svg")}})])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }