import initializeJsonFromLocalStorage from "../../../utils/storage/initialize-storage-utils";
import getPermisaoAcessoTermUse from "../../../services/term-use/term-use-services.js";
import VerifyUserTermError from "../../../errors/VerifyUserTermError.js"
const setDateUseTermOnStore = (dateAccepted) => {
  try {
    localStorage.setItem("use-term", JSON.stringify(dateAccepted));
  } catch (error) {
    console.error("error on setDateUseTermOnStore", error);
    return false;
  }
};

const initializeUseTermStore = () => {
  try {
    const accepted = initializeJsonFromLocalStorage("use-term");
    return accepted;
  } catch (error) {
    console.error("error on initializeUseTerm", error);
    return false;
  }
};

const useTerm = {
  state: {
    dateAcceptedUseTerm: initializeUseTermStore(),
  },
  mutations: {
    setDateAcceptedUseTerm(state, value) {
      state.dateAcceptedUseTerm = value;
    },
  },
  actions: {
    async verifyUseTerm({ commit, rootGetters }) {
      try {
        if (rootGetters.getUserName) {
          const { dateAcccepted } = await getPermisaoAcessoTermUse(
            rootGetters.getUserName
          );
          await setDateUseTermOnStore(dateAcccepted);
          commit("setDateAcceptedUseTerm", dateAcccepted);
          return dateAcccepted;
        }
        return false;
      } catch (error) {
        await commit("setDateAcceptedUseTerm", false);
        await setDateUseTermOnStore(null);
        console.error("Error on verifyUseTerm", error);
          
        throw new VerifyUserTermError("Não foi possível consultar se termo já foi aceito.");
      }
    },
    setDateAcceptedUseTerm({ commit }, accepted) {
      commit("setDateAcceptedUseTerm", accepted);
    },
  },
  getters: {
    dateAcceptedUseTerm: (state) => state.dateAcceptedUseTerm,
  },
};

export default useTerm;
