<script>
import CustomCardInfo from "../../../../components/custom-info-card/CustomInfoCard.vue";
import VisualizationTemplate from "../../../../templates/VisualizationTemplate.vue";
import NavigationButton from "../../../../components/navigation-button/NavigationButton.vue";

export const FormLayout = {
  props: ["leftInfo", "leftAction", "content", "showTable", "isShow"],
  components: {
    VisualizationTemplate,
    CustomCardInfo,
    NavigationButton,
  },

  render() {
    const invalidHandler = (e) => {
      console.warn("Invalid handler!", e);
    };

    const leftAction = {
      text: "Invalid Text!",
      onClick: invalidHandler,
      ...this.$props.leftAction,
    };

    const leftInfo = this.$props.leftInfo;

    return (
      <b-container>
        <VisualizationTemplate isShowseparator={true}>
          <template slot="leftAction">
            <NavigationButton
              actionClickHandler={leftAction.onClick}
              underLine={false}
            >
              <template slot="icon">
                <i class="fas fa-arrow-left"></i>
              </template>
              <template slot="text">{leftAction.text}</template>
            </NavigationButton>
          </template>

          <template slot="leftInfo">
            <p class="p4">{leftInfo}</p>
          </template>

          <template slot="headerContent">{this.$slots.headerContent}</template>
          <template slot="content"></template>
          <template slot="footerContent">{this.$slots.footerContent}</template>
        </VisualizationTemplate>
      </b-container>
    );
  },
};

export default FormLayout;
</script>
