<template>
  <div>
    <div
      class="invalid"
      v-show="!checkVisibleCodeError([
      'LOGIN_MAX_ATTEMPT_FAILURE',
      'LOGIN_ALERT_ATTEMPT_FAILURE',
      'LOGIN_MULTIPLE_ATTEMPT_FAILURE'
    ])"
    >
      <i class="fas fa-exclamation-circle invalid"></i>
      {{ $props.msgErroInvalid }}
    </div>
    <div
      class="invalid"
      v-show="checkVisibleCodeError(['LOGIN_MAX_ATTEMPT_FAILURE'])"
    >
      <i class="fas fa-exclamation-circle invalid"></i>
      CRM e/ou senha inválido(s).<br />
      Você excedeu o número máximo de tentativas de login.
      Por segurança, sua conta está temporariamente bloqueada por 15 minutos. 
      Por favor, aguarde e tente novamente mais tarde.
    </div>
    <div
      class="invalid"
      v-show="checkVisibleCodeError(['LOGIN_ALERT_ATTEMPT_FAILURE'])"
    >
      <i class="fas fa-exclamation-circle invalid"></i>
      CRM e/ou senha inválido(s).<br />
      <b>Considere redefinir sua senha!</b><br />
      Após mais uma tentativa incorreta, sua conta será bloqueada temporariamente.
    </div>
    <div
      class="invalid"
      v-show="checkVisibleCodeError(['LOGIN_MULTIPLE_ATTEMPT_FAILURE'])"
    >
      <i class="fas fa-exclamation-circle invalid"></i>
      CRM e/ou senha inválido(s).<br />
      <b>Considere redefinir sua senha!</b><br />
      Múltiplas tentativas incorretas podem resultar no bloqueio temporário de sua conta.
    </div>
  </div>
</template>
<script>
export default {
  props: [
    "msgErroInvalid",
    "codeErro",
  ],
  data() {
    return {

    };
  },
  methods: {
    checkVisibleCodeError(codeErros) {
      return codeErros.includes(this.codeErro);
    },
  },
};
</script>

<style lang="scss" scoped>
.invalid {
  color: #dc3545;
  margin-top: 0.8rem;
}
</style>
