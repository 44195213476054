<template>
  <div class="benefactor">
    <SimpleLayout
      viewComponent="benefactor-tab"
      :cardInfo="this.cardInfo"
      :leftAction="this.leftAction"
      :leftLinkNavigation="this.leftLinkNavigation"
    />
  </div>
</template>

<script>
import Vue from "vue";
import SimpleLayout from "../../../layouts/SimpleLayout.vue";
import { ServiceType } from "../../../static-data/ServiceType";
import RoutesPaths from "../../../router/router-structure/routes-paths";

Vue.component("benefactor-tab", () =>
  import("../components/visualization/BenefactorTab.vue")
);

export default {
  components: {
    SimpleLayout,
  },
  data: function() {
    return {
      leftAction: {
        text: "Benefícios",
        onClick: this.leftActionClickHandler,
      },
      leftLinkNavigation: this.leftHistoryNavigation,
      cardInfo: {
        headerIcon: "far fa-address-card",
        icon: "medal-outline.svg",
        title: "Benemérito",
        description: "Solicite o benefício pelos seus serviços prestados.",
      },

      serviceType: ServiceType.Benefactor,
    };
  },
  methods: {
    leftActionClickHandler() {
      this.$router.push({
        path: RoutesPaths.benefit.rootName(),
        replace: true,
      });
    },
    leftHistoryNavigation() {
      this.$router.push({
        path: RoutesPaths.requestHistory.rootName(),
        params: {
          serviceType: this.serviceType,
        },
      });
    },
  },
};
</script>
