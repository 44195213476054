/* eslint-disable no-unused-vars */
import axios from "../http-client/axios";
import { SLIPKNOP_URL } from "../urls/base-urls";

// eslint-disable-next-line no-unused-vars
export async function getPermisaoAcessoTermUse(crm) {
  const resp = await axios({
    url: `${SLIPKNOP_URL}/term-user/acceptance?crm=${crm}`,
    method: "get",
    data: {},
    headers: { "content-type": "application/json" },
  });

  return resp.data;
}

// eslint-disable-next-line no-unused-vars
export async function addAcessoTermUse(crm, userName, textTerm) {
  const data = {
    crm: crm,
    username: userName,
    version: "V1",
    textTerm: textTerm,
  };

  const resp = await axios({
    url: `${SLIPKNOP_URL}/term-user/`,
    method: "post",
    data: data,
    headers: { "content-type": "application/json" },
  });

  return resp.data;
}

export default getPermisaoAcessoTermUse;
