import Home from "../../modules/home/views/Home.vue";
import RouterViewRender from "../../utils/routes/router-view-render";
import { PermissionList } from "../../static-data/PermissionList";
import { IsAbleNavigation } from "../utils/is-able-navigation";
const HomeRoutes = {
  path: "",
  beforeEnter: (to, from, next) => {
    IsAbleNavigation(to, from, next, PermissionList.HOME_MENU);
  },
  meta: {
    requiresAuth: true,
  },
  component: RouterViewRender,
  children: [
    {
      path: "home",
      component: Home,
      meta: {
        requiresAuth: true,
      },
    },
  ],
};

export default HomeRoutes;
