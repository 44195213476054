import axios from "../http-client/axios";
import { KREATOR_URL } from "../urls/base-urls";
import { getRequest } from "../../utils/update-occurrences/occurrences-utils";
import { SCORPIONS_URL } from "../urls/base-urls";

export async function sendOccurrences() {
  var request = getRequest();

  if (request != null) {
    const resp = await axios.post(
      `${SCORPIONS_URL}/notification/ocorrence`,
      request
    );
    return resp.data;
  }
}

export async function getShowNotification() {
  const resp = await axios({
    url: `${KREATOR_URL}/show-notification`,
    method: "get",
    data: {},
    headers: { "content-type": "application/json" },
  });

  return resp.data;
}

export async function confirmRequiredNotification() {
  await axios({
    url: `${KREATOR_URL}/confirm-notification`,
    method: "put",
    headers: { "content-type": "application/json" },
  });
}

export default { getShowNotification, confirmRequiredNotification };
