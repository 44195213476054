<template>
  <div>
    <b-form-group
      label="Selecione o e-mail em que deseja receber o link para recuperação de senha:"
      v-slot="{ ariaDescribedby }"
      v-if="this.emailLista"
      class="password-email-container"
    >
      <b-form-radio-group
        class="password-radio"
        v-model="selected"
        :options="emailLista"
        :aria-describedby="ariaDescribedby"
        name="radios-stacked"
        size="lg"
        stacked
      ></b-form-radio-group>
    </b-form-group>
    <div class="mt-4">
      <b-button
        @click="onCancelar()"
        class="button mr-4"
        variant="outline-primary"
        id="cancelar"
        >Cancelar
      </b-button>
      <b-button
        :disabled="isDisabled"
        @click="onContinuar()"
        class="button "
        variant="primary"
        id="continuar"
        >Continuar
        <i class="fas fa-spinner fa-spin icon" v-show="this.loading"></i
      ></b-button>
    </div>
    <div class="invalid" v-show="this.msgErroInvalid != null && !this.loading">
      <i class="fas fa-exclamation-circle invalid"></i>
      {{ this.msgErroInvalid }}
    </div>
  </div>
</template>
<script>
import RoutesPaths from "../../../../router/router-structure/routes-paths";
import { passwordRecoveryService } from "../../../../services/password-recovery/change-password-service";

export default {
  props: ["listaEmail", "crm"],
  mounted() {
    this.emailLista = this.$props.listaEmail;
  },
  data() {
    return {
      emailLista: "",
      selected: null,
      msgErroInvalid: null,
      loading: false,
      isDisabled: false,
    };
  },

  methods: {
    onCancelar() {
      this.$router.push(RoutesPaths.login.rootName());
    },
    async onContinuar() {
      try {
        if (this.onSubmit()) {
          this.loading = true;
          this.setDisabled(true);
          await passwordRecoveryService(this.$props.crm, this.selected);
          this.loading = false;
          this.setDisabled(false);
          this.$emit("setSucess", true);
        }
      } catch (error) {
        this.loading = false;
        this.setDisabled(false);
        console.error("onContinuar", error);
        if (
          error.response &&
          error.response.status == 400 &&
          error.response.data &&
          error.response.data
        ) {
          this.msgErroInvalid = error.response.data.message;
        }

        if (
          error.response &&
          error.response.status == 500 &&
          error.response.data.message ==
            "Mais de uma requisição de geração do mesmo codigo de validação."
        ) {
          this.msgErroInvalid =
            "As instruções para alteração de senha já foram enviadas para o email selecionado!";
        }
      }
    },
    onSubmit() {
      if (this.selected) {
        this.msgErroInvalid = null;
        return true;
      } else {
        this.msgErroInvalid = "Selecione um e-mail";
        return false;
      }
    },
    setDisabled(value) {
      this.isDisabled = value;
    },
  },
};
</script>

<style lang="scss">
.password-email-container {
  padding: 1rem;
  max-height: 20rem;
  min-height: 20rem;
  overflow-y: auto;
  overflow-x: hidden;
}
.button {
  margin-top: 2rem;
  width: 12rem;
  height: 4rem;
}
.invalid {
  margin-top: 1rem;
  color: #dc3545;
  margin-top: 0.8rem;
}

.password-radio {
  color: var(--grey-1);
}
</style>
