<template>
  <div>
    <b-input-group class="input-group-running">
      <b-input-group-prepend>
        <span class="input-group-text"><i class="fa fa-search fa-lg"></i></span>
      </b-input-group-prepend>
      <b-form-input
        v-model="filter"
        class="search-input-running"
        size="lg"
        placeholder="Buscar"
        @change="onFilter(filter)"
      >
      </b-form-input>
    </b-input-group>

    <Training
      v-for="item in trainingFiltered"
      :key="item.id"
      :id="item.id"
      :title="item.title"
      :status="item.status"
      :steps="item.steps"
      :item="item"
      :onStepClick="$props.onStepClick"
      :currentStep="$props.currentStep"
    />
  </div>
</template>

<script>
import Training from "../components/training.vue";

export default {
  props: ["onStepClick", "currentStep", "trainings", "eventDisabled"],
  components: {
    Training,
  },
  data() {
    return {
      filter: "",
      trainingFiltered: this.$props.trainings,
    };
  },
  methods: {
    onNextStep(currentStep) {
      const training = this.$props.trainings.find(
        (t) => t.id == currentStep.idTraining
      );
      const nextStep = training.steps.find(
        (s) => s.order >= currentStep.order + 1
      );
      this.$props.onStepClick(nextStep, training.steps);
    },
    onFilter() {
      if (this.filter) {
        this.trainingFiltered = this.$props.trainings.filter((t) =>
          t.title.toLowerCase().includes(this.filter.toLowerCase())
        );
        return;
      }
      this.trainingFiltered = this.$props.trainings;
    },
  },
};
</script>
<style lang="scss" scoped>
.content-training {
  width: 100%;
  display: flex;
  align-items: flex-start;
}
.input-group-text {
  background-color: white !important;
  border-right: 0;
}
.fa-search {
  color: var(--grey-3);
}
.input-group-running {
  margin: 6px 0 6px 0;
}
.search-input-running {
  text-align: left;
  border-left: 0;
}
</style>
