import CooperativeDeclaration from "../../modules/cooperative-declaration/views/CooperativeDeclaration.vue";
import DeclarationINSS from "../../modules/declaration-inss/views/DeclarationINSS.vue";
import DeclarationISS from "../../modules/declaration-iss/views/DeclarationISS.vue";
import DeclarationOthers from "../../modules/declaration-others/views/DeclarationOthers.vue";
import DeclarationLTCATPP from "../../modules/declaration-ltcatpp/views/DeclarationLTCATPP.vue";
import RouterViewRender from "../../utils/routes/router-view-render";
import { PermissionList } from "../../static-data/PermissionList";
import { IsAbleNavigation } from "../utils/is-able-navigation";
import Declarations from "../../modules/declarations/views/Declarations.vue";

const DeclarationsRoutes = {
  path: "declarations",
  component: RouterViewRender,
  beforeEnter: (to, from, next) =>
    IsAbleNavigation(to, from, next, PermissionList.DECLARATIONS_MENU),
  children: [
    {
      path: "",
      component: Declarations,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "cooperative-declaration",
      component: CooperativeDeclaration,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "inss",
      component: DeclarationINSS,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "iss",
      component: DeclarationISS,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "ltcatpp",
      component: DeclarationLTCATPP,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "others",
      component: DeclarationOthers,
      meta: {
        requiresAuth: true,
      },
    },
  ],
};

export default DeclarationsRoutes;
