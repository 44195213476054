const LinkType = {
  SGU_CARD: "https://rda.unimedsc.com.br/cmagnet/Login.do",
  OUR_STORY: "https://www.unimed.coop.br/site/web/florianopolis/historico",
  COLLECTIONS: "https://www.unimed.coop.br/site/web/florianopolis/reconhecimentos",
  TRANSPARENCY_AND_COMMITMENT: "https://www.unimed.coop.br/site/web/florianopolis/transparencia",
  OUR_WAY_OF_CARE: "https://www.unimed.coop.br/site/web/florianopolis/jeitodecuidar",
  DOCUMENT_AND_MINUTES_OF_UGF: "https://cooperado.unimedflorianopolis.com.br/Documentos",
  PEP: "https://pep.pepunimed.com.br/Account/Login?ReturnUrl=%2f#",
  HUB: "https://www.confirmationcall.com.br/confirmationcall-admin-lte/pages/v1/unimed.html",
  CLUB: "https://unimedflorianopolis.redeparcerias.com/",
  ECONOMIC_PANEL: "https://cooperado.unimedflorianopolis.com.br/PainelEconomico",
  BACKBEAT: "https://cooperado.unimedflorianopolis.com.br/Backbeat",
  CONNECTION: "https://sites.google.com/unimedflorianopolis.com.br/conexao-cooperado/pagina-inicial",  
  SURGICAL_CONSENT: "https://www.unimed.coop.br/site/web/florianopolis/formul%C3%A1rios-para-autoriza%C3%A7%C3%A3o",
  RES_ELECTRONIC_HEALTH_RECORD: "https://resweb.appinterall.com.br/AutorizadorMedico/",
  ANT_DUTS: "https://www.gov.br/ans/pt-br/acesso-a-informacao/participacao-da-sociedade",
  NEW_TECNOLOGY: "https://www.cognitoforms.com/UnimedGrandeFlorian%C3%B3polis1/Avalia%C3%A7%C3%A3oDeTecnologiaEmSa%C3%BAde",
  INVOICES: "https://sites.google.com/unimedflorianopolis.com.br/conexao-cooperado/sua-unimed/calend%C3%A1rio-produ%C3%A7%C3%A3o-m%C3%A9dica-2024",
  IP: "https://api.ipify.org?format=json"
};

export default LinkType;
