<template>
  <div class="absence">
    <SimpleLayout
      viewComponent="AbsenceInformations"
      :cardInfo="this.cardInfo"
      :leftAction="this.leftAction"
      :leftLinkNavigation="this.leftLinkNavigation"
    />
  </div>
</template>

<script>
import Vue from "vue";
import SimpleLayout from "../../../layouts/SimpleLayout.vue";
import { ServiceType } from "../../../static-data/ServiceType";
import RoutesPaths from "../../../router/router-structure/routes-paths";

Vue.component("AbsenceInformations", () =>
  import("../components/visualization/AbsenceInformations.vue")
);

export default {
  components: {
    SimpleLayout,
  },
  data: function() {
    return {
      leftAction: {
        text: "Benefícios",
        onClick: this.leftActionClickHandler,
      },
      leftLinkNavigation: this.leftHistoryNavigation,
      cardInfo: {
        headerIcon: "fas fa-map-marker-alt",
        icon: "clipboard-text-outline.svg",
        title: "Afastamento",
        description: "Justifique o motivo e solicite o benefício.",
      },
    };
  },
  methods: {
    leftActionClickHandler() {
      this.$router.push({
        path: RoutesPaths.benefit.rootName(),
        replace: true,
      });
    },
    leftHistoryNavigation() {
      this.$router.push({
        path: RoutesPaths.requestHistory.rootName(),
        params: {
          serviceType: ServiceType.Absence,
        },
      });
    },
  },
};
</script>

<style lang="scss">
.absence {
  .simple-layout--content {
    width: 100%;
  }
}
</style>
