<template>
  <div>
    <AlterFormLayout
      modalId="change-password-modal"
      formTitle="Alterar senha"
      cancelButtonText="Cancelar"
      submitButtonText="Salvar"
      :onSubmitEvent="onSubmit"
      :onCloseEvent="onClose"
      :onCancelEvent="onCancel"
      :disableEvent="this.$store.getters.isAdmin"   
      :noCloseOnEsc="preventCloseOnEsc"
    >
      <template slot="header">
        <h5 class="modal-title custom-title"> Alterar senha</h5>
      </template>
      <template #content>
        <ChangePasswordForm
          :changePasswordForm="changePasswordForm"
          :invalidCurrentPassword="invalidCurrentPassword"
        />
      </template>
    </AlterFormLayout>

    <success-modal
      ref="success-modal"
      description="Senha alterada com sucesso!"
      buttonText="Ok, entendi!"
    />

    <error-modal
      modalId="change-password-error-modal"
      title="Error!"
      submitButtonText="Ok, entendi!"
      ref="error-modal"
      :onAction="onActionErrorModal"
    >
      <template slot="description">
        <div class="justify-content-center mt-5">
          <div>
            <p class="p1 mb-5">
              {{ this.msgErroInvalid }}
            </p>
          </div>
        </div>
      </template>
    </error-modal>
  </div>
</template>

<script>
import AlterFormLayout from "../../../../layouts/AlterFormLayout.vue";
import SuccessModal from "../../../../components/base-modals/SuccessModal.vue";
import ErrorModal from "../../../../components/base-modals/ErrorModal.vue";
import ChangePasswordForm from "./ChangePasswordForm.vue";
import { changePasswordService } from "../../../../services/password-recovery/change-password-service";
import isAbleRole from "../../../../utils/permissions/is-able-role";
import { RoleList } from "../../../../static-data/RoleList";
import { Firebase } from "../../../../services/firebase/log-firebase";
export default {
  computed: {
    RoleList() {
      return RoleList;
    },
  },
  data() {
    return {
      msgErroInvalid: null,
      changePasswordForm: null,
      invalidCurrentPassword: null,
      preventCloseOnEsc: false,
      mustChangePassword: false,
    };
  },
  components: {
    AlterFormLayout,
    SuccessModal,
    ChangePasswordForm,
    ErrorModal,
  },
  beforeDestroy () {
    this.onRemoveEventListener()
  },
  async mounted() {
    await this.checkMustChangePassword();
    this.initLog();
  },
  methods: {
    initLog() {
      this.firebase = new Firebase();
    },
    isAbleRole: isAbleRole,
    onRemoveEventListener() {
      window.removeEventListener('keyup', this.onEscapeKeyUp)
    },
    checkMustChangePassword(){
      if (this.$store.getters.communicationPassword) {
        this.preventCloseOnEsc = true;
        this.mustChangePassword = true;
      }
    },
    async onSubmit(formData) {
      try {
        this.changePasswordForm = {
          username: this.$store.getters.getUserName,
          password: formData.password,
          currentPassword: formData.currentPassword,
        };
        await changePasswordService(this.changePasswordForm);
        const modal = this.$refs["success-modal"].$children[0];
        modal.show();
        this.onClean();
        if(this.mustChangePassword) {
          this.firebase.addInfoLog(
              "- Trocou a senha com sucesso.",
              "COMUNICADO PASSWORD"
            );
          this.$bvModal.show("communication-password-modal");
        }
      } catch (error) {
        if (error.response.data.message == "Senha atual esta incorreta.") {
          this.msgErroInvalid = "Senha atual está incorreta.";
          this.invalidCurrentPassword = false;
        } else {
          if (error.response.data.error_code == "USER_INVALID_PASSWORD") {
            this.msgErroInvalid = error.response.data.message;
          } else {
            this.msgErroInvalid = "Ocorreu um erro ao alterar senha";
          }
        }
        const errorModal = this.$refs["error-modal"].$children[0];
        errorModal.show();
      }
    },
    onActionErrorModal() {
      this.$bvModal.show("change-password-modal");
    },
    onClose() {
      if (this.msgErroInvalid) return;
      this.onClean();
    },
    onCancel() {
      
      if(this.mustChangePassword) {
        this.firebase.addInfoLog(
            "- Clicou em cancelar no formulário de troca de senha.",
            "COMUNICADO PASSWORD"
          );
        this.$bvModal.show("communication-password-modal");
      }
      this.onClean();
    },
    onClean() {
      this.changePasswordForm = null;
      this.msgErroInvalid = null;
      this.invalidCurrentPassword = null;
      this.onRemoveEventListener();
    },
  },
};
</script>
<style lang="scss" scoped>
.custom-title {
  margin-left: 0rem !important;
}
</style>