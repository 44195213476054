<template>
  <div class="file-management">
    <FormLayout
      :tabs="this.tabs"
      :cardInfo="this.cardInfo"
      :leftAction="this.leftAction"
    />
  </div>
</template>

<script>
import Vue from "vue";
import FormLayout from "../../../layouts/FormLayout.vue";
import RoutesPaths from "../../../router/router-structure/routes-paths";

Vue.component("pdf-file-tab", () =>
  import("../components/visualization/PdfFileTab.vue")
);

Vue.component("sheet-file-tab", () =>
  import("../components/visualization/SheetFileTab.vue")
);

Vue.component("documents-atas-tab", () =>
  import("../components/visualization/DocumentsAndAtasTab.vue")
);

export default {
  components: {
    FormLayout,
  },
  data() {
    return {
      leftAction: {
        text: "Menu",
        onClick: this.leftActionClickHandler,
      },
      cardInfo: {
        headerIcon: "far fa-file",
        icon: "medal-outline.svg",
        title: "Gerenciamento de Arquivos",
        description: "Gerenciamento de arquivos.",
      },
      tabs: [
        {
          title: "Importação de PDFs",
          formComponent: "pdf-file-tab",
        },
        {
          title: "Importação de Planilhas",
          formComponent: "sheet-file-tab",
        },
        {
          title: "Documentos e Atas",
          formComponent: "documents-atas-tab",
        },
      ],
    };
  },
  methods: {
    leftActionClickHandler() {
      this.$router.push({
        path: RoutesPaths.contentManagement.rootName(),
      });
    },
  },
};
</script>

<style lang="scss">
.file-management {
  .simple-layout--content {
    width: 75rem;
  }
}
</style>
