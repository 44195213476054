export const MEGA_DEATH_URL = `${process.env.VUE_APP_MEGA_DEATH_URL}`;
export const METALICA_URL = `${process.env.VUE_APP_METALICA_URL}`;
export const SCORPIONS_URL = `${process.env.VUE_APP_SCORPIONS_URL}`;
export const ANTHRAX_URL = `${process.env.VUE_APP_ANTHRAX_URL}`;
export const AUTH_URL = `${process.env.VUE_APP_AUTH_URL}`;
export const SABBATH_URL = `${process.env.VUE_APP_SABBATH_URL}`;
export const OPETH_URL = `${process.env.VUE_APP_OPETH_URL}`;
export const SLIPKNOP_URL = `${process.env.VUE_APP_SLIPKNOP_URL}`;
export const MANOWAR_URL = `${process.env.VUE_APP_MANOWAR_URL}`;
export const GORIJA_URL = `${process.env.VUE_APP_GORIJA_URL}`;
export const KREATOR_URL = `${process.env.VUE_APP_KREATOR_URL}`;
export const GENESIS_URL = `${process.env.VUE_APP_GENESIS_URL}`;
