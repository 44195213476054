<template>
  <div class="password-container">
    <div class="password-card-container">
      <div class="password-wecolme">
        <h2>Esqueci minha senha</h2>
        <div class="separator-line"></div>
        <p class="p2 mt-3">
          Para redefinir sua senha informe os dados solicitados abaixo. Atenção!
          O e-mail deve ser o mesmo do seu cadastro.
        </p>
      </div>
      <div class="mt-5">
        <b-form>
          <b-card class="password-card card-border--1">
            <b-card-body>
              <CRM @setLista="lista" @setCRM="crm" v-if="!listaEmail" />
              <Email
                :listaEmail="listaEmail"
                :crm="crmUser"
                @setSucess="setSucess"
                v-if="listaEmail && !sucess && crmUser"
              />
              <Sucess v-if="sucess" />
            </b-card-body>
          </b-card>
        </b-form>
      </div>
    </div>
  </div>
</template>

<script>
import Sucess from "../components/forms/Sucess.vue";
import Email from "../components/forms/Email.vue";
import CRM from "../components/forms/CRM.vue";

export default {
  data() {
    return {
      listaEmail: null,
      crmUser: null,
      sucess: false,
    };
  },
  components: {
    Sucess,
    Email,
    CRM,
  },
  methods: {
    lista(n) {
      this.listaEmail = n;
    },
    crm(n) {
      this.crmUser = n;
    },
    setSucess(n) {
      this.sucess = n;
    },
  },
};
</script>

<style lang="scss" scoped>
.password-card-container {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  width: 55rem;
}

.password-card {
  width: 44rem;
  height: 41rem;
  padding: 4rem 5rem;
  @include header5;
}

.password-container {
  display: flex;
  width: 100%;
  height: 100%;
  background-image: url("../../../assets/images/passwordRecovery/password.svg");
  background-repeat: no-repeat;

  @include media-breakpoint-up(xs) {
    justify-content: center;
    background-position: right bottom;
    background-size: 90% 50%;
  }

  @include media-breakpoint-up(sm) {
    justify-content: center;
    background-position: right bottom;
    background-size: 90% 50%;
  }

  @include media-breakpoint-up(md) {
    justify-content: center;
    background-position: right bottom;
    background-size: 70% 50%;
  }

  @include media-breakpoint-up(lg) {
    justify-content: initial;
    background-position: 60% 30%;
    background-size: 60% 60%;
  }

  @include media-breakpoint-up(xl) {
    justify-content: initial;
    background-position: 80% 30%;
    background-size: 60% 60%;
  }
}

.password-wecolme {
  max-width: 43rem;
  text-align: center;
}
.icon {
  font-size: 2rem;
}

.button {
  margin-top: 15rem;
  width: 12rem;
  height: 4rem;
}

.invalid {
  color: #dc3545;
  margin-top: 0.8rem;
}
</style>
