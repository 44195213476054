import store from "../../store";
import axios from "../http-client/axios";
import { GORIJA_URL } from "../urls/base-urls";

export async function getAnalyticsAfterSelectingPeriod(crm, year, month) {
  if (checkUseCache(year, month)) {
    return store.getters.getAnalyticalData;
  }

  const reponseAPi = await getAnalyticsSummary(crm, year, month);
  const { period } = reponseAPi;
  const requests = requestAnalyticalMapper(reponseAPi, true, period);
  return requests;
}

export async function getAnalyticsAfterLogin(crm, year, month) {
  const reponseAPi = await getAnalyticsSummary(crm, year, month);
  const { period } = reponseAPi;
  const requests = requestAnalyticalMapper(reponseAPi, true, period);
  store.dispatch("setStoreAnalyticalData", requests);
  return requests;
}

export async function getAnalyticsForceUpdate(crm, year, month) {

  if(!store.getters.getAnalyticalData)
    return null;

  const { competence } = store.getters.getAnalyticalData;

  if (year == null || competence == month.concat("/").concat(year)) {
    return await getAnalyticsAfterLogin(crm, year, month);
  }

  return null;
}

async function getAnalyticsSummary(crm, year, month) {
  let url = "";

  if (year && month) url = `/year/${year}/month/${month}`;

  const resp = await axios({
    url: `${GORIJA_URL}/financial-summary${url}`,

    method: "get",
    data: {},
    headers: {
      "content-type": "application/json",
    },
  });

  return resp.data;
}

function requestAnalyticalMapper(analytical, updateDate, competence) {
  let requests = {
    analytical: analytical,
    competence: competence,
    dataAnalytical: updateDate
      ? new Date().toLocaleString("pt-br")
      : store.getters.getAnalyticalData.dataAnalytical,
  };

  return requests;
}

function checkUseCache(year, month) {

  if(!store.getters.getAnalyticalData)
    return null;

  const { competence, analytical } = store.getters.getAnalyticalData;

  if (analytical != null && year == null && month == null)
    return store.getters.getAnalyticalData;

  return (
    year != null &&
    month != null &&
    competence != null &&
    competence == month.concat("/").concat(year)
  );
}