<template>
  <div>
    <div class="password-wecolme">
      <img src="@/assets/icons/mail.svg" />
      <h1 class="font-weight-bold mt-4">
        Pronto! Enviamos um e-mail para você
      </h1>
    </div>
    <div>
      <p class="p2 mt-4">
        Dentro de alguns instantes você receberá um e-mail com um link para
        redefinir sua senha. Verifique a caixa de spam ou lixo eletrônico.
      </p>
      <div class="mt-4">
        <b-button @click="onOk()" class="button" variant="primary" id="ok"
          >Ok, entendi!
        </b-button>
      </div>
    </div>
  </div>
</template>
<script>
import RoutesPaths from "../../../../router/router-structure/routes-paths";

export default {
  methods: {
    onOk() {
      this.$router.push(RoutesPaths.login.rootName());
    },
  },
};
</script>

<style lang="scss" scoped>
.password-wecolme {
  max-width: 43rem;
  text-align: center;
}
.button {
  margin-top: 2rem;
  width: 12rem;
  height: 4rem;
}
</style>
