export function anchorBase64Download(
  isApp,
  mimeType,
  fileNameWithExtension,
  fileData
) {
  var a = document.createElement("a");
  
  a.download = fileNameWithExtension;
  a.href = `data:${mimeType};name:${fileNameWithExtension};base64,${fileData}`;
  
  a.setAttribute("target", "_blank");
  a.click();
}
