// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../../assets/images/ilus_analitica.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".margin[data-v-0afa234c]{margin-top:2.2rem}.span[data-v-0afa234c]{display:flex;width:100%;height:.15rem;border-radius:20%;background-color:var(--light-4)}.imagem-analytics[data-v-0afa234c]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-repeat:no-repeat;background-position:85% 90%}.flex-container[data-v-0afa234c]{max-width:70rem;display:grid;border-style:none;grid-column:0;justify-content:center;align-items:center}@media (min-width:725px){.flex-container[data-v-0afa234c]{grid-template-columns:auto 300px;justify-content:normal;align-items:normal}}.custom-title[data-v-0afa234c]{text-align:left}@media (max-width:577px){.custom-title[data-v-0afa234c]{text-align:center!important}}", ""]);
// Exports
module.exports = exports;
