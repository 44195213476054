import axios from "../http-client/axios";
import { GORIJA_URL } from "../urls/base-urls";

export async function getResultVoxis(crm, month, year) {
  let url = `crm=${crm}&month=${month}&year=${year}`;
  if (year === null) {
    url = `crm=${crm}`;
  }

  const resp = await axios({
    url: `${GORIJA_URL}/gloss-report?${url}`,
    method: "get",
    data: {},
  });

  return resp.data;
}
