<template>
  <b-card
    :class="isMobile() ? 'custom-accordion-mobile' : 'custom-accordion'"
    no-body
  >
    <b-card-header header-tag="header" role="tab">
      <div
        class="d-flex"
        @click="setVisible"
        :class="visible ? null : 'collapsed'"
        :aria-expanded="visible"
        :aria-controls="collapseId"
      >
        <div class="custom-header">
          <slot name="header"> </slot>
        </div>
        <div class="ml-auto custom-slot-header-button">
          <slot name="header-button"> </slot>
          <i
            v-show="!visible"
            class="fas fa-angle-down text-primary p2 custom-fa-angle"
          />
          <i
            v-show="visible"
            class="fas fa-angle-up text-primary p2 custom-fa-angle"
          />
        </div>
      </div>
    </b-card-header>
    <b-collapse
      :id="collapseId"
      accordion="custom-accordion"
      role="tabpanel"
      v-model="visible"
    >
      <b-card-body>
        <slot name="content"> </slot>
      </b-card-body>
    </b-collapse>
  </b-card>
</template>

<script>
import { ref } from "@vue/composition-api";
import { isMobile } from "../../utils/mobile/mobile";

export default {
  props: ["collapseId", "collapseText", "visibleIfOnlyOne"],
  methods: {
    isMobile: isMobile,
  },
  setup(props) {
    const visible = ref(false);

    const setVisible = () => {
      visible.value = !visible.value;
      return visible;
    };
    if (props.visibleIfOnlyOne) {
      setVisible(props.visibleIfOnlyOne);
    }
    return { setVisible, visible };
  },
};
</script>

<style lang="scss" scoped>
.card-header {
  background: white;
  border-color: transparent;
  border-radius: 1rem !important;
  padding-bottom: 0rem;
  padding-left: 0rem;
  padding-top: 0rem;
}
div.card-body {
  padding: 0rem;
}
div.card {
  border-color: transparent;
  border-radius: 1rem;
}

.custom-accordion {
  padding: 1rem 4rem 1rem 4rem;
}
.custom-accordion-mobile {
  padding: 1rem 0rem 1rem 0rem;
}
.custom-slot-header-button {
  display: flex;
}
.custom-fa-angle {
  margin-top: 1rem;
}
.custom-header {
  margin: auto 0px auto 0px;
}
</style>
