<template>
  <div class="divider" />
</template>

<script>
export default {
  name: "DropdownDivider",
};
</script>

<style lang="scss">
.divider {
  padding: 0.5rem 0rem 0.5rem 0rem;
  background: white;
}
</style>
<div class="divider"></div>
