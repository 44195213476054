import { formatMoney } from "../../utils/format/formtUtils";
import axios from "../http-client/axios";
import { GORIJA_URL } from "../urls/base-urls";

export async function getDetailsBackbeatAPI(crm, year, month) {
  let url = "";
  if (year && month) url = `&year=${year}&month=${month}`;

  const resp = await axios({
    url: `${GORIJA_URL}/backbeat?crm=${crm}${url}`,

    method: "get",
    data: {},
    headers: {
      "content-type": "application/json",
    },
  });
  if (resp.data) {
    return mapperResponse(resp.data);  
  }
  
}

function mapperResponse(response) {
  response.amountPaid = parseAmountPaid(response.amountPaid);
  response.resultCollective = parseValues(response.resultCollective);
  response.goal = parseValues(response.goal);
  response.lossRatio = parseValues(response.lossRatio);
  response.additionalGainPerformance = parseValues(
    response.additionalGainPerformance
  );
  response.additionalGainTechnologicalPackage = parseValues(
    response.additionalGainTechnologicalPackage
  );
  response.additionalGainTechnicalEfficiency = parseValues(
    response.additionalGainTechnicalEfficiency
  );

  response.technicalEfficiency.forEach((element) => {
    element.reachedGoal = parseBooleanToString(element.reachedGoal);
    element.resultSpecialty = parseValues(element.resultSpecialty, true);
    element.resultIndividual = parseValues(element.resultIndividual, true);
  });

  return response;
}

function parseBooleanToString(value) {
  return value ? "Sim" : "Não";
}

function parseAmountPaid(value) {
  return formatMoney(value);
}

function parseValues(value, twoZero) {
  if (!value.includes(".")) {
    const sizeZero = twoZero == true ? ",00%" : ",0%";
    return value + sizeZero;
  }

  return value.replace(".", ",") + "%";
}
