import isAble from "../../utils/permissions/is-able";
import RoutesPaths from "../router-structure/routes-paths";
import store from "../../store/index";

export const IsAbleNavigation = (_to, _from, next, permission) => {
  if (!isAble(permission) && !store.getters.isAdmin) {
    next(RoutesPaths.home.rootName());
  } else if (!isAble(permission) && store.getters.isAdmin) {
    next(RoutesPaths.contentManagement.rootName());
  } else {
    next();
  }
};
