<template>
  <div>
    <TableVisualizationLayout :leftInfo="leftInfo" :leftAction="leftAction" :isCenterTitle="true">
      <template slot="headerContent">
        <div class="wrapper my-4">
          <div class="custom-div-competence">
            <div
              class="image-class"
              @click="onClickNotasFicais()"
            >
              <div class="img-custon-principal">
                <img
                  class="img-custon"
                  src="@/assets/images/MDI-calendar-month.svg"
                />
              </div>
              <div class="text-custom d-flex align-items-center">
                Calendário de entrega de Notas Fiscais
              </div>
            </div>
          </div>
          <div class="d-flex align-items-center">
            <div class="competence-w">
              <div class="d-flex align-items-center">COMPETÊNCIA:</div>
              <div>
                <b-select v-model="year" :options="yearsOptions" size="lg" />
              </div>
            </div>
          </div>
        </div>
      </template>
      <template slot="table">
        <b-table
          :items="items"
          v-show="!this.loading"
          responsive
          show-empty
          empty-text="Nenhuma informação para exibir!"
        >
          <template v-slot:head(valor)="">
            Valor da Nota Fiscal
          </template>
          <template v-slot:head(mes)="">
            Mês
          </template>
          <template v-slot:head(status)="">
            <div class="status-align">Status</div>
          </template>
          <template #cell(mes)="data">
            {{ data.item.mes }}
          </template>
          <template #cell(valor)="data">
            <div class="text-valor">
              {{ data.item.valor }}
            </div>
          </template>
          <template #cell(status)="data">
            <div class="status-align">
              <img
                src="@/assets/icons/check-circle-outline.svg"
                data-toggle="tooltip"
                data-placement="top"
                v-show="data.item.status == 'Integrada'"
              />

              <div v-show="data.item.status == 'Pendente'">
                <span id="disabled-wrapper" class="d-inline-block" tabindex="0">
                  <img
                    :id="'button-' + data.index + 1"
                    src="@/assets/icons/close-circle-outline.svg"
                  />
                </span>
                <b-tooltip placement="top" :target="'button-' + data.index + 1"
                  >Identificamos que não há nota fiscal cadastrada para essa
                  competência, reforçamos que o não envio bloqueará o pagamento
                  dos honorários nas competências seguintes.
                </b-tooltip>
              </div>
              <div
                v-show="
                  data.item.status == 'Sem pagamento PF para a competência'
                "
              >
                <span id="disabled-wrapper" class="d-inline-block" tabindex="0">
                  <img
                    :id="'PF-' + data.index + 1"
                    src="@/assets/icons/circle-off-outline.svg"
                  />
                </span>
                <b-tooltip placement="top" :target="'PF-' + data.index + 1">
                  Sem pagamento PF para a competência
                </b-tooltip>
              </div>
            </div>
          </template>
        </b-table>
        <div v-show="this.loading">
          <b-skeleton-table :rows="12" :columns="3"></b-skeleton-table>
        </div>
      </template>
    </TableVisualizationLayout>
  </div>
</template>

<script>
import TableVisualizationLayout from "../../../layouts/TableVisualizationLayout.vue";
import { getRequestHistory } from "../../../services/income-history/income-history";
import { isMobile } from "../../../utils/mobile/mobile";
import { downloadBase64File } from "../../../services/pdf-download/pdf-download-services";
import { anchorBase64Download } from "../../../utils/anchor-base64-download/anchor-base64-download";
import LinkType from '../../../static-data/Links';

export default {
  components: {
    TableVisualizationLayout,
  },
  mounted() {
    this.selectedOption = { year: this.year };
    this.getHistory(this.year);
  },
  watch: {
    year: {
      handler(newValue) {
        this.getHistory(newValue);
      },
    },
  },
  computed: {
    yearsOptions: () => {
      let years = [];
      for (var n = 2017; n <= new Date().getFullYear(); n++) {
        years.push(n);
      }
      return years.reverse();
    },
  },
  data() {
    return {
      year: new Date().getFullYear(),
      leftAction: {
        text: "Histórico de Notas Fiscais",
        onClick: this.leftActionClickHandler,
      },
      leftInfo: "",
      fields: [],
      items: [],
      selectedViewRequestItem: null,
      requestTypeDescription: "",
      selectedAttachments: [],
      loading: true,
      downloadedFile: null,
      fileUrl: null,
      selectedOption: null,
    };
  },
  methods: {
    isMobile: isMobile,
    async getHistory(year) {
      try {
        this.loading = true;

        const crm = this.$store.getters.getUserName;
        this.items = await getRequestHistory(crm, year);

        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.error("getHistory", error);
      }
    },
    leftActionClickHandler() {
      this.$router.go(-1);
    },
    async onClickNotasFicais() {
      this.onWindowsOpen(LinkType.INVOICES);
    },
    onWindowsOpen(link) {
      window.open(link, "_blank");
    },
    downloadPdf() {
      if (!this.downloadedFile) return;
      anchorBase64Download(
        this.$store.getters.isApp,
        "application/pdf",
        "fundo-sustentabilidade.pdf",
        this.downloadedFile
      );
    },
    async getDocumentHandler() {
      const download = await this.downloadFile();
      this.downloadedFile = download;
    },
    async downloadFile() {
      try {
        const response = await downloadBase64File(
          this.$store.getters.getUserName,
          "CALENDAR_INVOICES",
          this.year
        );

        this.fileUrl = response.fileUrl;
        return response.document;
      } catch (error) {
        console.error("Error on function downloadFile:", error);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.table-icon-size {
  font-size: 1.8rem;
}
.table th,
.table td {
  padding-left: 2.5em;
}
.status-align {
  text-align: center;
}
.tooltip-inner {
  background-color: #6a6a6a;
}
div.align-select-left select.custom-select {
  text-align: right;
}
div.text-valor {
  font-weight: bold;
  margin-left: 4px;
}
div.competence {
  color: var(--grey-2);
}

option {
  direction: rtl;
}

.wrapper {
  display: grid;
  width: 100%;
  padding-bottom: 0;
}

.img-custon-principal {
  padding-top: 1.5rem;
  padding-left: 1.5rem;
}

@media (min-width: 530px) {
  .wrapper {
    grid-template-columns: auto 210px;
  }

  .custom-div-competence {
    margin-bottom: 0rem;
  }

  .image-class {
    width: 21.3rem;
  }
}

.competence-w {
  display: grid;
  width: 100%;
  grid-template-columns: 70px auto;
}

.image-class {
  cursor: pointer;
  height: 6rem;
  border-radius: 1rem;
  background-color: white;
  display: grid;
  grid-template-columns: 55px auto;
}

.img-custon {
  height: 2.7rem;
  width: 2.4rem;
}

.text-custom {
  color: var(--dark-0);
  font-size: multiplier-default-font-size(2); // 14
}

.custom-div-competence {
  margin-bottom: 2rem;
}

.image-class:hover {
  border: 0.1rem solid #0a5f55 !important;
  text-decoration: none;
}
</style>
