<template>
  <b-card class="custom-accordion">
    <b-card-header header-tag="header" role="tab">
      <div
        class="d-flex"
        @click="setVisible"
        :class="visible ? null : 'collapsed'"
        :aria-expanded="visible"
        :aria-controls="collapseId"
      >
        <div class="custom-align">
          <slot name="header"> </slot>
        </div>
        <div class="ml-auto custom-align">
          <slot name="header-button"> </slot>
          <i v-show="!visible" class="fas fa-angle-down text-primary p2" />
          <i v-show="visible" class="fas fa-angle-up text-primary p2" />
        </div>
      </div>
    </b-card-header>
    <b-collapse
      :id="collapseId"
      accordion="custom-accordion"
      role="tabpanel"
      v-model="visible"
    >
      <b-card-body>
        <slot name="content"> </slot>
      </b-card-body>
    </b-collapse>
  </b-card>
</template>

<script>
import { ref } from "@vue/composition-api";
import { isMobile } from "../../utils/mobile/mobile";

export default {
  props: ["collapseId", "collapseText"],
  methods: {
    isMobile: isMobile,
  },
  setup() {
    const visible = ref(true);

    const setVisible = () => {
      visible.value = !visible.value;
      return visible;
    };

    return { setVisible, visible };
  },
};
</script>

<style lang="scss" scoped>
.card-header {
  background: white;
  cursor: pointer;
  border-color: transparent;
}

div.card {
  border-color: transparent;
  padding: 0rem;
  border-radius: 1rem;
}

div.card-body {
  padding: 0rem;
}

.custom-align {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
