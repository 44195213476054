<script>
import CustomCardInfo from "../../../../components/custom-info-card/CustomInfoCard.vue";
import VisualizationTemplate from "../../../../templates/VisualizationTemplate.vue";
// import NavigationButton from "../../../     components/navigation-button/NavigationButton.vue";
import NavigationButton from "../../../../components/navigation-button/NavigationButton.vue";

export const FormLayout = {
  props: ["leftInfo", "leftAction", "content", "showTable", "isShow"],
  components: {
    VisualizationTemplate,
    CustomCardInfo,
    NavigationButton,
  },

  render() {
    const invalidHandler = (e) => {
      console.warn("Invalid handler!", e);
    };

    const leftAction = {
      text: "Invalid Text!",
      onClick: invalidHandler,
      ...this.$props.leftAction,
    };

    const leftInfo = this.$props.leftInfo;

    return (
      <VisualizationTemplate isShowseparator={false}>
        <template slot="leftAction">
          <div class="d-flex justify-content-between custom-header">
            <NavigationButton
              actionClickHandler={leftAction.onClick}
              underLine={false}
            >
              <template slot="icon">
                <i class="fas fa-arrow-left"></i>
              </template>
              <template slot="text">{leftAction.text}</template>
            </NavigationButton>
            <div>{this.$slots.rightAction}</div>
          </div>
        </template>

        <template slot="leftInfo">
          <p class="p4">{leftInfo}</p>
        </template>

        <template slot="headerContent">{this.$slots.headerContent}</template>
        <template slot="content"></template>
        <template slot="footerContent">{this.$slots.footerContent}</template>
      </VisualizationTemplate>
    );
  },
};

export default FormLayout;
</script>

</style>

<style lang="scss" scoped>
.custom-header { 
  width: 97.6rem;
  max-width: 97.6rem;
  width: 100%;
  padding-left: 1.5rem;
}

</style>

