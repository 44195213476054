<template>
  <div class="page-container">
    <div>
      <section class="page-header">
        <h2 class="font-weight-bold">
          Declarações
        </h2>
      </section>

      <div class="separator-line"></div>
      <section class="sub-header">
        <p>
          Aqui você poderá solicitar e entregar as declarações de forma prática
          e ágil.
        </p>
      </section>
    </div>
    <section>
      <div class="card-container">
        <CustomInfoCard
          class="deck deck-margin"
          v-for="card in getCards"
          :key="card.id"
          :onCardClick="() => onClickCard(card.route)"
          :title="card.header"
          :description="card.description"
          :icon="card.icon"
        />
      </div>
    </section>
  </div>
</template>

<script>
import CustomInfoCard from "../../../components/custom-info-card/CustomInfoCard.vue";
import RoutesPaths from "../../../router/router-structure/routes-paths";

export default {
  components: {
    CustomInfoCard,
  },
  computed: {
    getCards: () => [
      {
        id: 0,
        icon: "file-document-outline.svg",
        header: "Declaração de Médico Cooperado",
        description: "Acesse a sua declaração com apenas um clique",
        route: RoutesPaths.declarations.cooperativeDeclaration(),
      },
      {
        id: 1,
        icon: "file-document-outline.svg",
        header: "Declaração de histórico de retenção de INSS",
        description: "Solicite declaração de contribuição pela UGF",
        route: RoutesPaths.declarations.inss(),
      },
      {
        id: 2,
        icon: "file-document-outline.svg",
        header: "Declaração de histórico de retenção de ISS",
        description: "Solicite declaração de retenção de ISS",
        route: RoutesPaths.declarations.iss(),
      },
      {
        id: 3,
        icon: "file-document-outline.svg",
        header: "Declaração LTCAT/PPP",
        description:
          "Solicite o LTCAT (Laudo Técnico das Condições do Ambiente de Trabalho) ou PPP (Perfil Profissiográfico Previdenciário).",
        route: RoutesPaths.declarations.ltcatpp(),
      },
      {
        id: 4,
        icon: "file-document-outline.svg",
        header: "Solicitar outras declarações",
        description:
          "Caso não tenha encontrado a opção para solicitar a declaração desejada, acesse aqui.",
        route: RoutesPaths.declarations.others(), // criar rota
      },
    ],
  },
  methods: {
    onClickCard(route) {
      this.$router.push(route);
    },
  },
};
</script>

<style lang="scss" scoped>
.deck-margin {
  margin: 2rem;
}

.page-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.page-header {
  text-align: center;
}

.sub-header {
  max-width: 83rem;
  margin: 0 auto;
  margin-top: 1.5rem;
  text-align: center;
}

.card-container {
  max-width: 90rem;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  @include media-breakpoint-up(xs) {
    justify-content: center;
  }

  @include media-breakpoint-up(md) {
    justify-content: center;
  }
}
</style>
