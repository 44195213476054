import EmptyHome from "../../modules/home/views/EmptyHome.vue";
import RouterViewRender from "../../utils/routes/router-view-render";

const EmptyHomeRoutes = {
  path: "",
  component: RouterViewRender,
  meta: {
    requiresAuth: true,
  },
  children: [
    {
      path: "empty-home",
      component: EmptyHome,
      meta: {
        requiresAuth: true,
      },
    },
  ],
};

export default EmptyHomeRoutes;
