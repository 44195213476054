<template>
  <div class="navigation">
    <div class="navigation--button">
      <div @click="this.$props.actionClickHandler">
        <slot name="icon"></slot>
      </div>
      <div class="navigation--button--content">
        <h2>
          <slot name="text"></slot>
        </h2>
        <div class="separator-line" v-show="this.$props.underLine"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    actionClickHandler: {
      type: Function,
      default: (e) => {
        console.warn("Invalid click! default click handler was defined!", e);
      },
    },
    underLine: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.navigation {
  display: flex;
  flex-direction: column;

  &--button {
    display: flex;
    align-items: center;

    div > i {
      cursor: pointer;
      color: var(--grey-1);
      font-size: 2.3rem;
    }

    &--content {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-left: 1.4rem;

      p {
        color: var(--dark-0);
        font-size: 2.4rem;
      }

      span {
        display: flex;
        width: 3.5rem;
        height: 0.25rem;
        border-radius: 20%;
        background-color: var(--secondary-green);
      }
    }
  }
}
</style>
