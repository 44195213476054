import { getFirestore } from "firebase/firestore/lite";
import { initializeApp } from "firebase/app";
import {
  APP_FIREBASE_API_ID,
  APP_FIREBASE_APP_KEY,
  APP_FIREBASE_AUTH_DOMAIN,
  APP_FIREBASE_DATABASE_URL,
  APP_FIREBASE_MEASURE_MENT_ID,
  APP_FIREBASE_MESSAGING_SENDER_ID,
  APP_FIREBASE_PROJECT_ID,
  APP_FIREBASE_STORAGE_BUCKET,
} from "../urls/enviroment-variables";

//Retorna a mesma instacia criada (Singleton)
var FirebaseClient = (function() {
  var instance;

  function createInstance() {
    const FIREBASE_CONFIGURATION = {
      apiKey: APP_FIREBASE_APP_KEY,
      authDomain: APP_FIREBASE_AUTH_DOMAIN,
      databaseURL: APP_FIREBASE_DATABASE_URL,
      projectId: APP_FIREBASE_PROJECT_ID,
      storageBucket: APP_FIREBASE_STORAGE_BUCKET,
      messagingSenderId: APP_FIREBASE_MESSAGING_SENDER_ID,
      appId: APP_FIREBASE_API_ID,
      measurementId: APP_FIREBASE_MEASURE_MENT_ID,
    };

    const app = initializeApp(FIREBASE_CONFIGURATION);
    return getFirestore(app);
  }

  return {
    getInstance: function() {
      if (!instance) {
        instance = createInstance();
      }
      return instance;
    },
  };
})();

export default FirebaseClient;
