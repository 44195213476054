import axios from "../http-client/axios";
import { MANOWAR_URL } from "../urls/base-urls";

export async  function getLinkTelemedicina(crm, valor) {
  const resp = await axios({
    url: `${MANOWAR_URL}/tele-medicine?crm=${crm}&term=${valor}`,
    method: "get",
    data: {},
  });
  
  if (resp.data.deeplink)
    return resp.data.deeplink;
  return;
}

export async function getLinkPedidoMedico(crm) {
  const resp = await axios({
    url: `${MANOWAR_URL}/medical-order?crm=${crm}`,
    method: "get",
    data: {},
  });

  return resp.data.deepLink;
}


export async function getUserInformation(crm) {
  const resp = await axios({
    url: `${MANOWAR_URL}/tele-medicine/user-information?crm=${crm}`,
    method: "get",
    data: {},
  });

  return resp.data;
}
