<template>
  <div class="principal">
    <DependentInclusionRulesModal />
    <ListBoletoModal />
    <DemonstrativeAmountsPaidModal />
    <CustomVisualizationLayout
      :leftInfoOne="leftInfoOne"
      :leftActionOne="this.leftActionOne"
      :leftInfoTwo="leftInfoTwo"
      :leftActionTwo="this.leftActionTwo"
    >
      <template slot="contentOne">
        <p class="p3">
          <b
            >Você sabia que um dos benefícios de ser um cooperado da UGF é ter
            direito ao PLAC (plano de saúde do cooperado) e que a cooperativa
            subsidia parte do valor na mensalidade do titular?</b
          >
        </p>
        <div class="align-reflesh paddind-space">
          <img
            v-show="true"
            class="mdi-hospital"
            src="@/assets/images/mdi-hospital.svg"
          />
          <p class="p5">Como aderir</p>
        </div>
        <div class="paddind-space-left">
          <p class="p3">
            Os cooperados podem a qualquer momento, solicitar a adesão ou migrar
            de um plano vigente para o plano de saúde do cooperado, deverá ser
            realizada pelo Contato: 0800 048 35 00 - opção 5
          </p>

          <a class="a1" v-b-modal:add-speciality-modal
            >Acesse aqui as regras para inclusão de dependentes.</a
          >
        </div>
        <div class="align-reflesh paddind-space">
          <img
            v-show="true"
            class="mdi-hospital"
            src="@/assets/images/mdi-hospital.svg"
          />
          <p class="p5">Mensalidade e coparticipação</p>
        </div>
        <div class="paddind-space-left">
          <p class="p3">
            A cooperativa subsidia parte do valor na mensalidade do titular,
            conforme deliberação em Assembleia Geral.
          </p>
          <p class="p3">
            Valores referentes à coparticipação e mensalidade de seus
            dependentes deve seguir tabela.
          </p>
          <p class="p3">
            Além do subsídio da mensalidade, o titular não terá coparticipação.
          </p>
        </div>
        <div class="align-reflesh paddind-space">
          <img
            v-show="true"
            class="mdi-hospital"
            src="@/assets/images/mdi-hospital.svg"
          />
          <p class="p5">Coberturas e abrangência</p>
        </div>
        <div class="paddind-space-left">
          <p class="p3">
            O Plano Médico Cooperado possui abrangência Nacional e segue todas
            as coberturas, regras e condições inerentes ao plano contratado, bem
            como as normas, regulamentações e legislações vigentes aplicáveis
            pela ANS - Agência Nacional de Saúde.
          </p>
        </div>
        <div class="align-reflesh paddind-space">
          <img
            v-show="true"
            class="mdi-hospital"
            src="@/assets/images/mdi-hospital.svg"
          />
          <p class="p5">Direitos</p>
        </div>
        <div class="paddind-space-left">
          <p class="p3">
            Em todo e qualquer atendimento coberto e realizado em caráter de
            urgência e emergência realizado ao médico cooperado titular e/ou
            seus dependentes no Plano Médico Cooperado, em hospitais com tabela
            própria, os custos daí provenientes serão reembolsados de acordo com
            as tabelas de remuneração e pagamentos à rede prestadora da
            Cooperativa, as quais podem divergir daquelas praticadas pelo
            hospital em que se deu o atendimento.
          </p>
          <p class="p3 paddind-space">
            A solicitação de reembolso deverá ser realizada diretamente pela
            Equipe de Relacionamento com o cooperado que terá o prazo de 30
            (trinta) dias para realizar o reembolso, contados da data de entrega
            da documentação necessária. O cooperado perderá o direito do
            reembolso, caso as solicitações se deem após 12 (doze) meses da data
            do atendimento.
          </p>
          <p class="p3 paddind-space">
            Importante informar que, os reembolsos para as demais despesas não
            contempladas, deverão se limitar exclusivamente aos procedimentos
            com cobertura no Rol da ANS, observando todas as regras e condições
            inerentes ao plano contratado, bem como as normas, regulamentações e
            legislações vigentes aplicáveis.
          </p>
          <p class="p3 paddind-space">
            As solicitações de autorização de exames e procedimentos, poderão
            ser realizadas através do APP - aplicativo do cliente, e-mail e
            whatsapp.
          </p>
        </div>
        <div class="align-reflesh paddind-space">
          <img
            v-show="true"
            class="mdi-hospital"
            src="@/assets/images/mdi-hospital.svg"
          />
          <p class="p5">Deveres</p>
        </div>
        <div class="paddind-space-left">
          <p class="p3">
            Poderão perder o direito ao subsídio, aqueles médicos cooperados que
            não apresentem produção equivalente, no mínimo, ao valor bruto de
            300 (trezentas) consultas médicas no período de 12 (doze) meses.
          </p>
          <p class="p3 paddind-space">
            O plano médico cooperado será automaticamente cancelado quando o
            cooperado deixar de efetuar o devido pagamento da parte que lhes
            cabe, ou das coparticipações e mensalidades referentes aos seus
            dependentes, por período superior a 60 (sessenta) dias.
          </p>
        </div>
      </template>
      <template id="contentTwo" class="contentTwo" slot="contentTwo">
        <div class="p2 links upperCase">
          <div v-b-modal:list-boleto-modal class="links--internal">
            2ª via do boleto
            <img
              v-show="true"
              class="mdi-chevron-right"
              src="@/assets/images/mdi-chevron-right.svg"
            />
          </div>
        </div>
        <div class="p2 links upperCase">
          <div
            v-b-modal:demonstrative-amounts-paid-modal
            class="links--internal"
          >
            Demonstrativo de valores pagos
            <img
              v-show="true"
              class="mdi-chevron-right"
              src="@/assets/images/mdi-chevron-right.svg"
            />
          </div>
        </div>
        <div class="img-coracao">
          <img
            id="id-img-coracao"
            v-show="true"
            class="unimed-coracao"
            src="@/assets/images/unimed-coracao.svg"
          />
        </div>
      </template>
    </CustomVisualizationLayout>
  </div>
</template>

<script>
import RoutesPaths from "../../../router/router-structure/routes-paths";
import CustomVisualizationLayout from "../components/visualization/CustomVisualizationLayout.vue";
import DependentInclusionRulesModal from "../views/DependentInclusionRulesModal.vue";

import ListBoletoModal from "../views/ListBoletoModal.vue";
import DemonstrativeAmountsPaidModal from "../views/DemonstrativeAmountsPaidModal.vue";

export default {
  components: {
    CustomVisualizationLayout,
    DependentInclusionRulesModal,
    ListBoletoModal,
    DemonstrativeAmountsPaidModal,
  },
  data: function() {
    return {
      leftActionOne: {
        text: "Conheça mais sobre o plano de saúde dos Médicos Cooperados",
        onClick: this.leftActionClickHandler,
      },
      leftInfoOne: "",
      leftActionTwo: {
        text: "Acesso rápido",
        onClick: null,
      },
      leftInfoTwo: "",
    };
  },
  methods: {
    leftActionClickHandler() {
      this.$router.push({
        path: RoutesPaths.benefit.rootName(),
        replace: true,
      });
    },
  },
};
</script>

<style lang="scss">
.principal {
  background-color: var(--background);
  height: 100%;
}

.div-img-coracao {
  height: 100%;
}

.T5 {
  font-size: multiplier-default-font-size(2); // 14
  font-weight: bold;
  color: var(--dark-0);
}

.contentTwo {
  display: flex;
  flex-flow: column;
  height: 100vh;
  padding-left: 0rem !important;
  width: 100% I !important;
}

.paddind-space {
  padding-top: 1.5rem;
}

.paddind-space-left {
  padding-left: 2.5rem;
}

.align-reflesh {
  display: flex;
  justify-content: left;
  flex-direction: row;
  align-items: center;
}

.links {
  width: 100%;

  &--internal {
    display: flex;
    align-items: center;
    padding: 0 1rem 0 2rem;

    cursor: pointer;
    border-radius: 1rem;

    background-color: white;
    color: var(--primary);
    width: 100%;
    text-align: left;
    height: 5rem;
    margin-bottom: 2rem;

    img {
      margin-left: auto;
    }
  }
}

.img-coracao {
  padding-top: calc(100vh - 590px);
  position: fixed;
  z-index: 1000;
}

@media (max-width: 1241px) and (min-width: 1200px) {
  .img-coracao {
    position: fixed;
    padding-top: calc(100vh - 628px);
    z-index: 1000;
  }
}

@media (max-width: 1199px) and (min-width: 1115px) {
  .img-coracao {
    position: fixed;
    padding-top: calc(100vh - 648px);
    z-index: 1000;
  }
}

@media (max-width: 1113px) and (min-width: 921px) {
  .img-coracao {
    position: fixed;
    padding-top: calc(100vh - 650px);
    z-index: 1000;
  }
}

@media (max-width: 920px) and (min-width: 907px) {
  .img-coracao {
    position: fixed;
    padding-top: calc(100vh - 580px);
    z-index: 1000;
  }
}

@media (max-width: 906px) and (min-width: 900px) {
  .img-coracao {
    position: fixed;
    padding-top: calc(100vh - 640px);
    z-index: 1000;
  }
}

@media (max-width: 899px) {
  .img-coracao {
    padding-top: 0%;
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
  }
}
</style>
