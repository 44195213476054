<template>
  <div class="password-container">
    <div class="password-card-container">
      <b-form>
        <b-card class="password-card card-border--1">
          <b-card-body>
            <div class="password-wecolme">
              <img src="@/assets/icons/mail.svg" />
              <h1 class="font-weight-bold mt-5">
                Pronto! Enviamos um e-mail para você
              </h1>
            </div>
            <div>
              <p class="p2 mt-4">
                Dentro de alguns instantes você receberá um e-mail com um link
                para redefinir sua senha. Verifique a caixa de spam ou lixo
                eletrônico.
              </p>
              <div class="mt-4">
                <b-button
                  @click="onOk()"
                  class="button"
                  variant="primary"
                  id="ok"
                  >Ok, entendi!
                  <i
                    class="fas fa-spinner fa-spin icon"
                    v-show="this.loading"
                  ></i
                ></b-button>
              </div>
              <div
                class="invalid"
                v-show="this.msgErroInvalid != null && !this.loading"
              >
                <i class="fas fa-exclamation-circle invalid"></i>
                {{ this.msgErroInvalid }}
              </div>
            </div>
          </b-card-body>
        </b-card>
      </b-form>
    </div>
  </div>
</template>

<script>
import RoutesPaths from "../../../router/router-structure/routes-paths";
import {
  validateField,
  validateFieldEmail,
  requiredFieldsFilled,
} from "../../../utils/validate/validate.js";

export default {
  data() {
    return {
      formSubmited: false,
      validations: {},
      loading: false,
      msgErroInvalid: null,
      validateField,
      validateFieldEmail,
      requiredFieldsFilled,
      form: {
        crm: null,
        email: null,
      },
    };
  },
  methods: {
    onOk() {
      this.$router.push(RoutesPaths.login.rootName());
    },
  },
};
</script>

<style lang="scss" scoped>
.password-card-container {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  width: 55rem;
  margin-top: 11.5rem;
}

.password-card {
  width: 44rem;
  height: 41rem;
  padding: 2rem 5rem;
  @include header5;
}

.password-container {
  display: flex;
  width: 100%;
  height: 100%;
  background-image: url("../../../assets/images/passwordRecovery/password.svg");
  background-repeat: no-repeat;

  @include media-breakpoint-up(xs) {
    justify-content: center;
    background-position: right bottom;
    background-size: 90% 50%;
  }

  @include media-breakpoint-up(sm) {
    justify-content: center;
    background-position: right bottom;
    background-size: 90% 50%;
  }

  @include media-breakpoint-up(md) {
    justify-content: center;
    background-position: right bottom;
    background-size: 70% 50%;
  }

  @include media-breakpoint-up(lg) {
    justify-content: initial;
    background-position: 60% 30%;
    background-size: 60% 60%;
  }

  @include media-breakpoint-up(xl) {
    justify-content: initial;
    background-position: 80% 30%;
    background-size: 60% 60%;
  }
}

.password-card-container {
  /* width: 44rem; */
  /* height: 41rem; */
}

.password-wecolme {
  max-width: 43rem;
  text-align: center;
}
.icon {
  font-size: 2rem;
}

.button {
  margin-top: 2rem;
  width: 12rem;
  height: 4rem;
}

.invalid {
  color: #dc3545;
  margin-top: 0.8rem;
}
</style>
