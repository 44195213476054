import Register from "../../modules/register/views/Register.vue";
import PersonalInformation from "../../modules/personal-information/views/PersonalInformation.vue";
import MedicalSpecialities from "../../modules/medical-specialities/views/MedicalSpecialities.vue";
import AttendencePlace from "../../modules/attendance-places/views/AttendencePlace.vue";
import PaymentOptions from "../../modules/payment-options/views/PaymentOptions.vue";
import TaxLicense from "../../modules/tax-license/views/TaxLicense.vue";
import RouterViewRender from "../../utils/routes/router-view-render";
import { PermissionList } from "../../static-data/PermissionList";
import { IsAbleNavigation } from "../utils/is-able-navigation";

const RegisterRoutes = {
  path: "register",
  meta: {
    requiresAuth: true,
  },
  beforeEnter: (to, from, next) =>
    IsAbleNavigation(to, from, next, PermissionList.REGISTER_MENU),
  component: RouterViewRender,
  children: [
    {
      path: "",
      component: Register,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "personal-information",
      component: PersonalInformation,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "payment-options",
      component: PaymentOptions,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "tax-license",
      component: TaxLicense,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "medical-specialities",
      component: MedicalSpecialities,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "attendence-place",
      component: AttendencePlace,
      meta: {
        requiresAuth: true,
      },
    },
  ],
};

export default RegisterRoutes;
