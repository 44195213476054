import Benefit from "../../modules/benefit/view/Benefit.vue";
import RouterViewRender from "../../utils/routes/router-view-render";
import { PermissionList } from "../../static-data/PermissionList";
import { IsAbleNavigation } from "../utils/is-able-navigation";
import HealthPlan from "../../modules/health-plan/views/HealthPlan.vue";
import FuneralAssistance from "../../modules/funeral-assistance/views/FuneralAssistance.vue";
import TemporaryAssistance from "../../modules/temporary-assistance/views/TemporaryAssistance.vue";
import Absence from "../../modules/absence/views/Absence.vue";
import Benefactor from "../../modules/benefactor/views/Benefactor.vue";
import OtherSolicitation from "../../modules/Other-solicitations/views/OtherSolicitations.vue";

const BenefitRoutes = {
  path: "benefit",
  component: RouterViewRender,
  beforeEnter: (to, from, next) =>
    IsAbleNavigation(to, from, next, PermissionList.BENEFIT_MENU),
  children: [
    {
      path: "",
      component: Benefit,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "health-plan",
      component: HealthPlan,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "benefactor",
      component: Benefactor,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "funeral-assistance",
      component: FuneralAssistance,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "temporary-assistance",
      component: TemporaryAssistance,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "absence",
      component: Absence,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "other-solicitation",
      component: OtherSolicitation,
      meta: {
        requiresAuth: true,
      },
    },
  ],
};

export default BenefitRoutes;
