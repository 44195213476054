import {
    getStorage,
    ref,
    uploadBytesResumable,
    getDownloadURL,
    deleteObject,
    getBlob
  } from "firebase/storage";

export function getFileRef(path) {    
  const storage = getStorage(); 
  return ref(storage, path);
}

export function uploadFile(ref, file) {  
  return uploadBytesResumable(ref, file);
}

export function getURL(uploadTask) { 
  return getDownloadURL(uploadTask.snapshot.ref);
}

export function deleteFile(fileRef) { 
  return deleteObject(fileRef);
}

export function getFileBlob(fileRef) { 
  return getBlob(fileRef);
}