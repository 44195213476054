import { RoleList } from "../../static-data/RoleList";
import store from "../../store";
import isAbleRole from "../permissions/is-able-role";
import initializeJsonFromLocalStorage from "../storage/initialize-storage-utils";

export function getNameUser() {
  if (
    store.getters.isAdmin &&
    !isAbleRole(RoleList.AUTHORITY_USER_IS_CUSTOMER)
  ) {
    const tokenReponse = initializeJsonFromLocalStorage("token-reponse");
    return tokenReponse.additionalInformation.username;
  }
  return store.getters.getUserInformation?.nickname;
}
