// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "input{text-align:right;font-size:1.4rem}.documents-atas_vdp-datepicker__calendar_3nEdt{font-size:3.4rem}select.documents-atas_select-custom_128FJ{text-align:right}.documents-atas_principal-custom-ata_35L3A{height:4.2rem}", ""]);
// Exports
exports.locals = {
	"vdp-datepicker__calendar": "documents-atas_vdp-datepicker__calendar_3nEdt",
	"select-custom": "documents-atas_select-custom_128FJ",
	"principal-custom-ata": "documents-atas_principal-custom-ata_35L3A"
};
module.exports = exports;
