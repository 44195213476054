import axios from "axios";

export async function downloadAxios(pathFile) {

  const resp = await axios({
    url: pathFile,
    method: "get",
    responseType: 'blob'
  });    
  
  const dataUrl = await blobToBase64(resp.data);

  var base64 = dataUrl.split(',')[1];

  return base64;
}

function blobToBase64(blob) {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
}