import Vue from "vue";
const { default: isAble } = require("../utils/permissions/is-able");

Vue.directive("is-able", (el, binding, vnode) => {
  if (!isAble(binding.value)) {
    // replaces HTMLElement with comment node
    const comment = document.createComment(" ");
    Object.defineProperty(comment, "setAttribute", {
      value: () => undefined,
    });
    vnode.elm = comment;
    vnode.text = " ";
    vnode.isComment = true;
    vnode.context = undefined;
    vnode.tag = undefined;
    vnode.data.directives = undefined;

    if (vnode.componentInstance) {
      vnode.componentInstance.$el = comment;
    }

    if (el.parentNode) {
      el.parentNode.replaceChild(comment, el);
    }
  }
});
