<script>
import CustomModalTemplate from "../components/custom-modal/CustomModalTemplate.vue";
import { inject, provide, ref } from "@vue/composition-api";
export default {
  props: {
    modalId: {},
    formTitle: {},
    deleteButtonText: {},
    newItemButtonText: {},
    cancelButtonText: {},
    submitButtonText: {},
    onSubmitEvent: {},
    onDeleteEvent: {},
    onCloseEvent: {},
    onNewItemEvent: {},
    onCancelEvent: {},
    attentionModalId: {},
    newItemModalId: {},
    disableValidation: { default: false },
    disableEvent: {},
    landscape: {},
    newItemVisible: { default: true },
    noCloseOnEsc: {},
  },
  components: { CustomModalTemplate },
  setup() {
    const globalLoading = inject("globalLoading");
    const formData = ref({
      form: null,
      onSubmitHandler: function() {
        console.warn("invalid onSubmit handler!");
      },
      onDeleteHandler: function() {
        console.warn("invalid onDelete handler!");
      },
      formSelected: false,
    });

    const setForm = (form) => {
      if (form) formData.value.form = { ...form };
    };

    const setSubmitHandler = (handler) => {
      formData.value.onSubmitHandler = handler;
    };

    const setDeleteHandler = (handler) => {
      formData.value.onDeleteHandler = handler;
    };

    const setFormSelected = (value) => {
      formData.value.formSelected = value;
    };

    provide("formData", formData);
    provide("setSubmitHandler", setSubmitHandler);
    provide("setDeleteHandler", setDeleteHandler);
    provide("setFormSelected", setFormSelected);
    provide("setForm", setForm);

    return { formData, globalLoading };
  },
  methods: {
    onSubmitHandler(funHandler) {
      if (funHandler) {
        funHandler();
      }
    },
    async onSubmit(e) {
      e.preventDefault();
      try {
        const { onSubmitHandler } = this.formData;
        if (onSubmitHandler) {
          let validation = await onSubmitHandler();
          const disableValidation = this.$props.disableValidation;

          if (validation || disableValidation) {
            this.globalLoading = true;
            const { form } = this.formData;
            await this.$props.onSubmitEvent(form);
            this.globalLoading = false;
            this.onCloseModalHandler();
          }
        } else {
          console.warning("Null onSubmitHandler Unexpected");
        }
      } catch (error) {
        this.globalLoading = false;
        console.error("error on AlterForm onSubmit: ", error);
      }
    },
    async doDelete() {
      try {
        const { onDeleteHandler } = this.formData;
        if (onDeleteHandler) {
          const form = await onDeleteHandler();
          if (form) {
            this.globalLoading = true;
            await this.$props.onDeleteEvent(form);
            this.globalLoading = false;
            this.onCloseModalHandler();
          }
        } else {
          console.warning("Null onDeleteHandler unexpected.");
        }
      } catch (error) {
        this.globalLoading = false;
        console.error("Error on AlterForm onDelete: ", error);
      }
    },
    onCloseModalHandler() {
      this.$refs["form-modal"].$children[0].hide();
    },
    onClose() {
      this.onCloseModalHandler();
      if (this.$props.onCancelEvent) this.$props.onCancelEvent();
    },
    onNewItem() {
      if (this.$props.onNewItemEvent) this.$props.onNewItemEvent();
    },
  },
  render() {
    return (
      <CustomModalTemplate
        modalId={this.$props.modalId}
        title={this.$props.formTitle}
        ref={"form-modal"}
        noCloseOnBackdrop
        noCloseOnEsc={this.$props.noCloseOnEsc}
        landscape={this.$props.landscape}
      >
        <template slot="header">{this.$slots.header}</template>
        <template slot="content">
          <b-form id="alter-form" class="alter-form" onSubmit={this.onSubmit}>
            {this.$slots.content}
          </b-form>
        </template>
        <template slot="footer">
          {this.$props.deleteButtonText && (
            <b-button
              class="mr-auto"
              variant="outline-primary"
              v-b-modal={
                this.formData.formSelected && this.$props.attentionModalId
              }
              disabled={this.$props.disableEvent}
            >
              {this.$props.deleteButtonText}
            </b-button>
          )}

          {this.$props.newItemButtonText && (
            <b-button
            variant="outline-primary"
            onClick={this.onNewItem}
            class="mr-auto"
            v-b-modal={this.$props.newItemModalId}
            disabled={this.$props.disableEvent}
            v-show={this.$props.newItemVisible}
            >
              {this.$props.newItemButtonText}
            </b-button>
          )}

          <b-button
            variant="outline-primary"
            onClick={this.onClose}
            class="ml-4"
          >
            {this.$props.cancelButtonText}
          </b-button>

          <b-button
            form="alter-form"
            type="submit"
            class="ml-4"
            variant="primary"
            id="submitButtonText"
            disabled={this.$props.disableEvent}
          >
            {this.$props.submitButtonText}
          </b-button>
        </template>
      </CustomModalTemplate>
    );
  },
};
</script>
