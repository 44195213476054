<template>
  <div class="temporary-assistance">
    <SimpleLayout
      viewComponent="TemporaryAssistanceInformations"
      :cardInfo="this.cardInfo"
      :leftAction="this.leftAction"
      :leftLinkNavigation="this.leftLinkNavigation"
    />
  </div>
</template>

<script>
import Vue from "vue";
import SimpleLayout from "../../../layouts/SimpleLayout.vue";
import { ServiceType } from "../../../static-data/ServiceType";
import RoutesPaths from "../../../router/router-structure/routes-paths";

Vue.component("TemporaryAssistanceInformations", () =>
  import("../components/visualization/TemporaryAssistanceInformations.vue")
);

export default {
  components: {
    SimpleLayout,
  },
  data: function() {
    return {
      leftAction: {
        text: "Benefícios",
        onClick: this.leftActionClickHandler,
      },
      leftLinkNavigation: this.leftHistoryNavigation,
      cardInfo: {
        headerIcon: "fas fa-map-marker-alt",
        icon: "auxilio_temporario.svg",
        title: "Auxílio Temporário",
        description:
          "Para os casos de afastamento de doença ou licença maternidade.",
      },
    };
  },
  methods: {
    leftActionClickHandler() {
      this.$router.push({
        path: RoutesPaths.benefit.rootName(),
        replace: true,
      });
    },
    leftHistoryNavigation() {
      this.$router.push({
        name: "request-history",
        params: {
          serviceType: ServiceType.Absence,
        },
      });
    },
  },
};
</script>

<style lang="scss">
.temporary-assistance {
  .simple-layout--content {
    width: 100%;
  }
}
</style>
