import FileManagement from "../../modules/file-management/views/FileManagement.vue";
import { PermissionList } from "../../static-data/PermissionList";
import { IsAbleNavigation } from "../utils/is-able-navigation";

const FileManagementRoutes = {
  path: "file-management",
  component: FileManagement,
  beforeEnter: (to, from, next) =>
    IsAbleNavigation(to, from, next, PermissionList.FILE_MANAGEMENT_MENU),
  meta: {
    requiresAuth: true,
  },
};

export default FileManagementRoutes;
