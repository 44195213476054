<template>
  <div class="page-container">
    <div>
      <section class="page-header">
        <h2 class="font-weight-bold">
          Benefícios
        </h2>
      </section>

      <div class="separator-line"></div>
      <section class="sub-header">
        <p>
          Aqui você terá acesso às informações sobre os benefícios que a UGF
          oferece, podendo solicitar clicando nos ícones correspondentes.
        </p>
      </section>
    </div>
    <section>
      <div class="card-container">
        <CustomInfoCard
          class="deck deck-margin"
          v-for="card in getCards"
          :key="card.id"
          :onCardClick="() => onClickCard(card)"
          :title="card.header"
          :description="card.description"
          :icon="card.icon"
        />
      </div>
    </section>
  </div>
</template>

<script>
import CustomInfoCard from "../../../components/custom-info-card/CustomInfoCard.vue";
import RoutesPaths from "../../../router/router-structure/routes-paths";
const linkSale = "https://unimedflorianopolis.redeparcerias.com";
import { isMobile } from "../../../utils/mobile/mobile";

export default {
  components: {
    CustomInfoCard,
  },
  computed: {
    getCards: () => {
      let cards = [
        {
          id: 0,
          icon: "heart-multiple-outline.svg",
          header: "Plano de saúde do Cooperado",
          description: "Plano de saúde dos médicos Cooperados (PLAC)",
          route: RoutesPaths.benefit.healthPlan(),
          showMobile: true,
        },
        {
          id: 1,
          icon: "medal-outline.svg",
          header: "Solicitar Benemérito",
          description: "Solicite o benefício pelos seus serviços prestados",
          route: RoutesPaths.benefit.benefactor(),
          showMobile: true,
        },
        {
          id: 2,
          icon: "clipboard-text-outline.svg",
          header: "Solicitar Afastamento",
          description: "Justifique o motivo e solicite o benefício",
          route: RoutesPaths.benefit.absence(),
          showMobile: true,
        },
        {
          id: 3,
          icon: "auxilio_funeral.svg",
          header: "Auxílio Funeral",
          description:
            "Benefício concedido aos sucessores legais do médico cooperado em caso de falecimento",
          route: RoutesPaths.benefit.funeralAssistance(),
          showMobile: true,
        },
        {
          id: 4,
          icon: "auxilio_temporario.svg",
          header: "Auxílio Temporário",
          description:
            "Para os casos de afastamento de doença ou licença maternidade",
          route: RoutesPaths.benefit.temporaryAssistance(),
          showMobile: true,
        },
        {
          id: 5,
          icon: "club_unimed.svg",
          header: "Meu Clube Unimed",
          description:
            "Oferece benefícios e convênios exclusivos para os participantes  Unimed Florianópolis",
          link: linkSale,
          showMobile: false,
        },
      ];
      if (isMobile()) {
        return cards.filter((c) => c.showMobile);
      }
      return cards;
    },
  },
  methods: {
    isMobile: isMobile,
    onClickCard({ route, link }) {
      if (link) window.open(link, "_blank");
      else if (route) this.$router.push(route);
    },
  },
};
</script>

<style lang="scss" scoped>
.deck-margin {
  margin: 2rem;
}

.page-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.page-header {
  text-align: center;
}

.sub-header {
  max-width: 83rem;
  margin: 0 auto;
  margin-top: 1.5rem;
  text-align: center;
}

.card-container {
  max-width: 90rem;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  @include media-breakpoint-up(xs) {
    justify-content: center;
  }

  @include media-breakpoint-up(md) {
    justify-content: center;
  }
}
</style>
