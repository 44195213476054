<template>
  <div class="principal">
    <div class="info-header">
      <div>
        <img src="../../../assets/images/MDI-information-outline.svg" />
      </div>
      <div>
        <p class="p4">
          Importante: Selecione o ano e clique no botão Download do
          Demonstrativo para baixar o arquivo com o demonstrativo de valores
          pagos..
        </p>
      </div>
    </div>
    <b-card class="custom-card my-4" border-variant="light">
      <div class="custom-selects-principal">
        <div class="competence-w align-items-center custom-number-card">
          <div class="d-flex align-items-center">
            NÚMERO DA CARTEIRINHA:
          </div>
          <div
            class="div-select-custom"
            :class="isLoadCard ? 'div-load' : null"
          >
            <b-select
              :disabled="isLoadCard"
              class="select-custom"
              :options="cardsOptions"
              v-model.lazy.trim="card"
              :state="validateField('card')"
              size="lg"
            />
            <div v-if="isLoadCard" class="d-flex align-items-center">
              <i class="fas fa-spinner fa-spin icon"></i>
            </div>
          </div>
        </div>
        <div class="competence-w align-items-center">
          <div class="d-flex align-items-center">ANO DA COMPETÊNCIA:</div>
          <div
            class="div-select-custom"
            :class="isLoadYear ? 'div-load' : null"
            id="div-year"
          >
            <b-select
              :disabled="isLoadYear || isLoadCard || !isSelectCard"
              class="select-custom"
              :options="yearsOptions"
              v-model.lazy.trim="form.year"
              :state="validateField('year')"
              size="lg"
            />
            <div v-if="isLoadYear" class="d-flex align-items-center">
              <i class="fas fa-spinner fa-spin icon"></i>
            </div>
          </div>
        </div>
      </div>
      <div>
        <p class="text-one p3">
          Informamos que os valores pagos referentes aos seguintes itens, não
          são considerados para a composição da declaração de IR:
        </p>
        <p class="p3">
          SOS Unimed; 2ª via de cartão; Correção (juros e multa); Cota capital;
          Inscrição; Manutenção; Multa de Rescisão contratual; Valores glosados;
          Plus médico; Unimed Seguro cooperado; Transporte aéreo; Desconto
          Inscrição; Desconto opcional; Desconto reajuste opcional; Reajuste
          opcional anterior; Desconto de Multa ou Juros; Assistência domiciliar.
        </p>
      </div>
    </b-card>
  </div>
</template>

<script>
import { inject } from "@vue/composition-api";
import {
  validateField,
  requiredFieldsFilled,
  validateFieldFile,
  validateCheckBox,
} from "../../../utils/validate/validate.js";
import {
  getCards,
  getYears,
} from "../../../services/DemonstrativeAmountsPaid/demonstrative-amounts-paid";

const DefaultForm = {
  year: null,
  card: null,
};

export default {
  components: {},
  setup() {
    const formData = inject("formData");
    const setForm = inject("setForm");
    const setSubmitHandler = inject("setSubmitHandler");
    return { formData, setForm, setSubmitHandler };
  },

  async mounted() {
    this.initForm();
  },
  data() {
    return {
      formSubmited: false,
      form: { ...DefaultForm },
      validations: {},
      validateField,
      requiredFieldsFilled,
      validateFieldFile,
      validateCheckBox,
      cardsOptions: [],
      yearsOptions: [],
      card: null,
      isLoadCard: true,
      isLoadYear: false,
      isSelectCard: false,
    };
  },
  watch: {
    card: {
      async handler() {
        try {
          this.isSelectCard = false;
          this.isLoadYear = true;
          this.form.card = this.card;
          const years = await getYears(this.card.item);
          this.yearsOptions = this.buildSelectYearsOptions(years);
        } finally {
          this.isLoadYear = false;
          this.isSelectCard = true;
        }
      },
    },
  },
  methods: {
    async initForm() {
      this.setSubmitHandler(this.onSubmit);
      this.formData = {
        form: { ...this.form },
        onSubmitHandler: this.onSubmit,
      };
      try {
        this.isLoadCard = true;
        const crm = this.$store.getters.getUserName;
        const cards = await getCards(crm);
        this.cardsOptions = this.buildSelectCardsOptions(cards);
      } finally {
        this.isLoadCard = false;
      }
    },
    buildSelectCardsOptions(formDataOptions) {
      return [
        { value: null, text: "selecione uma carteirinha" },
        ...formDataOptions.map((formOption) => ({
          value: formOption,
          text: formOption.item,
        })),
      ];
    },
    buildSelectYearsOptions(formDataOptions) {
      return [
        { value: null, text: "selecione uma competência" },
        ...formDataOptions.map((formOption) => ({
          value: formOption,
          text: formOption.item,
        })),
      ];
    },
    onChangeFormSelect(formData) {
      if (!formData) {
        this.form = { ...DefaultForm };
        this.setForm(this.form);
        this.formData = {
          form: { ...this.form },
          onSubmitHandler: this.onSubmit,
        };

        return;
      }

      const form = {
        ...this.form,
        ...formData,
      };

      this.form = form;
      this.setForm(this.form);
      this.formData = {
        form: { ...this.form },
        onSubmitHandler: this.onSubmit,
      };
    },
    onSubmit() {
      this.setForm(this.form);
      this.formSubmited = true;
      if (this.requiredFieldsFilled()) {
        return true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.principal {
  margin-top: 3rem;
}

.pointer {
  cursor: pointer;
}

.competence-w {
  display: grid;
  width: 100%;
  height: 4rem;
  padding-right: 0%;
}

.text-one {
  margin-bottom: 1rem;
}

.info-header {
  display: grid;
  width: 100%;
  grid-template-columns: 30px auto;
}

.div-load {
  display: grid;
  grid-template-columns: auto 20px;
}

.div-select-custom {
  height: 3.5rem;
  border: 0.01rem solid #9ea3bb !important;
  text-decoration: none;
  border-radius: 5%;
}

.select-custom {
  border-radius: 5%;
}

.custom-number-card {
  margin-bottom: 2rem;
}

.custom-selects-principal {
  margin-bottom: 3.5rem;
}

@media (min-width: 460px) {
  .competence-w {
    grid-template-columns: 155px 202px auto;
    padding-right: 0%;
  }

  .custom-number-card {
    margin-bottom: 1rem;
  }
}

.invalid {
  color: var(--danger-2);
}
</style>
