<template>
  <div>
    <b-navbar
      class="navbar-custon"
      toggleable="md"
      type="light"
      variant="light"
    >
      <div class="cards">
        <slot name="menuAdm"></slot>
        <div class="shadow-sm px-5"><slot name="menu"></slot></div>
      </div>
    </b-navbar>
  </div>
</template>
<script>
export default {};
</script>

<style lang="scss" scoped>
.navbar-custon {
  padding: 0rem 0rem !important;
}
.cards {
  width: 100%;
  display: grid;
  border-style: none;
  grid-column: 0;
}
</style>
