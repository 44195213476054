import store from "../../store";

export function isAllowedUserNavigation() {
  const dataLastNavigation = store.getters.getUserInteractionInactivity;
  const dataNow = new Date();

  if (dataLastNavigation == null || dataLastNavigation == undefined) {
    setData(dataNow);
    return true;
  }

  const expiredPermission = checkUserExpiredPermission(
    dataLastNavigation,
    dataNow
  );

  if (expiredPermission) {
    store.dispatch("clearUserInteractionInactivity");
    return false;
  }

  setData(dataNow);
  return true;
}

function checkUserExpiredPermission(dataLastNavigation, dataNow) {
  try {
    let dateExpiration = new Date(dataLastNavigation);
    return dateExpiration < dataNow;
  } catch (error) {
    console.log("error -> ", error);
    return false;
  }
}

function setData(data) {
  store.dispatch(
    "setStoreUserInteractionInactivity",
    data.setMinutes(data.getMinutes() + 60)
  );
}
