import InitialLayout from "../../layouts/InitialLayout.vue";
import PasswordRecovery from "../../modules/password-recovery/views/PasswordRecovery.vue";
import PasswordRecoveryChange from "../../modules/password-recovery/views/PasswordRecoveryChange.vue";
import PasswordRecoverySucess from "../../modules/password-recovery/views/PasswordRecoverySucess.vue";

const PasswordRecoveryRoutes = {
  path: "/password-recovery",
  component: InitialLayout,
  children: [
    {
      path: "",
      name: "PasswordRecoveryView",
      component: PasswordRecovery,
    },
    {
      path: "change/:id",
      name: "PasswordRecoveryChangeView",
      component: PasswordRecoveryChange,
    },
    {
      path: "sucess",
      name: "PasswordRecoverySucessView",
      component: PasswordRecoverySucess,
    },
  ],
};

export default PasswordRecoveryRoutes;
