import axios from "../http-client/axios";
import { SLIPKNOP_URL } from "../urls/base-urls";

export async function getProfileImage(crm) {
  const resp = await axios({
    url: `${SLIPKNOP_URL}/profile-image?crm=${crm}`,
    method: "get",
    data: {},
    headers: { "content-type": "application/json" },
  });

  return resp.data;
}


export async function updateProfileImage(crm, file) {
   if (!file) return [];
  let formDataInstance = new FormData();
  formDataInstance.append("file", file);
  formDataInstance.append("crm", crm);

  const resp = await axios({
    url: `${SLIPKNOP_URL}/profile-image`,
    method: "post",
    data: formDataInstance,
    headers: { "content-type": "application/json" },
  });

  return resp.data;
}


export async function deleteProfileImage(crm) {
  let formDataInstance = new FormData();
  formDataInstance.append("crm", crm);

  const resp = await axios({
    url: `${SLIPKNOP_URL}/profile-image`,
    method: "delete",
    data: formDataInstance,
    headers: { "content-type": "application/json" },
  });

  return resp.data;
}

export default getProfileImage;
