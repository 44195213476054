<template>
  <div>
    <FormLayout
      :tabs="this.tabs"
      :cardInfo="this.cardInfo"
      :leftAction="this.leftAction"
      :leftLinkNavigation="this.leftLinkNavigation"
      :onChangeTab="onChangeTabIndex"
    ></FormLayout>
  </div>
</template>

<script>
import Vue from "vue";
import FormLayout from "../../../layouts/FormLayout.vue";
import { ServiceType } from "../../../static-data/ServiceType";
import RoutesPaths from "../../../router/router-structure/routes-paths";

Vue.component("general-information", () =>
  import("../components/visualization/GeneralInformation.vue")
);

Vue.component("address-information", () =>
  import("../components/visualization/AddressInformation.vue")
); 

Vue.component("ContactsInformation", () =>
  import("../components/visualization/ContactsInformation.vue")
);

Vue.component("contacts-emergencies-information", () =>
  import("../components/visualization/ContactsEmergenciesInformation.vue")
);

export default {
  components: {
    FormLayout,
  },
  data: function() {
    return {
      currentTabIndex: 0,
      leftAction: {
        text: "Cadastro",
        onClick: this.leftActionClickHandler,
      },
      leftLinkNavigation: this.leftHistoryNavigation,
      cardInfo: {
        icon: "smart-card-outline.svg",
        title: "Informações pessoais",
        description: "Informações gerais, endereços e contatos",
      },
      tabs: [
        {
          title: "Informações gerais",
          formComponent: "general-information",
          serviceType: ServiceType.PersonalInformation,
        },
        {
          title: "Endereços",
          formComponent: "address-information",
          serviceType: ServiceType.Addresses,
        },
        {
          title: "Contatos",
          formComponent: "contacts-information",
          serviceType: ServiceType.Contacts,
        },
        {
          title: "Referência",
          formComponent: "contacts-emergencies-information",
          serviceType: ServiceType.Emergencies,
          featureName: "REGISTER_PERSONAL_INFORMATION_TAB_REFERENCE_CONTACT"
        },
      ],
    };
  },
  methods: {
    onChangeTabIndex(newValue) {
      this.currentTabIndex = newValue;
    },
    leftActionClickHandler() {
      this.$router.push(RoutesPaths.register.rootName());
    },
    leftHistoryNavigation() {
      this.$router.push({
        path: RoutesPaths.requestHistory.rootName(),
        params: {
          serviceType: this.tabs[this.currentTabIndex].serviceType,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
