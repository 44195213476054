import axios from "../http-client/axios";
import { METALICA_URL, SCORPIONS_URL } from "../urls/base-urls";
import AlterType from "../../static-data/TypeRequestedFlow";
import requestBuilder from "../request-builder/request-builder";
import mapperToGeneralInformatioRequest from "./general-information-mapper";
import FormRequestAction from "../../static-data/FormRequestAction";
import ServiceRequestType from "../../static-data/ServiceRequestType";

export async function buildResponseArray(list) {
  let array = [];

  for (const item in list) {
    let obj = { id: list[item].code, description: list[item].description };
    if (item.description != "") {
      array.push(obj);
    }
  }
  return array;
}

export async function buildSchoolingArray(list) {
  let array = [];
  let cont = 0;
  for (const item in list) {
    let obj = {
      id: cont++,
      description: list[item].description,
    };
    if (item.description != "") {
      array.push(obj);
    }
  }
  console.log("array => ", array);
  return array;
}

const buildObjectChanged = (formData) => {
  let toReturn = {}
  for (const property in formData) {
    if (formData[property] != formData.originalData[property]
        && property != "files" && property != "originalData") {
      toReturn[property] = formData[property]
    }
  }
  return toReturn;
}
export async function updateGeneralInformation(crm, formData) {
  const bodyToRequest = {
    "crm" : crm,
    "ethniCode" : formData.ethnicity.code,
    "genderCode" : formData.gender.id,
    "maritalStatusCode" : formData.maritalStatus.code,
    "flgRetiree" : formData.retiree.id,
    "schoolingCode" : formData.schooling.code
  }
  await axios.put(
    `${SCORPIONS_URL}/general-information`,
    bodyToRequest
  );
}
export async function solicitationUpdateGeneralInformation(formData, attachments) {
  const originalData = mapperToGeneralInformatioRequest(
    formData.originalData,
    FormRequestAction.update
  );
  
  const filedsChanged = buildObjectChanged(formData)
  const changedData = mapperToGeneralInformatioRequest(
    filedsChanged,
    FormRequestAction.update
  );

  const resp = await axios.post(
    `${METALICA_URL}/v2/integration-flow`,
    requestBuilder(
      originalData,
      AlterType.update,
      attachments,
      ServiceRequestType.PERSON_INFORMATION_GENERAL,
      null,
      changedData
    )
  );

  return resp.data;
}

export async function getGeneralInformation(crm) {
  const resp = await axios({
    url: `${SCORPIONS_URL}/v2/general-information/?crm=${crm}`,
    method: "get",
    data: {},
    headers: {
      "content-type": "application/json",
    },
  });

  let toReturn = {
    ...resp.data
  };

  return toReturn;
}

export async function getNewGeneralInformation(crm) {
  const resp = await axios({
    url: `${SCORPIONS_URL}/v2/general-information?crm=${crm}`,
    method: "get",
    data: {},
    headers: { "content-type": "application/json" },
  });
  
  return resp.data
}