import axios from "../http-client/axios";
import { SCORPIONS_URL } from "../urls/base-urls";
export async function updateCommunication(idStatement) {
  const resp = await axios.post(
    `${SCORPIONS_URL}/communication-user`,
     idStatement
  );
  return resp.data;
}
export async function getCommunication() {
  const resp = await axios.get(
    `${SCORPIONS_URL}/communication`,
  );
  return resp.data;
}