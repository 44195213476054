<template>
  <div>
    <TableVisualizationLayout :leftInfo="leftInfo" :leftAction="leftAction">
      <template slot="headerContent">
        <b-col class="pl-0 pr-0">
          <b-row class="mt-4 mb-4" align-h="between">
            <b-col class="pl-0 pr-0" sm="4">
              <b-form-input
                id="input-1"
                class="text-left"
                v-model="filterTitle"
                type="text"
                placeholder="Busque por nome do treinamento..."
              ></b-form-input>
            </b-col>
            <b-col class="pl-0 pr-0" sm="4">
              <b-select v-model="filterTypeId" :options="filterTypes" />
            </b-col>
          </b-row>
        </b-col>
      </template>
      <template slot="table">
        <div class="scrollme">
          <b-table
            class="table-custom-training spaced-text"
            :fields="fields"
            :items="items"
            v-show="!this.loading"
            show-empty
            empty-text="Nenhuma informação para exibir!"
          >
            <template #cell(report)="data">
              <div
                class="custom-row-table d-flex justify-content-center align-items-center"
              >
                <div
                  :id="'report-enabled-' + data.item.id"
                  v-if="data.item.allowReport"
                  :class="'custom-icon-report-enable'"
                  class="mt-4 justify-content-center align-items-center custom-icon-report"
                  @click="onReportParticipationClick(data.item)"
                >
                  <b-tooltip
                    placement="top"
                    :target="'report-enabled-' + data.item.id"
                    >Ver relatório
                  </b-tooltip>
                </div>
                <div
                  :id="'report-disable-' + data.item.id"
                  v-if="!data.item.allowReport"
                  :class="'custom-icon-report-disable'"
                  class="mt-4 justify-content-center align-items-center custom-icon-report"
                >
                  <b-tooltip
                    placement="top"
                    :target="'report-disable-' + data.item.id"
                    >Relatório não está disponível
                  </b-tooltip>
                </div>
              </div>
            </template>
            <template #cell(statusTraining)="data">
              <div
                class="custom-row-table d-flex justify-content-center align-items-center"
              >
                <div
                  :id="'button-' + data.item.id"
                  :class="onGetClassStatusTraining(data.item)"
                  class="w-100 d-flex justify-content-center align-items-center custom-cursor-status "
                >
                  <b-form-checkbox
                    size="lg"
                    class="w-100 custom-cursor-status "
                    :class="onGetClassStatusTraining(data.item)"
                    switch
                    v-model="data.item.isVisible"
                    @change="onUpdateStatusTrainingClick(data.item)"
                    :disabled="!data.item.allowAlterStatus"
                  ></b-form-checkbox>
                </div>
                <b-tooltip
                  v-if="
                    data.item.statusTraining == 'REGISTER_FINISHED_AND_VISIBLE'
                  "
                  placement="top"
                  :target="'button-' + data.item.id"
                  >Visível para os<br />
                  cooperados
                </b-tooltip>
                <b-tooltip
                  v-else
                  placement="top"
                  :target="'button-' + data.item.id"
                  >Não visível para<br />
                  os cooperados
                </b-tooltip>
              </div>
            </template>
            <template #cell(actions)="data">
              <div class="custom-actions d-flex justify-content-between">
                <div
                  class="icon custom-icon-edit"
                  @click="goForm(data.item)"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Editar"
                ></div>
                <div
                  class="icon custom-icon-duplicate"
                  @click="onDuplicate(data.item)"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Duplicar"
                ></div>
                <div
                  class="icon custom-icon-delete"
                  :class="
                    data.item.allowDeletion
                      ? 'icon-delete-enable'
                      : 'icon-delete-disable'
                  "
                  @click="onDelete(data.item)"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Excluir"
                ></div>
              </div>
            </template>
          </b-table>
        </div>
        <div v-show="this.loading">
          <b-skeleton-table :rows="10" :columns="6"></b-skeleton-table>
        </div>
      </template>
      <template slot="footerContent">
        <div
          class="d-flex bd-highlight mb-3 justify-content-between"
          :class="isMobile() ? 'flex-column-reverse' : 'flex-row'"
          style="width: 100%;"
        >
          <div :class="isMobile() ? 'align-self-center' : 'me-auto'">
            <b-button
              class="my-3"
              variant="primary"
              @click="goForm()"
              v-show="!this.loading"
            >
              Novo Treinamento
            </b-button>
          </div>
          <div
            class="mx-auto"
            :class="isMobile() ? '' : 'pagination-training align-self-center'"
          >
            <b-pagination
              v-model="page"
              :total-rows="totalSize"
              :per-page="perPage"
              aria-controls="my-table"
              size="lg"
            />
          </div>
        </div>
      </template>
    </TableVisualizationLayout>
    <success-modal
      ref="success-modal"
      :description="this.msgSuccess"
      buttonText="Ok, entendi!"
    />
    <attention-modal
      modalId="delete-attention-modal"
      title="Atenção!"
      submitButtonText="Sim"
      cancelButtonText="Não"
      ref="attention-modal"
      :onAction="this.onDeleteConfirm"
    >
      <template slot="description">
        <p class="p1 mb-5 confirm-text">
          Tem certeza que deseja excluir este treinamento?
        </p>
      </template>
    </attention-modal>
    <attention-modal
      modalId="update-attention-modal"
      title="Atenção!"
      :submitButtonText="this.textConfirm"
      cancelButtonText="Não tenho certeza"
      ref="update-attention-modal"
      :onAction="this.onUpdateStatusTrainingConfirm"
    >
      <template slot="description">
        <p class="p1 mb-5 confirm-text">
          Tem certeza que deseja {{ this.textModalUpdateStatus }} este
          treinamento dos cooperados?
        </p>
      </template>
    </attention-modal>
  </div>
</template>

<script>
import TableVisualizationLayout from "../../../layouts/TableVisualizationLayout.vue";
import {
  dateToLocaleDateString,
  dateToTime,
} from "../../../utils/date/date-utils";
import RoutesPaths from "../../../router/router-structure/routes-paths";
import { resolve_path_url } from "../../../utils/image/image-utils";
import { createToast } from "../../../components/toast/toast";
import { isMobile } from "../../../utils/mobile/mobile";

import {
  getTrainings,
  getTrainingsReport,
  deleteTraining,
  cloneTraining,
  updateTrainingActivation,
} from "../../../services/training/trainingManagementService";
import { anchorBase64Download } from "../../../utils/anchor-base64-download/anchor-base64-download";

import AttentionModal from "../../../components/base-modals/AttentionModal.vue";
import SuccessModal from "../../../components/base-modals/SuccessModal.vue";

export default {
  components: {
    TableVisualizationLayout,
    AttentionModal,
    SuccessModal,
  },
  mounted() {
    this.getTrainings();
  },
  watch: {
    page: {
      handler() {
        this.getTrainings();
      },
    },
    filterTypeId: {
      handler() {
        this.getTrainings();
      },
    },
    filterTitle: {
      handler() {
        if (this.filterTitle.length > 2 || this.filterTitle.length == 0) {
          this.getTrainings();
        }
      },
    },
  },
  data() {
    return {
      filterTypes: [
        { value: "RUNNING", text: "TREINAMENTOS VIGENTES" },
        { value: "FINISHED", text: "TREINAMENTOS FINALIZADOS" },
        { value: "REGISTER_IN_PROGRESS", text: "TREINAMENTOS EM CONSTRUÇÃO" },
      ],
      filterTypeId: "RUNNING",
      filterTitle: "",
      page: 1,
      perPage: 10,
      totalSize: 10,
      itemToDelete: {},
      itemToUpdateStatus: {},
      statusUpdate: null,
      textModalUpdateStatus: null,
      textConfirm: null,
      msgSuccess: "",
      leftAction: {
        text: "Gestão de treinamentos",
        onClick: this.leftActionClickHandler,
      },
      leftInfo:
        "Para acessar as informações dos treinamentos clique no botão editar.",
      fields: [
        {
          key: "title",
          label: "Nome",
          thStyle: { width: "40%" },
        },
        {
          key: "publicTypes",
          label: "Público",
          thStyle: { width: "15%" },
        },
        {
          key: "initialDate",
          label: "Início",
          thStyle: { width: "10%" },
        },
        {
          key: "endDate",
          label: "Fim",
          thStyle: { width: "10%" },
        },
        {
          key: "report",
          label: "Relatório",
          class: "text-center",
          thStyle: { width: "10%" },
        },
        {
          key: "statusTraining",
          label: "Status",
          class: "text-center",
          thStyle: { width: "5%" },
        },
        {
          key: "actions",
          label: "Ações",
          class: "text-center",
          thStyle: { width: "10%" },
        },
      ],
      items: [],
      loading: true,
      resolve_path_url,
    };
  },
  methods: {
    isMobile: isMobile,
    onGetTextConfirm() {
      this.textConfirm = "Sim, " + this.textModalUpdateStatus;
    },
    async onUpdateStatusTrainingClick(item) {
      if (!item.allowAlterStatus) {
        item.isVisible = item.isVisible ? false : true;
        return;
      }

      this.statusUpdate =
        item.statusTraining == "REGISTER_FINISHED_AND_VISIBLE"
          ? "REGISTER_FINISHED_AND_INVISIBLE"
          : "REGISTER_FINISHED_AND_VISIBLE";

      this.textModalUpdateStatus =
        item.statusTraining == "REGISTER_FINISHED_AND_VISIBLE"
          ? "desativar"
          : "ativar";

      this.onGetTextConfirm();

      this.itemToUpdateStatus = item;
      const modal = this.$refs["update-attention-modal"].$children[0];
      modal.show();
    },
    onUpdateStatusTrainingConfirm(confirmed) {
      if (!confirmed) {
        this.itemToUpdateStatus.isVisible = this.itemToUpdateStatus.isVisible
          ? false
          : true;
        return;
      }

      updateTrainingActivation(this.itemToUpdateStatus.id, this.statusUpdate)
        .then(() => {
          this.msgSuccess = "Treinamento atualizado com sucesso!!";
          const modal = this.$refs["success-modal"].$children[0];
          modal.show();
          this.getTrainings();
        })
        .catch((error) => {
          this.itemToUpdateStatus.isVisible = this.itemToUpdateStatus.isVisible
            ? false
            : true;
          if (error.response && error.response.status == 400) {
            createToast(
              "Informação",
              "Não é possível alterar o status. Motivo: " +
                error.response.data.message,
              "warning"
            );
          } else {
            console.error("error on AlterForm onSubmit: ", error);
            createToast("Atenção", "Ocorreu um erro ao atualizar o status.");
          }
        });
    },
    onGetClassStatusTraining(item) {
      let response =
        item.statusTraining == "REGISTER_FINISHED_AND_VISIBLE"
          ? " custom-cursor-status-visible"
          : " custom-cursor-status-invisible";

      if (!item.allowAlterStatus) {
        response += " custom-cursor-status-disable";
      }

      return response;
    },
    async onReportParticipationClick(item) {
      if (!item.allowReport) return;
      const id = await this.encodeStr(item.id);
      this.$router.push({
        path: RoutesPaths.reportParticipation.rootName(),
        query: { training: id, name: item.title },
        replace: true,
      });
    },
    async encodeStr(id) {
      console.log(id);
      const response = btoa(id);
      return response;
    },
    goForm(item) {
      this.$router.push({
        path: RoutesPaths.trainingManagement.form(item?.id),
      });
    },
    async onDelete(item) {
      if (!item.allowDeletion) return;

      this.itemToDelete = item;
      const modal = this.$refs["attention-modal"].$children[0];
      modal.show();
    },
    onDeleteConfirm(confirmed) {
      if (!confirmed) return;

      deleteTraining(this.itemToDelete.id).then(() => {
        this.msgSuccess = "Treinamento excluído com sucesso!!";
        const modal = this.$refs["success-modal"].$children[0];
        modal.show();
        this.getTrainings();
      });
    },
    onDuplicate(item) {
      cloneTraining(item.id).then((training) => {
        this.$router.push({
          path: RoutesPaths.trainingManagement.form(training?.idTraining),
        });
      });
    },
    async onGenerateReport(item) {
      if (item.allowReport) {
        const reportResponse = await getTrainingsReport(item.id);

        anchorBase64Download(
          null,
          "application/xls",
          item.title + reportResponse.extension,
          reportResponse.fileBase64
        );
      }
    },
    async getTrainings() {
      try {
        this.loading = true;
        const trainingsResponse = await getTrainings(
          this.filterTypeId,
          this.filterTitle,
          this.page - 1,
          this.perPage
        );

        this.items = trainingsResponse.items;
        this.totalSize = trainingsResponse.totalItems;

        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.error("getInformation", error);
      }
    },
    leftActionClickHandler() {
      this.$router.go(-1);
    },
    onFormatDate(value) {
      let valueSplit = value.split(" ");
      let horaSplit = valueSplit[1].split(":");
      return `${valueSplit[0]} às ${horaSplit[0]}h${horaSplit[1]}`;
    },
    dateToTime: dateToTime,
    dateToLocaleDate: dateToLocaleDateString,
  },
};
</script>
<style lang="scss">
div.table-custom-training td {
  color: #7a7e83;
  padding-left: 2rem;
}
div.table-custom-training th {
  padding-left: 2rem;
}
</style>
<style lang="scss" scoped>
.custom-icon-report {
  &-enable {
    background-image: url("../../../assets/icons/training-report-enable.svg");

    &:hover {
      cursor: pointer;
    }
  }

  &-disable {
    background-image: url("../../../assets/icons/training-report-disable.svg");
  }

  background-repeat: no-repeat;
  width: 22px;
  height: 36px;
}
.flex-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
}

.itemleft {
  position: absolute;
  left: 0;
}
.icon {
  background-repeat: no-repeat;
  width: 24px;
  height: 24px;

  &-ordering {
    background-image: url("../../../assets/icons/arrow-collapse-vertical.svg");
    float: left;
  }

  &-folder {
    background-image: url("../../../assets/icons/folder-outline.svg");
    margin: auto;
  }

  &-edit {
    background-image: url("../../../assets/icons/pencil-outline.svg");
    float: left;

    &:hover {
      background: rgba(1, 157, 95, 0.1) 0% 0% no-repeat padding-box;
      background-image: url("../../../assets/icons/pencil-outline-houver.svg");
      background-position: center;
      border-radius: 30px;
    }
  }

  &-delete-enable {
    background-image: url("../../../assets/icons/trash-can-outline.svg");
    float: left;

    &:hover {
      background: #fbe9ed 0% 0% no-repeat padding-box;
      background-image: url("../../../assets/icons/trash-can-outline-houver.svg");
      background-position: center;
      border-radius: 30px;
    }
  }

  &-delete-disable {
    float: left;
    background-image: url("../../../assets/icons/trash-can-outline-disable.svg");

    &:hover {
      cursor: default !important;
    }
  }

  &-duplicate {
    background-image: url("../../../assets/icons/clone.svg");
    float: left;
    margin-left: 6px;

    &:hover {
      background: rgba(1, 157, 95, 0.1) 0% 0% no-repeat padding-box;
      background-image: url("../../../assets/icons/clone-hover.svg");
      background-position: center;
      border-radius: 30px;
    }
  }

  &:hover {
    cursor: pointer;
  }
}
.confirm-text {
  margin: auto;
}
.custom-actions {
  width: 90%;
}

.custom-cursor-status {
  &-disable {
    &:hover {
      cursor: default !important;
    }
  }

  &:hover {
    cursor: pointer;
  }
}
.icon {
  padding: 1.4rem;
}

.custom-icon-edit {
  background-image: url("../../../assets/icons/pencil-outline.svg");
  background-repeat: no-repeat;
  width: 32px;
  height: 25px;
  border-radius: 30px;
  background-position: center;
  cursor: pointer;
  &:hover {
    background: rgba(1, 157, 95, 0.1) 0% 0% no-repeat padding-box;
    background-image: url("../../../assets/icons/pencil-outline-houver.svg");
    background-position: center;
  }
}

.custom-icon-duplicate {
  background-image: url("../../../assets/icons/clone.svg");
  background-repeat: no-repeat;
  width: 36px;
  height: 25px;
  border-radius: 30px;
  background-position: center;
  cursor: pointer;
  &:hover {
    background: rgba(1, 157, 95, 0.1) 0% 0% no-repeat padding-box;
    background-image: url("../../../assets/icons/clone-hover.svg");
    background-position: center;
  }
}
.custom-icon-delete {
  background-image: url("../../../assets/icons/trash-can-outline.svg");
  background-repeat: no-repeat;
  width: 36px;
  height: 25px;
  border-radius: 30px;
  background-position: center;
  &:hover {
    background: rgba(213, 36, 83, 0.1) 0% 0% no-repeat padding-box;
    background-image: url("../../../assets/icons/trash-can-outline-houver.svg");
    background-position: center;
  }
}
.custom-actions div:not(:last-child) {
  margin-right: 1.2rem;
}
.pagination-training ul {
  margin-bottom: 0rem;
}
.scrollme {
  overflow-x: auto;
}
</style>
