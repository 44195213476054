import PortalViewsReport from "../../modules/reports/PortalViewsReport.vue";
import { PermissionList } from "../../static-data/PermissionList";
import { IsAbleNavigation } from "../utils/is-able-navigation";

const ReportsRoutes = {
  path: "portal-views-report",
  component: PortalViewsReport,
  beforeEnter: (to, from, next) =>
    IsAbleNavigation(to, from, next, PermissionList.REPORTS_MENU),
  meta: {
    requiresAuth: true,
  },
};

export default ReportsRoutes;
