import store from "../../store";
import { anchorBase64Download } from "../../utils/anchor-base64-download/anchor-base64-download";
import axios from "../http-client/axios";
import { METALICA_URL } from "../urls/base-urls";

export async function getRequestHistory(
  userDocument,
  serviceType,
  page,
  size = 10,
  updateData
) {
  const resp = await axios.get(
    `${METALICA_URL}/service-types-history?userDocument=${userDocument}${
      serviceType ? "&serviceType=" + serviceType : ""
    }&page=${page}&size=${size}&updateData=${updateData}`
  );
  const mappedData = await requestHistoryMapper(resp.data);
  return mappedData;
}

export async function getIdsAttachment(protocol) {
  const resp = await axios.get(
    `${METALICA_URL}/attachement?numberProtocol=${protocol}`
  );
  return resp.data;
}

export async function downloadAttachment(id, protocol) {
  await axios
    .get(
      `${METALICA_URL}/attachement/download?numberProtocol=${protocol}&id=${id}`
    )
    .then((res) => {
      anchorBase64Download(
        store.getters.isApp,
        res.data.contentType,
        res.data.filename	? res.data.filename : 'file' ,
        res.data.file
      );
    });
}

function requestHistoryMapper(data) {
  let requests = [];
  const { items, totalItems } = data;

  items.forEach((requestItem) => {
    const { serviceType, itens } = requestItem;

    const mappedItens = itens.map((item) => ({
      serviceType: serviceType,
      ...item,
    }));

    requests.push(...mappedItens);
  });

  return { items: requests, totalItems };
}
