<template>
  <div>
    <CustomModalTemplate
      modalId="details-analytics-modal"
      title="Ver analítica detalhada"
      ref="form-modal"
      noCloseOnBackdrop
    >
      <template slot="header"><h4>Ver analítica detalhada</h4></template>

      <template slot="content">
        <b-form id="alter-form" class="alter-form">
          <p class="p4 mt-2">
            <i class="fas fa-info-circle"></i>
            Clique no ícone para fazer o download da opção desejada.
          </p>

          <b-card class="custom-card my-4" border-variant="light">
            <b-form-row>
              <b-col>
                <img src="@/assets/icons/file-pdf.svg" class="mr-2 icon-size" />
                PDF - recebimentos
              </b-col>
              <b-col class="justify-content-end">
                <div>
                  <img
                    v-if="!this.loading && this.downloadType != DownloadType.PDF_RECEIPT"
                    class="button-custom"
                    src="@/assets/icons/download-outline.svg"
                    v-on:click="getDownloadClickHandler(DownloadType.PDF_RECEIPT)"
                  />
                  <img
                    v-if="this.loading && this.downloadType != DownloadType.PDF_RECEIPT"
                    src="@/assets/icons/download-outline.svg"
                    class="disable"
                  />
                </div>
                <i
                  class="fas fa-spinner fa-spin icon"
                  v-show="this.loading && this.downloadType == DownloadType.PDF_RECEIPT"
                ></i>
              </b-col>
            </b-form-row>
            <b-form-row>
              <b-col>
                <img
                  src="@/assets/icons/microsoft-excel.svg"
                  class="mr-2 icon-size"
                />
                EXCEL - recebimento
              </b-col>
              <b-col class="justify-content-end">
                <div>
                  <img
                    v-if="!this.loading && this.downloadType != DownloadType.EXCEL_RECEIPT"
                    class="button-custom"
                    src="@/assets/icons/download-outline.svg"
                    v-on:click="getDownloadClickHandler(DownloadType.EXCEL_RECEIPT)"
                  />
                  <img
                    v-if="this.loading && this.downloadType != DownloadType.EXCEL_RECEIPT"
                    src="@/assets/icons/download-outline.svg"
                    class="disable"
                  />
                </div>
                <i
                  class="fas fa-spinner fa-spin icon"
                  v-show="this.loading && this.downloadType == DownloadType.EXCEL_RECEIPT"
                ></i>
              </b-col>
            </b-form-row>
            <b-form-row>
              <b-col>
                <img src="@/assets/icons/xml.svg" class="mr-2 icon-size" />
                XML - resumido
              </b-col>
              <b-col class="justify-content-end">
                <div>
                  <img
                    v-if="!this.loading && this.downloadType != DownloadType.XML_SUMMARY"
                    class="button-custom"
                    src="@/assets/icons/download-outline.svg"
                    v-on:click="getDownloadClickHandler(DownloadType.XML_SUMMARY)"
                  />
                  <img
                    v-if="this.loading && this.downloadType != DownloadType.XML_SUMMARY"
                    src="@/assets/icons/download-outline.svg"
                    class="disable"
                  />
                </div>
                <i
                  class="fas fa-spinner fa-spin icon"
                  v-show="this.loading && this.downloadType == DownloadType.XML_SUMMARY"
                ></i>
              </b-col>
            </b-form-row>
            <b-form-row>
              <b-col>
                <img src="@/assets/icons/xml.svg" class="mr-2 icon-size" />
                XML - detalhado
              </b-col>
              <b-col class="justify-content-end">
                <div>
                  <img
                    v-if="!this.loading && this.downloadType != DownloadType.XML_DETAILS"
                    class="button-custom"
                    src="@/assets/icons/download-outline.svg"
                    v-on:click="getDownloadClickHandler(DownloadType.XML_DETAILS)"
                  />
                  <img
                    v-if="this.loading && this.downloadType != DownloadType.XML_DETAILS"
                    src="@/assets/icons/download-outline.svg"
                    class="disable"
                  />
                </div>
                <i
                  class="fas fa-spinner fa-spin icon"
                  v-show="this.loading && this.downloadType == DownloadType.XML_DETAILS"
                ></i>
              </b-col>
            </b-form-row>
            <b-form-row>
              <b-col>
                <img src="@/assets/icons/file-pdf.svg" class="mr-2 icon-size" />
                PDF - repasse médico
              </b-col>
              <b-col class="justify-content-end">
                <div>
                  <img
                    v-if="!this.loading && this.downloadType != DownloadType.MEDICAL_TRANSFER"
                    class="button-custom"
                    src="@/assets/icons/download-outline.svg"
                    v-on:click="getDownloadClickHandler(DownloadType.MEDICAL_TRANSFER)"
                  />
                  <img
                    v-if="this.loading && this.downloadType != DownloadType.MEDICAL_TRANSFER"
                    src="@/assets/icons/download-outline.svg"
                    class="disable"
                  />
                </div>
                <i
                  class="fas fa-spinner fa-spin icon"
                  v-show="this.loading && this.downloadType == DownloadType.MEDICAL_TRANSFER"
                ></i>
              </b-col>
            </b-form-row>
          </b-card>
        </b-form>
      </template>

      <template slot="footer">
        <b-button
          variant="primary"
          class="ml-4"
          @click="$bvModal.hide('details-analytics-modal')"
        >
          Fechar
        </b-button>
      </template>
      <div
        class="invalid"
        v-show="this.msgErroInvalid != null && !this.loading"
      >
        <i class="fas fa-exclamation-circle invalid"></i>
        {{ this.msgErroInvalid }}
      </div>
    </CustomModalTemplate>
  </div>
</template>

<script>
import CustomModalTemplate from "../../../../components/custom-modal/CustomModalTemplate.vue";
import { createToast } from "../../../../components/toast/toast";
import { downloadFile } from "../../../../services/analytics/download-services.js";
import { DownloadType } from "../../../../static-data/DownloadType";
import { anchorBase64Download } from "../../../../utils/anchor-base64-download/anchor-base64-download";
import { getMonthNumber } from "../../../../utils/date/date-utils";

export default {
  data() {
    return {
      msgErroInvalid: null,
      loading: false,
      downloadType: null,
    };
  },
  props: ["year", "month"],
  components: { CustomModalTemplate },
  computed: {
    DownloadType() {
      return DownloadType;
    },
  },
  methods: {
    getDownloadClickHandler(type) {
      if (this.loading) {
        return;
      }

      this.getDownload(type);
    },
    async getDownload(type) {
      try {
        this.msgErroInvalid = null;
        this.loading = true;
        this.downloadType = type;

        const mes = getMonthNumber(this.$props.month);
        const object = {
          downloadType: type,
          crm: this.$store.getters.getUserName,
          year: this.$props.year,
          month: mes,
        };

        const response = await downloadFile(object);

        if (!response.fileBase64) {
          this.msgErroInvalid = "Não existe documentos para serem baixados.";
          createToast(
            "Informação",
            "Não existe documentos para serem baixados.",
            "info"
          );
          this.loading = false;
          this.downloadType = null;
          return;
        }

        const fileBase64 = response.fileBase64;
        const extension = response.extension;

        anchorBase64Download(
          this.$store.getters.isApp,
          "data:application/zip",
          "Analítica " + mes + "-" + this.$props.year + " " + this.getTypeDescription(type) + extension,
          fileBase64
        );

        this.loading = false;
        this.downloadType = null;
      } catch (error) {
        this.downloadType = null;
        this.loading = false;
        console.error("getDownload", error);
      }
    },
    getTypeDescription(downloadType) {
      
      switch (downloadType) {
        case DownloadType.PDF_RECEIPT:
          return "PDF"
        case DownloadType.EXCEL_RECEIPT:
          return "EXCEL"
        case DownloadType.XML_SUMMARY:
          return "XML"
        case DownloadType.XML_DETAILS:
          return "XML"
        case DownloadType.MEDICAL_TRANSFER:
          return "PDF"
        default:
          return ""
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.icon {
  margin-right: 5px;
  &-size {
    color: var(--green-0);
    height: 3rem;
    width: 2.2rem;
  }
}

.button:hover {
  cursor: pointer;
}

.button-custom:hover {
  cursor: pointer;
}

.disable {
  opacity: 0.5;
}
</style>
