<template>
  <div class="page-container">
    <div>
      <section class="page-header">
        <h2 class="font-weight-bold">
          Financeiro
        </h2>
      </section>

      <div class="separator-line"></div>
      <section class="sub-header">
        <p>
          Aqui você terá acesso para consulta de informes e extratos
          financeiros, acompanhar a entrega e status das Notas Fiscais de Pessoa
          Física e seus indicadores no backbeat.
        </p>
      </section>
    </div>
    <section>
      <div class="card-container">
        <CustomInfoCard
          class="deck deck-margin"
          v-for="card in getCards"
          :key="card.id"
          :onCardClick="() => onClickCard(card)"
          :title="card.header"
          :description="card.description"
          :icon="card.icon"
        />
      </div>
    </section>
  </div>
</template>

<script>
import CustomInfoCard from "../../../components/custom-info-card/CustomInfoCard.vue";
import RoutesPaths from "../../../router/router-structure/routes-paths";

export default {
  components: {
    CustomInfoCard,
  },
  computed: {
    getCards: () => [
      {
        id: 0,
        icon: "finance.svg",
        header: "Analítica",
        description:
          "Tenha acesso a analítica financeira E gerencie seus ganhos",
        route: RoutesPaths.financial.analytics(),
      },
      {
        id: 1,
        icon: "chart-bar.svg",
        header: "Backbeat",
        description:
          "Acompanhe como foi o seu desempenho e o da sua especialidade.",
        route: RoutesPaths.financial.backbeat(),
      },
      {
        id: 2,
        icon: "document-card-outline.svg",
        header: "Recurso de Glosas",
        description:
          "Solicite o recurso de glosas das três últimas competências.",
        route: RoutesPaths.financial.reviewGlosses(),
      },
      {
        id: 3,
        icon: "file-table-box-multiple-outline.svg",
        header: "Histórico de Notas Fiscais",
        description: "Acompanhe a entrega e status de Notas Fiscais de PF.",
        route: RoutesPaths.financial.incomeHistory(),
      },
      {
        id: 4,
        icon: "report-income.svg",
        header: "Informe de Rendimentos",
        description:
          "Acesse o seu informe para a declaração de imposto de renda.",
        route: RoutesPaths.financial.reportIncome(),
      },
      {
        id: 5,
        icon: "sprout-outline.svg",
        header: "Fundo de Sustentabilidade",
        description: "Acesse o seu extrato de fundo de Sustentabilidade",
        route: RoutesPaths.financial.sustainabilityFund(),
      },
      {
        id: 6,
        icon: "file-document-outline.svg",
        header: "Cota Capital",
        description: "Acesse o seu extrato de cota de forma fácil e rápida",
        route: RoutesPaths.financial.quotaStatement(),
      },
      {
        id: 7,
        icon: "document-card-outline.svg",
        header: "Relatório de Glosas Voxis",
        description: "Acesse relatório de procedimentos hospitalares",
        route: RoutesPaths.financial.reportVoxis(),
      },
    ],
  },
  methods: {
    onClickCard({ route, link }) {
      if (link) window.open(link, "_blank");
      if (route) this.$router.push(route);
    },
  },
};
</script>

<style lang="scss" scoped>
.deck-margin {
  margin: 2rem;
}

.page-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.page-header {
  text-align: center;
}

.sub-header {
  max-width: 83rem;
  margin: 0 auto;
  margin-top: 1.5rem;
  text-align: center;
}

.card-container {
  max-width: 100rem;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  @include media-breakpoint-up(xs) {
    justify-content: center;
  }

  @include media-breakpoint-up(md) {
    justify-content: center;
  }
}
</style>
