<template>
  <div>
    <div ref="pdfContainer"></div>
    <b-card v-show="$props.loading && !this.msgError">
      <b-skeleton-img no-aspect height="405px"></b-skeleton-img>
    </b-card>
    <div
      class="container footer-pdf"
      :class="isMobile() ? 'footer-pdf-mobile' : 'footer-pdf-web'"
    >
      <slot name="left-button" v-if="!this.msgError"> </slot>
      <div
        class="footer-margin"
        style="flex-direction: column"
        v-show="!this.msgError"
      >
        <b-pagination
          v-model="currentPage"
          :total-rows="numPages"
          :per-page="1"
          aria-controls="my-table"
          size="lg"
        />
      </div>
    </div>

    <div class="invalid" v-show="this.msgError">
      <b-container>
        <b-row class="justify-content-center">
          <div class="attention-container">
            <div class="attention-container--outter-circle">
              <div class="attention-container--inner-circle">
                <i class="attention-container--icon fas fa-exclamation"></i>
              </div>
            </div>
          </div>
        </b-row>
        <b-row class="justify-content-center mt-5">
          <h5>
            {{ this.msgError }}
          </h5>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
const pdfjsLib = require("pdfjs-dist/build/pdf");
import { isMobile } from "../../utils/mobile/mobile";

pdfjsLib.GlobalWorkerOptions.workerSrc =
  "https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.10.377/pdf.worker.min.js";

export default {
  setup() {
    return {};
  },
  props: ["getDocumentHandler", "landscape", "loading"],
  data() {
    return {
      pdfViewer: null,
      currentPage: 1,
      numPages: 0,
      pdf: null,
      msgError: null,
    };
  },
  mounted() {
    this.setDocument();
  },
  watch: {
    currentPage: {
      handler(newValue) {
        if (this.pdf !== null && newValue <= this.numPages) {
          this.pdf.getPage(newValue).then(this.handlePages);
          if (newValue == this.pdf.numPages) {
            this.$emit("onLastPageVisualized");
          }
        }
      },
    },
  },
  methods: {
    isMobile: isMobile,

    async setDocument() {
      try {
        const getDocumentHandler = this.$props.getDocumentHandler;

        if (!getDocumentHandler)
          throw Error("Invalid getDocumentHandler!", getDocumentHandler);

        const pdfData = await getDocumentHandler();

        const loadingPdfTask = pdfjsLib.getDocument({ data: atob(pdfData) });

        await loadingPdfTask.promise.then(async (pdf) => {
          this.pdf = pdf;

          this.numPages = this.pdf.numPages;
          if (this.numPages <= 0) return;
          if (this.pdf.numPages == 1) this.$emit("onLastPageVisualized");

          await this.pdf.getPage(1).then(this.handlePages);
          this.$emit("onPdfLoaded");
        });
      } catch (error) {
        console.error(error);

        this.msgError = "Arquivo Indisponível!";
        document.getElementById("submitButtonText").hidden = true;
        this.$emit("onPdfLoaded");
      }
    },
    handlePages(page) {
      const scale = this.$props.landscape ? 2 : 1;
      const viewport = page.getViewport({ scale: scale });

      let canvas = document.createElement("canvas");
      canvas.setAttribute("class", "pdf-page");

      var context = canvas.getContext("2d");
      canvas.height = viewport.height;
      canvas.width = viewport.width;

      page.render({ canvasContext: context, viewport: viewport });

      let pdfContainer = this.$refs.pdfContainer;
      pdfContainer.innerHTML = "";
      pdfContainer.appendChild(canvas);

      if (this.pdf !== null && this.currentPage <= this.numPages) {
        this.pdf.getPage(this.currentPage);
      }
    },
  },
};
</script>

<style lang="scss">
.pdf-page {
  display: block;
  width: 100%;
  margin-bottom: 1rem;
}

.attention-container {
  &--icon {
    font-size: 4rem;
    color: white;
  }

  &--inner-circle {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 7rem;
    width: 7rem;
    background: var(--yellow-1);
    border-radius: 100%;
  }

  &--outter-circle {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 9rem;
    width: 9rem;
    background: var(--yellow-0);
    border-radius: 100%;
  }
}
.footer-margin {
  margin-top: 1rem;
  justify-content: center;
  align-content: center;
}
.footer-pdf {
  display: flex;
  justify-content: center;
}
.footer-pdf-mobile {
  flex-direction: column-reverse;
}
.footer-pdf-web {
  flex-direction: row;
}
</style>
