import Vue from "vue";
import Router from "vue-router";
import BaseLayoutNewMenu from "../layouts/BaseLayoutNewMenu.vue";
import LoginRoutes from "./login";
import store from "../store/index";
import RegisterRoutes from "./register";
import FaqRoutes from "./faq";
import HomeRoutes from "./home";
import RequestHistoryRoutes from "./request-history";
import { AcceptedUseTermsRoutes, UseTermsRoutes } from "./use-terms";
import DocumentsRoutes from "./documents";
import FileManagementRoutes from "./file-management";
import RoutesPaths from "./router-structure/routes-paths";
import EmptyHomeRoutes from "./empty-home";
import BaseRoutes from "./base-routes";
import VirtualOfficeRoutes from "./virtual-office";
import InstitutionalPageRoutes from "./institutional-page";
import AnalyticsRoutes from "./analytics";
import PasswordRecoveryRoutes from "./password-recovery";
import DeclarationsRoutes from "./declarations";
import FinancialRoutes from "./financial";
import BenefitRoutes from "./benefit";
import PanelEconomicRoutes from "./panel-economic";
import DocumentsAtasRoutes from "./documents-atas";
import TrainingRoutes from "./training-page";
import { createToast } from "../components/toast/toast";
import PrivacyRoutes from "./privacy-policy";
import { Firebase } from "../services/firebase/log-firebase";
import { RoleList } from "../static-data/RoleList";
import ContentManagementRoutes from "./content-management";
import TrainingManagementRoutes from "./training-management";
import ReportsRoutes from "./reports";
import ReportParticipationRoutes from "./report-participation";

Vue.use(Router);

const HomeRedirection = {
  path: "",
  redirect: "home",
};

function buildBaseRoutes() {
  const routes = [];
  routes.push(BaseRoutes);
  routes.push(PasswordRecoveryRoutes);
  routes.push(...LoginRoutes);
  routes.push(...UseTermsRoutes);
  routes.push(PrivacyRoutes);
  if (store.getters.isAdmin == undefined || store.getters.isAdmin)
    routes.push(routerAdmin);
  if (store.getters.isAdmin == undefined || !store.getters.isAdmin)
    routes.push(routerCoop);
  return routes;
}

const routerAdmin = {
  path: "/admin",
  component: BaseLayoutNewMenu,
  meta: {
      requiresAuth: true,
    },
    children: [
      HomeRedirection,
      RequestHistoryRoutes,
      HomeRoutes,
      RegisterRoutes,
      VirtualOfficeRoutes,
      InstitutionalPageRoutes,
      AnalyticsRoutes,
      DocumentsRoutes,
      DeclarationsRoutes,
      EmptyHomeRoutes,
      FileManagementRoutes,
      FaqRoutes,
      FinancialRoutes,
      BenefitRoutes,
      PanelEconomicRoutes,
      DocumentsAtasRoutes,
      AcceptedUseTermsRoutes,
      TrainingRoutes,
      ContentManagementRoutes,
      TrainingManagementRoutes,
      ReportsRoutes,
      ReportParticipationRoutes,
    ],
  };
const routerCoop = {
  path: "/",
  component: BaseLayoutNewMenu,
  meta: {
      requiresAuth: true,
    },
    children: [
      HomeRedirection,
      RequestHistoryRoutes,
      HomeRoutes,
      RegisterRoutes,
      VirtualOfficeRoutes,
      InstitutionalPageRoutes,
      AnalyticsRoutes,
      DocumentsRoutes,
      DeclarationsRoutes,
      EmptyHomeRoutes,
      FaqRoutes,
      FinancialRoutes,
      BenefitRoutes,
      PanelEconomicRoutes,
      DocumentsAtasRoutes,
      AcceptedUseTermsRoutes,
      TrainingRoutes,
    ],
  };

let router = new Router({
  routes: [...buildBaseRoutes()],
  base: "/admin",
});
const containsRole = (role) => store.getters.roles.indexOf(role) >= 0;
const checkAuthenticationRequired = (to) =>
  to.path != "/login" && to.fullPath.indexOf('password-recovery') == -1 && to.path != '/privacy-policy';

router.beforeEach(async (to, _from, next) => {
  
  const response = checkAuthenticationRequired(to)
    ? await store.dispatch("validarLogin")
    : true;

  if (!response) {
    store.dispatch("logout");
    next("/login");
    createToast(
      "Atenção",
      "Seu login expirou! É necessário efetuar o login novamente.",
      "warning"
    );
    return;
  }
  var firebase = new Firebase();

  if (store.getters.isLoggedIn) {
    if (containsRole(RoleList.AUTHORITY_ADMIN)
      && !to.path.includes("/admin")
    ) {
      firebase.addInfoLog(to.path,'NAVEGACAO');
      next(RoutesPaths.contentManagement.rootName());
      return;
    }
    if (!containsRole(RoleList.AUTHORITY_ADMIN)
        && to.path.includes("/admin")
    ) {
      firebase.addInfoLog(to.path,'NAVEGACAO');
      next(RoutesPaths.home.rootName());
      return;
    }
    firebase.addInfoLog(to.path,'NAVEGACAO');
    next();
    return;
  }

  if (to.matched.length <= 0) {
    firebase.addInfoLog(to.path,'NAVEGACAO');
    next();
    return;
  }

  //Validar e analisa, nunca está chegando.
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.getters.isLoggedIn) {
      store.dispatch("logout");
      next("/login");
      return;
    }

    if (store.getters.isLoggedIn) {
      if (
        !store.getters.dateAcceptedUseTerm &&
        to.path !== RoutesPaths.useTerm.rootName()
      ) {
        firebase.addInfoLog(to.path,'NAVEGACAO');
        next(RoutesPaths.useTerm.rootName());
        return;
      }
      firebase.addInfoLog(to.path,'NAVEGACAO');
      next();
      return;
    }
  } else {
    firebase.addInfoLog(to.path,'NAVEGACAO');
    next();
  }

  if (to.path == "/login") {
    if (store.getters.isLoggedIn) {
      next("/");
      return;
    }
  }
});
router.addDynamic = () => {
  const isAdmin = store.getters.isAdmin;
  const buildRouteObject = isAdmin ? routerAdmin : routerCoop;
  router.addRoute(buildRouteObject)
}
export default router;
