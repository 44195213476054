/* eslint-disable no-unused-vars */
import axios from "../http-client/axios";
import { SLIPKNOP_URL } from "../urls/base-urls";

// eslint-disable-next-line no-unused-vars
export async function getStatus(crm) {
  const resp = await axios({
    url: `${SLIPKNOP_URL}/user-status?crm=${crm}`,
    method: "get",
    data: {},
    headers: { "content-type": "application/json" },
  });
 
  return resp.data;
}

export default getStatus;
