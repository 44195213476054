import router from "../../../router";

const routerDynamic = {
  actions: {
    async setRoutesDynamic() {
      router.addDynamic();
    },
  }
};

export default routerDynamic;
