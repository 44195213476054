<script>
import CustomModalTemplate from "../components/custom-modal/CustomModalTemplate.vue";
import { inject } from "@vue/composition-api";
export default {
  props: ["modalId", "title", "hideFotter", "cancelButtonText"],
  components: { CustomModalTemplate },
  setup() {
    const globalLoading = inject("globalLoading");

    return { globalLoading };
  },
  methods: {
    onCloseModalHandler() {
      this.$refs["form-modal"].$children[0].hide();
    },
  },
  render() {
    return (
      <CustomModalTemplate
        modalId={this.$props.modalId}
        ref={"form-modal"}
        title={this.$props.title}
        noCloseOnBackdrop={this.$props.noCloseOnBackdrop}
      >
        <template slot="header">{this.$slots.header}</template>
        <template slot="content">{this.$slots.content}</template>
        <template slot="footer">
          {this.$slots.footer}
          <b-button
            variant="primary"
            onClick={this.onCloseModalHandler}
            class="ml-4"
          >
            {this.$props.cancelButtonText}
          </b-button>
        </template>
      </CustomModalTemplate>
    );
  },
};
</script>

<style lang="scss" scoped>
div.card {
  border-radius: 1.5%;
}
</style>
