<template>
  <div>
    <label class="upload-file-cursor text-primary" @click="uploadClick()">
      <img class="mr-3" :src="resolve_img_url('cloud-upload-outline.svg')" />
      <label class="camelCase">{{ this.$props.description }}</label>
    </label>

    <div
      class="box-file"
      :class="this.$props.custonMaxheight ? 'box-file-max' : 'box-file-min'"
    >
      <div v-for="(file, index) in files" :key="file.name">
        {{ file.name }}
        <i
          v-on:click="removeFile(index)"
          class="fas fa-times text-primary ml-1 upload-file-cursor"
        ></i>
      </div>
    </div>
    <input
      type="file"
      ref="file"
      class="input-file"
      :multiple="this.$props.multiple"
      v-on:change="handleFileUpload()"
      :accept="this.$props.accept"
      capture="user"
    />
    <div
      class="invalid"
      v-show="
        !this.$props.state && this.$props.state != null && this.$props.required
      "
    >
      <i class="fas fa-exclamation-circle invalid"></i>
      {{ this.$props.msgErro ? this.$props.msgErro : this.msgErroInvalid }}
    </div>
  </div>
</template>

<script>
import resolve_img_url from "../../utils/image/image-utils";
import { base64toFile } from "../../utils/File/fileUtils";
import eventBus from "./eventBus.js";

export default {
  name: "upload-file",
  props: [
    "accept",
    "multiple",
    "description",
    "state",
    "msgErro",
    "required",
    "custonMaxheight",
  ],
  created() {
    this.$root.$refs.UploadFile = this;
  },
  mounted() {
    
    eventBus.$on("attachFilesOnForm", (jsonSerializedFiles) => {
      this.attachFilesFromApp(jsonSerializedFiles);
    });
  },
  methods: {
    async uploadClick(){
      if(this.$store.getters.isApp) {
        window.upload_channel.postMessage(this.$props.multiple);
      }
      else {
        this.$refs.file.click();
      }      
    },
    async attachFilesFromApp(base64FileObjects) {
      
      var jsonString = atob(base64FileObjects);

      var jsObject = JSON.parse(jsonString);

      if (jsObject != null || jsObject != undefined) {
        for (const fileObject of jsObject) {
          var file = base64toFile(fileObject.content, fileObject.name);
          this.files.push(file);
        }
        this.files = [...this.$refs.file.files, ...this.files];
        
        const names = this.files.map((o) => o.name);
        this.files = this.files.filter(
          ({ name }, index) => !names.includes(name, index + 1)
        );
        
        this.$emit("updateFile", this.files);
      }  
    },
    resolve_img_url: resolve_img_url,
    handleFileUpload() {
      this.files = [...this.$refs.file.files, ...this.files];
      const names = this.files.map((o) => o.name);
      this.files = this.files.filter(
        ({ name }, index) => !names.includes(name, index + 1)
      );
      this.$emit("updateFile", this.files);
    },
    hasFile() {
      return this.files && this.files.length > 0;
    },
    removeFile(index) {
      const fileListArrFile = Array.from(this.files);

      fileListArrFile.splice(index, 1);
      this.files = fileListArrFile;
      const dT = new ClipboardEvent("").clipboardData || new DataTransfer();
      for (let file of fileListArrFile) {
        dT.items.add(file);
      }
      this.$refs.file.files = dT.files;

      this.$emit("updateFile", this.files);
    },
    removeAllFiles() {
      this.files = [];
      const dT = new ClipboardEvent("").clipboardData || new DataTransfer();
      this.$refs.file.files = dT.files;
      this.$emit("updateFile", this.files);
    },
  },
  data() {
    return {
      files: [],
      msgErroInvalid: "É necessário o anexo do documento",
    };
  },
};
</script>

<style lang="scss" scoped>
.upload-file-cursor {
  cursor: pointer;
  font-size: multiplier-default-font-size(3);
  align-items: center;
}

.input-file {
  display: none;
}

.box-file-max {
  max-height: 9rem;
}

.box-file-min {
  max-height: 5rem;
}

.box-file {
  min-height: 2rem;
  overflow-y: auto;
  overflow-x: hidden;
}

.invalid {
  color: var(--danger-2);
}
</style>
