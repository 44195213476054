<template>
  <div class="absence">
    <SimpleLayout
      viewComponent="FuneralInformations"
      :cardInfo="this.cardInfo"
      :leftAction="this.leftAction"
      :leftLinkNavigation="this.leftLinkNavigation"
    />
  </div>
</template>

<script>
import Vue from "vue";
import SimpleLayout from "../../../layouts/SimpleLayout.vue";
import { ServiceType } from "../../../static-data/ServiceType";
import RoutesPaths from "../../../router/router-structure/routes-paths";

Vue.component("FuneralInformations", () =>
  import("../components/FuneralInformations.vue")
);

export default {
  components: {
    SimpleLayout,
  },
  data: function() {
    return {
      leftAction: {
        text: "Benefícios",
        onClick: this.leftActionClickHandler,
      },
      leftLinkNavigation: this.leftHistoryNavigation,
      cardInfo: {
        headerIcon: "fas fa-map-marker-alt",
        icon: "funeral_assistence.svg",
        title: "Auxílio Funeral",
        description:
          "Benefício concedido aos sucessores legais do médico cooperado em caso de falecimento.",
      },
    };
  },
  methods: {
    leftActionClickHandler() {
      this.$router.push({
        path: RoutesPaths.benefit.rootName(),
        replace: true,
      });
    },
    leftHistoryNavigation() {
      this.$router.push({
        path: RoutesPaths.requestHistory.rootName(),
        params: {
          serviceType: ServiceType.Absence,
        },
      });
    },
  },
};
</script>

<style lang="scss">
.absence {
  .simple-layout--content {
    width: 100%;
  }
}
</style>
