<template>
  <div>
    <CustomModalTemplate
      modalId="communication-password-modal"
      ref="communication-password-modal"
      class="communication-password-modal"
      noCloseOnBackdrop
      noCloseOnEsc
    >
      <template slot="header">
        <div class="header-communication-password color-green-2">
          <strong>AVISO DE SEGURANÇA: REQUISITO DE SENHA ATUALIZADO</strong>
        </div>
        <div class="second--image-div">
          <img
            class="second--image"
            src="../../../assets/images/unimed-robozinho.svg"
          />
        </div>
      </template>
      <template slot="content">
        <div class="content-principal">
          <p class="color-green-2 font-communication-password-size">
            Sua senha atual não atende ao novo padrão de segurança, que exige um
            mínimo de 12 caracteres.
          </p>
          <p class="color-green-2 font-communication-password-size">
            Para proteger sua conta e continuar acessando o Portal, você deve
            atualizar sua senha.
          </p>
          <p class="color-green-2 font-communication-password-size">
            Deseja redefinir sua senha neste momento?
          </p>
        </div>
      </template>
      <template slot="footer">
        <div class="footer-div-container">
          <div class="line-footer"></div>
          <div class="footer-div-button">
            <b-button
              class="ml-4"
              variant="outline-primary"
              @click="this.onExit"
            >
              Sair do Portal
            </b-button>
            <b-button
              class="ml-4"
              variant="primary"
              @click="this.onChangePassword"
            >
              Redefinir senha
            </b-button>
          </div>
        </div>
      </template>
    </CustomModalTemplate>
  </div>
</template>

<script>
import CustomModalTemplate from "../../../components/custom-modal/CustomModalTemplate.vue";
import { Firebase } from "../../../services/firebase/log-firebase";
import BlipClient from "../../../components/blip-chat/blip-chat";
import { isMobile } from "../../../utils/mobile/mobile";
import RoutesPaths from "../../../router/router-structure/routes-paths";

export default {
  components: {
    CustomModalTemplate,
  },
  data() {
    return {
      firebase: null,
    };
  },
  async mounted() {
    await this.checkVisibility();
    this.initLog();
  },
  methods: {    
    initLog() {
      this.firebase = new Firebase();
    },
    onChangePassword() {
      this.onCloseModalHandler();
      if (this.firebase !== null) {
        this.firebase.addInfoLog(
          "- Clicou em trocar senha.",
          "COMUNICADO PASSWORD"
        );
      }
      this.$bvModal.show("change-password-modal");
    },
    async onExit() {
      try {
        if (this.firebase !== null) {
          this.firebase.addInfoLog(
            "- Clicou em sair e não trocar senha.",
            "COMUNICADO PASSWORD"
          );
        }
        if (!isMobile() && !this.$store.getters.isAdmin) {
          const blipClient = BlipClient.getInstance();
          blipClient.destroy();
        }
        this.$store.dispatch("logout");

        this.$router.push({
          path: RoutesPaths.login.rootName(),
          query: { isApp: this.$store.getters.isApp },
        });       
        
      } catch (error) {
        console.error("logout", error);
      }
    },
    onCloseModalHandler() {
      this.$refs["communication-password-modal"].$children[0].hide();
    },
    async checkVisibility() {
      
      if (this.$store.getters.communicationPassword) {
        if (this.firebase !== null) {
          this.firebase.addInfoLog(
            "- Mostrou modal de troca de senha.",
            "COMUNICADO PASSWORD"
          );
        }
        
        this.$refs["communication-password-modal"].$children[0].show();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
p {
  color: black;
}
.content-principal {
  height: 17rem;
  margin-top: 1rem;
  width: 70%;
  padding: 0rem 0.5rem 0rem;
}
.second--image-div {
  position: absolute;
  top: 0;
  right: 0;
  margin: 7px 2rem 0px 0px;
}
.icon--close {
  cursor: pointer;
  @extend .second--image-div;
  margin: 18px 2rem 0px 0px;
}

@media only screen and (max-width: 800px) {
  .content-principal {
    width: 100%;
  }
  .second--image {
    width: 60%;
    float: right;
  }
}
.footer-div-container {
  position: relative;
  width: 100%;
  padding: 0rem 0rem 0rem 0rem;
}
.footer-div-button {
  position: relative;
  margin-top: 2rem;
  display: flex;
  padding: 1rem 2rem 0rem 2rem;
  justify-content: flex-end;
}
.line-footer {
  position: absolute;
  width: 100%;
  height: 8px;
  background: linear-gradient(to bottom, transparent 0%, #0000000a 80%);
}
.font-communication-password-size {
  font-size: 16px;
}
.font-communication-password-size a {
  font-size: 1.6rem;
  color: var(--green-2);
  text-decoration: underline;
}
.font-communication-password-size:not(:last-of-type) {
  margin-bottom: 2.5rem;
}
.header-communication-password {  
  font-size: 16px;  
}
@media only screen and (max-width: 800px) {
  .content-principal {
    height: 21rem;
  }
}

</style>

<style lang="scss">

#communication-password-modal>div
{
  display: flex;
  align-items: center;
  min-height: calc(100% - 3.5rem);
}

#communication-password-modal div.modal-content {
  padding: 0rem 0rem;
}
#communication-password-modal footer.modal-footer {
  padding: 0rem 0rem;
}

#communication-password-modal .modal-header {
  padding-left: 1.5rem;
  padding-top: 4rem;
}
</style>
