<template>
  <div class="password-container">
    <div class="password-card-container">
      <div class="password-wecolme">
        <h2>Redefinir senha</h2>
        <div class="separator-line"></div>
      </div>
      <div class="mt-5">
        <b-form @submit="this.onSubmit">
          <b-card class="password-card card-border--1">
            <b-card-body>
              <b-form-group label="Nova senha" label-for="password">
                <i
                  class="fas fa-eye"
                  v-show="showPassword"
                  @click="toogleShowPassword()"
                ></i>
                <i
                  class="fas fa-eye-slash"
                  v-show="!showPassword"
                  @click="toogleShowPassword()"
                ></i>
                <b-form-input
                  placeHolder="Informe sua nova senha"
                  id="password"
                  :type="getInputType()"
                  required
                  v-model.lazy.trim="form.password"
                />
              </b-form-group>
              <b-form-group
                class="my-5"
                label="Confirmar nova senha"
                label-for="passwordConfirm"
              >
                <i
                  class="fas fa-eye"
                  v-show="showPassword"
                  @click="toogleShowPassword()"
                ></i>
                <i
                  class="fas fa-eye-slash"
                  v-show="!showPassword"
                  @click="toogleShowPassword()"
                ></i>
                <b-form-input
                  placeHolder="Informe sua nova senha"
                  id="passwordConfirm"
                  ref="passwordConfirm"
                  :type="getInputType()"
                  required
                  v-model.lazy.trim="form.passwordConfirm"
                  :state="validateFieldSamePassword('passwordConfirm')"
                />

                <div
                  class="invalid"
                  v-show="this.msgErroInvalid != null && !this.loading"
                >
                  <i class="fas fa-exclamation-circle invalid"></i>
                  {{ this.msgErroInvalid }}
                </div>
              </b-form-group>
              <RulePassword
                :password="form.password"
                :passwordConfirm="form.passwordConfirm"
                @onValidPassword="isValidPassword"
              />
              <b-button
                :disabled="isDisabled"
                class="button"
                @click="onConcluir"
                variant="primary"
                id="concluir"
                >Concluir
                <i class="fas fa-spinner fa-spin icon" v-show="this.loading"></i
              ></b-button>
            </b-card-body>
          </b-card>
        </b-form>
      </div>
    </div>
  </div>
</template>

<script>
import { passwordRecoveryChangeService } from "../../../services/password-recovery/change-password-service";
import {
  validateFieldPassword,
  validateFieldSamePassword,
} from "../../../utils/validate/validate.js";
import { createToast } from "../../../components/toast/toast";
import RulePassword from "../../../components/password/RulePassword.vue";

export default {
  components: { RulePassword },
  data() {
    return {
      formSubmited: false,
      loading: false,
      msgErroInvalid: null,
      isDisabled: true,
      showPassword: false,
      form: {
        password: null,
        passwordConfirm: null,
        authCode: null,
      },
      validations: {},
      validateFieldPassword,
      validateFieldSamePassword,
    };
  },
  methods: {
    requiredFieldsFilled() {
      return Object.values(this.validations).every(
        (fieldValue) => fieldValue == true
      );
    },
    async onConcluir() {
      if (this.onSubmit()) {
        this.loading = true;
        this.setDisabled(true);

        this.form.authCode = this.$route.params.id;
        const objeto = {
          password: this.form.password,
          passwordConfirm: this.form.passwordConfirm,
          authCode: this.form.authCode,
        };

        passwordRecoveryChangeService(objeto)
          .then((response) => {
            console.log(response);

            this.loading = false;
            this.setDisabled(false);
            this.msgErroInvalid = "";

            createToast("Informação", "Senha alterada com sucesso.", "success");
            this.$router.push("/");
          })
          .catch((error) => {
            this.loading = false;
            this.setDisabled(false);
            console.error("onConcluir", error);
            if (
              error.response &&
              error.response.status == 400 &&
              error.response.data &&
              error.response.data.message
            ) {
              this.msgErroInvalid = error.response.data.message;
            } else if (
              error.response &&
              error.response.status != 400 &&
              error.response.data &&
              error.response.data.message
            ) {
              createToast("Atenção", error.response.data.message);
            } else {
              createToast("Atenção", "Ocorreu um erro ao alterar senha.");
            }
          });
      }
    },
    onSubmit() {
      this.formSubmited = true;
      if (this.requiredFieldsFilled()) {
        this.msgErroInvalid = null;
        return true;
      }
    },
    setDisabled() {
      this.isDisabled = !this.formIsValid;
    },
    toogleShowPassword() {
      this.showPassword = !this.showPassword;
    },
    getInputType() {
      return this.showPassword ? "text" : "password";
    },
    isValidPassword(isValidPassword) {
      this.isDisabled = !isValidPassword;
    },
  },
};
</script>

<style lang="scss" scoped>
.password-card-container {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  width: 55rem;
}

.password-card {
  width: 44rem;
  padding: 4rem 5rem;
  @include header5;
}

.password-container {
  display: flex;
  width: 100%;
  height: 100%;
  background-image: url("../../../assets/images/passwordRecovery/password.svg");
  background-repeat: no-repeat;

  ul {
    padding-left: 0;
    list-style-type: none;
    margin-bottom: 40px;
  }

  i.fa-eye,
  i.fa-eye-slash {
    position: absolute;
    margin-left: 263px;
    margin-top: 10px;
    &:hover {
      cursor: pointer;
    }
  }

  input.form-control {
    text-align: left;
  }

  .password-tips {
    color: #707070;
    .valid {
      color: var(--primary);
    }
  }

  @include media-breakpoint-up(xs) {
    justify-content: center;
    background-position: right bottom;
    background-size: 90% 50%;
  }

  @include media-breakpoint-up(sm) {
    justify-content: center;
    background-position: right bottom;
    background-size: 90% 50%;
  }

  @include media-breakpoint-up(md) {
    justify-content: center;
    background-position: right bottom;
    background-size: 70% 50%;
  }

  @include media-breakpoint-up(lg) {
    justify-content: initial;
    background-position: 60% 30%;
    background-size: 60% 60%;
  }

  @include media-breakpoint-up(xl) {
    justify-content: initial;
    background-position: 80% 30%;
    background-size: 60% 60%;
  }
}

.password-card-container {
  /* width: 44rem; */
  /* height: 41rem; */
}

.password-wecolme {
  max-width: 43rem;
  text-align: center;
}
.icon {
  font-size: 2rem;
}

.button {
  position: absolute;
  bottom: 5rem;
  width: 11rem;
  height: 4rem;
}

.invalid {
  color: #dc3545;
  margin-top: 0.5rem;
}
</style>
