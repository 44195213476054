import { ServiceType } from "./ServiceType";

export const ServiceTypesOptions = [
  { value: ServiceType.All, text: "Todas Solicitações" },
  {
    value: ServiceType.PersonalInformation,
    text: "Informações pessoais",
  },
  { value: ServiceType.Contacts, text: "Contatos" },
  { value: ServiceType.Addresses, text: "Endereços" },
  {
    value: ServiceType.MedicalSpecialities,
    text: "Especialidades médicas",
  },
  { value: ServiceType.AttendencePlaces, text: "Locais de atendimento" },
  {
    value: ServiceType.BankData,
    text: "Dados bancários",
  },
  {
    value: ServiceType.PaymentRouting,
    text: "Direcionamento de Pagamentos",
  },
  { value: ServiceType.InssInformation, text: "Informações de INSS" },
  { value: ServiceType.IssInformation, text: "Informações de ISS" },
  { value: ServiceType.PermitInformation, text: "Alvará" },
  { value: ServiceType.Absence, text: "Afastamento" },
  { value: ServiceType.Benefactor, text: "Benemérito" },
  { value: ServiceType.Declaration, text: "Declaração" },
  { value: ServiceType.AbsenceAnticipation, text: "Antecipação do retorno do afastamento" },
  { value: ServiceType.AbsenceDelay, text: "Adiamento do afastamento" },
  { value: ServiceType.TemporaryAssistance, text: "Assistência Temporária" },
  { value: ServiceType.TemporaryAssistanceAnticipation, text: "Assistência Temporária Antecipação" },
  { value: ServiceType.ItensGlossados, text: "Revisão de glosas" },
  { value: ServiceType.GENERAL_REQUESTS, text: "Outras Solicitações" },
];
