import TrainingManagement from "../../modules/training-management/views/TrainingManagement.vue"
import TrainingManagementForm from "../../modules/training-management/components/forms/TrainingManagementForm.vue"

import RouterViewRender from "../../utils/routes/router-view-render";

const TrainingManagementRoutes = {
  path: "",
  component: RouterViewRender,
  meta: {
    requiresAuth: true,
  },
  children: [
    {
      path: "training-management",
      component: TrainingManagement,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "training-management-form/:id",
      component: TrainingManagementForm,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "training-management-form",
      component: TrainingManagementForm,
      meta: {
        requiresAuth: true,
      },
    },
  ],
};

export default TrainingManagementRoutes;
