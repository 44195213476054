export function getFunctionalityName(name) {
    const funcNames = {
        "acessou /home":"Acesso a Home",
        "acessou /register":"Menu cadastro",
        "acessou /financial/analytics":"Analiticas",
        "acessou /financial/backbeat":"Visualizar os dados do backbeat",
        "acessou /financial/sustainability-fund":"Fundo de sustentabilidade",
        "acessou /login":"Logins na plataforma",
        "acessou /password-recovery":"Recuperação de senha",
        "acessou /declarations":"Pagina de declarações",
        "acessou /declarations/cooperative-declaration":"Declaração de médico cooperado",
        "acessou /financial/review-glosses":"Solicitação de revisão de glosas",
        "acessou /panel-economic":"Painel econômico",
        "acessou /financial/income-history":"Histórico de notas fiscais cadastradas",
        "acessou /register/personal-information":"Cadastro Informações pessoais",
        "acessou /register/medical-specialities":"Cadastro de especialidade médica",
        "acessou /register/attendence-place":"Cadastro - Local de atendimento",
        "acessou /request-history":"Histórico de solicitações",
        "acessou /register/payment-options":"Cadastro de direcionamento de pagamentos",
        "acessou /change":"Redefinição de senha",
        "acessou /benefit":"Tela de benefícios",
        "acessou /faq":"Acesso ao FAQ",
        "acessou /financial":"Menu Financeiro",
        "acessou /financial/quota-statement":"Extrato de cota capital",
        "acessou /virtual-office":"Consultório virtual",
        "acessou /institutional-page":"Página institucional",
        "acessou /documents-atas":"Página de documentos",
        "acessou /register/tax-license":"Atualização cadastral - Impostos (iss e inss)",
        "acessou /benefit/health-plan":"Informações plano de saúde cooperado",
        "acessou /benefit/absence":"Afastamento temporário",
        "acessou /financial/report-income":"Informe de Rendimentos",
        "acessou /declarations/inss":"Solicitação de declarações de INSS",
        "acessou /benefit/benefactor":"Tela de solicitações de benemérito",
        "acessou /funeral-assistance":"Informações sobre Aux. Funeral",
        "acessou /declarations/ltcatpp":"Solciitação de LTCAT",
        "acessou /benefit/temporary-assistance":"Auxilio temporário",
        "acessou /declarations/others":"Solcitação de outras declarações",
        "acessou /use-term/accepted":"Termo de uso",
        "acessou /declarations/iss":"Solicitação de declarações de ISS",
        "acessou /benefit/other-solicitation":"Serviços de outras solicitações",
        "acessou /financial/report-voxis":"Relatório de glosas Voxis",
        "acessou /training-page":"Pagina de treinamento",
        "acessou /empty-home":"Acesso Admin",
        "acessou /financial/backbeat-know-more":"Backbeat - Saiba Mais",
        "acessou Assistiu vídeo backbeat":"Assistiu vídeo backbeat",
        "acessou - Iniciou o video da tela saiba mais sobre o Backbeat.":"Iniciou vídeo backbeat",
        "acessou a tela de saiba mais sobre o Backbeat.":"Backbeat - Acessou tela saiba mais",
        "acessou  a tela de saiba mais sobre o Backbeat .":"Backbeat - Acessou tela saiba mais",
    }

    return funcNames[name];
}