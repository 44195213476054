import login from "./login";
import userInformation from "./user-information";
import userRoles from "./user-roles";
import useTerm from "./use-term";
import isApp from "./is-app";
import statusUser from "./status";
import appInfo from "./app-info";
import analyticalData from "./analytics";
import newnessData from "./newness";
import userInteractionInactivity from "./user-interaction-inactivity";
import updateOccurrences from "./update-occurrences-requests";
import routerDynamic from "./router"
import communicationPassword from "./communication-password";
export default {
  login,
  userInformation,
  userRoles,
  useTerm,
  appInfo,
  analyticalData,
  statusUser,
  userInteractionInactivity,
  updateOccurrences,
  isApp,
  routerDynamic,
  newnessData,
  communicationPassword
};
