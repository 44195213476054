var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CustomAccordionButton',{staticClass:"component-training",attrs:{"collapseId":_vm.id}},[_c('template',{slot:"header"},[_c('div',{staticClass:"title T16 color-primary"},[_vm._v(" "+_vm._s(_vm.$props.title)+" ")])]),_c('template',{slot:"content"},[_c('hr'),_c('Period',{attrs:{"item":_vm.$props.item}}),_vm._l((_vm.$props.steps),function(item,index){return _c('div',{key:_vm.id + index},[_c('hr'),_c('div',[_c('div',{staticClass:" align-items-center step-title T16",class:_vm.getClassToPending(item, _vm.$props.steps[index - 1]),on:{"click":function () { return _vm.onClick(item); }}},[_c('div',[_c('img',{staticClass:"custom-img",attrs:{"src":_vm.getPathUrl(item, _vm.$props.steps[index - 1])}})]),_c('div',{staticClass:"title align-items-center",class:_vm.isDisabled(item, _vm.$props.steps[index - 1]) && 'custom-disabled'},[_vm._v(" "+_vm._s(item.title)+" ")]),(_vm.onCheckDownload(item))?_c('div',{staticClass:"ml-auto icon-download-pdf"},[_c('img',{staticClass:"custom-img",class:_vm.isDisabled(item, _vm.$props.steps[index - 1]) && 'custom-disabled',attrs:{"src":_vm.resolve_path_url('download-pencil-outline.svg')},on:{"click":function($event){!_vm.isDisabled(item, _vm.$props.steps[index - 1]) && _vm.onDownload(item)}}})]):_vm._e()]),(_vm.getFilesAdditionalNoPrincial(item.filesAdditional).length > 0)?_c('div',{staticClass:"custom-files-additional pb-3"},[_c('div',{staticClass:"effect-line-left"},[_c('hr',{staticClass:"step-file--margin-left"}),_vm._l((_vm.getFilesAdditionalNoPrincial(
                item.filesAdditional
              )),function(file,fileIndex){return _c('div',{key:_vm.id + index + fileIndex},[_c('div',{staticClass:"align-items-center step-file step-file--margin-left",class:!_vm.isDisabled(item, _vm.$props.steps[index - 1]) && 'cursor-point',on:{"click":function($event){!_vm.isDisabled(item, _vm.$props.steps[index - 1]) &&
                    _vm.onDownload(file)}}},[_c('p',{staticClass:"text-file pr-1",class:_vm.isDisabled(item, _vm.$props.steps[index - 1]) &&
                      'custom-disabled',domProps:{"textContent":_vm._s(
                    file.fileName ? file.fileName : 'file-' + (fileIndex + 1)
                  )}}),_c('img',{staticClass:"custom-img",class:_vm.isDisabled(item, _vm.$props.steps[index - 1])
                      ? 'custom-disabled'
                      : 'cursor-point',attrs:{"src":_vm.resolve_path_url('download-pencil-outline.svg')}})])])})],2)]):_vm._e()])])})],2)],2)}
var staticRenderFns = []

export { render, staticRenderFns }