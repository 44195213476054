import axios from "../http-client/axios";
import { GORIJA_URL } from "../urls/base-urls";
import {
  mapperToResponse,
  mapperToCardsResponse,
} from "./demonstrative-amounts-paid-mapper";

export async function getCards() {
  const resp = await axios({
    url: `${GORIJA_URL}/doctor-card`,
    method: "get",
    data: {},
    headers: { "content-type": "application/json" },
  });

  const data = resp.data;
  return mapperToCardsResponse(data);
}

export async function getYears(card) {
  const resp = await axios({
    url: `${GORIJA_URL}/demonstrative-payment/reference-years?card=${card}`,
    method: "get",
    data: {},
    headers: { "content-type": "application/json" },
  });

  const data = resp.data;

  return mapperToResponse(data.years);
}

export async function downloadDocument(card, year) {
  const resp = await axios({
    url: `${GORIJA_URL}/demonstrative-payment/download?year=${year}&card=${card}`,
    method: "get",
    data: {},
    headers: { "content-type": "application/json" },
  });

  return resp.data;
}
