const resolve_img_url = (path) => {
  try {
    if (path) {
      let images = require.context(
        "@/assets/icons/",
        false,
        /\.png$|\.jpg$|\.svg$/
      );
      return images("./" + path);
    } else {
      return "";
    }
  } catch (error) {
    console.error(`Error on initialize ${path}: `, error);
    return "";
  }
};

export const resolve_path_url = (path) => {
  try {
    if (path) {
      let images = require.context(
        "@/assets/images/",
        false,
        /\.png$|\.jpg$|\.svg$/
      );
      return images("./" + path);
    } else {
      return "";
    }
  } catch (error) {
    console.error(`Error on initialize ${path}: `, error);
    return "";
  }
};
export default resolve_img_url;
