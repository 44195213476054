import documentsAtasPage from "../../modules/documents-atas/views/documents-atas.vue";
import { PermissionList } from "../../static-data/PermissionList";
import RouterViewRender from "../../utils/routes/router-view-render";
import { IsAbleNavigation } from "../utils/is-able-navigation";

const DocumentsAtasRoutes = {
  path: "documents-atas",
  component: RouterViewRender,
  beforeEnter: (to, from, next) =>
    IsAbleNavigation(to, from, next, PermissionList.INSTITUTIONAL_PAGE_MENU),
  children: [
    {
      path: "",
      component: documentsAtasPage,
      meta: {
        requiresAuth: true,
      },
    },
  ],
};

export default DocumentsAtasRoutes;
