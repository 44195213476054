import { returnDateFormat } from "../../utils/date/date-utils";
import { formatCpfCnpj, formatPispasep } from "../../utils/format/formtUtils";

export default function mapperToGeneralInformatioRequest(
  formData,
  requestAction
) {
  let toReturn = {"Ação da solicitação": requestAction}
  if (formData.name) {
    toReturn["NOME"] = formData.name
  }
  if (formData.birthdate) {
    toReturn["DATA DE NASCIMENTO"] = returnDateFormat(formData.birthdate)
  }
  if (formData.rg) {
    toReturn["RG"] = formData.rg
  }
  if (formData.cpf) {
    toReturn["CPF"] = formatCpfCnpj(formData.cpf)
  }
   
  if (formData.schooling) {
    toReturn["ESCOLARIDADE"] = formData.schooling.description
  }
   if (formData.crm) {
    toReturn["CRM"] = formData.crm
  }
   if (formData.nationalHealthCard) {
    toReturn["CARTÃO NACIONAL DE SAÚDE"] = formData.nationalHealthCard
  }
  if (formData.pis) {
    toReturn["PIS/NIT"] = formatPispasep(formData.pis)
  }

  if (formData.maritalStatus) {
    toReturn["Estado Civil"] = formData.maritalStatus.description
  }
  if (formData.ethnicity) {
    toReturn["Raca"] = formData.ethnicity.description
  }
  if (formData.gender) {
    toReturn["Sexo"] = formData.gender.description
  }
  if (formData.retiree) {
    toReturn["Aposentado"] = formData.retiree.description
  }
  return toReturn;
}
