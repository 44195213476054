import initializeJsonFromLocalStorage from "../../../utils/storage/initialize-storage-utils";

const updateOccurrences = {
  state: {
    updateOccurrences: initializeJsonFromLocalStorage(
      "update-occurrences-data"
    ),
  },
  mutations: {
    setOccurrencesData(state, data) {
      state.updateOccurrences = data;
    },
    clearOccurrencesData(state) {
      state.updateOccurrences = null;
    },
  },
  actions: {
    setStoreOccurrencesData({ commit }, data) {
      localStorage.setItem("update-occurrences-data", JSON.stringify(data));
      commit("setOccurrencesData", data);
    },
    clearOccurrencesData({ commit }) {
      return new Promise((resolve, reject) => {
        try {
          localStorage.setItem("update-occurrences-data", "");
          commit("clearOccurrencesData");
          resolve();
        } catch (err) {
          reject(err);
        }
      });
    },
  },
  getters: {
    getOccurrencesData: (state) => state.updateOccurrences,
  },
};

export default updateOccurrences;
