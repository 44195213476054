<template>
  <b-card
    no-body
    border-variant="light"
    :class="getCardClass(isClickable, isClickable)"
    @click="interceptionClickHandler"
  >
    <div class="tag-new" v-if="isNew($props.featureName)">
      {{ getLabelNew() }}
    </div>
    <b-card-body class="custom-card--body">
      <div class="tam-control-up">
        <i
          :class="'custom-card--icon-card ' + $props.headerIcon"
          v-if="!$props.icon"
        >
        </i>
        <img :src="resolve_img_url($props.icon)" v-else />
      </div>
      <div class="tam-control-down">
        <b-card-text class="p-card-text">
          <h5>
            {{ $props.title }}
          </h5>
          {{ $props.description }}
        </b-card-text>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import resolve_img_url from "../../utils/image/image-utils";
import { getNewByName } from "../../utils/news/news-utils";
import { validateShowNewness, saveNewness } from "../../services/newness/newness-new.js";

export default {
  props: [
    "headerIcon",
    "title",
    "description",
    "onCardClick",
    "responsive",
    "icon",
    "featureName",
  ],
  setup(props) {
    const invalidHandler = function invalidHandler(e) {
      console.warn("Invalid card click handler", e);
    };

    const onClickHandler = props.onCardClick || invalidHandler;
    const isClickable = !!props.onCardClick;

    const getCardClass = (isClickable) => {
      return (
        "custom-card custom-card--size-control " +
        (isClickable ? " custom-card--pointer " : "")
      );
    };

    return { onClickHandler, isClickable, getCardClass };
  },
  methods: {
    async interceptionClickHandler() {
      const checkValidateShowNewness = await validateShowNewness();
  
      if (checkValidateShowNewness) {
        const currentNew = getNewByName(this.$props.featureName);
        if (currentNew && currentNew?.lastLevel) {
          saveNewness(currentNew?.idConfigurationNewness);
          this.$store.dispatch(
            "removeNewness",
            currentNew?.idConfigurationNewness
          );
        }
      }
      this.onClickHandler();
    },
    isNew() {
      const currentNew = getNewByName(this.$props.featureName);
      if (currentNew) {
        return true;
      }
      return false;
    },
    getLabelNew() {
      const currentNew = getNewByName(this.$props.featureName);
      switch (currentNew?.type) {
        case "NEWNESS":
          return "Novidade";
        default:
          return "Novo";
      }
    },
    resolve_img_url: resolve_img_url,
  },
};
</script>

<style lang="scss" scoped>
div.card {
  border-radius: 2rem;
}
.p-card-text {
  width: 100%;
}
.tam-control {
  &-up {
    height: 6rem;
  }
  &-down {
    height: 8rem;
  }
}

.custom-card {
  &--pointer {
    cursor: pointer;
  }

  &--size-control {
    height: 19rem;
    width: 20rem;
  }

  &--body {
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    justify-content: center;
    text-align: left;
  }

  &--icon-card {
    color: var(--green-0);
    font-size: 5.8rem;
  }
}
.tag-new {
  background: #b1d34b 0% 0% no-repeat padding-box;
  border-radius: 12px;
  opacity: 1;
  color: #ffffff;
  text-align: center;
  width: 80px;
  height: 22px;
  position: absolute;
  top: -8px;
  right: 20px;
  padding-top: 2px;
}
</style>
