<script>
export default {
  render() {
    return (
      <div>
        <div class="mr-auto">{this.$slots.leftAction}</div>
        <div class="custom-text">
          <div class="separator-line my-4"></div>
          <div>{this.$slots.leftInfo}</div>

          <div class="my-2 px-4">{this.$slots.headerContent}</div>
          <div class="body-custom">{this.$slots.content}</div>
          <div class="footer-margin">{this.$slots.footerContent}</div>
        </div>
      </div>
    );
  },
};
</script>

<style lang="scss" scoped>
.footer-margin {
  margin-top: 4rem;
  justify-content: center;
  align-content: center;
}

.body-custom {
  margin-top: 3rem;
}

.custom-text {
  padding-left: 6%;
  height: 100%;
}

div.separator-line {
  width: 100%;
  height: 0.15rem;
  border-radius: 10%;
  background-color: var(--secondary-green);
}
</style>
