export const PermissionList = {
  FILE_MANAGEMENT_MENU: "FILE_MANAGEMENT_MENU",
  DOCUMENTS_MENU: "DOCUMENTS_MENU",
  REGISTER_MENU: "REGISTER_MENU",
  VIRTUAL_OFFICE_MENU: "VIRTUAL_OFFICE_MENU",
  ANALYTICS_MENU: "ANALYTICS_MENU",
  FAQ_MENU: "FAQ_MENU",
  HOME_MENU: "HOME_MENU",
  HOME_EMPTY: "HOME_EMPTY",
  INSTITUTIONAL_PAGE_MENU: "INSTITUTIONAL_PAGE_MENU",
  DECLARATIONS_MENU: "DECLARATIONS_MENU",
  FINANCIAL_MENU: "FINANCIAL_MENU",
  REQUEST_HISTORY_MENU: "REQUEST_HISTORY_MENU",
  USE_TERM_MENU: "USE_TERM_MENU",
  BENEFIT_MENU: "BENEFIT_MENU",
  CONTENT_MANAGEMENT_MENU: "CONTENT_MANAGEMENT_MENU",
  REPORTS_MENU: "REPORTS_MENU",
};
