<template>
  <div>
    <TableVisualizationLayout :leftInfo="leftInfo" :leftAction="leftAction">
      <template slot="headerContent">
        <div class="d-flex mb-3 justify-content-end flex-wrap w-100 ">
          <div class="p-2 competence align-self-center">
            COMPETÊNCIA:
          </div>
          <div class="p-1 align-self-center">
            <vue-monthly-picker
              class="periodo"
              v-model="selectedDate"
              :monthLabels="this.allMonth"
              dateFormat="MM/YYYY"
            ></vue-monthly-picker>
          </div>
          <div class="p-2 ms-auto">
            <b-button v-b-modal:report-modal class="ml-4" variant="primary">
              Ver relatório detalhado
            </b-button>
          </div>
        </div>
      </template>
      <template slot="table">
        <b-table
          :fields="fields"
          :items="items"
          v-show="!this.loading"
          responsive
          show-empty
          empty-text="Nenhuma informação para exibir!"
        >
          <template #cell(date)="data">
            {{ dateToLocaleDate(data.item.date) }}
          </template>
          <template #cell(collect)="data">
            {{  "R$ "+ formatMoney(data.item.collect), }}
          </template>
          <template #cell(approved)="data">
            {{  "R$ "+ formatMoney(data.item.approved), }}
          </template>
          <template #cell(glossed)="data">
            {{  "R$ "+ formatMoney(data.item.glossed), }}
          </template>
          <template v-slot:custom-foot>
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td class="custom-text-size bold">Totais</td>
              <td class="custom-value-color custom-text-size">
                {{ "R$ " + formatMoney(totalCollect) }}
              </td>
              <td class="custom-value-color custom-text-size">
                {{ "R$ " + formatMoney(totalApproved) }}
              </td>
              <td class="custom-value-color custom-text-size">
                {{ "R$ " + formatMoney(totalGlossed) }}
              </td>
            </tr>
          </template>
        </b-table>
        <div v-show="this.loading">
          <b-skeleton-table :rows="10" :columns="6"></b-skeleton-table>
        </div>
      </template>
    </TableVisualizationLayout>
    <ReportModal :year="year" :month="month" />
  </div>
</template>

<script>
import ReportModal from "../../../modules/voxis/ReportModal.vue";

import TableVisualizationLayout from "../../../layouts/TableVisualizationLayout.vue";
import { getResultVoxis } from "../../../services/voxis/voxis-services";
import moment from "moment";
import { formatMoney } from "../../../utils/format/formtUtils";

export default {
  components: {
    TableVisualizationLayout,
    ReportModal,
  },
  mounted() {
    this.year = null;
    this.month = null;
    this.getResultVoxis();
  },
  watch: {
    selectedDate: {
      handler() {
        this.year = this.getYear();
        this.month = this.getMonth();
        this.getResultVoxis();
      },
    },
  },
  computed: {},
  data() {
    return {
      month: "",
      year: "",
      totalCollect: "0",
      totalApproved: "0",
      totalGlossed: "0",
      leftAction: {
        text: "Relatório de Glosas Voxis",
        onClick: this.leftActionClickHandler,
      },
      leftInfo: "ITENS GLOSADOS CONFORME GUIAS APRESENTADAS NESTA COMPETÊNCIA",
      fields: [
        {
          key: "date",
          label: "Data",
        },
        {
          key: "beneficiary",
          label: "Beneficiário",
        },
        {
          key: "operatorGuide",
          label: "Guia Operadora",
        },
        {
          key: "provider",
          label: "Prestador",
        },
        {
          key: "proceeding",
          label: "Procedimento",
        },
        {
          key: "glossesCode",
          label: "Glosa",
        },
        {
          key: "collect",
          label: "Cobrado",
        },
        {
          key: "approved",
          label: "Aprovado",
        },
        {
          key: "glossed",
          label: "Glosado",
        },
      ],
      items: [],
      selectedViewRequestItem: null,
      requestTypeDescription: "",
      selectedAttachments: [],
      loading: true,
      selectedDate: "",
      allMonth: [
        "JAN",
        "FEV",
        "MAR",
        "ABR",
        "MAI",
        "JUN",
        "JUL",
        "AGO",
        "SET",
        "OUT",
        "NOV",
        "DEZ",
      ],
    };
  },
  methods: {
    getYear() {
      if (this.selectedDate._f == "MM/YYYY")
        return this.selectedDate._i.substr(3, 4);
      else return this.selectedDate._i.substr(0, 4);
    },
    getMonth() {
      if (this.selectedDate._f == "MM/YYYY")
        return this.selectedDate._i.substr(0, 2);
      else return this.selectedDate._i.substr(5, 2);
    },
    async getResultVoxis() {
      try {
        this.loading = true;
        this.totalCollect = 0;
        this.totalApproved = 0;
        this.totalGlossed = 0;
        this.items = [];


        const crm = this.$store.getters.getUserName;
        const {
          content,
          totalCollect,
          totalApproved,
          totalGlossed,
          firstCompetition,
          lastCompetition,
        } = await getResultVoxis(crm, this.month, this.year);

        if (this.year === null) {
          if (!this.selectedDate)
          this.selectedDate = moment(lastCompetition, "MM/YYYY");
        } else {
          if (!this.selectedDate)
          this.selectedDate = moment(firstCompetition, "MM/YYYY");
        }
        
        this.totalCollect = totalCollect;
        this.totalApproved = totalApproved;
        this.totalGlossed = totalGlossed;
        this.items = content;

        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.error("getInformation", error);
      }
    },
    leftActionClickHandler() {
      this.$router.go(-1);
    },
    dateToLocaleDate: (dateString) => {
      if (!dateString) return;
      const [date] = dateString.split(" ");
      return date;
    },
    formatMoney: formatMoney,
  },
};
</script>
<style lang="scss">
.table-icon-size {
  font-size: 1.8rem;
}
div.competence {
  color: var(--grey-2);
}
.custom-value-color {
  color: var(--green-2);
  font-size: 1.4rem;
}
.custom-text-size {
  font-size: 1.4rem;
}
@media (min-width: 1200px) {
  .container {
    max-width: 1400px;
  }
}
.month-picker-wrapper .input {
  width: 20rem !important;
}
</style>
