<template>
  <div>
    <TableVisualizationLayout :leftInfo="leftInfo" :leftAction="leftAction">
      <template slot="headerContent">
        <b-col class="pl-0 pr-0">
          <b-row class="mt-4 mb-4" align-h="between">
            <b-col class="pl-0 pr-0" sm="4">
              <b-form-input
                id="input-1"
                class="text-left"
                v-model="filterTitle"
                type="text"
                placeholder="Busque por CRM, nome do cooperado, especialidade…"
              ></b-form-input>
            </b-col>
            <b-col class="pl-0 pr-0" sm="4">
              <div
                class="d-flex justify-content-end"
                @click="onGenerateReport()"
              >
                <div class="class-download">
                  <div
                    class="d-flex justify-content-center align-items-center img-custon-principal"
                  >
                    <img
                      class="img-custon"
                      src="@/assets/icons/MDI-microsoft-excel.svg"
                    />
                  </div>
                  <div class="custom-text d-flex align-items-center">
                    Download
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-col>
      </template>
      <template slot="table">
        <b-table
          class="table-custom spaced-text"
          :fields="fields"
          :items="items"
          v-show="!this.loading"
          show-empty
          empty-text="Nenhuma informação para exibir!"
        >
          <template #cell(participationPercentage)="data">
            <div class="custom-row-table d-flex align-items-center">
              <div class="custom-tooltip">
                <div class="pt-2">
                  <b-progress
                    :id="'button-' + data.item.crm"
                    data-toggle="tooltip"
                    data-placement="bottom"
                    :value="data.item.participationPercentage"
                    :max="max"
                  >
                  </b-progress>
                  <b-tooltip
                    :id="'button-tooltip-' + data.item.crm"
                    :target="'button-' + data.item.crm"
                    triggers="hover"
                  >
                    <p v-if="data.item.participationPercentage == '100'">
                      Treinamento
                    </p>
                    <p v-else>Parou no passo:</p>
                    <p>{{ data.item.descriptionCurrentStep }}</p>
                  </b-tooltip>
                </div>
                <div class="h-100">
                  <p class="p3" v-text="onGetPercent(data)"></p>
                </div>
              </div>
            </div>
          </template>
        </b-table>
        <div v-show="this.loading">
          <b-skeleton-table :rows="10" :columns="6"></b-skeleton-table>
        </div>
      </template>
      <template slot="footerContent">
        <b-pagination
          v-model="page"
          :total-rows="totalSize"
          :per-page="perPage"
          aria-controls="my-table"
          size="lg"
        />
      </template>
    </TableVisualizationLayout>
  </div>
</template>

<script>
import TableVisualizationLayout from "../../../layouts/TableVisualizationLayout.vue";
import {
  dateToLocaleDateString,
  dateToTime,
} from "../../../utils/date/date-utils";

import {
  getTrainingsReport,
  getListReportTraining,
} from "../../../services/training/trainingManagementService";
import { anchorBase64Download } from "../../../utils/anchor-base64-download/anchor-base64-download";
export default {
  components: {
    TableVisualizationLayout,
  },
  mounted() {
    this.idTraining = this.decodeStr(this.$route.query.training);
    this.nameTraining = this.$route.query.name;
    this.title = "Relatório de participação: " + this.nameTraining;
    this.onUpdateTitle();

    console.log(this.idTraining);
    this.getListReportTraining();
  },
  watch: {
    page: {
      handler() {
        this.getListReportTraining();
      },
    },
    filterTitle: {
      handler() {
        if (this.filterTitle.length > 2 || this.filterTitle.length == 0) {
          this.getListReportTraining();
        }
      },
    },
  },
  data() {
    return {
      max: 100,
      filterTitle: "",
      page: 1,
      perPage: 10,
      totalSize: 10,
      leftAction: {
        text: this.title,
        onClick: this.leftActionClickHandler,
      },
      leftInfo: "",
      fields: [
        {
          key: "crm",
          label: "CRM",
        },
        {
          key: "cooperativeName",
          label: "Nome do Cooperado",
        },
        {
          key: "specialty",
          label: "Especialidade",
        },
        {
          key: "userStartedTrainingDate",
          label: "Data início",
        },
        {
          key: "lastAccess",
          label: "Último acesso",
        },
        {
          key: "userFinishedTrainingDate",
          label: "Data de conclusão",
        },
        {
          key: "participationPercentage",
          label: "Progresso",
          class: "text-center",
        },
      ],
      items: [],
      loading: true,
    };
  },
  methods: {
    ongetTooltipDescription(value) {
      return value == "100" ? "Treinamento" : "Parou no passo:";
    },
    onGetPercent(data) {
      return data.item.participationPercentage + "%";
    },
    onGetParticipationPercentage(data) {
      console.log(
        "data.participationPercentage -> ",
        data.participationPercentage
      );
    },
    onUpdateTitle() {
      this.leftAction = {
        text: this.title,
        onClick: this.leftActionClickHandler,
      };
    },
    decodeStr(id) {
      const response = atob(id);
      console.log(response);
      return response;
    },
    async onGenerateReport() {
      if (this.idTraining) {
        const reportResponse = await getTrainingsReport(
          this.idTraining,
          this.filterTitle
        );
        anchorBase64Download(
          null,
          "application/xls",
          this.nameTraining + reportResponse.extension,
          reportResponse.fileBase64
        );
      }
    },
    async getListReportTraining() {
      try {
        this.loading = true;
        const trainingsResponse = await getListReportTraining(
          this.idTraining,
          this.filterTitle,
          this.page - 1,
          this.perPage
        );

        this.items = trainingsResponse.items;
        this.totalSize = trainingsResponse.totalItems;

        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.error("getListReportTraining", error);
      }
    },
    leftActionClickHandler() {
      this.$router.go(-1);
    },
    onFormatDate(value) {
      let valueSplit = value.split(" ");
      let horaSplit = valueSplit[1].split(":");
      return `${valueSplit[0]} às ${horaSplit[0]}h${horaSplit[1]}`;
    },
    dateToTime: dateToTime,
    dateToLocaleDate: dateToLocaleDateString,
  },
};
</script>

<style lang="scss" scoped>
.custom-icon-report {
  &-enable {
    background-image: url("../../../assets/icons/training-report-enable.svg");

    &:hover {
      cursor: pointer;
    }
  }

  &-disable {
    background-image: url("../../../assets/icons/training-report-disable.svg");
  }

  background-repeat: no-repeat;
  width: 22px;
  height: 36px;
}

.class-download {
  cursor: pointer;
  height: 3.8rem;
  width: 13.8rem;
  border-radius: 0.5rem;
  display: grid;
  grid-template-columns: 55px auto;
  background: #00995d 0% 0% no-repeat padding-box;
}

.custom-text {
  color: var(--background-1);
  font-size: multiplier-default-font-size(3); // 16
}

.img-custon-principal {
  width: 5rem;
}

.custom-row-table {
  height: 2.5rem;
  width: 100%;
}

.custom-tooltip {
  width: 100%;
  display: grid;
  grid-template-columns: auto 33px;
}
</style>
