<template>
  <VisualizationModalLayout
    :modalId="this.$props.modalId"
    :title="this.$props.title"
    cancelButtonText="Fechar"
  >
    <template slot="content">
      <b-card class="py-5" border-variant="light">
        <b-table
          class="custom-table spaced-text"
          stacked
          :fields="buildFields($props.request)"
          :items="[$props.request]"
        >
        </b-table>
        <b-col
          v-show="
            this.$props.requestAttachments &&
              this.$props.requestAttachments.length > 0
          "
        >
          <div class="box-file ">
            <label class="upload-file-cursor text-primary p3 upperCase">
              <i class="fas fa-cloud-upload-alt"></i>
              {{ this.$props.attachmentsDescription }}
            </label>

            <div
              class="ml-2"
              v-for="(attachment, index) in this.$props.requestAttachments ||
                []"
              :key="index"
            >
              <a
                download
                class="text-primary upload-file-cursor p2"
                @click="handleFileDownload(attachment)"
              >
                Anexo {{ index + 1 }}
              </a>
            </div>
          </div>
        </b-col>
      </b-card>
    </template>
  </VisualizationModalLayout>
</template>

<script>
import VisualizationModalLayout from "../../layouts/VisualizationModalLayout.vue";
export default {
  components: { VisualizationModalLayout },
  props: [
    "title",
    "request",
    "modalId",
    "requestAttachments",
    "attachmentsDescription",
  ],
  methods: {
    handleFileDownload(attachment) {
      this.$emit("downloadFile", attachment.id, attachment.numberProtocol);
    },
  },
  setup(props) {
    const attachments = props?.requestAttachments || [];
    const buildFields = (request) => {
      if (!request) return [];
      const requestFields = Object.keys(request);
      return requestFields.map((field) => ({
        key: field,
        label: field,
        tdClass: "text-right",
      }));
    };
    return { attachments, buildFields };
  },
};
</script>

<style lang="scss">
.box-file {
  padding: 1rem;
  max-height: 8rem;
  min-height: 6rem;
  overflow-y: auto;
  overflow-x: hidden;
}
</style>
