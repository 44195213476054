<template>
  <div>
    <div class="term-container">
      <TermDocument
        id="use-term"
        ref="termDocument"
        :dataAceite="getDateAcceptedTermUse()"
      />
      <div v-if="!$props.disableAction" class="d-flex mt-5">
        <b-button
          @click="onActioHandler(false)"
          class="mr-3"
          variant="outline-primary"
        >
          Não concordo
        </b-button>
        <b-button
          @click="onActioHandler(true)"
          class="mr-auto"
          variant="primary"
        >
          Concordo
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "@vue/composition-api";
import router from "../../../router";
import { addAcessoTermUse } from "../../../services/term-use/term-use-services";
import store from "../../../store";
import TermDocument from "./components/documents/TermDocument.vue";

export default {
  props: ["disableAction"],
  components: { TermDocument },
  setup() {
    const termDocument = ref(null);
    const onActioHandler = function(event) {
      acceptedTermUseRoute(event);
      notAcceptedTermUseRoute(event);
    };

    const acceptedTermUseRoute = async (event) => {
      if (!event) return;
      try {
        const element = termDocument?.value?.$el;
        const text = element?.textContent;
        const { crm, name } = store.getters.getUserInformation;

        if (text) {
          await addAcessoTermUse(crm, name, text);
          store.dispatch("setDateAcceptedUseTerm", true);
          router.push("/");
        }
      } catch (error) {
        console.error("acceptedTermUseRoute", error);
      }
    };

    const notAcceptedTermUseRoute = async (event) => {
      if (event) return;
      logout();
    };

    const logout = async () => {
      await store.dispatch("logout");
      await store.dispatch("clearUserInformation");
      await this.$store.dispatch("clearAnalyticalData");
      window.location.reload();
    };

    return { termDocument, onActioHandler };
  },
  methods: {
    getDateAcceptedTermUse() {
      return store.getters.dateAcceptedUseTerm;
    },
  },
};
</script>

<style lang="scss">
.term-container {
  padding: 2rem 15vw;
}

.table-icon-size {
  font-size: 1.8rem;
}
</style>
