import store from "../../store";

const isAbleRole = (roleName) => {
  try {
    return (
      store.getters.roles && store.getters.roles.find((element) => element == roleName)
    );
  } catch (error) {
    console.error("Error on isAbleRole: When checking for roles: ", error);
    return false;
  }
};

export default isAbleRole;
