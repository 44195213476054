import store from "../../store";

const getNewByName = (featureName) => {
  try {
    if(!featureName) return null;
    let news = store.getters.getNewnessData;
    if (news && news != "undefined") {
      for (var i = 0; i < news.length; i++) {
        const isNew = featureName == news[i].nameComponent
        if (isNew) {
          return news[i]
        }
      }
    }
    return null;
  } catch (error) {
    console.error(`Error on isNew ${featureName}: `, error);
    return null;
  }
};

export { getNewByName };
