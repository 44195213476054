import store from "../../store";

const isAble = (permissionName) => {
  try {
    return (
      store.getters.permissions && store.getters.permissions[permissionName]
    );
  } catch (error) {
    console.error("Error on isAble: When checking for permissions: ", error);
    return false;
  }
};

export default isAble;
