<template>
  <div>
    <p class="p4">
      <i class="fas fa-info-circle"></i>
      Importante: Você pode enviar todos os arquivos referente a esse recurso de
      uma só vez.
    </p>

    <b-card class="custom-card my-4" border-variant="light">
      <b-form-row>
        <b-col>
          <UploadFile
            @updateFile="eventToUpdateFile"
            :multiple="true"
            :custonMaxheight="true"
            :description="'Anexar arquivo(s)'"
            :msgErro="'É necessário inserir um anexo.'"
            :state="validateFieldFile('files')"
            :required="true"
            class="custom-update-file"
          />
        </b-col>
      </b-form-row>
    </b-card>
  </div>
</template>

<script>
import UploadFile from "../../../../components/upload-file/UploadFile.vue";
import { inject } from "@vue/composition-api";

const DefaultForm = {
  files: null,
};

export default {
  props: [],
  components: { UploadFile },
  setup() {
    const formData = inject("formData");
    const setSubmitHandler = inject("setSubmitHandler");
    const setDeleteHandler = inject("setDeleteHandler");
    const setFormSelected = inject("setFormSelected");
    const setForm = inject("setForm");

    return {
      formData,
      setSubmitHandler,
      setDeleteHandler,
      setFormSelected,
      setForm,
    };
  },
  mounted() {
    this.initForm();
  },
  data() {
    return {
      formSubmited: false,
      form: {
        ...DefaultForm,
      },
      validations: {},
    };
  },
  methods: {
    initForm() {
      this.formData = {
        form: { ...this.form },
        onSubmitHandler: this.onSubmit,
        onDeleteHandler: this.onDelete,
        formSelected: false,
      };
    },
    validateFieldFile(name) {
      let value = this.form[name];
      const validation = !!value && value.length > 0;
      this.validations[name] = validation;
      if (!this.formSubmited) return null;
      return validation;
    },
    requiredFieldsFilled() {
      return Object.values(this.validations).every(
        (fieldValue) => fieldValue == true
      );
    },
    eventToUpdateFile(files) {
      this.form.files = files;
    },
    onSubmit() {
      this.setForm(this.form);
      this.formSubmited = true;
      if (this.requiredFieldsFilled()) {
        return true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
div.form-row {
  padding: 0.5rem;
  border-bottom: 1px solid $gray-300;
  &:last-child {
    border-bottom: none;
  }
}

.custom-update-file {
  height: 9rem;
}

input.form-control {
  text-align: right;
  border: 1px solid transparent;
}

select.custom-select {
  text-align: right;
  border: 1px solid transparent;
}
</style>
