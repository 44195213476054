<template>
  <div>
    <div>
      <h3>Termos e condições de uso Portal do Cooperado</h3>
      <p class="P9 right" v-if="this.$props.dataAceite">
        <span class="T3">
          (Termo aceito em: {{ this.$props.dataAceite }})
        </span>
      </p>
    </div>
    <div class="separator-line separator-line--full separator-line--huge"></div>
    <p class="P4"></p>
    <p class="P9">
      <span class="T3">
        Cooperado, seja bem-vindo! Sabemos que o texto é um pouco longo, mas
        pedimos, por favor, que reserve um tempo para ler os nossos Termos e
        Condições de Uso. É que para acessar o Portal do Cooperado você precisa
        estar de acordo com tudo que dissermos aqui e na nossa Política de
        Privacidade.
      </span>
    </p>
    <p class="P7"></p>
    <p class="P9">
      <span class="T3"
        >Apresentamos os Termos e Condições de Uso (ou, simplesmente, “Termos de
        Uso”) com as principais regras que devem ser observadas por </span
      ><span class="T1">Você</span
      ><span class="T3">, trataremos assim, sem formalidades, </span
      ><span class="T1"> </span><span class="T3">que acessa e utiliza </span
      ><span class="T1"> </span
      ><span class="T3">o espaço exclusivo para médicos cooperados da </span
      ><span class="T1"
        >UNIMED GRANDE FLORIANÓPOLIS - COOPERATIVA DE TRABALHO MÉDICO </span
      ><span class="T3">(“</span><span class="T1">UGF</span
      ><span class="T3"
        >”), com sede na Rua Dom Jaime Câmara, n.º 94, Centro, Florianópolis/SC,
        CEP 88015-120, CNPJ: 77.858.611/0001-080, pelo link
      </span>
      <a href="https://cooperado.unimedfln.com.br"
        >https://cooperado.unimedfln.com.br</a
      >
      <span class="T3"> (“</span><span class="T1">Portal do Cooperado</span
      ><span class="T3">”).</span>
    </p>
    <p class="P7"></p>
    <p class="P9"><span class="T1">1. USO DO PORTAL DO COOPERADO</span></p>
    <p class="P7"></p>
    <p class="P9">
      <span class="T3"
        >1.1. Estes Termos de Uso tem o objetivo de informar as
        responsabilidades, deveres e obrigações que </span
      ><span class="T1">Você</span><span class="T3"> assume ao acessar o </span
      ><span class="T1">Portal do Cooperado </span
      ><span class="T3">e/ou utilizar as suas funcionalidades.</span>
    </p>
    <p class="P7"></p>
    <p class="P9">
      <span class="T3">1.2. </span><span class="T1">Você </span
      ><span class="T3">
        deverá ler com atenção as condições aqui descritas antes de acessar ou
        utilizar o </span
      ><span class="T1">Portal do Cooperado, </span
      ><span class="T3"
        >estando plenamente ciente e de acordo com os termos, condições e
        informações.</span
      >
    </p>
    <p class="P7"></p>
    <p class="P9">
      <span class="T3">1.3. Não se preocupe! A </span><span class="T1">UGF</span
      ><span class="T3"> somente fornecerá a </span><span class="T1">Você </span
      ><span class="T3"
        >informações e serviços mediante sua expressa concordância aos termos,
        condições e informações aqui contidas, assim como aos demais documentos
        incorporados a ele.
      </span>
    </p>
    <p class="P7"></p>
    <p class="P9">
      <span class="T4"
        >1.4. Fique atento, pois a aceitação livre, expressa e informada dos
        presentes Termos de Uso ocorrerá por meio do clique, por </span
      ><span class="T2">Você</span
      ><span class="T4"
        >, no botão “Aceitar e continuar”, quando do primeiro acesso as
        funcionalidades do </span
      ><span class="T2">Portal do Cooperado</span><span class="T4">.</span
      ><span class="T3"> </span>
    </p>
    <p class="P7"></p>
    <p class="P9">
      <span class="T3"
        >1.5. Para sua segurança e confidencialidade dos dados, durante a
        utilização do </span
      ><span class="T1">Portal do Cooperado </span
      ><span class="T3"
        >e suas funcionalidades serão solicitados procedimentos de validação e
        verificação dos seus acessos.
      </span>
    </p>
    <p class="P7"></p>
    <p class="P9"><span class="T1">2. CADASTRO NO PORTAL DO COOPERADO</span></p>
    <p class="P7"></p>
    <p class="P9">
      <span class="T3">2.1. Os conteúdos e as funcionalidades do </span
      ><span class="T1">Portal do Cooperado </span
      ><span class="T3"
      >são disponibilizados gratuitamente e, para ter acesso, </span
      ><span class="T1">Você</span
      ><span class="T3">
        terá que cadastrar e manter uma conta pessoal e intransferível (“Conta
        de Acesso”).</span
      >
    </p>
    <p class="P7"></p>
    <p class="P9">
      <span class="T4">2.2. </span><span class="T2">Você</span
      ><span class="T4"> poderá realizar o seu cadastro diretamente no</span
      ><span class="T2"> Portal do Cooperado</span
      ><span class="T4"
        >, com a indicação das seguintes informações no campo “Esqueceu a Senha
        ou Primeiro Acesso? Clique aqui”: (i) Número de inscrição no CRM/SC; e
        (ii) E-mail.
      </span>
    </p>
    <p class="P6" />
    <p class="P9">
      <span class="T4">2.3.</span> <span class="T1">Você</span>
      <span class="T4">
        receberá um e-mail com a senha de acesso e a confirmação de cadastro no </span
      ><span class="T2">Portal do Cooperado. </span
      ><span class="T4"
        >Por isso, o e-mail do cadastro deve ser válido e de sua propriedade
        para que consiga o acesso. Se precisar alterar os dados, basta atualizar
        no </span
      ><span class="T2">Portal do Cooperado</span><span class="T4">.</span>
    </p>
    <p class="P7"></p>
    <p class="P9">
      <span class="T3">2.4. A </span><span class="T1">UGF </span
      ><span class="T3"
        >se reserva o direito de recusar, não aprovar, excluir ou limitar
        quaisquer solicitações de cadastro ou acesso previamente aceito, a seu
        exclusivo critério e sem prévio aviso, não cabendo nenhuma indenização
        ou reparação pelo cancelamento de quaisquer cadastros ou pela
        impossibilidade de cadastro.</span
      >
    </p>
    <p class="P7"></p>
    <p class="P9">
      <span class="T3">2.5. Ao criar sua Conta de Acesso no </span
      ><span class="T1">Portal do Cooperado</span><span class="T3">, </span
      ><span class="T1">Você</span
      ><span class="T3">
        autoriza e concorda em receber, no e-mail e/ou telefone informados,
        mensagens com avisos da </span
      ><span class="T1">UGF. </span
      ><span class="T3">Fique tranquilo, pois </span><span class="T1">Você</span
      ><span class="T3"> pode</span><span class="T1"> s</span
      ><span class="T3"
        >uspender o recebimento destas comunicações, a qualquer tempo, desde que
        não interfira no acesso das funcionalidades do </span
      ><span class="T1">Portal do Cooperado</span><span class="T3">. </span>
    </p>
    <p class="P7"></p>
    <p class="P9">
      <span class="T1">3. FUNCIONALIDADES DO PORTAL DO COOPERADO</span>
    </p>
    <p class="P5"></p>
    <p class="P9">
      <span class="T3">3.1. O </span><span class="T1">Portal do Cooperado</span
      ><span class="T3"> é um espaço exclusivo para médicos cooperados da </span
      ><span class="T1">UGF,</span><span class="T3"> que oferece </span
      ><span class="T4">várias funcionalidades, conteúdos e serviços</span
      ><span class="T3">, como:</span>
    </p>
    <p class="P1"></p>
    <ol>
      <li>
        <p class="P2">
          <span class="ListLabel_20_1">a)</span
          ><span class="T4">Atualização Cadastral; </span
          ><span class="odfLiEnd" />
        </p>
      </li>
      <li>
        <p class="P2">
          <span class="ListLabel_20_1">b)</span
          ><span class="T4">Acesso ao Estatuto Social e Regimento da </span
          ><span class="T1">UGF</span><span>;</span><span class="odfLiEnd" />
        </p>
      </li>
      <li>
        <p class="P2">
          <span class="ListLabel_20_1">c)</span
          ><span class="T4"
            >Solicitações de benefícios previstos no Estatuto Social e Regimento
            Interno da </span
          ><span class="T1">UGF</span><span>;</span><span class="odfLiEnd" />
        </p>
      </li>
      <li>
        <p class="P2">
          <span class="ListLabel_20_1">d)</span
          ><span class="T4"
            >Pedido de cooperação ou demissão da Cooperativa;</span
          ><span class="odfLiEnd" />
        </p>
      </li>
      <li>
        <p class="P2">
          <span class="ListLabel_20_1">e)</span
          ><span class="T4">Emissão de declarações diversas; </span
          ><span class="odfLiEnd" />
        </p>
      </li>
      <li>
        <p class="P2">
          <span class="ListLabel_20_1">f)</span
          ><span class="T4"
            >Informações financeiras, 2ª via de boleto, histórico de nota
            fiscal, revisão de contas e glosas; </span
          ><span class="odfLiEnd" />
        </p>
      </li>
      <li>
        <p class="P2">
          <span class="ListLabel_20_1">g)</span
          ><span class="T4"
            >Atualização de locais de atendimento e solicitação de
            direcionamento de pagamento; </span
          ><span class="odfLiEnd" />
        </p>
      </li>
      <li>
        <p class="P2">
          <span class="ListLabel_20_1">h)</span
          ><span class="T4">Notícias e comunicados sobre a </span
          ><span class="T2">UGF </span
          ><span class="T4">e as normas estatutárias e regimentais; </span
          ><span class="odfLiEnd" />
        </p>
      </li>

      <li>
        <p class="P2">
          <span class="ListLabel_20_1">j)</span
          ><span class="T4"
            >Outras funcionalidades, serviços e conteúdos que possam
            eventualmente ser disponibilizadas no </span
          ><span class="T2">Portal do Cooperado</span><span class="T4">.</span
          ><span class="odfLiEnd" />
        </p>
      </li>
    </ol>
    <p class="P7"></p>
    <p class="P9">
      <span class="T3">3.2. O </span><span class="T1">Portal do Cooperado </span
      ><span class="T3"
        >e suas funcionalidades, sem prévio aviso, podem passar por constantes
        aprimoramentos e atualizações, podendo a </span
      ><span class="T1">UGF</span
      ><span class="T3">
        incluir, excluir ou alterar quaisquer funcionalidades, especificações,
        conteúdos indicados nestes Termos de Uso.</span
      >
    </p>
    <p class="P7"></p>
    <p class="P9">
      <span class="T3">3.3. Apesar de utilizarmos o </span
      ><span class="T1">Portal do Cooperado</span
      ><span class="T3">
        para oferecer as funcionalidades e os serviços mencionados acima, as
        condições de cada funcionalidade oferecida pela
      </span>
      <span class="T1">UGF</span
      ><span class="T3"
        >, são tratadas no Estatuto Social, no Regimento Interno, Resoluções e
        demais documentos específicos da </span
      ><span class="T1">UGF</span
      ><span class="T3">, que não se confundem com estes Termos de Uso.</span>
    </p>
    <p class="P7"></p>
    <p class="P9">
      <span class="T1"
        >4. DA RESPONSABILIDADE PELO ACESSO AO PORTAL DO COOPERADO</span
      >
    </p>
    <p class="P7"></p>
    <p class="P9">
      <span class="T3">4.1. </span><span class="T1">Você </span
      ><span class="T3">
        deverá manter o sigilo dos dados de sua Conta de Acesso e utilizá-los de
        modo individual e intransferível, não disponibilizando, divulgando ou
        compartilhando sua senha ou qualquer mecanismo de autenticação com
        terceiros.
      </span>
    </p>
    <p class="P9">
      <span class="T3"
        >4.1.1. Caso suspeite de violação da confidencialidade de sua senha ou
        que tenha sido vítima de ataque de hackers ou de programas e/ou arquivos
        maliciosos (vírus), </span
      ><span class="T1">Você</span
      ><span class="T3">
        deverá efetuar a troca ou atualização da senha e comunicar à </span
      ><span class="T1">UGF</span
      ><span class="T3">
        pelo Canal do Cooperado, para que possamos adotar as devidas
        providências, quando possível.</span
      >
    </p>
    <p class="P7"></p>
    <p class="P9">
      <span class="T3">4.2. </span><span class="T1">Você</span
      ><span class="T3">
        é o único responsável pela integridade e veracidade das informações
        inseridas no </span
      ><span class="T1">Portal do Cooperado</span
      ><span class="T3"
        >, respondendo civil e criminalmente pelo uso irregular de informações
        de terceiros ou informações falsas.</span
      >
    </p>
    <p class="P7"></p>
    <p class="P9">
      <span class="T3">4.3. </span><span class="T1">Você </span
      ><span class="T3">
        está ciente e reconhece que todos os acessos e operações realizadas no </span
      ><span class="T1">Portal do Cooperado</span
      ><span class="T3">
        após seu acesso bem-sucedido são interpretados como sendo de sua
        responsabilidade, de forma incontestável, inclusive aqueles derivados de
        uso indevido ou de divulgação deste para terceiros.</span
      >
    </p>
    <p class="P7"></p>
    <p class="P9">
      <span class="T4">4.4. </span><span class="T2">Você</span
      ><span class="T4">
        deverá deixar seus sistemas de anti-spam, filtros similares ou
        configurações de redirecionamento de mensagens ajustados de modo que não
        interfiram no recebimento dos comunicados e materiais da </span
      ><span class="T2">UGF</span><span class="T4">.</span>
    </p>
    <p class="P7"></p>
    <p class="P9">
      <span class="T3">4.5. </span><span class="T1">Você</span
      ><span class="T3">
        deverá se certificar que está no ambiente disponibilizado pela </span
      ><span class="T1">UGF</span
      ><span class="T3"
        >, acessando o Portal do Cooperado apenas pelo link
      </span>
      <a href="https://cooperado.unimedfln.com.br"
        >https://cooperado.unimedfln.com.br</a
      >
      <span class="T3">, considerando as ações de hackers de </span>
      <span class="T6">replicar</span><span class="T3"> uma cópia do </span>
      <span class="T1">Portal Unimed Grande Florianópolis </span>
      <span class="T3">ou áreas destes para roubar dados.</span>
    </p>
    <p class="P7"></p>
    <p class="P9">
      <span class="T3">4.6. Na hipótese de </span><span class="T1">Você</span
      ><span class="T3"> praticar atos que causem prejuízo à </span
      ><span class="T1">UGF</span
      ><span class="T3">
        ou a terceiros, deverá arcar com todas as obrigações de indenizar a
        pessoa, física ou jurídica, lesada, inclusive aqueles cuja origem for de
        atos praticados por meio de seu cadastro no </span
      ><span class="T1">Portal do Cooperado</span
      ><span class="T3"
        >. Se isso acontecer, sem prejuízo de outras medidas cabíveis, a </span
      ><span class="T1">UGF</span
      ><span class="T3">
        poderá advertir, suspender temporariamente ou excluir definitivamente a
        sua Conta de Acesso.</span
      >
    </p>
    <p class="P7"></p>
    <p class="P9">
      <span class="T3">4.7. </span><span class="T1">Você</span
      ><span class="T3">
        é responsável por qualquer ação judicial ou administrativa proposta
        contra a </span
      ><span class="T1">UGF</span
      ><span class="T3">
        decorrente de atos praticados por sua Conta de Acesso, devendo, nesse
        caso, assumir o polo passivo de demanda e requerer a exclusão da </span
      ><span class="T1">UGF</span><span class="T3">, arcando com todas e </span
      ><span class="T3"
        >quaisquer despesas incorridas por essa, incluindo, mas não se limitando
        a condenações, multas, honorários advocatícios, custas processuais,
        entre outras.
      </span>
    </p>
    <p class="P7"></p>
    <p class="P9">
      <span class="T3">4.8. A </span><span class="T1">UGF </span
      ><span class="T3"
        >não pratica, colabora, incentiva ou concorda com atos discriminatórios
        de qualquer natureza em suas relações, principalmente em decorrência de
        sexo, origem, raça, cor, condição física, religião, estado civil,
        situação familiar ou qualquer outra condição inerente ao indivíduo.
        Sabemos que isso não ocorrerá, mas caso ocorra, a
        <span class="T1">UGF</span> poderá tomar as medidas cabíveis, inclusive,
        de suspender ou excluir o acesso do agressor ao </span
      ><span class="T1">Portal do Cooperado</span><span class="T3">.</span>
    </p>
    <p class="P7"></p>
    <p class="P9">
      <span class="T1">5. LIMITAÇÃO DE RESPONSABILIDADE </span
      ><span class="T1">UGF</span>
    </p>
    <p class="P7"></p>
    <p class="P9">
      <span class="T3">5.1. A </span><span class="T1">UGF </span
      ><span class="T3"
        >não medirá esforços para a manutenção da disponibilidade contínua do </span
      ><span class="T1">Portal do Cooperado, </span
      ><span class="T3"
        >mas não podemos em nenhum caso garantir a sua disponibilidade e
        continuidade permanente.</span
      >
    </p>
    <p class="P9">
      <span class="T3"
        >5.1.1. Poderá ocorrer a indisponibilidade temporária do </span
      ><span class="T1">Portal Unimed Grande Florianópolis</span
      ><span class="T3">
        decorrente de manutenção necessária ou mesmo gerada por motivo de força
        maior, como desastres naturais, falhas nos sistemas de comunicação e
        acesso à internet, ataques cibernético invasivos, ou quaisquer fatos de
        terceiro que fogem da esfera de responsabilidade da </span
      ><span class="T1">UGF</span><span class="T3">.</span>
    </p>
    <p class="P9">
      <span class="T4"
        >5.1.2. Os procedimentos de manutenção que ocasionem a indisponibilidade
        do </span
      ><span class="T2">Portal do Cooperado</span
      ><span class="T4">
        por período prolongado serão informados por meio dos canais oficiais da </span
      ><span class="T2">UGF</span><span class="T4">.</span>
    </p>
    <p class="P7"></p>
    <p class="P9">
      <span class="T3">5.2. A </span><span class="T1">UGF</span
      ><span class="T3">
        obriga-se a proteger, por medidas técnicas e administrativas, os dados
        pessoais de acessos não autorizados e de situações acidentais ou
        ilícitas de destruição, perda, alteração, comunicação ou difusão no </span
      ><span class="T1">Portal do Cooperado, </span
      ><span class="T3">somente acessíveis pela</span
      ><span class="T1"> UGF</span><span class="T3">.</span>
    </p>
    <p class="P7"></p>
    <p class="P9">
      <span class="T3"
        >5.3. Sem prejuízo de outras condições destes Termos de Uso, a </span
      ><span class="T1">UGF </span
      ><span class="T3">não se responsabilizará:</span>
    </p>
    <p class="P7"></p>
    <p class="P9">
      <span class="T3"
        >a) Por quaisquer problemas, bugs ou funcionamentos indevidos que
        ocorrerem nos seu dispositivo e/ou equipamento;</span
      >
    </p>
    <p class="P9">
      <span class="T3"
        >b) Por qualquer dano direto ou indireto ocasionado por eventos de
        terceiros, a exemplo, mas não se limitando a ataque de hackers, falhas
        no sistema, no servidor ou na conexão à internet, inclusive por ações de
        softwares que possam, de algum modo, danificar ativos físicos ou lógicos
        em decorrência do acesso, utilização ou navegação no</span
      ><span class="T1"> Portal do Cooperado</span
      ><span class="T3"
        >, bem como a transferência de dados, arquivos, imagens, textos, áudios
        ou vídeos contidos no </span
      ><span class="T1">Portal do Cooperado</span><span class="T3">;</span>
    </p>
    <p class="P9">
      <span class="T3">c) Demora ou incapacidade de usar o </span
      ><span class="T1">Portal do Cooperado</span><span class="T3">;</span>
    </p>
    <p class="P9">
      <span class="T3"
        >d) Perda ou uso inadequado de informações enviadas por meio do </span
      ><span class="T1">Portal do Cooperado</span><span class="T3">;</span>
    </p>
    <p class="P9">
      <span class="T4"
        >e) Pela navegação em quaisquer links externos disponibilizados no </span
      ><span class="T2">Portal do Cooperado</span
      ><span class="T4"
        >, sendo seu dever, a leitura dos Termos e Condições de Uso e da
        Política de Privacidade do recurso acessado e agir conforme o
        determinado;</span
      >
    </p>
    <p class="P9">
      <span class="T4"
        >f) Por verificar, controlar, aprovar ou garantir a adequação ou
        exatidão das informações ou dados disponibilizados em tais links, não
        sendo, portanto, responsáveis por prejuízos, perdas ou danos ocorridos
        pela visita de tais sites, cabendo ao interessado verificar a
        confiabilidade das informações e dados ali exibidos antes de tomar
        alguma decisão ou praticar algum ato</span
      ><span class="T3">.</span>
    </p>
    <p class="P7"></p>
    <p class="P9">
      <span class="T3">5.4. A </span><span class="T1">UGF</span
      ><span class="T3"> poderá, a qualquer momento, descontinuar o </span
      ><span class="T1">Portal do Cooperado</span
      ><span class="T3">
        e todas as suas funcionalidades sem que isso gere qualquer direito para </span
      ><span class="T1">Você</span
      ><span class="T3">. Não se preocupe, se isso acontecer, </span
      ><span class="T1">Você</span
      ><span class="T3">
        será avisado e orientado sobre os novos canais disponíveis pela </span
      ><span class="T1">UGF</span><span class="T3">.</span>
    </p>
    <p class="P7"></p>
    <p class="P9">
      <span class="T1">6. DOS DIREITOS AUTORAIS E PROPRIEDADE INTELECTUAL</span>
    </p>
    <p class="P8" />
    <p class="P9">
      <span class="T3"
        >6.1. Todas as informações, notícias, imagens, marcas, nome empresarial,
        banco de dados, redes, arquivos e informações de mercado
        disponibilizados no </span
      ><span class="T1">Portal do Cooperado</span
      ><span class="T3"> são de exclusiva propriedade da </span
      ><span class="T1">UGF </span
      ><span class="T3"
        >ou licenciado a ela (“Conteúdo”) e estão assegurados pelas leis e
        tratados internacionais de proteção à propriedade intelectual,
        incluindo, mas não se limitando, à Lei n.º 9.609/1998, Lei n.º
        9.610/1998 e o Código Civil (Lei n.º 10.406/2002).</span
      >
    </p>
    <p class="P9">
      <span class="T3"
        >6.1.1. A reprodução, a distribuição e a transmissão de tais materiais
        não são permitidas sem expresso consentimento, por escrito, da </span
      ><span class="T1">UGF </span
      ><span class="T3">ou do respectivo titular, especialmente para </span
      ><span class="T6">fins </span><span class="T3"> </span
      ><span class="T6">comerciais</span><span class="T3"> ou </span
      ><span class="T6">econômicos.</span>
    </p>
    <p class="P8" />
    <p class="P9">
      <span class="T3">6.2. </span><span class="T1">Você</span
      ><span class="T3">
        se compromete a não acessar áreas de programação do</span
      ><span class="T1"> Portal do Cooperado</span
      ><span class="T3"
        >, seu banco de dados, códigos fonte ou qualquer outro conjunto de dados
        disponíveis nestes ambientes, bem como não realizar ou permitir
        engenharia reversa, nem traduzir, decompilar, copiar, modificar,
        reproduzir, alugar, sublicenciar, publicar, divulgar, transmitir,
        emprestar, distribuir ou, de outra maneira, dispor inapropriadamente das
        funcionalidades do </span
      ><span class="T1">Portal do Cooperado</span><span class="T3">.</span>
    </p>
    <p class="P8" />
    <p class="P9">
      <span class="T1">7. DA PRIVACIDADE E PROTEÇÃO DE DADOS DOS USUÁRIOS</span>
    </p>
    <p class="P5"></p>
    <p class="P9">
      <span class="T3">7.1.</span><span class="T1"> </span
      ><span class="T3">Para acessar e utilizar o </span
      ><span class="T1">Portal do Cooperado</span
      ><span class="T3"
        >, determinadas informações suas, incluindo dados pessoais, serão
        tratadas pela</span
      ><span class="T1"> UGF</span
      ><span class="T3">, conforme nossa Política de Privacidade</span
      ><span class="T1">, </span><span class="T3">disponível no link</span
      ><span class="T1"> </span>
      <a href="https://www.unimed.coop.br/web/florianopolis/lgpd">
        https://www.unimed.coop.br/web/florianopolis/lgpd</a
      >

      <span class="T3">.</span>
    </p>
    <p class="P5"></p>
    <p class="P9">
      <span class="T3"
        >7.2. A Política de Privacidade é parte integrante e inseparável destes
        Termos de Uso, portanto, siga em frente apenas se concordar com as suas
        condições. Fique tranquilo, pois a</span
      ><span class="T1"> UGF </span
      ><span class="T3"
        >trata os seus dados pessoais com ética e transparência, garantindo sua
        segurança e confidencialidade.</span
      >
    </p>
    <p class="P7" />
    <p class="P9">
      <span class="T3"
        >7.3. Na hipótese de conflito entre as disposições da Política de
        Privacidade e a deste documento, deverá prevalecer o descrito na norma
        mais específica.</span
      >
    </p>
    <p class="P7"></p>
    <p class="P9"><span class="T1">8. PENALIDADES</span></p>
    <p class="P7"></p>
    <p class="P9">
      <span class="T3"
        >8.1. O descumprimento de qualquer cláusula destes Termos de Uso ou de
        legislação aplicável poderá acarretar na suspensão temporária e/ou
        exclusão da sua Conta de Acesso ao </span
      ><span class="T1">Portal do Cooperado, </span
      ><span class="T3"
        >sem prejuízo de sua responsabilização nas esferas cível e penal.</span
      >
    </p>
    <p class="P5"></p>
    <p class="P9"><span class="T1">9. DISPOSIÇÕES GERAIS</span></p>
    <p class="P7"></p>
    <p class="P9">
      <span class="T4">9.1. </span><span class="T2">Você</span
      ><span class="T4">
        poderá registrar elogios, dúvidas, reclamações ou denúncias quando
        constatar conteúdos impróprios no</span
      ><span class="T2"> Portal do Cooperado </span
      ><span class="T4">pelo Canal do Cooperado</span><span class="T3">. </span>
    </p>
    <p class="P5"></p>
    <p class="P9">
      <span class="T3"
        >9.2. O mundo está em constante mudança e estes Termos de Uso também. A </span
      ><span class="T1">UGF</span
      ><span class="T3">
        poderá modificá-lo, a qualquer momento, de acordo com sua finalidade ou
        conveniência ou para adequação e conformidade legal de disposição de lei
        ou norma. Quando isso acontecer, a </span
      ><span class="T1">UGF</span><span class="T3"> avisará </span
      ><span class="T1">Você</span
      ><span class="T3"> pelas ferramentas disponíveis no </span
      ><span class="T1">Portal do Cooperado </span
      ><span class="T3">ou pelos meios de contato </span
      ><span class="T1"> </span><span class="T3">fornecidos para a</span
      ><span class="T1"> UGF. </span>
    </p>
    <p class="P7"></p>
    <p class="P9">
      <span class="T3">9.2.1. </span
      ><span class="T3">
        Deverá rever os Termos de Uso periodicamente, sendo certo que o acesso
        ou uso contínuo do </span
      ><span class="T1">Portal do Cooperado </span
      ><span class="T3">subordina-se à aceitação das condições vigentes.</span>
    </p>
    <p class="P7"></p>
    <p class="P9">
      <span class="T3"
        >9.3. Em caso de nulidade, total ou parcial, de uma disposição destes
        Termos de Uso, as restantes disposições do mesmo não serão afetadas pela
        disposição nula, valendo as demais cláusulas que não foram
        alteradas.</span
      >
    </p>
    <p class="P7"></p>
    <p class="P9">
      <span class="T3">9.4. </span><span class="T1"> Você </span
      ><span class="T3"
        >reconhece que toda comunicação realizada por e-mail ou telefone
        informados no seu cadastro, é válida, eficaz e suficiente para a
        divulgação de qualquer assunto que se refira ao </span
      ><span class="T1">Portal do Cooperado</span><span class="T3">.</span>
    </p>
    <p class="P7"></p>
    <p class="P9">
      <span class="T3">9.5. Não perca o seu prazo. O </span
      ><span class="T1">Portal do Cooperado</span
      ><span class="T3">
        adota em suas funcionalidades a data e horários oficiais de Brasília
        (UTC/GMT -03:00).</span
      >
    </p>
    <p class="P7"></p>
    <p class="P9">
      <span class="T3">10. </span><span class="T1">LEGISLAÇÃO APLICÁVEL</span>
    </p>
    <p class="P7"></p>
    <p class="P9">
      <span class="T3">10.1. </span><span class="T6">Estes</span
      ><span class="T3">
        Termos de Uso são regidos pela legislação brasileira, no idioma
        português.</span
      >
    </p>
    <p class="P7"></p>
    <p class="P3">
      <span class="T1">Versão anterior: </span
      ><span class="T5">30/03/2022</span>
    </p>
    <p class="P3">
      <span class="T1">Data da atualização:</span
      ><span class="T3"> 30/03/2022.</span>
    </p>
    <p class="P3"></p>
    
    <p class="P3 external-link"><a target="_blank" href="https://firebasestorage.googleapis.com/v0/b/ugf-prod-plataforma-cooperado/o/others%2FAnexo%2001%20-%20Descritivo%20de%20Dados%20Coletados%2C%20Formas%20de%20Utiliza%C3%A7%C3%A3o%20e%20Servi%C3%A7os%20do%20Aplicativo.pdf?alt=media&token=5a553612-a86f-4659-b610-244b79524422&_gl=1*xm80qm*_ga*ODc1ODUwMi4xNjc0MjE0MjQ4*_ga_CW55HF8NVT*MTY4NjAwNDI1NC4xMC4xLjE2ODYwMDQzNjkuMC4wLjA."
      >Acesse aqui o Detalhamento de Uso de Dados.</a
      ></p>
    
  </div>
</template>

<script>
export default {
  props: ["dataAceite"],
  data() {
    return {
      termHtml: ``,
    };
  },
};
</script>

<style lang="scss" scoped>
@page {
}

.external-link {
  text-decoration: underline;
  text-decoration-color: var(--primary);
}

span, a  {
  font-size: 12px;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
  empty-cells: show;
}

td,
th {
  vertical-align: top;
  /* font-size: 12pt; */
}

h1,
h2,
h3,
h4,
h5,
h6 {
  clear: both;
}

ol,
ul {
  /* margin: 0;
  padding: 0; */
}

li {
  list-style: none;
  /* margin: 0;
  padding: 0; */
}

li span {
  clear: both;
  line-height: 0;
  width: 0;
  height: 0;
  /* margin: 0;
  padding: 0; */
}

span.footnodeNumber {
  padding-right: 1em;
}

span.annotation_style_by_filter {
  /* font-size: 95%; */
  /* font-family: Arial; */
  /* background-color: #fff000; */
  /* margin: 0;
  border: 0;
  padding: 0; */
}

span.heading_numbering {
  margin-right: 0.8rem;
}

* {
  /* margin: 0; */
}

.P1 {
  /* font-size: 12pt; */
  line-height: 115%;
  /* margin-bottom: 0in;
  margin-left: 0.5in;
  margin-right: 0in;
  margin-top: 0in; */
  text-align: justify !important;
  text-indent: 0in;
  /* font-family: Cambria; */
  writing-mode: horizontal-tb;
  direction: ltr;
  padding: 0in;
  border-style: none;
  /* color: #000000; */
}

.P10 {
  /* font-size: 11pt; */
  line-height: 100%;
  /* margin-bottom: 0in;
  margin-top: 0in; */
  text-align: center !important;
  /* font-family: Arial; */
  writing-mode: horizontal-tb;
  direction: ltr;
  padding: 0in;
  border-style: none;
}

.P2 {
  /* font-size: 11pt; */
  line-height: 115%;
  /* margin-bottom: 0in;
  margin-left: 0in;
  margin-right: 0in;
  margin-top: 0in; */
  text-align: justify !important;
  text-indent: -0.0075in;
  /* font-family: Arial; */
  writing-mode: horizontal-tb;
  direction: ltr;
  padding: 0in;
  border-style: none;
}

.P3 {
  /* font-size: 11pt; */
  line-height: 100%;
  /* margin-bottom: 0in; */
  /* margin-top: 0in; */
  text-align: justify !important;
  /* font-family: Arial; */
  writing-mode: horizontal-tb;
  direction: ltr;
  padding: 0in;
  border-style: none;
}

.P4 {
  /* font-size: 12pt; */
  line-height: 100%;
  /* margin-bottom: 0in; */
  /* margin-top: 0in; */
  text-align: justify !important;
  /* font-family: Cambria; */
  writing-mode: horizontal-tb;
  direction: ltr;
  padding: 0in;
  border-style: none;
  /* color: #000000; */
  font-weight: bold;
}

.P5 {
  /* font-size: 12pt; */
  line-height: 115%;
  /* margin-bottom: 0in; */
  /* margin-top: 0in; */
  text-align: justify !important;
  /* font-family: Cambria; */
  writing-mode: horizontal-tb;
  direction: ltr;
  padding: 0in;
  border-style: none;
  /* color: #000000; */
  font-weight: bold;
}

.P6_borderStart {
  /* font-size: 12pt; */
  line-height: 115%;
  /* margin-top: 0in; */
  text-align: justify !important;
  /* font-family: Cambria; */
  writing-mode: horizontal-tb;
  direction: ltr;
  padding: 0in;
  border-style: none;
  /* color: #000000; */
  font-weight: bold;
  /* background-color: #ffff00; */
  padding-bottom: 0in;
  border-bottom-style: none;
}

.P6 {
  /* font-size: 12pt; */
  line-height: 115%;
  text-align: justify !important;
  /* font-family: Cambria; */
  writing-mode: horizontal-tb;
  direction: ltr;
  padding: 0in;
  border-style: none;
  /* color: #000000; */
  font-weight: bold;
  /* background-color: #ffff00; */
  padding-bottom: 0in;
  padding-top: 0in;
  border-top-style: none;
  border-bottom-style: none;
}

.P6_borderEnd {
  /* font-size: 12pt; */
  line-height: 115%;
  /* margin-bottom: 0in; */
  text-align: justify !important;
  /* font-family: Cambria; */
  writing-mode: horizontal-tb;
  direction: ltr;
  padding: 0in;
  border-style: none;
  /* color: #000000; */
  font-weight: bold;
  /* background-color: #ffff00; */
  padding-top: 0in;
  border-top-style: none;
}

.P7 {
  /* font-size: 12pt; */
  line-height: 115%;
  /* margin-bottom: 0in; */
  /* margin-top: 0in; */
  text-align: justify !important;
  /* font-family: Cambria; */
  writing-mode: horizontal-tb;
  direction: ltr;
  padding: 0in;
  border-style: none;
  /* color: #000000; */
}

.P8_borderStart {
  /* font-size: 12pt; */
  line-height: 115%;
  /* margin-top: 0in; */
  text-align: justify !important;
  /* font-family: Cambria; */
  writing-mode: horizontal-tb;
  direction: ltr;
  padding: 0in;
  border-style: none;
  /* color: #000000; */
  /* background-color: #ffff00; */
  padding-bottom: 0in;
  border-bottom-style: none;
}

.P8 {
  /* font-size: 12pt; */
  line-height: 115%;
  text-align: justify !important;
  /* font-family: Cambria; */
  writing-mode: horizontal-tb;
  direction: ltr;
  padding: 0in;
  border-style: none;
  /* color: #000000; */
  /* background-color: #ffff00; */
  padding-bottom: 0in;
  padding-top: 0in;
  border-top-style: none;
  border-bottom-style: none;
}

.P8_borderEnd {
  /* font-size: 12pt; */
  line-height: 115%;
  /* margin-bottom: 0in; */
  text-align: justify !important;
  /* font-family: Cambria; */
  writing-mode: horizontal-tb;
  direction: ltr;
  padding: 0in;
  border-style: none;
  /* color: #000000; */
  /* background-color: #ffff00; */
  padding-top: 0in;
  border-top-style: none;
}

.P9 {
  /* font-size: 11pt; */
  line-height: 115%;
  /* margin-bottom: 0in; */
  /* margin-top: 0in; */
  text-align: justify !important;
  /* font-family: Arial; */
  writing-mode: horizontal-tb;
  direction: ltr;
  padding: 0in;
  border-style: none;
}

.ListLabel_20_1 {
  /* font-weight: bold; */
  margin-right: 0.5rem;
}

.T1 {
  /* color: #000000; */
  /* font-family: Cambria; */
  /* font-size: 12pt; */
  font-weight: bold;
}

.T2 {
  /* color: #000000; */
  /* font-family: Cambria; */
  /* font-size: 12pt; */
  font-weight: bold;
  /* background-color: #ffff00; */
}

.T3 {
  /* color: #000000; */
  /* font-family: Cambria; */
  /* font-size: 12pt; */
}

.T4 {
  /* color: #000000; */
  /* font-family: Cambria; */
  /* font-size: 12pt; */
  /* background-color: #ffff00; */
}

.T5 {
  /* color: #000000; */
  /* font-family: Cambria; */
  /* font-size: 12pt; */
  font-style: italic;
}

.T6 {
  /* font-family: Cambria; */
  /* font-size: 12pt; */
}

.T7 {
  /* color: #0000ff; */
  /* font-family: Cambria; */
  /* font-size: 12pt; */
  text-decoration: underline;
}

/* ODF styles with no properties representable as CSS */
.Endnote_20_Symbol .Footnote_20_Symbol {
}

p {
  color: #23272b !important;
  margin-bottom: 1rem !important;
}

li > p {
  color: #23272b !important;
  margin-bottom: 0.2rem;
}
p.right {
  float: right;
}

div.separator-line {
  margin: 3.5rem 0rem;
}
</style>
