<script>
import FormTemplate from "../../../../templates/FormTemplate.vue";
import RoutesPaths from "../../../../router/router-structure/routes-paths";
import {
  confirmRequiredNotification,
  sendOccurrences,
} from "../../../../services/updated-informations/updated-informations-service";

export const BodyLayout = {
  props: ["navigation", "tabs", "onChangeTab", "onCloseModal"],
  data() {
    return {
      voltar: false,
      update: false,
      avancar: true,
      confirmar: false,
      tabIndex: 0,
    };
  },
  watch: {
    tabIndex: {
      immediate: true,
      handler(newValue) {
        this.$props.onChangeTab && this.$props.onChangeTab(newValue);
        this.onCustonTela(newValue);
      },
    },
  },
  components: {
    FormTemplate,
  },
  methods: {
    onCustonTela(index) {
      if (index == 0) {
        this.voltar = false;
        this.avancar = true;
        this.update = false;
        this.confirmar = false;
      } else if (index == 1) {
        this.voltar = true;
        this.avancar = true;
        this.update = false;
        this.confirmar = false;
      } else {
        this.voltar = true;
        this.avancar = false;
        this.update = true;
        this.confirmar = true;
      }
    },
    onEventClickNext() {
      this.tabIndex++;
    },
    onEventClickBack() {
      this.tabIndex--;
    },
    onSolicitacoesClick() {
      this.$router.push(RoutesPaths.register.rootName());
      this.onConfirmarClick();
    },
    onCloseModalHandler() {
      this.$props.onCloseModal();
    },
    async onConfirmarClick() {
      if (!this.$store.getters.isAdmin) {
        await sendOccurrences();
        await this.$store.dispatch("clearOccurrencesData");
        await confirmRequiredNotification();
      }
      this.onCloseModalHandler();
    },
  },
  render(createElement) {
    const tabs = this.$props.tabs.map((tab, index) => {
      const tabContent = createElement(tab.formComponent);

      return (
        <b-tab ref={"tabContainer"} key={index} title={tab.title}>
          {this.tabIndex == index && tabContent}
        </b-tab>
      );
    });

    return (
      <div>
        <div class="custon-tabs">
          <b-tabs class="custom-navbar" v-model={this.tabIndex} align="center">
            {tabs}
          </b-tabs>
        </div>
        <div class="row span my-3"></div>
        <div class="principal-buttons">
          <div class="principal-voltar">
            <b-button
              id="b-voltar"
              v-show={this.voltar}
              onClick={this.onEventClickBack}
              variant="outline-primary"
              class="mr-3"
            >
              Voltar
            </b-button>
          </div>
          <div class="principal-outros">
            <b-button
              id="b-update-info"
              variant="outline-primary"
              v-show={this.update}
              onClick={this.onSolicitacoesClick}
              class="ml-4"
              disabled={this.$store.getters.isAdmin}
            >
              Solicitar Atualizações
            </b-button>
            <b-button
              class="ml-4"
              id="b-avancar"
              v-show={this.avancar}
              onClick={this.onEventClickNext}
              variant="primary"
            >
              Avançar
            </b-button>
            <b-button
              class="ml-4"
              id="b-confirmar"
              v-show={this.confirmar}
              onClick={this.onConfirmarClick}
              variant="primary"
            >
              Confirmar
            </b-button>
          </div>
        </div>
      </div>
    );
  },
};

export default BodyLayout;
</script>

<style scoped lang="scss">
.principal-buttons {
  max-width: 100%;
  display: grid;
  border-style: none;
  grid-column: 0;
  margin-top: 2rem;
}

.principal-outros {
  display: flex;
  justify-content: flex-end;
}

.span {
  height: 0.25rem;
  border-radius: 20%;
  background-color: var(--light-4);
}

.custon-tabs {
  margin-bottom: 1.5rem;
}

.custton-buttons {
  height: 4rem;
  margin-top: 2rem;
}

@media (min-width: 370px) {
  .principal-buttons {
    grid-template-columns: 20% 80%;
  }
}
</style>
