<template>
  <div>
    <CustomModalLayout
      modalId="id-informations-modal"
      formTitle="test"
      cancelButtonText="Fechar"
      ref="information-modal"
      class="informations-modal"
      hideFotter="true"
      noCloseOnBackdrop
    >
      <template slot="content">
        <div class="principal">
          <div class="secundaria">
            <div class="secundaria--text">
              <p class="p6">Olá, <b v-text="getUser()"></b></p>
              <p class="p6 paddind-space">
                Queremos te ajudar a manter seus cadastros atualizados!
              </p>
              <p class="p6 paddind-space">
                Confira as suas informações que estão sendo divulgadas
                atualmente.
              </p>
            </div>
            <div class="secundaria--imagem">
              <img src="../../../assets/images/unimed-robozinho.svg" />
            </div>
          </div>

          <div class="body-custon">
            <BodyInformations
              :tabs="this.tabs"
              :onChangeTab="onChangeTabHandler"
              :onCloseModal="onCloseModalHandler"
            />
          </div>
        </div>
      </template>
    </CustomModalLayout>
  </div>
</template>

<script>
import CustomModalLayout from "../components/CustomModalLayout.vue";
import Vue from "vue";
import BodyInformations from "../components/visualization/BodyInformations.vue";
import { getShowNotification } from "../../../services/updated-informations/updated-informations-service";
import isAbleRole from "../../../utils/permissions/is-able-role";
import { RoleList } from "../../../static-data/RoleList";
import { getNameUser } from "../../../utils/user/user";

Vue.component("registered-addresses", () =>
  import(
    "../components/visualization/registeredAddresses/RegisteredAddresses.vue"
  )
);

Vue.component("occupation-areas", () =>
  import("../components/visualization/occupationAreas/OccupationAreas.vue")
);

Vue.component("registered-specialties", () =>
  import(
    "../components/visualization/registeredSpecialties/RegisteredSpecialties.vue"
  )
);

export default {
  components: {
    BodyInformations,
    CustomModalLayout,
  },
  async mounted() {
    this.checkVisibility();
  },
  data: function() {
    return {
      currentTabIndex: 0,
      tabs: [
        {
          title: "Endereços cadastrados",
          formComponent: "registered-addresses",
        },
        {
          title: "Especialidades",
          formComponent: "registered-specialties",
        },
        {
          title: "Área de atuação",
          formComponent: "occupation-areas",
        },
      ],
    };
  },
  computed: {
    showIfStoreChange() {
      return this.$store.getters.getUserInformation?.nickname;
    },
  },
  watch: {
    showIfStoreChange() {
      this.checkVisibility();
    },
  },

  methods: {
    getUser() {
      return getNameUser();
    },
    onCloseModalHandler() {
      this.$refs["information-modal"].$children[0].hide();
    },
    checkVisibility() {
      if (isAbleRole(RoleList.AUTHORITY_USER_IS_CUSTOMER)) {
        getShowNotification().then((response) => {
          if (response.showNotification)
            this.$refs["information-modal"].$children[0].show();
        });
      }
    },
    onChangeTabHandler() {},
  },
};
</script>

<style lang="scss">
.paddind-space {
  padding-top: 1.5rem;
}

.container {
  padding: 0 !important;
}

.principal {
  height: 100%;
  max-width: 100%;
}

.secundaria {
  display: grid;
  border-style: none;
  padding: 0px;
}

.body-custon {
  padding-top: 2.5rem;
}

@media (min-width: 900px) {
  .secundaria {
    padding: 0;
    grid-template-columns: auto 177px;
  }
}

div.modal-content {
  border-radius: 1rem;
  @include media-breakpoint-up(xs) {
    padding: 0;
  }

  @include media-breakpoint-up(sm) {
    padding: 0;
  }

  @include media-breakpoint-up(md) {
    padding: 0rem 2rem 2rem !important;
  }
}

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 69rem !important;
  }
}
</style>
