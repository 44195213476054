<template>
  <span :class="this.getClass()">
    {{ getLabel() }}
  </span>
</template>

<script>
const Type = {
  active: { class: "status--active", label: "Ativo" },
  suspended: { class: "status--suspended", label: "Suspenso" },
  deleted: { class: "status--deleted", label: "Excluído" },
  away: { class: "status--away", label: "Afastado" },
};
const ActiveStatus = "active";
export default {
  data() {
    return {
      status: "",
      statusType: {},
    };
  },
  mounted() {
    this.status = this.$store.getters.status;
    this.statusType = Type[this.status];
  },
  methods: {
    getClass() {
      const classValue = "status ";
      return classValue + this.statusType?.class;
    },
    getLabel() {
      this.status = this.$store.getters.status ?? ActiveStatus;
      this.statusType = Type[this.status];
      return this.statusType?.label;
    },
  },
};
</script>
<style lang="scss" scoped>
.status {
  &--active {
    background-color: var(--primary);
  }
  &--suspended {
    background-color: var(--orange-0);
  }
  &--deleted {
    background-color: var(--danger-1);
  }
  &--away {
    background-color: var(--grey-1);
  }
  &:hover {
    color: white;
  }
  border-radius: 0.8rem;
  text-align: center;
  color: white;
  padding: 0.4rem 0.9rem;
}
</style>
