<template>
  <div>
    <AlterFormLayout
      id="upload-file-modal"
      modalId="upload-file-modal"
      formTitle="Enviar solicitação para revisão de glosa"
      cancelButtonText="Cancelar"
      submitButtonText="Enviar"
      :onSubmitEvent="onSubmit"
      :disableEvent="this.$store.getters.isAdmin"
    >
      <template #content>
        <UploadGlosasForm />
      </template>
    </AlterFormLayout>
    <success-modal
      ref="success-modal"
      description="Seu arquivo foi enviado com sucesso"
      :subdescription="subdescriptionUpload"
      buttonText="Ok, entendi!"
    />
  </div>
</template>

<script>
import UploadGlosasForm from "./UploadGlosasForm.vue";
import AlterFormLayout from "../../../../layouts/AlterFormLayout.vue";
import SuccessModal from "../../../../components/base-modals/SuccessModal.vue";
import { sendRequestAndGetProtocol } from "../../../../services/review-glosses/review-glosses";

export default {
  props: [],
  components: {
    AlterFormLayout,
    UploadGlosasForm,
    SuccessModal,
  },
  data() {
    return {
      protocol: "",
      subdescriptionUpload: "",
    };
  },
  methods: {
    async onSubmit(formData) {
      try {
        const response = await sendRequestAndGetProtocol(formData.files);
        this.subdescriptionUpload = `Número do protocolo: ${response.protocol}`;
        const modal = this.$refs["success-modal"].$children[0];
        modal.show();
      } catch(error) {
        if (error.response &&
          error.response.status == 400) {
            this.$emit("errorUploadFile", error.response.data.message);
        }
      }
    },
  },
};
</script>
