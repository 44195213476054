<script>
import CustomModalTemplate from "../components/custom-modal/CustomModalTemplate.vue";
import { inject, provide, ref } from "@vue/composition-api";
export default {
  props: [
    "modalId",
    "formTitle",
    "deleteButtonText",
    "cancelButtonText",
    "submitButtonText",
    "onSubmitEvent",
    "onDeleteEvent",
    "onCloseEvent",
    "onCancelEvent",
    "attentionModalId",
    "disableValidation",
    "landscape",
  ],
  components: { CustomModalTemplate },
  setup() {
    const globalLoading = inject("globalLoading");
    const formData = ref({
      form: null,
      onSubmitHandler: function() {
        console.warn("invalid onSubmit handler!");
      },
      onDeleteHandler: function() {
        console.warn("invalid onDelete handler!");
      },
      formSelected: false,
    });

    const setForm = (form) => {
      if (form) formData.value.form = { ...form };
    };

    const setFormSelected = (value) => {
      formData.value.formSelected = value;
    };

    provide("formData", formData);
    provide("setFormSelected", setFormSelected);
    provide("setForm", setForm);

    return { formData, globalLoading };
  },
  methods: {
    onCloseModalHandler() {
      this.$refs["form-modal"].$children[0].hide();
    },
    onClose() {
      this.onCloseModalHandler();
      if (this.$props.onCancelEvent) this.$props.onCancelEvent();
    },
  },
  render() {
    return (
      <CustomModalTemplate
        modalId={this.$props.modalId}
        title={this.$props.formTitle}
        ref={"form-modal"}
        noCloseOnBackdrop
        landscape={this.$props.landscape}
      >
        <template slot="header">{this.$slots.header}</template>
        <template slot="content">{this.$slots.content}</template>
        <template slot="footer">
          <b-button variant="primary" onClick={this.onClose} class="ml-4">
            {this.$props.cancelButtonText}
          </b-button>
        </template>
      </CustomModalTemplate>
    );
  },
};
</script>
