<template>
  <div class="div-welcome">
    <p class="p-welcome">
      Boas-vindas à área administrativa do Portal do Cooperado!
    </p>
    <div class="separator-line separator"></div>
    <p class="p-sub-title">
      Pensando em melhorar a sua experiência, preparamos um novo portal com
      serviços exclusivos para você.
    </p>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.div-welcome {
  text-align: center;
  width: 100%;
}
.p-welcome {
  font-size: multiplier-default-font-size(5); //20px
  font-weight: bold;
  color: var(--dark-0);
}

.p-sub-title {
  font-size: multiplier-default-font-size(2); //20px
  color: var(--green-2);
}

.separator {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}
</style>
