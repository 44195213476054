import { requestLogin, requestLoginInspect } from "../../../services/login/requestLogin";
import jwt_decode from "jwt-decode";
import { requestRefreshToken } from "../../../services/login/refresh-token";
import initializeJsonFromLocalStorage from "../../../utils/storage/initialize-storage-utils";
import { isAllowedUserNavigation } from "../../../services/user-interaction-inactivity/services";
import moment from "moment";
import routeBackbeat from "../../../services/backbeat/backbeat-route.js";

const initailizeAdminToken = () => {
  try {
      return initializeJsonFromLocalStorage("admin-token");
  } catch (error) {
    console.error("error on initailizeAdminToken", error);
  }
};

const prepareAdminToken = () => {
  try {
    const tokenReponse = initializeJsonFromLocalStorage("token-reponse");
    localStorage.setItem("admin-token", JSON.stringify(tokenReponse));
    return tokenReponse;
  } catch (error) {
    console.error("error on prepareAdminToken", error);
  }
};

const prepareToken = (tokenReponse) => {
  try {
    let token = {};
    token.value = tokenReponse.value;
    token.expiration = tokenReponse.expiration;
    localStorage.setItem("token", JSON.stringify(token));
    return token;
  } catch (error) {
    console.error(`Error on prepareToken: `, error);
    return null;
  }
};

const prepareRefreshToken = (tokenReponse) => {
  try {
    let refreshToken = tokenReponse.refreshToken;

    localStorage.setItem("refresh-token", JSON.stringify(refreshToken));

    return refreshToken;
  } catch (error) {
    console.error(`Error on prepateRefreshToken: `, error);
    return null;
  }
};

const validateTokenExpiration = (token) => {
  try {
    const dateExpiration = moment(new Date(token.expiration)).tz("UTC")
    return dateExpiration.isBefore(moment(new Date()).tz("UTC"))
  } catch (error) {
    return false;
  }
};

const initializeUserInformation = (token) => {
  try {
    if (!token) {
      const restoredToken = initializeJsonFromLocalStorage("token");

      if (restoredToken) {
        token = { value: restoredToken.value };
      }

      if (!token) return;
    }

    let user = jwt_decode(token.value);
    return user;
  } catch (error) {
    console.error("error on initializeUserInformation", error);
  }
};

const login = {
  state: {
    loading: false,
    token: initializeJsonFromLocalStorage("token"),
    refreshToken: initializeJsonFromLocalStorage("refresh-token"),
    user: initializeUserInformation(),
    adminToken: initailizeAdminToken(),
    validadeLogin: false,
  },
  mutations: {
    restoreAdminToken(state) { 
      state.adminToken = null;
    },
    saveAdminToken(state) { 
      state.adminToken = prepareAdminToken();
    },
    loading(state) {
      state.loading = !state.loading;
    },
    isLogin(state, value) {
      state.validadeLogin = value;
    },
    authSuccess(state, tokenReponse) {
      state.refreshToken = prepareRefreshToken(tokenReponse);
      state.token = prepareToken(tokenReponse);
      state.user = initializeUserInformation(state.token);
    },
    logout(state) {
      state.loading = false;
      state.token = "";
      state.refreshToken = "";
      state.adminToken = "";
      state.user = "";
    },
    setDateAcceptedUseTerm(state, value) {
      state.dateAcceptedUseTerm = value;
    },
  },
  actions: {
    requestLogin({ commit }, { user, tokenCaptcha }) {
      return new Promise((resolve, reject) => {
        requestLogin(user, tokenCaptcha)
          .then(async (tokenReponse) => {
            await commit("authSuccess", tokenReponse);
            await localStorage.setItem(
              "token-reponse",
              JSON.stringify(tokenReponse)
            );

            resolve(tokenReponse);
          })
          .catch((err) => {
            console.error("requestLogin error: ", err);
            reject(err);
          });
      });
    },
    async cancelLoginInspect({ commit, rootGetters, dispatch }) { 
      if (rootGetters.getTokenAdmin) {
        await localStorage.setItem(
          "token-reponse",
          JSON.stringify(rootGetters.getTokenAdmin)
        );
        await dispatch("initializePermissions", rootGetters.getTokenAdmin);
        await commit("restoreAdminToken");
        localStorage.setItem("admin-token", undefined);
      }
    },
    requestLoginInspect({ commit }, user) {
      commit("saveAdminToken");
      return new Promise((resolve, reject) => {
        requestLoginInspect(user)
          .then(async (tokenReponse) => {
            await commit("authSuccess", tokenReponse);
            await localStorage.setItem(
              "token-reponse",
              JSON.stringify(tokenReponse)
            );

            resolve(tokenReponse);
          })
          .catch((err) => {
            console.error("requestLoginInspect error: ", err);
            reject(err);
          });
      });
    },
    async logout({ commit, dispatch }) {
      return new Promise((resolve) => {
        localStorage.setItem("token", "");
        localStorage.setItem("token-reponse", "");
        localStorage.setItem("refresh-token", "");
        localStorage.setItem("use-term", null);
        localStorage.setItem("admin-token", null);
        dispatch("setDateAcceptedUseTerm", null);
        dispatch("setStoreOccurrencesData", null);
        dispatch("setStoreUserInteractionInactivity", null);
        dispatch("clearUserInformation");
        dispatch("clearAnalyticalData");
        dispatch("clearNewnessData");
        dispatch("clearUserInteractionInactivity");
        dispatch("clearDateStatus");
        dispatch("clearPermissions");
        dispatch("clearCommunicationPassword");
        routeBackbeat.setPropertyValue("showBackbeatDetails", null);
        commit("logout");
        resolve();
      });
    },
   
    validarLogin({ commit, state }) {
      if (!state.token) {
        commit("isLogin", false);
        return false;
      }
      if (
        !isAllowedUserNavigation()
        || validateTokenExpiration(state.token) 
        || validateTokenExpiration(state.refreshToken)
      ) {
        commit("isLogin", false);
        return false;
      }

      commit("isLogin", true);
      return true;
    },
    refreshToken({ commit, state}) {
      return new Promise((resolve, reject) => {
        commit("loading");
        if (!state.refreshToken || validateTokenExpiration(state.refreshToken)) {
          resolve(false);
        } else {
          requestRefreshToken(state.refreshToken.value)
            .then((tokenReponse) => {
              localStorage.setItem("token-reponse", JSON.stringify(tokenReponse));
              commit("authSuccess", tokenReponse);
              resolve(tokenReponse);
              commit("loading");
            })
            .catch((err) => {
              console.error("requestLogin error: ", err);
              commit("loading");
              reject(err);
            });
        }
      });
    },
  },
  getters: {
    getLoading: (state) => state.loading,
    getToken: (state) => state.token.value,
    isLoggedIn: (state) => {
      return state.validadeLogin;
    },
    getUserName: (state) => (state.user ? state.user.username : null),
    getUserNameAdmin: (state) => (state.user ? state.user.userNameAdmin : null),
    getTokenAdmin: (state) =>  state.adminToken,
  },
};

export default login;
