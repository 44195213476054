const ServiceRequestType = {
  DOCTOR_DECLARATION_INSS: "DOCTOR_DECLARATION_INSS",
  DOCTOR_DECLARATION_ISS: "DOCTOR_DECLARATION_ISS",
  DOCTOR_DECLARATION_LTCAT: "DOCTOR_DECLARATION_LTCAT",
  DOCTOR_DECLARATION_OTHERS: "DOCTOR_DECLARATION_OTHERS",
  PERSON_INFORMATION_GENERAL: "PERSON_INFORMATION_GENERAL",
  PERSON_DATA_CONTACT: "PERSON_DATA_CONTACT",
  PERSON_DATA_ADDRESS: "PERSON_DATA_ADDRESS",
  DOCTOR_MEDICAL_SPECIALTIES: "DOCTOR_MEDICAL_SPECIALTIES",
  DOCTOR_MEDICAL_SPECIALTIES_OCCUPATION_AREAS: "DOCTOR_MEDICAL_SPECIALTIES_OCCUPATION_AREAS",
  DOCTOR_SERVICE_LOCATIONS: "DOCTOR_SERVICE_LOCATIONS",
  DOCTOR_PAYMENT_METHODS_TARGETING: "DOCTOR_PAYMENT_METHODS_TARGETING",
  DOCTOR_PAYMENT_METHODS_BANK_DATA: "DOCTOR_PAYMENT_METHODS_BANK_DATA",
  DOCTOR_TAXES_AND_PERMIT_INFORMATION_INSS_SAVE:
    "DOCTOR_TAXES_AND_PERMIT_INFORMATION_INSS_SAVE",
    DOCTOR_TAXES_AND_PERMIT_INFORMATION_INSS_UPDATE:
    "DOCTOR_TAXES_AND_PERMIT_INFORMATION_INSS_UPDATE",
  DOCTOR_TAXES_AND_PERMIT_INFORMATION_ISS:
    "DOCTOR_TAXES_AND_PERMIT_INFORMATION_ISS",
  DOCTOR_TAXES_AND_PERMIT_SEND: "DOCTOR_TAXES_AND_PERMIT_SEND",
  DOCTOR_ABSENCE_SEND:"DOCTOR_ABSENCE_SEND",
  DOCTOR_ABSENCE_ANTECIPATION_SEND: "DOCTOR_ABSENCE_ANTECIPATION_SEND",
  DOCTOR_ABSENCE_DELAY_SEND:"DOCTOR_ABSENCE_DELAY_SEND",
  DOCTOR_DECLARATION_SEND: "DOCTOR_DECLARATION_SEND",
  DOCTOR_TEMPORARY_ASSISTENCE: "DOCTOR_TEMPORARY_ASSISTENCE",
  DOCTOR_TEMPORARY_ASSISTENCE_ANTICIPATION: "DOCTOR_TEMPORARY_ASSISTENCE_ANTICIPATION",
  FEATURE_GLOSS_SEND: "FEATURE_GLOSS_SEND",
  GENERAL_REQUESTS: "GENERAL_REQUESTS"
};

export default ServiceRequestType;
