export function formatPispasep(v) {
  v = v.replace(/\D/g, ""); //Remove tudo o que não é dígito
  v = v.replace(/^(\d{3})(\d)/, "$1.$2"); //Coloca ponto entre o terceiro e o quarto dígitos
  v = v.replace(/^(\d{3})\.(\d{5})(\d)/, "$1.$2.$3"); //Coloca ponto entre o quinto e o sexto dígitos
  v = v.replace(/(\d{3})\.(\d{5})\.(\d{2})(\d)/, "$1.$2.$3-$4"); //Coloca ponto entre o décimo e o décimo primeiro dígitos
  return v;
}

export function formatCpfCnpj(v) {
  if (!v) return "";
  //Remove tudo o que não é dígito
  v = v.replace(/\D/g, "");

  if (v.length == 11) {
    //CPF

    //Coloca um ponto entre o terceiro e o quarto dígitos
    v = v.replace(/(\d{3})(\d)/, "$1.$2");

    //Coloca um ponto entre o terceiro e o quarto dígitos
    //de novo (para o segundo bloco de números)
    v = v.replace(/(\d{3})(\d)/, "$1.$2");

    //Coloca um hífen entre o terceiro e o quarto dígitos
    v = v.replace(/(\d{3})(\d{1,2})$/, "$1-$2");
  } else {
    //CNPJ

    //Coloca ponto entre o segundo e o terceiro dígitos
    v = v.replace(/^(\d{2})(\d)/, "$1.$2");

    //Coloca ponto entre o quinto e o sexto dígitos
    v = v.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3");

    //Coloca uma barra entre o oitavo e o nono dígitos
    v = v.replace(/\.(\d{3})(\d)/, ".$1/$2");

    //Coloca um hífen depois do bloco de quatro dígitos
    v = v.replace(/(\d{4})(\d)/, "$1-$2");
  }

  return v;
}

export function formatMoney(value) {
    if (value === 0) return "0,00";
    const val = Number(value.replace(",", "."));
    if (!val) return '0,00';
    const valueString = val.toFixed(2).replace(".", ",");
    return valueString.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}

export function formatCep(campo) {
  var v = campo.replace(/D/g, "");
  v = v.replace(/(\d{5})(\d)/, "$1-$2");
  return v;
}

export function formatFone(campo) {
  if (campo === null || campo === undefined) return campo;
  var v = campo.replace(/D/g, "");
  v = v.replace(/(\d{0})(\d)/, "$1($2");
  v = v.replace(/(\d{2})(\d)/, "$1)$2");
  v = v.replace(/(\d{5})(\d)/, "$1-$2");
  return v;
}
