import axios from "../http-client/axios";
import router from "../../router";
import RoutesPaths from "../../router/router-structure/routes-paths";
import { RoleList } from "../../static-data/RoleList";
import store from "../../store";
import isAbleRole from "../../utils/permissions/is-able-role";
import { KREATOR_URL } from "../urls/base-urls";
import routeBackbeat from "./backbeat-route";

function getRequest() {
  return {
    crm: store.getters.getUserName,
    showBackbeatDetails: false,
  };
}

export async function saveShowBackbeatDetails() {
  var request = getRequest();

  if (request != null) {
    const resp = await axios.post(`${KREATOR_URL}/notification`, request);
    return resp.data;
  }
}

export async function getShowBackbeatDetails() {
  const resp = await axios({
    url: `${KREATOR_URL}/notification`,
    method: "get",
    data: {},
    headers: { "content-type": "application/json" },
  });

  return resp.data;
}

export async function saveShowBackbeat() {
  if (!isAbleRole(RoleList.AUTHORITY_ADMIN)) {
    await saveShowBackbeatDetails();
    routeBackbeat.setPropertyValue("showBackbeatDetails", false);
  }
}

export async function showBackbeat(year, month) {
  if (isAbleRole(RoleList.AUTHORITY_ADMIN)) {
    showRouteBackbeat(year, month);
  }

  let showBackbeatDetails = routeBackbeat.getPropertyByName(
    "showBackbeatDetails"
  );

  if (showBackbeatDetails == null) {
    const notification = await getNotification();

    if (notification == true) {
      routeBackbeat.setPropertyValue("showBackbeatDetails", true);
    } else if (notification == false) {
      routeBackbeat.setPropertyValue("showBackbeatDetails", false);
    }
  }

  showBackbeatDetails = routeBackbeat.getPropertyByName("showBackbeatDetails");
  if (showBackbeatDetails == true) {
    showRouteBackbeatKnowMore();
  } else if (showBackbeatDetails == false) {
    showRouteBackbeat(year, month);
  }
}

async function getNotification() {
  try {
    const data = await getShowBackbeatDetails();
    return data.showBackbeatDetails;
  } catch (error) {
    if (
      error.response &&
      error.response.data &&
      error.response.data.error_code == "USER_NOTIFICATION_STATUS_NOT_FOUND"
    ) {
      return true;
    }

    console.error("getShowBackbeatDetails", error);
    return null;
  }
}

function showRouteBackbeat(year, month) {
  if (year == null && month == null) {
    router.push({
      path: RoutesPaths.financial.backbeat(),
      replace: true,
    });
  } else {
    router.push({
      path: RoutesPaths.financial.backbeat(),
      query: { year: year, month: month },
      replace: true,
    });
  }
}

function showRouteBackbeatKnowMore() {
  router.push({
    path: RoutesPaths.financial.backbeatKnowMore(),
    replace: true,
  });
}
