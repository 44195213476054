const AppInfo = {
  state: {
    buildedRoutes: false,
    vueRouter: null,
  },
  mutations: {
    setBuildedRoutes(state, value) {
      state.buildedRoutes = value;
    },
    setVueRouter(state, value) {
      state.vueRouter = value;
    },
  },
  actions: {
    setBuildedRoutes({ commit }, isBuilded) {
      commit("setBuildedRoutes", isBuilded);
    },
    setVueRouter({ commit }, router) {
      commit("setVueRouter", router);
    },
  },
  getters: {
    isBuildedRoutes: (state) => state.buildedRoutes,
    vueRouter: (state) => state.vueRouter,
  },
};

export default AppInfo;
