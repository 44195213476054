<template>
  <div>
    <TableVisualizationLayout
      :leftInfo="leftInfo"
      :leftAction="leftAction"
      :showTable="this.searched"
      :isCenterTitle="true"
    >
      <template slot="headerContent">
        <div class="col-12 w-100">
          <p class="pb-3">
            Selecione o “Tipo de documento” e o “Ano”, depois clique em “Buscar”
            para ter acesso aos documentos.
          </p>

          <CustomDocumentsAtas class="principal-custom-ata pb-4">
            <template slot="typeDocument">
              <CustomGenericDocumentAtas>
                <template slot="one">
                  <div class="d-flex align-items-center p3">
                    TIPO DE DOCUMENTO:
                  </div>
                </template>
                <template slot="two">
                  <div
                    class="div-select-custom"
                    :class="isLoadCard ? 'div-load' : null"
                  >
                    <b-select
                      :disabled="isLoadCard"
                      class="select-custom"
                      :options="itemsTypesSelect"
                      v-model.lazy.trim="type"
                      v-on:change="onChangeType(type)"
                      size="lg"
                    />
                    <div
                      v-if="isLoadCard"
                      class="d-flex align-items-center icon-container"
                    >
                      <i class="fas fa-spinner fa-spin icon"></i>
                    </div>
                  </div>
                </template>
              </CustomGenericDocumentAtas>
            </template>
            <template slot="yearCompetence">
              <CustomGenericDocumentAtasYear>
                <template slot="one">
                  <div class="d-flex align-items-center p3">
                    ANO (OPCIONAL):
                  </div>
                </template>
                <template slot="two">
                  <div
                    class="div-select-custom"
                    :class="isLoadYear ? 'div-load' : null"
                    id="div-year"
                  >
                    <b-select
                      :disabled="isLoadYear || isLoadCard || !isSelectCard"
                      class="select-custom"
                      :options="itemsYears"
                      v-model.lazy.trim="year"
                      v-on:change="onChangeYear(year)"
                      size="lg"
                    />
                    <div
                      v-if="isLoadYear"
                      class="d-flex align-items-center icon-container"
                    >
                      <i class="fas fa-spinner fa-spin icon"></i>
                    </div>
                  </div>
                </template>
              </CustomGenericDocumentAtasYear>
            </template>
            <template slot="buttonSearch">
              <b-button
                class="button-custom"
                variant="primary"
                @click="onGetDocuments()"
                :disabled="!allowSearch"
              >
                Buscar
              </b-button>
            </template>
          </CustomDocumentsAtas>
        </div>
      </template>
      <template slot="table">
        <b-table
          class="documents-table"
          :items="items"
          :fields="fields"
          v-show="this.searched && !this.loading"
          responsive
          show-empty
          empty-text="Nenhuma informação para exibir!"
        >
          <template v-slot:head(name)="">
            <div class="element-padding-left">Nome do Documento</div>
          </template>
          <template v-slot:head(year)="">
            <div class="element-padding-left">Ano</div>
          </template>
          <template v-slot:head(download)="">
            <div class="text-center">
              Download
            </div>
          </template>
          <template #cell(name)="data">
            <div class="element-padding-left">{{ data.item.name }}</div>
          </template>
          <template #cell(year)="data">
            <div class="element-padding-left">{{ data.item.year }}</div>
          </template>
          <template #cell(download)="data">
            <div class="d-flex justify-content-center">
              <img
                class="pointer"
                src="@/assets/icons/file-download-outline.svg"
                data-toggle="tooltip"
                data-placement="top"
                @click="downloadDocument(data.item.download, data.item.name)"
              />
            </div>
          </template>
        </b-table>
        <div v-show="this.loading">
          <b-skeleton-table :rows="12" :columns="3"></b-skeleton-table>
        </div>
      </template>
    </TableVisualizationLayout>
  </div>
</template>

<script>
import { DocumentType } from "../../../static-data/DocumentType";
import {
  getDocumentsTypes,
  getListDocuments,
  getListYearsDocumentByType,
} from "../../../services/documents_atas/documents-atas";
import TableVisualizationLayout from "../../../layouts/TableVisualizationLayout.vue";
import { downloadAxios } from "../../../services/download/download-service";
import { isMobile } from "../../../utils/mobile/mobile";
import { anchorBase64Download } from "../../../utils/anchor-base64-download/anchor-base64-download";
import { getMimeTypeByExtension } from "../../../utils/File/mimeType"
import CustomDocumentsAtas from "../components/CustomDocumentsAtas.vue";
import CustomGenericDocumentAtas from "../components/CustomGenericDocumentAtas.vue";
import CustomGenericDocumentAtasYear from "../components/CustomGenericDocumentAtasYear.vue";

export default {
  props: ["onSubmitHandler"],
  components: {
    TableVisualizationLayout,
    CustomDocumentsAtas,
    CustomGenericDocumentAtas,
    CustomGenericDocumentAtasYear,
  },
  mounted() {
    this.initDocumentsTypesOptions();
  },
  computed: {
    DocumentType() {
      return DocumentType;
    },
  },
  data() {
    return {
      fields: [
        {
          key: "name",
          label: "Nome do Documento",
        },
        {
          key: "year",
          label: "Ano",
        },
        {
          key: "download",
          label: "Download",
        },       
      ],
      documentId: null,
      year: null,
      leftAction: {
        text: "Documentos e Atas UGF",
        onClick: this.leftActionClickHandler,
      },
      leftInfo: "",
      items: [],
      type: null,
      itemsTypes: [],
      itemsTypesSelect: [],
      years: [],
      itemsYears: [],
      loading: true,
      searched: false,
      isLoadCard: true,
      isLoadYear: false,
      isSelectCard: false,
      allowSearch: false,
    };
  },
  methods: {
    isMobile: isMobile,
    onSubmit(e) {
      e.preventDefault();
      this.formSubmited = true;
      if (this.requiredFieldsFilled()) {
        this.$props.onSubmitHandler(this.form);
        this.formSubmited = false;
      }
    },
    leftActionClickHandler() {
      this.$router.go(-1);
    },
    async initDocumentsTypesOptions() {
      try {
        this.isLoadCard = true;
        this.itemsTypes = await getDocumentsTypes();
        this.itemsTypesSelect = this.buildSelectOptions(this.itemsTypes);
        this.isLoadCard = false;
      } catch (error) {
        this.isLoadCard = false;
        console.error("getDocumentsTypes", error);
      }
    },
    buildSelectOptions(types) {
      return [
        { value: null, text: "Selecione um tipo", disabled: true },
        ...types.map((value) => ({
          text: value.type,
          value: value,
        })),
      ];
    },
    async initListYearsDocumentByType(typeId) {
      try {
        this.isLoadYear = true;
        this.isSelectCard = false;
        this.years = await getListYearsDocumentByType(typeId);
        this.itemsYears = this.buildSelectOptionsYears(this.years);
        this.isLoadYear = false;
        this.isSelectCard = true;
      } catch (error) {
        this.isSelectCard = true;
        this.isLoadYear = false;
        console.error("getListYearsDocumentByType", error);
      }
    },
    buildSelectOptionsYears(years) {
      let yearsAux = [];

      years.forEach((element) => {
        yearsAux.push({ id: element, value: element });
      });

      yearsAux.reverse();

      return [
        { value: null, text: "Selecione um ano" },
        ...yearsAux.map(({ id, value }) => ({
          text: id,
          value: value,
        })),
      ];
    },
    async initListDocuments(type, date) {
      try {
        this.loading = true;
        const items = await getListDocuments(type, date);
        this.items = this.requestHistoryMapper(items);
        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.error("getListDocuments", error);
      }
    },
    requestHistoryMapper(documents) {
      let requests = [];
      documents.forEach((document) => {
        requests.push({
          name: document.name,
          year: document.year,
          download: document.download,
        });
      });

      return requests;
    },
    onGetDocuments() {
      this.searched = true;
      this.fields = this.fields.map((field) => {
        if (!this.typeDocument.showDate && field.key == "year") {
          field = { ...field, thClass: "d-none", tdClass: "d-none" };
        } else {
          delete field.thClass;
          delete field.tdClass;
        }
        return field;
      });
      this.initListDocuments(this.typeDocument.id, this.year);
    },
    async downloadDocument({ pathFile, name }) {
      const splitedPath = pathFile.split('.');
      const fileExtension = splitedPath[splitedPath.length -1].split('?')[0];

      this.loading = true;
      const downloadedFileBase64 = await downloadAxios(
        pathFile,
        name,
        this.$store.getters.isApp
      );

      var mimeType = getMimeTypeByExtension(fileExtension);
      anchorBase64Download(
        this.$store.getters.isApp,
        mimeType,
        name + "." + fileExtension,
        downloadedFileBase64
      );

      this.loading = false;
    },
    onChangeType(type) {
      this.typeDocument = type;
      this.initListYearsDocumentByType(this.typeDocument.id);
      this.allowSearch = true;
    },
    onChangeYear(year) {
      this.year = year;
    },
  },
};
</script>

<style lang="scss" scoped>
.documents-table table {
  th,
  td {
    text-align: start;
  }
}
.form-inline {
  width: 100%;
}
.element-padding-left {
  padding-left: 2rem;
}
.pointer {
  cursor: pointer;
}

.button-custom {
  height: 3.5rem;
  width: 8rem;
}

button.button-custom {
  font-size: small;
}

.vdp-datepicker__calendar {
  font-size: 25px;
}

.div-load {
  display: grid;
  grid-template-columns: auto 20px;
}

.div-select-custom {
  border: 1px solid #cacaca !important;
  text-decoration: none;
  border-radius: 5px;
}

.icon-container {
  padding-left: 5px;
}

.select-custom {
  border: none;
}
</style>
<style module>
input {
  text-align: right;
  font-size: 1.4rem;
}
.vdp-datepicker__calendar {
  font-size: 3.4rem;
}

select.select-custom {
  text-align: right;
}

.principal-custom-ata {
  height: 4.2rem;
}
</style>
