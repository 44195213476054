<template>
  <div>
    <VisualizationFormLayout
      modalId="add-speciality-modal"
      formTitle="Regras para inclusão de dependentes"
      cancelButtonText="Fechar"
      ref="alter-modal"
    >
      <template #content>
        <div class="principal">
          <div class="align-reflesh paddind-space">
            <img
              v-show="true"
              class="mdi-hospital"
              src="@/assets/images/mdi-hospital.svg"
            />
            <p class="p5">
              Para incluir dependentes temos as seguintes regras:
            </p>
          </div>
          <div class="secundario paddind-space">
            <p class="p2">
              • Cônjuge ou companheiro (este último sem concorrência com o
              cônjuge);
            </p>
            <p class="p2 paddind-space">
              • Filho solteiro até 24 anos incompletos;
            </p>
            <p class="p2 paddind-space">
              • Enteado solteiro até 24 anos incompletos;
            </p>
            <p class="p2 paddind-space">
              • Neto solteiro até 24 anos incompletos;
            </p>
            <p class="p2 paddind-space">
              • Criança/adolescente sob guarda ou tutela do beneficiário
              titular, por força de decisão judicial;
            </p>
            <p class="p2 paddind-space">
              • Pessoa sob curatela do beneficiário titular, por força de
              decisão judicial.
            </p>
          </div>
        </div>
      </template>
    </VisualizationFormLayout>
  </div>
</template>

<script>
import VisualizationFormLayout from "../../../layouts/VisualizationFormLayout.vue";
export default {
  components: {
    VisualizationFormLayout,
  },
  methods: {},
};
</script>

<style lang="scss">
.principal {
  margin-top: 3rem;
}

.align-reflesh {
  display: flex;
  justify-content: left;
  flex-direction: row;
  align-items: center;
}

.paddind-space {
  padding-top: 1.5rem;
}

.secundario {
  padding-left: 2rem;
}
</style>
