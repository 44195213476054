import ReportParticipation from "../../modules/training-management/views/ReportParticipation.vue"
import RouterViewRender from "../../utils/routes/router-view-render";

const ReportParticipationRoutes = {
  path: "",
  component: RouterViewRender,
  meta: {
    requiresAuth: true,
  },
  children: [
    {
      path: "report-participation",
      component: ReportParticipation,
      meta: {
        requiresAuth: true,
      },
    },
  ],
};

export default ReportParticipationRoutes;
