<template>
  <div version='1.0.0'>
    <router-view />
  </div>
</template>
<script>
export default {
  name: "App",
};
</script>

<style lang="scss">
/* global styles */
@font-face {
  font-family: "Trebuchet-ms";
  src: url("assets/fonts/trebuchet-ms.ttf");
}
</style>
