<template>
  <CustomAccordionButton class="component-training" :collapseId="id">
    <template slot="header"
      ><div class="title T16 color-primary">
        {{ $props.title }}
      </div></template
    >
    <template slot="content">
      <hr />
      <Period :item="$props.item" />
      <div v-for="(item, index) in $props.steps" :key="id + index">
        <hr />
        <div>
          <div
            class=" align-items-center step-title T16"
            @click="() => onClick(item)"
            :class="getClassToPending(item, $props.steps[index - 1])"
          >
            <div>
              <img
                class="custom-img"
                :src="getPathUrl(item, $props.steps[index - 1])"
              />
            </div>
            <div
              class="title align-items-center"
              :class="
                isDisabled(item, $props.steps[index - 1]) && 'custom-disabled'
              "
            >
              {{ item.title }}
            </div>
            <div class="ml-auto icon-download-pdf" v-if="onCheckDownload(item)">
              <img
                class="custom-img"
                :class="
                  isDisabled(item, $props.steps[index - 1]) && 'custom-disabled'
                "
                @click="
                  !isDisabled(item, $props.steps[index - 1]) && onDownload(item)
                "
                :src="resolve_path_url('download-pencil-outline.svg')"
              />
            </div>
          </div>
          <div
            v-if="getFilesAdditionalNoPrincial(item.filesAdditional).length > 0"
            class="custom-files-additional pb-3"
          >
            <div class="effect-line-left">
              <hr class="step-file--margin-left" />
              <div
                v-for="(file, fileIndex) in getFilesAdditionalNoPrincial(
                  item.filesAdditional
                )"
                :key="id + index + fileIndex"
              >
                <div
                  class="align-items-center step-file step-file--margin-left"
                  @click="
                    !isDisabled(item, $props.steps[index - 1]) &&
                      onDownload(file)
                  "
                  :class="
                    !isDisabled(item, $props.steps[index - 1]) && 'cursor-point'
                  "
                >
                  <p
                    v-text="
                      file.fileName ? file.fileName : 'file-' + (fileIndex + 1)
                    "
                    :class="
                      isDisabled(item, $props.steps[index - 1]) &&
                        'custom-disabled'
                    "
                    class="text-file pr-1"
                  ></p>
                  <img
                    class="custom-img"
                    :class="
                      isDisabled(item, $props.steps[index - 1])
                        ? 'custom-disabled'
                        : 'cursor-point'
                    "
                    :src="resolve_path_url('download-pencil-outline.svg')"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </CustomAccordionButton>
</template>

<script>
import CustomAccordionButton from "../../../components/custom-accordion/CustomAccordionButton.vue";
import { resolve_path_url } from "../../../utils/image/image-utils";
import Period from "../components/period.vue";

export default {
  components: {
    CustomAccordionButton,
    Period,
  },
  props: [
    "title",
    "status",
    "steps",
    "onStepClick",
    "currentStep",
    "id",
    "onTrainingClick",
    "item",
  ],
  data() {
    return {
      icons: {
        VIDEO: {
          enabled: "MDI-motion-play.svg",
          disabled: "MDI-motion-play-disabled.svg",
          pendingDisabled: false,
        },
        PDF: {
          enabled: "MDI-pdf.svg",
          disabled: "MDI-pdf-disabled.svg",
          pendingDisabled: true,
        },
        FORM: {
          enabled: "MDI-message-question.svg",
          disabled: "MDI-message-question-disabled.svg",
          pendingDisabled: true,
        },
        CERTIFICATE: {
          enabled: "MDI-certificate.svg",
          disabled: "MDI-certificate-disabled.svg",
          pendingDisabled: true,
        },
      },
    };
  },
  methods: {
    getFilesAdditionalNoPrincial(filesAdditional) {
      if (filesAdditional.length > 0) {
        return filesAdditional.filter((fa) => !fa.principal);
      }
      return [];
    },
    onCheckDownload(item) {
      return (
        item.type == "PDF" ||
        (item.filesAdditional != null &&
          item.filesAdditional != undefined &&
          item.filesAdditional.length > 0)
      );
    },
    isDisabled(item, beforeStep) {
      if (this.$store.getters.isAdmin) return false;
      return !(item.order == 0 || beforeStep?.status == "FINISHED");
    },
    getClassToPending(item, beforeStep) {
      let classCss = "";
      if (this.$props.currentStep?.id == item.id) {
        classCss += " effect ";
      }

      if (item.order == 0 || beforeStep?.status == "FINISHED") {
        classCss += " cursor-point ";
      }

      return classCss;
    },
    getPathUrl(item, beforeStep) {
      return item.order == 0 || beforeStep?.status == "FINISHED"
        ? resolve_path_url(this.icons[item.type].enabled)
        : resolve_path_url(this.icons[item.type].disabled);
    },
    onClick(step) {
      this.$props.onStepClick(step, this.$props.steps);
    },
    getLink({ link }) {
      if (this.$store.getters.isApp) {
        return link.replace("https", "http");
      } else {
        return link;
      }
    },
    async onDownloadFile(item) {
      const aElement = document.createElement("a");

      const outsideRes = await fetch(this.getLink(item));

      const blob = await outsideRes.blob();
      const url = window.URL.createObjectURL(blob);
      aElement.href = url;

      if (item.fileName != null && item.fileName != undefined) {
        aElement.setAttribute("download", item.fileName);
      }

      aElement.setAttribute("target", "_blank");
      document.body.appendChild(aElement);
      aElement.click();
    },
    onDownload(item) {
      if (item.type == "PDF") {
        if (item?.status != "FINISHED") return;
        this.onDownloadFile(item);
      } else {
        this.onDownloadFile(item);
      }
    },
    resolve_path_url: resolve_path_url,
  },
};
</script>
<style lang="scss" scoped>
.component-training {
  margin-bottom: 1em;
}
.cursor-point {
  cursor: pointer;
}
.title {
  padding-left: 1rem;
  font-size: 1.6rem;
}
.custom-step-menu {
  border-top-width: 0.5px;
  border-top-style: solid;
  border-top-color: #cdd2d7;

  padding-left: 1rem;
  display: grid;
  width: 100%;
  height: 4.4rem;
  padding-right: 0;
  grid-template-columns: 35px auto;
}
.cursor-point {
  cursor: pointer;
}
.effect {
  border-right-width: 3px;
  border-right-style: solid;
  border-right-color: #b1d34b !important;
}
.step-title {
  display: flex;
  flex-flow: row wrap;
  margin-left: 30px;
  padding-top: 12px;
  padding-bottom: 12px;
}

.step-file {
  &--margin-left {
    margin-left: 20px;
  }
  display: flex;
  flex-flow: row wrap;
  padding-top: 5px;
}

.effect-line-left {
  border-left-width: 1px;
  border-left-style: solid;
  border-left-color: #aaaaaa36 !important;
  margin: 0px 0 0 0;
}

.custom-img {
  height: 2.2rem;
  width: 2.2rem;
}
hr {
  border-color: #aaaaaa36;
  box-sizing: border-box;
  width: 100%;
  margin: 0px 0 0 0;
}
.selected {
  border-right-width: 3px;
  border-right-style: solid;
  border-right-color: #b1d34b !important;
}

.icon-download-pdf {
  margin-right: 6px;
}

.text-file {
  font-size: multiplier-default-font-size(3);
  color: var(--primary);
  text-decoration: underline;
  line-break: anywhere;
}

.custom-files-additional {
  padding-left: 42px;
}
.custom-disabled {
  opacity: 0.5;
}
</style>
