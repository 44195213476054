import initializeJsonFromLocalStorage from "../../../utils/storage/initialize-storage-utils";
import { getAnalyticsAfterLogin } from "../../../services/analytics/analytics-services.js";

const analyticalData = {
  state: {
    analyticalData: initializeJsonFromLocalStorage("analytical-data"),
  },
  mutations: {
    setAnalyticalData(state, data) {
      state.analyticalData = data;
    },
    clear(state) {
      state.analyticalData = null;
    },
  },
  actions: {
    requestAnalyticalData({ commit }, crm) {
      return new Promise((resolve, reject) => {
        getAnalyticsAfterLogin(crm)
          .then((analytical) => {
            localStorage.setItem("analytical-data", JSON.stringify(analytical));
            commit("setAnalyticalData", analytical);
            resolve(analytical);
          })
          .catch((err) => {
            console.error("requestLogin error: ", err);
            reject(err);
          });
      });
    },
    setStoreAnalyticalData({ commit }, data) {
      localStorage.setItem("analytical-data", JSON.stringify(data));
      commit("setAnalyticalData", data);
    },
    clearAnalyticalData({ commit }) {
      return new Promise((resolve, reject) => {
        try {
          localStorage.setItem("analytical-data", "");
          commit("clear");
          resolve();
        } catch (err) {
          reject(err);
        }
      });
    },
  },
  getters: {
    getAnalyticalData: (state) => state.analyticalData,
  },
};

export default analyticalData;
