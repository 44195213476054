export const AdminUserInformation = {
  crm: "112233",
  name: "Administrador",
  birthdate: "08/04/1970",
  maritalStatus: "SINGLE",
  nationalHealthCard: "",
  cpf: "858136724",
  rg: "054836804",
  pis: "11341076541",
  inss: "11341076541",
  schooling: "Mestrado completo",
  codePerson: "112233",
  nickname: "Administrador",
  isAdmin: true,
};
