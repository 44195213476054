import Analytics from "../../modules/analytics/views/Analytics.vue";
import { PermissionList } from "../../static-data/PermissionList";
import RouterViewRender from "../../utils/routes/router-view-render";
import { IsAbleNavigation } from "../utils/is-able-navigation";

const AnalyticsRoutes = {
  path: "analytics",
  component: RouterViewRender,
  beforeEnter: (to, from, next) =>
    IsAbleNavigation(to, from, next, PermissionList.ANALYTICS_MENU),
  children: [
    {
      path: "",
      component: Analytics,
      meta: {
        requiresAuth: true,
      },
    },
  ],
};

export default AnalyticsRoutes;
