import UseTerm from "../../modules/use-term/views/UseTerm.vue";
import RoutesPaths from "../router-structure/routes-paths";
import InitialLayout from "../../layouts/InitialLayout.vue";
import RouterViewRender from "../../utils/routes/router-view-render";
import { IsAbleNavigation } from "../utils/is-able-navigation";
import { PermissionList } from "../../static-data/PermissionList";

export const UseTermsRoutes = [
  {
    path: RoutesPaths.useTerm.rootName(),
    component: InitialLayout,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "",
        name: "UseTerm",
        component: UseTerm,
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },
];

export const AcceptedUseTermsRoutes = 
  {
    path: "use-term",
    component: RouterViewRender,
    beforeEnter: (to, from, next) =>
      IsAbleNavigation(to, from, next, PermissionList.USE_TERM_MENU),
    children: [
      {
        path: "accepted",
        component: UseTerm,
        props: {
          disableAction: true,
        },
        meta: {
          requiresAuth: true,
        },
      },
    ],
  };
