<script>
export default {
  render() {
    return (
      <div class="form-template--container">
        <div class="form-template--left-content">
          <div class="form-template--left-action">{this.$slots.leftAction}</div>
          {this.$slots.infoCard}
        </div>
        <div class="form-template--content">
          <div class="form-template--navigation">{this.$slots.navigation}</div>
        </div>
        <div>{this.$slots.default}</div>
      </div>
    );
  },
};
</script>

<style lang="scss">
.form-template {
  &--left-content {
    @include media-breakpoint-up(md) {
      margin: 0rem 5rem 5rem 5rem;
    }

    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &--left-action {
    margin: 0 4.2rem 5rem 0;
  }

  &--container {
    padding: 1.5rem 5rem;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    @include media-breakpoint-up(xs) {
      padding: 0rem 0rem;
      justify-content: center;
    }
    @include media-breakpoint-up(sm) {
      padding: 0rem 0rem;
      justify-content: center;
    }
    @include media-breakpoint-up(md) {
      padding: 1rem 3rem;
      justify-content: center;
    }
    @include media-breakpoint-up(lg) {
      justify-content: start;
    }
  }

  &--content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 60%;

    @include media-breakpoint-down(xs) {
      width: 100%;
      margin-left: 0rem;
    }

    @include media-breakpoint-down(sm) {
      width: 85%;
      margin-left: 0rem;
    }

    @include media-breakpoint-down(md) {
      width: 90%;
      margin-left: 0rem;
    }
  }

  &--navigation {
    // isso aqui não ta legal vamos ter que arrumar mexe em todas a telas acho.
    width: 100%;
  }
}
</style>
