<template>
  <div>
    <FormLayout
      :tabs="this.tabs"
      :cardInfo="this.cardInfo"
      :leftAction="this.leftAction"
      :leftLinkNavigation="this.leftLinkNavigation"
      :onChangeTab="onChangeTabIndex"
    ></FormLayout>
  </div>
</template>

<script>
import Vue from "vue";
import FormLayout from "../../../layouts/FormLayout.vue";
import { ServiceType } from "../../../static-data/ServiceType";
import RoutesPaths from "../../../router/router-structure/routes-paths";

Vue.component("specialities-information", () =>
  import("../components/visualization/SpecialitiesInformation.vue")
);

Vue.component("occupation-area", () =>
  import("../components/visualization/OccupationArea.vue")
);

export default {
  components: {
    FormLayout,
  },
  data: function() {
    return {
      currentTabIndex: 0,
      leftAction: {
        text: "Cadastro",
        onClick: this.leftActionClickHandler,
      },
      leftLinkNavigation: this.leftHistoryNavigation,
      cardInfo: {
        icon: "doctor.svg",
        title: "Especialidades",
        description: "Especialidades cadastradas, Tipo e área de atuação.",
      },
      tabs: [
        {
          title: "Especialidades Médicas",
          formComponent: "specialities-information",
          serviceType: ServiceType.MedicalSpecialities,
        },
        {
          title: "Área de atuação",
          formComponent: "occupation-area",
          serviceType: ServiceType.OccupationArea,
        },
      ],
    };
  },
  methods: {
    onChangeTabIndex(newValue) {
      this.currentTabIndex = newValue;
    },
    leftActionClickHandler() {
      this.$router.push(RoutesPaths.register.rootName());
    },
    leftHistoryNavigation() {
      console.log("leftHistoryNavigation", this.currentTabIndex, this.tabs);
      this.$router.push({
        path: RoutesPaths.requestHistory.rootName(),
        params: {
          serviceType: this.tabs[this.currentTabIndex].serviceType,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
