<template>
  <div class="avatar-container">
    <div v-show="$refs.upload && $refs.upload.dropActive" class="drop-active">
      <h3>Drop files to upload</h3>
    </div>
    <div class="avatar-upload" v-show="!edit">
      <div class="text-center p-2">
        <label for="avatar">
          <img
            :src="
              form.files.length
                ? form.files[0]
                : 'https://www.gravatar.com/avatar/default?s=200&r=pg&d=mm'
            "
            class="rounded-circle"
          />
          <h4 class="pt-2">Arraste a foto até aqui</h4>
          <h4 class="pt-2 text-muted">ou</h4>
        </label>
      </div>
      <div class="text-center p-2">
        <file-upload
          extensions="gif,jpg,jpeg,png,webp"
          accept="image/png,image/gif,image/jpeg,image/webp"
          name="avatar"
          class="btn btn-primary"
          post-action="/upload/post"
          :drop="!edit"
          v-model="form.files"
          @input-filter="inputFilter"
          @input-file="inputFile"
          ref="upload"
        >
          Escolha uma foto para fazer upload
        </file-upload>
      </div>
      <div class="text-center p-2"></div>
    </div>
    <div class="avatar-edit" v-show="form.files.length && edit">
      <div class="avatar-edit-image" v-if="form.files.length">
        <img
          ref="editImage"
          :src="form.files[0].url ? form.files[0].url : form.files[0]"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Cropper from "cropperjs";
import { inject } from "@vue/composition-api";
import { getProfileImage } from "../../../../services/profile-image/profile-image-services.js";
import FileUpload from "vue-upload-component";

const DefaultForm = {
  files: [],
};

export default {
  components: { FileUpload },
  setup() {
    const formData = inject("formData");
    const setForm = inject("setForm");
    const setSubmitHandler = inject("setSubmitHandler");
    const setFormSelected = inject("setFormSelected");

    return { formData, setForm, setSubmitHandler, setFormSelected };
  },
  mounted() {
    this.initForm();
  },
  data() {
    return {
      files: [],
      edit: false,
      cropper: false,
      formSubmited: false,
      form: { ...DefaultForm },
      validations: {},
    };
  },
  watch: {
    edit(value) {
      if (value) {
        this.$nextTick(function() {
          if (!this.$refs.editImage) {
            return;
          }
          let cropper = new Cropper(this.$refs.editImage, {
            dragMode: "move",
            restore: false,
            guides: false,
            center: false,
            highlight: false,
            toggleDragModeOnDblclick: false,
            background: false,
          });
          this.cropper = cropper;
        });
      } else {
        if (this.cropper) {
          this.cropper.destroy();
          this.cropper = false;
        }
      }
    },
  },
  methods: {
    async initForm() {
      this.setFormSelected(true);
      this.setSubmitHandler(this.onSubmit);

      this.formData = {
        form: { ...this.form },
        onSubmitHandler: this.onSubmit,
        onDeleteHandler: true,
        formSelected: true,
      };
    },
    validateFieldFile(name) {
      let value = this.form[name];
      const validation = !!value && value.length > 0;
      this.validations[name] = validation;
      if (!this.formSubmited) return null;
      return validation;
    },
    validateField(name) {
      let value = this.form[name];

      let validation = !!value;
      this.validations[name] = validation;
      if (!this.formSubmited) return null;

      return !!value;
    },
    async getUserImage() {
      return await getProfileImage(this.$store.getters.getUserName);
    },
    requiredFieldsFilled() {
      return Object.values(this.validations).every(
        (fieldValue) => fieldValue == true
      );
    },
    eventToUpdateFile(files) {
      this.form.files = files;
      this.editSave();
    },
    onSubmit() {
      this.editSave();
      this.setForm(this.form);
      this.formSubmited = true;
      if (this.requiredFieldsFilled()) {
        return true;
      }
    },
    editSave() {
      this.edit = false;
      let oldFile = this.form.files[0];
      let binStr = atob(
        this.cropper
          .getCroppedCanvas()
          .toDataURL(oldFile.type)
          .split(",")[1]
      );
      let arr = new Uint8Array(binStr.length);
      for (let i = 0; i < binStr.length; i++) {
        arr[i] = binStr.charCodeAt(i);
      }
      let file = new File([arr], oldFile.name, { type: oldFile.type });
      this.form.files = [file];
    },
    alert(message) {
      alert(message);
    },
    inputFile(newFile, oldFile) {
      if (newFile && !oldFile) {
        this.$nextTick(function() {
          this.edit = true;
        });
      }
      if (!newFile && oldFile) {
        this.edit = false;
      }
    },
    inputFilter(newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        if (!/\.(gif|jpg|jpeg|png|webp)$/i.test(newFile.name)) {
          this.alert("Your choice is not a picture");
          return prevent();
        }
      }
      if (newFile && (!oldFile || newFile.file !== oldFile.file)) {
        newFile.url = "";
        let URL = window.URL || window.webkitURL;
        if (URL && URL.createObjectURL) {
          newFile.url = URL.createObjectURL(newFile.file);
        }
      }
    },
  },
};
</script>

<style lang="scss">
//16px texto do upload
@import "../../../../../node_modules/cropperjs/dist/cropper.min.css";
.cropper-crop-box,
.cropper-view-box {
  border-radius: 50%;
}

.cropper-view-box {
  box-shadow: 0 0 0 1px #39f;
  outline: 0;
}
div.form-row {
  padding: 0.5rem;
  border-bottom: 1px solid $gray-300;
  &:last-child {
    border-bottom: none;
  }
}

input.form-control {
  text-align: right;
  border: 1px solid transparent;
}

select.custom-select {
  text-align: right;
  border: 1px solid transparent;
}

.rounded-circle {
  width: 200px;
  height: 200px;
}

.avatar-container {
  width: 100%;
  height: 100%;
  padding: 2rem 2rem;
}

.avatar-upload {
  padding: 5rem 4rem;
  background: var(--dashed-border-0);
}

span.btn {
  font-size: inherit;
}

.avatar-edit-image {
  max-width: 100%;
  max-height: 50rem;
}

.drop-active {
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  position: fixed;
  z-index: 9999;
  opacity: 0.6;
  text-align: center;
  background: #000;
}

.drop-active h3 {
  margin: -0.5em 0 0;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 40px;
  color: #fff;
  padding: 0;
}
</style>
