import initializeJsonFromLocalStorage from "../../../utils/storage/initialize-storage-utils";
const setCommunicationPasswordStore = (communicationPassword) => {
  try {
    localStorage.setItem("communication-password", JSON.stringify(communicationPassword));
  } catch (error) {
    console.error("error on setCommunicationPasswordStore", error);
    return false;
  }
};

const initializeCommunicationPasswordStatusStore = () => {
  try {
    const communicationPassword = initializeJsonFromLocalStorage("communication-password");
    return communicationPassword;
  } catch (error) {
    console.error("error on initializeCommunicationPasswordStatusStore", error);
    return false;
  }
};

const communicationPassword = {
  state: {
    communicationPassword: initializeCommunicationPasswordStatusStore(),
  },
  mutations: {
    setCommunicationPasswordStatus(state, value) {
      state.communicationPassword = value;
    },
    clear(state) {
      state.communicationPassword = null;
    },
  },
  actions: {
    async showCommunicationPassword({ commit }) {
      try {
        await setCommunicationPasswordStore(true);
        commit("setCommunicationPasswordStatus", true);
        return true;
      } catch (error) {
        console.error("Error on showCommunicationPassword", error);
        await setCommunicationPasswordStore(null);
      }
    },
    
    async clearCommunicationPassword({ commit }) {
      await setCommunicationPasswordStore(null);
      commit("clear");
    },
  },
  getters: {
    communicationPassword: (state) => state.communicationPassword,
  },
};

export default communicationPassword;
