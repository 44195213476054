<script>
export default {
  props: ["isShowseparator", "isCenterSeparadorLine"],
  methods: {
    checkShowSeparetor() {
      if (
        this.$props.isShowseparator == undefined ||
        this.$props.isShowseparator == null
      ) {
        return true;
      } else {
        return this.$props.isShowseparator;
      }
    },
    onGetClassSeparadorLine() {
       if (this.$props.isCenterSeparadorLine == true ) {
          return "separator-line-total my-4 custom-center"
       }

       return "separator-line-total my-4"
    }
  },
  render() {
    return (
      <b-container>
        <b-row class="d-flex flex-column">
          <div class="mr-auto w-100">{this.$slots.leftAction}</div>
          <div
            v-show={this.checkShowSeparetor()}
            class={this.onGetClassSeparadorLine()}
          ></div>
          <div class="margin-content">{this.$slots.leftInfo}</div>
        </b-row>
        <b-row class="header-content margin-content">
          {this.$slots.headerContent}
        </b-row>
        <b-container class="margin-content">{this.$slots.content}</b-container>
        <b-row class="footer-margin">{this.$slots.footerContent}</b-row>
      </b-container>
    );
  },
};
</script>

<style lang="scss" scoped>
.footer-margin {
  margin-top: 4rem;
  justify-content: center;
  align-content: center;
}

@include media-breakpoint-up(md) {
  .margin-content {
    margin-left: 3.4rem !important;
    width: 97%;
  }
  .footer-margin {
    margin-left: 3.4rem !important;
  }
}

@media (max-width: 899px) {
  .custom-center {
    width: 40px !important;
  }
}

.custom-center {
  width: 100%;
}
</style>
