<template>
  <div class="d-flex flex-wrap w-100 menu">
    <div class="mr-auto">
      <b-navbar variant="faded" type="light">
        <b-navbar-brand href="#/home">
          <img
            src="@/assets/images/logo_verde.svg"
            class="d-inline-block align-top logo"
            alt="Kitten"
          />
        </b-navbar-brand>
      </b-navbar>
    </div>
    <div>
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
    </div>
    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav class="ml-auto mr-6 menu">
        <b-nav-item v-if="validateRole(PermissionList.HOME_MENU)">
          <CustomDropdown
            title="Início"
            :items="this.dropdownHome()"
            :route="this.linkHome"
            :active="selectRoute(this.linkHome)"
        /></b-nav-item>
        <b-nav-item v-if="validateRole(PermissionList.INSTITUTIONAL_PAGE_MENU)">
          <CustomDropdown
            title="Institucional"
            :items="this.dropdownInstitucional()"
            :route="this.linkInstitucional"
            :active="selectRoute(this.linkInstitucional)"
        /></b-nav-item>
        <b-nav-item v-if="validateRole(PermissionList.REGISTER_MENU)">
          <CustomDropdown
            title="Cadastro"
            :items="this.dropdownCadastro()"
            :route="this.linkCadastro"
            :active="selectRoute(this.linkCadastro)"
        /></b-nav-item>
        <b-nav-item v-if="validateRole(PermissionList.DECLARATIONS_MENU)">
          <CustomDropdown
            title="Declarações"
            :items="this.dropdownDeclaracoes()"
            :route="this.linkDeclaracoes"
            :active="selectRoute(this.linkDeclaracoes)"
        /></b-nav-item>
        <b-nav-item v-if="validateRole(PermissionList.VIRTUAL_OFFICE_MENU)">
          <CustomDropdown
            title="Consultório Virtual"
            :items="this.dropdownConsultorioVirtual()"
            :route="this.linkConsultorioVirtual"
            :active="selectRoute(this.linkConsultorioVirtual)"
        /></b-nav-item>
        <b-nav-item v-if="validateRole(PermissionList.BENEFIT_MENU)">
          <CustomDropdown
            title="Benefícios"
            :items="this.dropdownBeneficios()"
            :route="this.linkBeneficios"
            :active="selectRoute(this.linkBeneficios)"
        /></b-nav-item>
        <b-nav-item v-if="validateRole(PermissionList.FINANCIAL_MENU)">
          <CustomDropdown
            title="Financeiro"
            :items="this.dropdownFinanceiro()"
            :route="this.linkFinanceiro"
            :active="selectRoute(this.linkFinanceiro)"
        /></b-nav-item>

        <b-nav-item
          v-if="
            validateRole(PermissionList.CONTENT_MANAGEMENT_MENU) &&
              !validateRole(PermissionList.HOME_MENU)
          "
        >
          <CustomDropdown
            title="Relatório de visualizações"
            :items="this.dropViewsReport()"
            :route="this.linkViewsReport"
            :active="selectRoute(this.linkViewsReport)"
        /></b-nav-item>

        <b-nav-item
          v-if="
            validateRole(PermissionList.CONTENT_MANAGEMENT_MENU) &&
              !validateRole(PermissionList.HOME_MENU)
          "
        >
          <CustomDropdown
            title="Gestão de conteúdos"
            :items="this.dropContentManagement()"
            :route="this.linkContentManagement"
            :active="selectRoute(this.linkContentManagement)"
        /></b-nav-item>
        <b-nav-item
          :active="selectRoute('/faq')"
          @click="selectNavItemClick('faq')"
          v-if="validateRole(PermissionList.FAQ_MENU)"
        >
          <img class="icon-faq" src="@/assets/icons/help-circle-outline.svg" />
        </b-nav-item>
        <b-nav-item>
          <Dropdown :text="getUser()" :userImage="this.imageProfile">
            <div class="d-flex flex-column">
              <DropdownItem
                class="dropdown-item-center pt-4"
                @click="openImageProfileModal"
              >
                <div class="user-img-gr">
                  <img :src="this.imageProfile" v-if="this.imageProfile" />
                  <img src="@/assets/icons/user.svg" v-else />
                  <div class="icon-photos">
                    <img src="@/assets/icons/add-a-photo.svg" />
                  </div>
                </div>
                <p class="pt-4">Adicione ou altere sua foto de perfil</p>
              </DropdownItem>
              <DropdownDivider />
              <DropdownItem>
                <div class="d-flex align-content-center align-items-center">
                  <div>
                    <h4 class="status-custom">Status</h4>
                  </div>

                  <div class="ml-3">
                    <status></status>
                  </div>
                </div>
              </DropdownItem>
              <DropdownItem @click="changePassword">
                <h4>Alterar senha</h4>
              </DropdownItem>
              <DropdownItem
                @click="navigateRequestHistory"
                v-if="validateRole(PermissionList.REQUEST_HISTORY_MENU)"
              >
                <h4>Histórico de solicitações</h4>
              </DropdownItem>
              <DropdownItem
                @click="onClickUseTerm"
                v-if="validateRole(PermissionList.USE_TERM_MENU)"
              >
                <h4>Termos e condições de uso</h4>
              </DropdownItem>
              <DropdownItem @click="logout">
                <h4>Sair</h4>
              </DropdownItem>
            </div>
          </Dropdown>
        </b-nav-item>
      </b-navbar-nav>
    </b-collapse>
  </div>
</template>

<script>
import RoutesPaths from "../router/router-structure/routes-paths";
import CustomDropdown from "../components/custom-dropdown/CustomDropdown.vue";
import Dropdown from "../components/dropdown/Dropdown.vue";
import DropdownItem from "../components/dropdown/DropdownItem.vue";
import DropdownDivider from "../components/dropdown/DropdownDivider.vue";
import getProfileImage from "../services/profile-image/profile-image-services.js";
import isAble from "../utils/permissions/is-able";
import { PermissionList } from "../static-data/PermissionList";
import {
  dropdownInstitucional,
  dropdownCadastro,
  dropdownDeclaracoes,
  dropdownConsultorioVirtual,
  dropdownBeneficios,
  dropdownFinanceiro,
  dropdownHome,
  dropdownContentManagement,
  dropdownViewsReport,
} from "../store/modules/menu/index.js";
import { isMobile } from "../utils/mobile/mobile";

import Status from "../components/status/Status.vue";
import BlipClient from "../components/blip-chat/blip-chat";
import { getNameUser } from "../utils/user/user.js";

export default {
  components: {
    CustomDropdown,
    DropdownDivider,
    DropdownItem,
    Dropdown,
    Status,
  },
  mounted() {
    this.getUserImage();
  },
  computed: {
    imageProfile() {
      return this.$store.getters.getImageProfile;
    },
    dropdownInstitucional() {
      return dropdownInstitucional;
    },
    dropdownCadastro() {
      return dropdownCadastro;
    },
    dropdownDeclaracoes() {
      return dropdownDeclaracoes;
    },
    dropdownConsultorioVirtual() {
      return dropdownConsultorioVirtual;
    },
    dropdownBeneficios() {
      return dropdownBeneficios;
    },
    dropdownFinanceiro() {
      return dropdownFinanceiro;
    },
    dropdownHome() {
      return dropdownHome;
    },
    dropContentManagement() {
      return dropdownContentManagement;
    },
    dropViewsReport() {
      return dropdownViewsReport;
    },
    PermissionList() {
      return PermissionList;
    },
  },
  data() {
    return {
      userImage: null,
      linkInstitucional: RoutesPaths.institutionalPage.rootName(),
      linkCadastro: RoutesPaths.register.rootName(),
      linkDeclaracoes: RoutesPaths.declarations.rootName(),
      linkConsultorioVirtual: RoutesPaths.virtualOffice.rootName(),
      linkBeneficios: RoutesPaths.benefit.rootName(),
      linkFinanceiro: RoutesPaths.financial.rootName(),
      linkContentManagement: RoutesPaths.contentManagement.rootName(),
      linkFileManagement: RoutesPaths.fileManagement.rootName(),
      linkHome: RoutesPaths.home.rootName(),
      linkViewsReport: RoutesPaths.reports.rootName(),
    };
  },
  methods: {
    validateRole(role) {
      return isAble(role);
    },
    selectRoute(routeCurrent) {
      return this.$route.matched.some((rota) => rota.path == routeCurrent);
    },
    getUser() {
      return getNameUser();
    },
    async getUserImage() {
      this.userImage = await getProfileImage(this.$store.getters.getUserName);
      await this.$store.dispatch("setImageProfile", this.userImage);
    },
    openImageProfileModal() {
      this.$bvModal.show("profile-image-modal");
    },
    async changePassword() {
      this.$bvModal.show("change-password-modal");
    },
    async navigateRequestHistory() {
      this.$router.push({
        path: RoutesPaths.requestHistory.rootName(),
        replace: true,
      });
    },
    onClickUseTerm() {
      this.$router.push(RoutesPaths.useTerm.accepted());
    },
    async logout() {
      try {
        if (!isMobile() && !this.$store.getters.isAdmin) {
          const blipClient = BlipClient.getInstance();
          blipClient.destroy();
        }
        this.$store.dispatch("logout");

        this.$router.push({
          path: RoutesPaths.login.rootName(),
          query: { isApp: this.$store.getters.isApp },
        });
      } catch (error) {
        console.error("logout", error);
      }
    },
    selectNavItemClick(item) {
      this.$router.push({ path: item });
    },
  },
};
</script>

<style lang="scss" scoped>
.user-img-gr {
  display: flex;
  height: 6.5rem;
  width: 6.5rem;
  margin-right: 1rem;
  justify-content: center;
  align-content: center;
  align-items: center;
  padding: 0.3rem;
  border: 0.2rem solid var(--primary);
  border-radius: 60%;
  position: relative;
  img {
    height: 5rem;
    width: 5rem;
    border-radius: 60%;
  }
}

.icon-photos {
  position: absolute;
  right: 0;
  bottom: 0;
  background-color: white;
  border-radius: 60%;
  img {
    height: 2.5rem;
    width: 2.5rem;
  }
}

.menu {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.dropdown-item-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#nav-collapse .nav-link {
  @include header4;
  color: var(--grey-1);
  padding-right: 2rem;

  @media screen and (max-width: 1112px) {
    @include header5;
    color: var(--grey-1);
    padding-right: 0.5rem;
  }

  @media screen and (max-width: 920px) and (min-width: 768px) {
    @include header6;
    color: var(--grey-1);
    padding-right: 0.2rem;
  }
}

#nav-collapse .nav-link.active {
  color: var(--dark);
}
.status-custom {
  margin-bottom: 0rem;
}
.logo {
  width: 9.7rem;
  height: 5.2rem;

  @media screen and (max-width: 1112px) {
    width: 8.7rem;
    height: 4.2rem;
  }
  @media screen and (max-width: 920px) and (min-width: 768px) {
    width: 7.7rem;
    height: 4.2rem;
  }
}
</style>
