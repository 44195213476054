import RequestHistory from "../../modules/request-history/views/RequestHistory.vue";

const RequestHistoryRoutes = {
  path: "request-history",
  component: RequestHistory,
  meta: {
    requiresAuth: true,
  },
};

export default RequestHistoryRoutes;
