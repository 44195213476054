<template>
  <b-container
    class="date-picker-custom"
    :class="
      this.$props.required && this.$props.state != null
        ? 'img-visible'
        : 'img-invisible'
    "
  >
    <div></div>
    <div class="">
      <b-form-datepicker
        :id="datePickerId"
        v-model.lazy.trim="value"
        locale="pt-BR"
        label-help=""
        placeholder="dd/MM/aaaa"
        size="lg"
        :date-format-options="{
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        }"
        @context="onContext"
      />
    </div>
    <div>
      <img
        class="d-flex align-items-center h-100 custom-icon-remove"
        src="@/assets/icons/close-circle.svg"
        @click="removeValue()"
      />
    </div>
    <div
      class="pl-2"
      v-if="
        this.$props.required && this.$props.state && this.$props.state != null
      "
    >
      <div class="d-flex align-items-center h-100">
        <img src="@/assets/images/check.svg" class="img-custom" />
      </div>
    </div>
    <div
      class="pl-2"
      v-if="
        this.$props.required && !this.$props.state && this.$props.state != null
      "
    >
      <div class="d-flex align-items-center h-100">
        <img
          src="../../assets/images/MDI-alert-circle-outline.svg"
          class="img-alert"
        />
      </div>
    </div>
  </b-container>
</template>

<script>
export default {
  props: ["dataValue", "state", "datePickerId", "required"],
  async mounted() {
    this.value = this.$props.dataValue;
  },
  watch: {
    value(value) {
      if (value) {
        this.$emit("datePickerChangedValue", value);
      }
    },
  },
  data() {
    return {
      value: null,
      cacheValue: null,
    };
  },
  methods: {
    onContext(ctx) {
      if (this.updateValue && !this.validateField(ctx.selectedYMD)) {
        this.$emit("datePickerChangedValue", null);
      }

      if (this.cacheValue != null) {
        this.updateValue = this.cacheValue != ctx.selectedYMD;
      }

      this.cacheValue = ctx.selectedYMD;
    },
    validateField(value) {
      return !!value;
    },
    removeValue() {
      this.value = null;
      this.updateValue = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.visible {
  visibility: visible;
}
.invisible {
  visibility: hidden;
}

.img-custom {
  width: 13px;
  height: 13px;
}

.img-alert {
  width: 15px;
  height: 15px;
}

.date-picker-custom {
  max-width: 100%;
  display: grid;
  border-style: none;
  grid-column: 0;
}

.img-invisible {
  grid-template-columns: auto 140px 25px;
}

.img-visible {
  grid-template-columns: auto 140px 25px 25px;
}

.custom-icon-remove {
  margin-right: 0.8rem;
  margin-left: 0.2rem;
  width: 1.8rem;
  height: 1.8rem;
  cursor: pointer;
}
</style>
<style lang="scss">
.b-form-datepicker {
  max-height: 3rem;
  label.form-control-lg {
    line-height: 21px;
  }
}
</style>
