const FaqList = [
  {
    header: "Onde consulto meus dados cadastrais?",
    content:
      "<div>Acessar o Portal do cooperado -> Selecionar o menu Cadastro na parte superior da tela -> Selecionar a opção Informações Pessoais.</div>" +
      "<div>Caso identifique dados divergentes, poderá entrar em contato com o Relacionamento com o cooperado para solicitar atualização.</div>",
    text:
      "Acessar o Portal do cooperado -> Selecionar o menu Cadastro na parte superior da tela -> Selecionar a opção Informações Pessoais." +
      "Caso identifique dados divergentes, poderá entrar em contato com o Relacionamento com o cooperado para solicitar atualização.",
  },
  {
    header: "Como incluo uma nova especialidade?",
    content:
      "Enviar através de <a href=mailto:atendimentocooperado@unimedflorianopolis.com.br  target=blank rel=noopener noreferrer>e-mail</a> " +
      "o RQE - Registro de Qualificação da Especialidade para área de atuação e Título de Especialista, Certificado de Residência ou RQE para especialidade.",
    text:
      "Enviar através de e-mail o RQE - Registro de Qualificação da Especialidade para área de atuação e Título de Especialista, Certificado de Residência ou RQE para especialidade.",
  },
  {
    header:
      "Quero incluir um novo local de atendimento, quais informações preciso?",
    content:
      "<div>Para inclusão de um novo local de atendimento, basta preencher o " +
      "<a href=https://www.cognitoforms.com/UGF2/atualiza%C3%A7%C3%A3odelocaisdeatendimento target=blank  rel=noopener noreferrer>formulário</a>.</div> " +
      "<div>Importante: É necessário estar devidamente cadastrado no corpo clínico do estabelecimento que poderá ser consultado através do site do " +
      "<a href=http://cnes.datasus.gov.br/ target=blank  rel=noopener noreferrer >CNES</a>.</div>",
    text:
      "Para inclusão de um novo local de atendimento, basta preencher o " +
      "formulário." +
      "Importante: É necessário estar devidamente cadastrado no corpo clínico do estabelecimento que poderá ser consultado através do site do " +
      "CNES.",
  },
  {
    header:
      "A partir de quando poderei atender em um novo local de atendimento após a solicitação?",
    content:
      "O atendimento só poderá ser iniciado, após recebimento do e-mail de confirmação que a equipe de Relacionamento com o cooperado irá enviar.",
    text:
      "O atendimento só poderá ser iniciado, após recebimento do e-mail de confirmação que a equipe de Relacionamento com o cooperado irá enviar.",
  },
  {
    header:
      "Como alterar a forma que eu recebo para a PJ - Pessoa Jurídica que sou sócio, e a partir de quando eu passarei a receber por esse novo local?",
    content:
      "<div>Preencher o <a href=https://www.cognitoforms.com/UGF2/atualiza%C3%A7%C3%A3odedirecionamentodepagamento target=blank rel=noopener noreferrer>formulário</a> " +
      "para alterar a sua forma de recebimento.</div>" +
      "<div>O pagamento ocorrerá pela PJ - Pessoa Jurídica somente após o cadastro no sistema, aguarde que a equipe de Relacionamento com o Cooperado enviará um e-mail com a confirmação da data que esse cadastro foi realizado.</div>",
    text: 
    "Preencher o formulário " +
      "para alterar a sua forma de recebimento." +
      "O pagamento ocorrerá pela PJ - Pessoa Jurídica somente após o cadastro no sistema, aguarde que a equipe de Relacionamento com o Cooperado enviará um e-mail com a confirmação da data que esse cadastro foi realizado.",
  },
  {
    header:
      "O que é e o que posso realizar dentro de um TPF - Terminal de pessoa Física?",
    content:
      "O TPF é um consultório isolado, que não se enquadra em prestador cooperado e nem credenciado da UGF. Neste local só poderão ser realizadas consultas eletivas, e procedimentos com porte anestésico 0 (zero), desde que não tenham custo operacional e/ou insumos envolvidos.",
    text:
      "O TPF é um consultório isolado, que não se enquadra em prestador cooperado e nem credenciado da UGF. Neste local só poderão ser realizadas consultas eletivas, e procedimentos com porte anestésico 0 (zero), desde que não tenham custo operacional e/ou insumos envolvidos.",
  },
  {
    header: "Como altero minha senha do Portal?",
    content:
      "Acessar o Portal do cooperado -> Selecionar no seu nome no canto superior direito da tela -> Selecionar a opção Alterar Senha -> Na tela Alterar Senha, informar a senha atual e a nova senha -> Clicar no botão Salvar.",
    text:
      "Acessar o Portal do cooperado -> Selecionar no seu nome no canto superior direito da tela -> Selecionar a opção Alterar Senha -> Na tela Alterar Senha, informar a senha atual e a nova senha -> Clicar no botão Salvar.",
  },
  {
    header: "O que é SGUCARD?",
    content:
      "O <a href=https://rda.unimedsc.com.br/cmagnet/Login.do target=blank rel=noopener noreferrer>SGUCARD</a> é o sistema de autorização da UGF.",
    text:
      "O SGUCARD é o sistema de autorização da UGF.",
  },
  {
    header: "Como recuperar a senha do SGUCARD?",
    content:
      "Acessar o sistema <a href=https://rda.unimedsc.com.br/cmagnet/Login.do target=blank rel=noopener noreferrer>SGUCARD</a> " +
      "-> Clicar na imagem da chave presente no rodapé da página -> Informar o usuário -> Acessar a nova senha no e - mail que consta no cadastro do usuário.",
    text:
      "Acessar o sistema SGUCARD " +
      "-> Clicar na imagem da chave presente no rodapé da página -> Informar o usuário -> Acessar a nova senha no e - mail que consta no cadastro do usuário.",
  },
  {
    header: "Quais as regras para solicitação de benemérito?",
    content:
      "Para se tornar benemérito o(a) médico(a) deverá ter 15 anos de cooperativa, 60 de idade para homens e 65 para mulheres, conforme consta no Art. 91 do Regimento Interno.",
    text:
      "Para se tornar benemérito o(a) médico(a) deverá ter 15 anos de cooperativa, 60 de idade para homens e 65 para mulheres, conforme consta no Art. 91 do Regimento Interno.",
  },
  {
    header: "Em quais situações posso solicitar meu auxílio temporário?",
    content:
      "<div>O auxílio temporário poderá ser solicitado junto com os casos de afastamento por problemas de saúde e licença maternidade.</div>" +
      "<div>Para ter as orientações na integra, acesse o Regimento Interno através do Portal do Cooperado –> Selecionar o menu Home -> Selecionar a opção Documentos UGF -> Informar usuário e senha no Portal do Cooperado antigo -> Opção Documentos Unimed.</div>",
    text:
      "O auxílio temporário poderá ser solicitado junto com os casos de afastamento por problemas de saúde e licença maternidade." +
      "Para ter as orientações na integra, acesse o Regimento Interno através do Portal do Cooperado –> Selecionar o menu Home -> Selecionar a opção Documentos UGF -> Informar usuário e senha no Portal do Cooperado antigo -> Opção Documentos Unimed.",
  },
  {
    header: "Em quais situações posso solicitar meu afastamento?",
    content:
      "Poderá solicitar afastamento nas seguintes situações:" +
      "<ul><li>(vi) realização de cursos de aperfeiçoamento ou especialização profissional no exterior;</li>" +
      "<li>(vii) acidente ou doença, que impossibilite, temporariamente, o exercício da profissão médica;</li>" +
      "<li>(viii) licença maternidade;</li>" +
      "<li>(ix) exercício de cargos ou funções públicas, bem como cargos políticos no Sistema Unimed, que exijam dedicação exclusiva.</li></ul>" +
      "Acessar o Portal do Cooperado –> Selecionar o menu Home -> Selecionar a opção Documentos UGF -> Informar usuário e senha no Portal do Cooperado antigo -> Opção Documentos Unimed.",
    text:
      "Poderá solicitar afastamento nas seguintes situações:" +
      "(vi) realização de cursos de aperfeiçoamento ou especialização profissional no exterior;" +
      "(vii) acidente ou doença, que impossibilite, temporariamente, o exercício da profissão médica;" +
      "(viii) licença maternidade;" +
      "(ix) exercício de cargos ou funções públicas, bem como cargos políticos no Sistema Unimed, que exijam dedicação exclusiva." +
      "Acessar o Portal do Cooperado –> Selecionar o menu Home -> Selecionar a opção Documentos UGF -> Informar usuário e senha no Portal do Cooperado antigo -> Opção Documentos Unimed.",
  },
  {
    header: "Como solicitar/informar meu desligamento?",
    content:
      "O pedido de demissão poderá ser feito formalmente através do <a href=mailto:atendimentocooperado@unimedflorianopolis.com.br target=blank rel=noopener noreferrer>e-mail</a> ou Whatsapp <a>48 99119-1130</a>.",
     text:
      "O pedido de demissão poderá ser feito formalmente através do 48 99119-1130.",
  },
  {
    header:
      "Como tenho acesso ao meu Informe de rendimentos, Extrato de cota capital, Fundo de Sustentabilidade e/ou declaração de médico cooperado?",
    content:
      "<div>Acessar o Portal do cooperado -> Selecionar o menu Documentos na parte superior da tela.</div>" +
      "<div>Importante: Caso o(a) cooperado(a) receba a sua produção através de uma PJ - Pessoa Jurídica, o informe de rendimentos deverá ser solicitado diretamente para a PJ.</div>",
     text:
      "Acessar o Portal do cooperado -> Selecionar o menu Documentos na parte superior da tela." +
      "Importante: Caso o(a) cooperado(a) receba a sua produção através de uma PJ - Pessoa Jurídica, o informe de rendimentos deverá ser solicitado diretamente para a PJ.",
  },
  {
    header: "Como tenho acesso ao meu Demonstrativo de valores pagos?",
    content:
      "<div>Acessar o Portal do Cooperado –> Selecionar o menu Home -> Selecionar a opção Portal Antigo -> Informar usuário e senha no Portal do Cooperado antigo -> Clicar no menu Financeiro -> Selecionar submenu Demonstrativo de valores pagos.</div>" +
      "<div>Importante: Caso o(a) cooperado(a) receba a sua produção através de uma PJ - Pessoa Jurídica, o informe de rendimentos deverá ser solicitado diretamente para a PJ.</div>",
    text:
      "Acessar o Portal do Cooperado –> Selecionar o menu Home -> Selecionar a opção Portal Antigo -> Informar usuário e senha no Portal do Cooperado antigo -> Clicar no menu Financeiro -> Selecionar submenu Demonstrativo de valores pagos." +
      "Importante: Caso o(a) cooperado(a) receba a sua produção através de uma PJ - Pessoa Jurídica, o informe de rendimentos deverá ser solicitado diretamente para a PJ.",
  },
  {
    header:
      "Quais os principais impostos que devo pagar como médico(a) PF - Pessoa Física?",
    content:
      "<ul><li>INSS (20% do valor bruto da produção ou valor proporcional, caso não tenha contribuído com o teto máximo em outra fonte);</li>" +
      "<li>ISS - Imposto sob serviço de Florianópolis (2% do valor bruto da produção, caso não tenha pago e apresentado o comprovante de pagamento da cota anual);</li>" +
      "<li>IRRF - Imposto de renda, conforme tabela estabelecido pela Receita Federal.</li></ul>",
    text:
      "INSS (20% do valor bruto da produção ou valor proporcional, caso não tenha contribuído com o teto máximo em outra fonte);" +
      "ISS - Imposto sob serviço de Florianópolis (2% do valor bruto da produção, caso não tenha pago e apresentado o comprovante de pagamento da cota anual);" +
      "IRRF - Imposto de renda, conforme tabela estabelecido pela Receita Federal.",
  },
  {
    header: "Como faço para acessar a 2ª via boleto do meu plano de saúde?",
    content:
      "Acessar o Portal do Cooperado –> Selecionar o menu Home -> Selecionar a opção Portal Antigo -> " +
      "Informar usuário e senha no Portal do Cooperado antigo -> Clicar no menu Plano de saúde -> Selecionar o submenu 2ª via de Boleto ou pelo " +
      "<a href=https://cliente.unimedflorianopolis.com.br target=blank rel=noopener noreferrer>Portal do Cliente</a> " +
      "ou através APP do cliente.",
    text:  
      "Acessar o Portal do Cooperado –> Selecionar o menu Home -> Selecionar a opção Portal Antigo -> " +
      "Informar usuário e senha no Portal do Cooperado antigo -> Clicar no menu Plano de saúde -> Selecionar o submenu 2ª via de Boleto ou pelo " +
      "Portal do Cliente " +
      "ou através APP do cliente.",
  },
  {
    header:
      "Não identifiquei na minha analítica o pagamento de uma consulta ou procedimento realizado, o que faço?",
    content:
      "<div>Acessar o Portal do Cooperado –> Selecionar o menu Home -> Selecionar a opção Portal Antigo -> Informar usuário e senha no Portal do Cooperado antigo -> clicar em Institucional -> Documentos -> Pasta produção médica -> Preencher a planilha de recurso de glosa / Revisão de pagamento.</div>" +
      "<div>Importante: o prazo para recursar glosa e pagamento é 45 dias a partir da divulgação da analítica.</div>",
    text:
      "Acessar o Portal do Cooperado –> Selecionar o menu Home -> Selecionar a opção Portal Antigo -> Informar usuário e senha no Portal do Cooperado antigo -> clicar em Institucional -> Documentos -> Pasta produção médica -> Preencher a planilha de recurso de glosa / Revisão de pagamento." +
      "Importante: o prazo para recursar glosa e pagamento é 45 dias a partir da divulgação da analítica.",
  },
  {
    header: "Como faço para colocar meu plano de saúde em débito em conta?",
    content:
      "Preencher o <a href=https://docs.google.com/forms/d/e/1FAIpQLSfVWM7l3D0QaPU_RO_OXizewqX7LUmBA_4yw4VZJ_j78ko7rg/viewform target=blank rel=noopener noreferrer>formulário</a> " +
      "de solicitação ou acessando o " +
      "<a href=https://cliente.unimedflorianopolis.com.br target=blank rel=noopener noreferrer>Portal do Cliente</a> " +
      "e APP.",
    text:
      "Preencher o formulário " +
      "de solicitação ou acessando o " +
      "Portal do Cliente " +
      "e APP.",
  },
  {
    header:
      "Como faço para aderir ao PLAC - Plano de Saúde do Médico Cooperado,",
    content:
      "<div>Deve ser enviado um " +
      "<a href=mailto:atendimentocooperado@unimedflorianopolis.com.br target=blank rel=noopener noreferrer>e-mail</a> " +
      "com as seguintes informações: </div>" +
      "<div>Se já possuir plano de saúde, informar se o plano é de pessoa física ou através de alguma instituição. </div>" +
      "<div>Informar se é plano de saúde da Unimed Grande Florianópolis. </div>",
    text:
      "Deve ser enviado um " +
      "e-mail " +
      "com as seguintes informações: " +
      "Se já possuir plano de saúde, informar se o plano é de pessoa física ou através de alguma instituição. " +
      "Informar se é plano de saúde da Unimed Grande Florianópolis. ",
  },
  {
    header:
      "Como faço para incluir ou excluir dependentes ao PLAC - Plano de Saúde do Médico Cooperado?",
    content:
      "Enviar " +
      "<a href=mailto:atendimentocooperado@unimedflorianopolis.com.br target=blank rel=noopener noreferrer>e-mail</a> " +
      "informando os dados pessoais do titular do plano, o nome e o motivo dos usuários que precisam ser incluídos e / ou excluídos.",
    text:
      "Enviar " +
      "e-mail " +
      "informando os dados pessoais do titular do plano, o nome e o motivo dos usuários que precisam ser incluídos e / ou excluídos.",
  },
  {
    header:
      "Como pedir autorização de procedimento/exame para mim ou meu dependente?",
    content:
      "Enviar o pedido médico, o número da carteirinha e os exames complementares (se houver) através do Portal do Cooperado –> Selecionar o menu Home -> Selecionar a opção Portal Antigo -> Informar usuário e senha no Portal do Cooperado antigo -> Clicar no menu Plano de Saúde -> Selecionar o submenu Pedido de Autorização.",
    text:
      "Enviar o pedido médico, o número da carteirinha e os exames complementares (se houver) através do Portal do Cooperado –> Selecionar o menu Home -> Selecionar a opção Portal Antigo -> Informar usuário e senha no Portal do Cooperado antigo -> Clicar no menu Plano de Saúde -> Selecionar o submenu Pedido de Autorização.",
  },
  {
    header:
      "O que preciso para solicitar reembolso de um exame/procedimento que paguei particular?",
    content:
      "Enviar a nota fiscal, pedido médico para avaliação através do Portal do Cooperado –> Selecionar o menu Home -> Selecionar a opção Portal Antigo -> Informar usuário e senha no Portal do Cooperado antigo -> Clicar no menu Plano de Saúde -> Selecionar o submenu Solicitação de Reembolso.",
     text:
      "Enviar a nota fiscal, pedido médico para avaliação através do Portal do Cooperado –> Selecionar o menu Home -> Selecionar a opção Portal Antigo -> Informar usuário e senha no Portal do Cooperado antigo -> Clicar no menu Plano de Saúde -> Selecionar o submenu Solicitação de Reembolso.",
  },
  {
    header: "Como faço para acessar o Regimento Interno e o Estatuto Social?",
    content:
      "Acessar o Portal do Cooperado –> Selecionar o menu Home -> Selecionar a opção Portal Antigo -> Informar usuário e senha no Portal do Cooperado antigo -> Selecionar a opção Documentos Unimed -> Acessar a pasta Estatuto e Regimento Interno.",
    text:
      "Acessar o Portal do Cooperado –> Selecionar o menu Home -> Selecionar a opção Portal Antigo -> Informar usuário e senha no Portal do Cooperado antigo -> Selecionar a opção Documentos Unimed -> Acessar a pasta Estatuto e Regimento Interno.",
  },
];

export default FaqList;
