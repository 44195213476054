<template>
  <div>
    <FormLayout
      :tabs="this.tabs"
      :cardInfo="this.cardInfo"
      :leftAction="this.leftAction"
      :leftLinkNavigation="this.leftLinkNavigation"
    ></FormLayout>
  </div>
</template>

<script>
import Vue from "vue";
import FormLayout from "../../../layouts/FormLayout.vue";

import RoutesPaths from "../../../router/router-structure/routes-paths";
import { ServiceType } from "../../../static-data/ServiceType";

Vue.component("DeclarationInformations", () =>
  import("../components/visualization/DeclarationInformations.vue")
);

export default {
  components: {
    FormLayout,
  },
  data: function() {
    return {
      leftAction: {
        text: "Declarações",
        onClick: this.leftActionClickHandler,
      },
      leftLinkNavigation: this.leftHistoryNavigation,
      cardInfo: {
        headerIcon: "far fa-address-card",
        icon: "file-document-outline.svg",
        title: "Declaração de histórico de retenção de INSS",
        description: "Solicite declaração de contribuição pela UGF",
      },
      tabs: [
        {
          title: "",
          formComponent: "DeclarationInformations",
          serviceType: ServiceType.Declaration,
        },
      ],
    };
  },
  methods: {
    leftActionClickHandler() {
      this.$router.push({
        path: RoutesPaths.declarations.rootName(),
        replace: true,
      });
    },
    leftHistoryNavigation() {
      this.$router.push({
        path: RoutesPaths.requestHistory.rootName(),
        params: {
          serviceType: ServiceType.Declaration,
        },
      });
    },
  },
};
</script>

<style lang="scss"></style>
