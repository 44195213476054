import { getGeneralInformation } from "../../../services/general-information/general-information";
import { AdminUserInformation } from "../../../static-data/AdminUserInformation";
import initializeJsonFromLocalStorage, { initializeFromLocalStorage } from "../../../utils/storage/initialize-storage-utils";

const userInformation = {
  state: {
    userInformation: initializeJsonFromLocalStorage("user-information"),
    imageProfile:  initializeFromLocalStorage("image-profile"),
  },
  mutations: {
    setUserInformation(state, user) {
      state.userInformation = user;
    },
    setImageProfile(state, link) {
      state.imageProfile = link;
    },
    clear(state) {
      state.userInformation = "";
      state.imageProfile = "";
    },
  },
  actions: {
    requestUserInformation({ commit }, user) {
      return new Promise((resolve, reject) => {
        getGeneralInformation(user)
          .then((userInformation) => {
            userInformation.nickname = userInformation.name
              ? userInformation.name.split(" ", 1)[0]
              : "Cooperado";
            localStorage.setItem(
              "user-information",
              JSON.stringify(userInformation)
            );
            commit("setUserInformation", userInformation);
            resolve(userInformation);
          })
          .catch((err) => {
            console.error("requestLogin error: ", err);
            reject(err);
          });
      });
    },
    setAdminUser({ commit }, userName) {
      localStorage.setItem(
        "user-information",
        JSON.stringify({ ...AdminUserInformation, crm: userName })
      );
      commit("setUserInformation", AdminUserInformation);
    },
    setImageProfile({ commit }, link) {
      localStorage.setItem(
        "image-profile",
        link
      );
      commit("setImageProfile", link);
    },
    clearUserInformation({ commit }) {
      localStorage.setItem("user-information", "");
      localStorage.setItem("image-profile", "");
      commit("clear");
    },
  },
  getters: {
    getUserInformation: (state) => state.userInformation,
    getImageProfile: (state) => state.imageProfile,
  },
};

export default userInformation;
