import { GENESIS_URL } from "../urls/base-urls";
import axios from "../http-client/axios";
import {
  getFileRef,
  getFileBlob
} from "../../services/training/trainingUploadFileService";

export async function getStatusTrainingRunning() {
  const resp = await axios.get(`${GENESIS_URL}/status-training-running`);
  return resp.data;
}

export async function getStatusTrainingFinished() {
  const resp = await axios.get(`${GENESIS_URL}/status-training-finished`);
  return resp.data;
}

export async function updateStatusTraining(idStep, idTraining, status, name) {
  const resp = await axios.post(`${GENESIS_URL}/update-status-training`, 
    {
      idTraining,
      idStep,
      status, 
      name,
    });
  return resp.data;
}

export async function getDocumentFirabase(file) {

  const fileRef = getFileRef(`training/${file.trainingName}/${file.fileName}`);
  return getFileBlob(fileRef);
}

export async function getDocument(path, fileName) {

  let formDataInstance = new FormData();

  formDataInstance.append("path", path);
  formDataInstance.append("fileName", fileName);

  const resp = await axios({
    url: `${GENESIS_URL}/document-training`,
    // url: `http://localhost:8303/document-training`,
    method: "post",
    data: formDataInstance,
    headers: { "content-type": "application/json" },
  });

  return resp?.data;
}

export async function documentPDFUpload(file, trainingName) {
  let formDataInstance = new FormData();

  formDataInstance.append("file", file);
  formDataInstance.append("trainingName", trainingName);

  const resp = await axios({
    url: `${GENESIS_URL}/admin/document-upload`,
    // url: `http://localhost:8303/admin/document-upload`,
    method: "post",
    data: formDataInstance,
    headers: { "content-type": "application/json" },
  });

  return resp?.data;
}
