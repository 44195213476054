import initializeJsonFromLocalStorage from "../../../utils/storage/initialize-storage-utils";

const userInteractionInactivity = {
  state: {
    userInteractionInactivity: initializeJsonFromLocalStorage("user-interaction-inactivity"),
  },
  mutations: {
    setUserInteractionInactivity(state, data) {
      state.userInteractionInactivity = data;
    },
    clear(state) {
      state.userInteractionInactivity = null;
    },
  },
  actions: {
    setStoreUserInteractionInactivity({ commit }, data) {
      localStorage.setItem("user-interaction-inactivity", data);
      commit("setUserInteractionInactivity", data);
    },
    clearUserInteractionInactivity({ commit }) {
      return new Promise((resolve, reject) => {
        try {
          localStorage.setItem("user-interaction-inactivity", null);
          commit("clear");
          resolve();
        } catch (err) {
          reject(err);
        }
      });
    },
  },
  getters: {
    getUserInteractionInactivity: (state) => state.userInteractionInactivity,
  },
};

export default userInteractionInactivity;
