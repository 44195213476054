<template>
  <div class="page-container">
    <div>
      <section class="page-header">
        <h2 class="font-weight-bold">
          FAQ - Perguntas frequentes
        </h2>
      </section>

      <div class="separator-line-total"></div>

      <div class="my-5">
        <b-form-input
          debounce="2000"
          v-model.lazy.trim="keyWord"
          class="input-key-word magnify"
          placeholder="Busque por palavras-chave. Ex: “senha”"
          type="search"
        />
      </div>
      <FaqList
        v-for="(faq, index) in list"
        :key="index"
        :index="'collaspse1' + index"
        :header="faq.header"
        :content="faq.content"
      />
    </div>
  </div>
</template>

<script>
import { inject } from "@vue/composition-api";
import FaqList from "../components/FaqList.vue";
import { getFaqList } from "../../../services/faq/faq-service.js";
export default {
  components: {
    FaqList,
  },
  name: "Faq",
  setup() {
    const globalLoading = inject("globalLoading");
    return { globalLoading };
  },
  data() {
    return {
      list: [],
      keyWord: "",
    };
  },
  watch: {
    keyWord: function() {
      if (!this.keyWord) {
        this.getFaqList();
      } else {
        this.list = this.list.filter(this.checkKeyWord);
      }
    },
  },
  mounted() {
    this.getFaqList();
  },
  methods: {
    checkKeyWord({ header, text }) {
      return (
        header.toUpperCase().indexOf(this.keyWord.toUpperCase()) != -1 ||
        text.toUpperCase().indexOf(this.keyWord.toUpperCase()) != -1
      );
    },
    getFaqList() {
      this.list = getFaqList();
    },
  },
};
</script>

<style scoped lang="scss">
.page-container {
  display: flex;
  flex-direction: column;
  width: 70%;
  margin: 0 auto;
}

.page-header {
  text-align: left;
  display: flex;
}

.tab-sub-header {
  align-items: center;
  justify-content: left;
  vertical-align: middle;
}

div.span {
  width: 10rem;
}

.input-key-word {
  width: 42.5rem;
  height: 3.8rem;
  text-align: left;
  font-size: 1.6rem;
}
div.separator-line-total {
  width: 100%;
}
.magnify {
  padding-left: 3rem;
  background: url("../../../assets/images/magnify.svg") white no-repeat 1% 50%;
  border-radius: 0.4rem;
}
</style>
