import axios from "../http-client/axios";
import { AUTH_URL } from "../urls/base-urls";
import {
  APP_AUTORIZATION_USER,
  APP_AUTORIZATION,
} from "../urls/enviroment-variables";

export async function requestLogin(user, token) {
  const config = {
    auth: {
      username: APP_AUTORIZATION_USER,
      password: APP_AUTORIZATION,
    },
    headers: {
      "token-captcha": token
    }
  };

  const encodedPass = encodeURIComponent(user?.password)
  
  const resp = await axios.post(
    `${AUTH_URL}/oauth/token?grant_type=password&username=${user?.login}&password=${encodedPass}`,
    {},
    config
  );
  return resp.data;
}
export async function requestLoginInspect(crm) {
  
 const resp = await axios({
    url: `${AUTH_URL}/login/inspecionation?crm=${crm}`,
    method: "post",
    data: {},
  });
  return resp.data;
}
