<template>
  <div>
    <div ref="pdfContainer" v-show="!this.msgError"></div>
    <div class="invalid" v-show="this.msgError">
      <b-container>
        <b-row class="justify-content-center">
          <div class="attention-container">
            <div class="attention-container--outter-circle">
              <div class="attention-container--inner-circle">
                <i class="attention-container--icon fas fa-exclamation"></i>
              </div>
            </div>
          </div>
        </b-row>
        <b-row class="justify-content-center mt-5">
          <h5>
            {{ this.msgError }}
          </h5>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
import { inject } from "@vue/composition-api";
const pdfjsLib = require("pdfjs-dist/build/pdf");

pdfjsLib.GlobalWorkerOptions.workerSrc =
  "https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.10.377/pdf.worker.min.js";

export default {
  setup() {
    const globalLoading = inject("globalLoading");

    return { globalLoading };
  },
  props: ["getDocumentHandler", "landscape"],
  data() {
    return {
      pdfViewer: null,
      currentPage: 1,
      numPages: 0,
      pdf: null,
      msgError: null,
    };
  },
  mounted() {
    this.setDocument();
  },
  methods: {
    async setDocument() {
      try {
        this.globalLoading = true;
        const getDocumentHandler = this.$props.getDocumentHandler;

        if (!getDocumentHandler)
          throw Error("Invalid getDocumentHandler!", getDocumentHandler);

        const pdfData = await getDocumentHandler();

        const loadingPdfTask = pdfjsLib.getDocument({ data: pdfData });

        await loadingPdfTask.promise.then(async (pdf) => {
          this.pdf = pdf;

          this.numPages = this.pdf.numPages;
          if (this.numPages <= 0) return;

          await this.pdf.getPage(1).then(this.handlePages);
        });

        this.globalLoading = false;
      } catch (error) {
        console.error(error);
        this.globalLoading = false;
        this.msgError = "Arquivo Indisponível!";
        document.getElementById("submitButtonText").hidden = true;
      }
    },
    handlePages(page) {
      const scale = this.$props.landscape ? 2 : 1;
      const viewport = page.getViewport({ scale: scale });

      let canvas = document.createElement("canvas");
      canvas.setAttribute("class", "pdf-page");

      var context = canvas.getContext("2d");
      canvas.height = viewport.height;
      canvas.width = viewport.width;

      page.render({ canvasContext: context, viewport: viewport });

      let pdfContainer = this.$refs.pdfContainer;
      pdfContainer.appendChild(canvas);

      this.currentPage++;
      if (this.pdf !== null && this.currentPage <= this.numPages) {
        this.pdf.getPage(this.currentPage).then(this.handlePages);
      }
    },
  },
};
</script>

<style lang="scss">
.pdf-page {
  display: block;
  width: 100%;
  margin-bottom: 1rem;
}

.attention-container {
  &--icon {
    font-size: 4rem;
    color: white;
  }

  &--inner-circle {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 7rem;
    width: 7rem;
    background: var(--yellow-1);
    border-radius: 100%;
  }

  &--outter-circle {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 9rem;
    width: 9rem;
    background: var(--yellow-0);
    border-radius: 100%;
  }
}
</style>
