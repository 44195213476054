<template>
  <Component
    :is="is"
    :href="localHref"
    :to="to"
    class="dropdown-itens px-4 py-2 "
    @click="onClick"
  >
    <slot />
  </Component>
</template>

<script>
export default {
  name: "DropdownItem",

  inject: ["dropdown"],

  props: {
    href: {
      type: String,
      default: undefined,
    },

    to: {
      type: [String, Object],
      default: undefined,
    },
  },

  data() {
    return {};
  },

  computed: {
    localHref() {
      if (typeof this.to !== "undefined") {
        return undefined;
      }

      return this.href;
    },

    is() {
      if (typeof this.to !== "undefined") {
        return "RouterLink";
      }

      if (typeof this.href !== "undefined") {
        return "a";
      }

      return "button";
    },
  },

  methods: {
    onClick(evt) {
      if (evt) {
        this.$emit("click", evt);
        this.closeDropdown();
      }
    },

    closeDropdown() {
      this.dropdown.close();
    },
  },
};
</script>
