import axios from "axios";
import axiosIntance from "../http-client/axios";
import { KREATOR_URL } from "../urls/base-urls";
const localAxiosIntance = axios.create({
  baseURL: "",
});
export async function changePasswordService(formData) {
  const resp = await axiosIntance.post(
    `${KREATOR_URL}/change-password-authenticated`,
    formData
  );
  return resp.data;
}

export async function passwordRecoveryService(crm, id) {
  const resp = await localAxiosIntance.post(
    `${KREATOR_URL}/${crm}/auth-code/${id}`
  );
  return resp.data;
}

export async function passwordRecoveryEmail(crm) {
  const resp = await localAxiosIntance.get(
    `${KREATOR_URL}/${crm}/emails`
  );
  return resp.data;
}

export async function passwordRecoveryChangeService(formData) {
  const resp = await localAxiosIntance.post(
    `${KREATOR_URL}/change-password`,
    formData
  );
  return resp.data;
}
