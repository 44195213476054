<template>
  <div class="app-content">
    <router-view></router-view>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.app-content {
  height: 89vh;
  width: 100vw;
  padding: 2rem;
  background-image: url("../../assets/images/ilustracao_completa.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: percentage($number: 0.6);
  margin-top: 5rem;

  @include media-breakpoint-down(xs) {
    padding: 1.4rem;
  }

  @include media-breakpoint-down(sm) {
    padding: 1.4rem;
  }
}
</style>
