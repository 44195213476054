<template>
  <div
    class="align-step-center"
    v-if="$props.item && $props.item.type == 'CERTIFICATE'"
  >
    <div id="div-certificate">
      <p class="T15 certificate-text">
        Seu certificado ainda não está disponível! Aguarde, em breve
        disponibilizaremos para você.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: ["onFinishedStep", "onStartStep", "item"],
  components: {},
  watch: {
    item: {
      async handler(val) {
        if (val?.type == "CERTIFICATE") {
          await this.$props.onStartStep(val);
          this.$props.onFinishedStep(val);
        }
      },
      deep: true,
    },
  },
  data() {
    return {};
  },
  methods: {
    onStepDownloadCertificateClick(step) {
      this.stepSelected = step;
      this.$props.onFinishedStep(step);
    },
  },
};
</script>
<style lang="scss" scoped>
.certificate-text {
  color: #23272b;
  font-size: 16px;
}
.align-step-center {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
