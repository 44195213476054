<template>
  <div>
    <TableVisualizationLayout :leftInfo="leftInfo" :leftAction="leftAction">
      <template slot="headerContent">
        <div class="my-2 ml-auto">
          <b-select
            v-model="serviceType"
            :options="serviceTypesOptions"
            size="lg"
          />
        </div>
      </template>
      <template slot="titleLoading">
        <div class="align-refresh-history">
          <img
            v-show="loadingDataUpdate"
            v-if="loadingDataUpdate"
            class="img-reflesh-history img-reflesh-history--loadind"
            src="@/assets/images/refresh.svg"
          />
          <img
            v-show="!loadingDataUpdate"
            v-if="!loadingDataUpdate"
            class="img-reflesh-history"
            src="@/assets/images/refresh.svg"
          />
          <p v-text="onDataUpdate()" class="data-feedback-update"></p>
        </div>
      </template>
      <template slot="table">
        <b-table
          class="table-custom spaced-text"
          :fields="fields"
          :items="items"
          v-show="!this.loading"
          responsive
          show-empty
          empty-text="Nenhuma informação para exibir!"
        >
          <template v-slot:head(area)="">
            <div class="custom-area">Área</div>
          </template>

          <template v-slot:head(integrationJMJStatus)="">
            <div class="d-flex justify-content-center">Status</div>
          </template>

          <template v-slot:head(details)="">
            <div class="d-flex justify-content-center">Detalhes</div>
          </template>

          <template #cell(area)="data">
            <div class="custom-row-table d-flex align-items-center">
              <div class="custom-area">{{ data.item.area }}</div>
            </div>
          </template>

          <template #cell(requestedItem)="data">
            <div class="custom-row-table d-flex align-items-center">
              <div>{{ data.item.requestedItem }}</div>
            </div>
          </template>

          <template #cell(typeRequested)="data">
            <div class="custom-row-table d-flex align-items-center">
              <div>{{ data.item.typeRequested }}</div>
            </div>
          </template>

          <template #cell(createdAt)="data">
            <div class="custom-row-table d-flex align-items-center">
              <p
                data-toggle="tooltip"
                data-placement="top"
                :title="dateToTime(data.item.createdAt)"
                class="p-custon"
              >
                {{ dateToLocaleDate(data.item.createdAt) }}
              </p>
            </div>
          </template>
          <template #cell(integrationJMJStatus)="data">
            <div class="custom-row-table d-flex justify-content-center align-items-center">
              <img
                :src="getIconByStatus(data.item).src"
                data-toggle="tooltip"
                data-placement="top"
                :title="getIconByStatus(data.item).title"
              />
            </div>
          </template>
          <template #cell(details)="data">
            <div class="custom-row-table d-flex justify-content-center align-items-center">
              <div 
                v-b-modal:request-history-modal
                @click="() => onClickViewRequest(data)" 
                class="custom-row-table d-flex justify-content-center align-items-center"
              >
                <i class="text-primary table-icon-size fas fa-list"></i>
              </div>
            </div>
          </template>
        </b-table>
        <div v-show="this.loading">
          <b-skeleton-table :rows="10" :columns="6"></b-skeleton-table>
        </div>
      </template>
      <template slot="footerContent">
        <b-pagination
          v-model="page"
          :total-rows="totalSize"
          :per-page="perPage"
          aria-controls="my-table"
          size="lg"
        />
      </template>
    </TableVisualizationLayout>
    <VisualizualizationModal
      :title="
        requestTypeDescription
          ? requestTypeDescription
          : 'Visualização da solicitação'
      "
      @downloadFile="eventToDownloadFile"
      modalId="request-history-modal"
      :request="selectedViewRequestItem"
      :requestAttachments="selectedAttachments"
      attachmentsDescription="Anexos"
    />
  </div>
</template>

<script>
import TableVisualizationLayout from "../../../layouts/TableVisualizationLayout.vue";
import {
  dateToLocaleDateString,
  dateToTime,
} from "../../../utils/date/date-utils";
import { ServiceTypesOptions } from "../../../static-data/ServiceTypesOptions";
import {
  getRequestHistory,
  getIdsAttachment,
  downloadAttachment,
} from "../../../services/request-history/request-history";
import { ServiceType } from "../../../static-data/ServiceType";
import VisualizualizationModal from "../../../components/visualization-modal/VisualizationModal.vue";
import resolve_img_url from "../../../utils/image/image-utils";
import { IntegrationJMJStatus } from "../../../static-data/IntegrationJMJStatus.js";
import moment from "moment";
export default {
  components: {
    VisualizualizationModal,
    TableVisualizationLayout,
  },
  mounted() {
    const serviceType = this.$route.params.serviceType;
    this.serviceType = serviceType || ServiceType.All;
    this.getHistory(this.page, serviceType, false);
    this.updateData = this.updateData + 1;
  },
  watch: {
    page: {
      handler(newValue) {
        this.getHistory(newValue, this.serviceType, false);
        this.updateData = this.updateData + 1; 
      },
    },
    serviceType: {
      handler(newValue) {
        this.getHistory(this.page, newValue, false);
        this.updateData = this.updateData + 1;
      },
    },
    updateData: {
      async handler() {
        await this.getHistoryUpdateData(this.page, this.serviceType, true)
      }
    }
  },
  computed: {
    serviceTypesOptions: () => ServiceTypesOptions,
  },
  data() {
    return {
      updateData: 0,
      loadingDataUpdate: false,
      dataUpdate: null,
      serviceType: ServiceType.All,
      page: 1,
      perPage: 10,
      totalSize: 0,
      leftAction: {
        text: "Histórico de solicitações",
        onClick: this.leftActionClickHandler,
      },
      leftInfo:
        "Todas as suas solicitações são analisadas e geridas pela área de negócios, que encaminham os retornos e acompanhamentos para o seu e-mail.",
      fields: [
        {
          key: "area",
          label: "Área",
        },
        {
          key: "requestedItem",
          label: "Item solicitado",
        },
        {
          key: "typeRequested",
          label: "Tipo de solicitação",
        },
        {
          key: "createdAt",
          label: "Data",
        },
        {
          key: "protocol",
          label: "Protocolo",
        },
        {
          key: "integrationJMJStatus",
          label: "Status",
        },
        {
          key: "details",
          label: "Detalhes",
          tdClass: "text-center",
        },
      ],
      items: [],
      integrationJMJStatus: IntegrationJMJStatus,
      selectedViewRequestItem: null,
      requestTypeDescription: "",
      selectedAttachments: [],
      loading: true,
    };
  },
  methods: {
    getTitleError(error, statusLastUpdated) {
      if (!error) return "";
      return (
        "Última Atualização: " +
        dateToLocaleDateString(statusLastUpdated) +
        " " +
        dateToTime(statusLastUpdated) +
        "\nNão foi possível atualizar."
      );
    },
    getIconByStatus(item) {
      const error = item.status == "ERROR";
      if (!item.integrationJMJStatus) {
        const staticStatus = this.integrationJMJStatus.find(
          (i) => i.enum == "DEFAULT"
        );
        return {
          title: staticStatus.title,
          src: resolve_img_url(staticStatus.src),
        };
      }
      const staticStatus = this.integrationJMJStatus.find(
        (i) => i.enum == item.integrationJMJStatus && i.error == error
      );
      return {
        title:
          staticStatus.title +
          this.getTitleError(error, item.statusLastUpdated),
        src: resolve_img_url(staticStatus.src),
      };
    },
    async eventToDownloadFile(idAttachement, protocol) {
      try {
        this.loading = true;
        await downloadAttachment(idAttachement, protocol);
        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.error("eventToDownloadFile", error);
      }
    },
    async getHistoryUpdateData(pageNumber, serviceType, updateData) {
      try{
          this.loadingDataUpdate = true;
          await this.getHistory(pageNumber, serviceType, updateData);
          this.loadingDataUpdate = false;
        } finally {
          this.loadingDataUpdate = false;
        }
    },
    async getHistory(pageNumber, serviceType, updateData) {
      try {
        this.loading = true;

        const userDocument = this.$store.getters.getUserName;
        const { items, totalItems } = await getRequestHistory(
          userDocument,
          serviceType,
          pageNumber - 1,
          this.perPage,
          updateData
        );

        this.items = items;
        this.totalSize = totalItems;

        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.error("getInformation", error);
      }
    },
    async onClickViewRequest(data) {
      const request = data?.item?.metadata;
      this.requestTypeDescription = data?.item?.requestedItem;
      this.selectedViewRequestItem = request;
      const attachments = await getIdsAttachment(data?.item?.protocol);
      this.selectedAttachments = attachments;
    },
    leftActionClickHandler() {
      this.$router.go(-1);
    },
    onDataUpdate() {
      return `Última atualização em: ${this.onFormatDate(moment().format('DD/MM/YYYY HH:mm'))}`;
    },
    onFormatDate(value) {
      let valueSplit = value.split(" ");
      let horaSplit = valueSplit[1].split(":");
      return `${valueSplit[0]} às ${horaSplit[0]}h${horaSplit[1]}`;
    },
    dateToTime: dateToTime,
    dateToLocaleDate: dateToLocaleDateString,
  },
};
</script>
<style lang="scss">
.table-icon-size {
  font-size: 1.8rem;
}
.align-refresh-history {
  width: 100%;
  display: flex;
  justify-content: right;
  flex-direction: row;
  align-items: center;
}

.img-reflesh-history {
  height: 60%;
  padding-right: 0.2rem;
  &--loadind {
    animation: spin 1s linear infinite;
  }
}
@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.data-feedback-update {
  text-align: center;
}

.custom-area {
  margin-left: 1.5rem;
}

.p-custon {
  font-size: 1.4rem;
  color: var(--grey-2);
}

.custom-row-table {
  height: 2.5rem;
  width: 100%;
}

</style>
