import { GENESIS_URL } from "../urls/base-urls";
import axios from "../http-client/axios";

export async function getTrainings(status, title, page, size) {
  let query = `?status=${status}&page=${page}&size=${size}`;

  if(title)
    query += `&title=${title}`

  const resp = await axios.get(`${GENESIS_URL}/list-training${query}`);
  return mapperToResponse(resp.data);
}

function mapperToResponse(data) {
  data.items.forEach((element) => {
    element.msgStatus = element.statusTraining == 'REGISTER_FINISHED_AND_VISIBLE' ? 'Visível para os cooperados' : 'Não visível para os cooperados';
    element.isVisible = element.statusTraining == 'REGISTER_FINISHED_AND_VISIBLE';
  });
  return data;
}

export async function updateTrainingActivation(idTraining, ststus) {
  const resp = await axios.put(`${GENESIS_URL}/admin/status?id=${idTraining}&status=${ststus}`);
  return resp.data;
}

export async function getTrainingById(idTraining) {  
  const resp = await axios.get(`${GENESIS_URL}/training?id=${idTraining}`);
  return resp.data;
}
export async function saveTraining(training) {
  const resp = await axios.post(`${GENESIS_URL}/admin/training`, training);
  return resp.data;
}

export async function updateTraining(training) {
  const resp = await axios.put(`${GENESIS_URL}/admin/training/${training.id}`, training);
  return resp.data;
}

export async function getTrainingsReport(idTraining, search) {  

  let query = `?idTraining=${idTraining}`;

  if(search)
    query += `&search=${search}`

  const resp = await axios.get(`${GENESIS_URL}/report-training${query}`);
  return resp.data;
}

export async function getListReportTraining(idTraining, search, page, size) {
  let query = `?idTraining=${idTraining}&page=${page}&size=${size}`;

  if(search)
    query += `&search=${search}`

  const resp = await axios.get(`${GENESIS_URL}/list-report-training${query}`);
  return resp.data;
}

export async function getStepsByTrainingId(idTraining) {  
  const resp = await axios.get(`${GENESIS_URL}/admin/steps?idTraining=${idTraining}`);
  return resp.data;
}

export async function reorderSteps(steps) {  
  await axios.put(`${GENESIS_URL}/admin/step/reorder`, steps);
}

export async function deleteStep(id) {  
  await axios.delete(`${GENESIS_URL}/admin/step?id=${id}`);
}
export async function saveStep(step) {  
  await axios.post(`${GENESIS_URL}/admin/step`, step);
}
export async function updateStep(step) {  
  await axios.put(`${GENESIS_URL}/admin/step`, step);
}


export async function deleteTraining(id) {
  const resp = await axios.delete(`${GENESIS_URL}/admin/training?id=${id}`);
  return resp.data;
}

export async function cloneTraining(id) {
  const resp = await axios.post(`${GENESIS_URL}/admin/clone-training`, { idTraining: id });
  return resp.data;
}

export async function getSpecialties() {  
  const resp = await axios.get(`${GENESIS_URL}/specialties`);
  return resp.data;
}