<script>
import CustomCardInfo from "../../../../components/custom-info-card/CustomInfoCard.vue";
import VisualizationRulesTemplate from "../visualization/VisualizationRulesTemplate.vue";
import NavigationButton from "../../../../components/navigation-button/NavigationButton.vue";

export const FormLayout = {
  props: [
    "leftInfoOne",
    "leftActionOne",
    "contentOneOne",
    "leftInfoTwo",
    "leftActionTwo",
    "contentTwo",
  ],
  components: {
    VisualizationRulesTemplate,
    CustomCardInfo,
    NavigationButton,
  },

  render() {
    const invalidHandler = (e) => {
      console.warn("Invalid handler!", e);
    };

    const leftActionOne = {
      text: "Invalid Text!",
      onClick: invalidHandler,
      ...this.$props.leftActionOne,
    };

    const leftActionTwo = {
      text: "Invalid Text!",
      onClick: invalidHandler,
      ...this.$props.leftActionTwo,
    };

    const leftInfoOne = this.$props.leftInfoOne;
    const leftInfoTwo = this.$props.leftInfoTwo;
    return (
      <b-container>
        <b-container class="cards">
          <b-container class="card-one">
            <VisualizationRulesTemplate>
              <template slot="leftAction">
                <NavigationButton
                  actionClickHandler={leftActionOne.onClick}
                  underLine={false}
                >
                  <template slot="icon">
                    <i class="fas fa-arrow-left"></i>
                  </template>
                  <template slot="text">{leftActionOne.text}</template>
                </NavigationButton>
              </template>

              <template slot="leftInfo">
                <p class="p4">{leftInfoOne}</p>
              </template>

              <template slot="headerContent">
                {this.$slots.headerContent}
              </template>
              <template slot="content">{this.$slots.contentOne}</template>
              <template slot="footerContent">
                {this.$slots.footerContent}
              </template>
            </VisualizationRulesTemplate>
          </b-container>

          <b-container class="card-two">
            <VisualizationRulesTemplate>
              <template slot="leftAction">
                <h2 class="custom-text">{leftActionTwo.text}</h2>
              </template>

              <template slot="leftInfo">
                <p class="p4">{leftInfoTwo}</p>
              </template>

              <template slot="headerContent">
                {this.$slots.headerContent}
              </template>
              <template slot="content">{this.$slots.contentTwo}</template>
              <template slot="footerContent">
                {this.$slots.footerContent}
              </template>
            </VisualizationRulesTemplate>
          </b-container>
        </b-container>
      </b-container>
    );
  },
};

export default FormLayout;
</script>

<style lang="scss" scoped>
.custom-text {
  padding-left: 6%;
  margin-bottom: 2rem;
}

.card-one {
  padding-right: 0%;
}

.card-two {
  padding-left: 0%;
  height: 100%;
}

.cards {
  max-width: 100%;
  display: grid;
  border-style: none;
  grid-column: 0;
}

@media (max-width: 1199px) and (min-width: 899px) {
  .custom-text {
    padding-left: 6%;
    margin-bottom: 4.4rem;
  }
}

@media (min-width: 900px) {
  .cards {
    grid-template-columns: 60% 40%;
  }

  .card-one {
    padding-right: 5%;
  }

  .card-two {
    padding-left: 5%;
  }
}
</style>
