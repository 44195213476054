import ContentManagement from "../../modules/content-management/views/ContentManagement.vue";
import RouterViewRender from "../../utils/routes/router-view-render";

const ContentManagementRoutes = {
  path: "",
  component: RouterViewRender,
  meta: {
    requiresAuth: true,
  },
  children: [
    {
      path: "content-management",
      component: ContentManagement,
      meta: {
        requiresAuth: true,
      },
    },
  ],
};

export default ContentManagementRoutes;
