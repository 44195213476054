import axios from "../http-client/axios";
import { METALICA_URL } from "../urls/base-urls";
import AlterType from "../../static-data/TypeRequestedFlow";
import requestBuilder from "../request-builder/request-builder";
import ServiceRequestType from "../../static-data/ServiceRequestType";

export async function addOtherSolicitation(formData, attachments) {

  let paramsJmj = {
    serviceType: ServiceRequestType.GENERAL_REQUESTS,
    finishedOnSave: false
  }
  if(formData.category) paramsJmj.category = formData.category;
  if(formData.subCategory) paramsJmj.subCategory = formData.subCategory;
  if(formData.manifestation) paramsJmj.manifestation = formData.manifestation;

  const message = {
    "Mensagem": formData.message
  }

  const resp = await axios.post(
    `${METALICA_URL}/v2/integration-flow`,
    requestBuilder(
      message,
      AlterType.update,
      attachments,
      ServiceRequestType.GENERAL_REQUESTS,
      { paramsJmj }
    )
  );
  return resp.data;
}

