export const ServiceType = {
  All: "",
  PersonalInformation: "PERSON_INFORMATION_GENERAL",
  Contacts: "PERSON_DATA_CONTACT",
  Emergencies: "PERSON_EMERGENCIES",
  Addresses: "PERSON_DATA_ADDRESS",
  MedicalSpecialities: "DOCTOR_MEDICAL_SPECIALTIES",
  OccupationArea: "DOCTOR_OCCUPATION_AREA",
  AttendencePlaces: "DOCTOR_SERVICE_LOCATIONS",
  BankData: "DOCTOR_PAYMENT_METHODS_BANK_DATA",
  PaymentRouting: "DOCTOR_PAYMENT_METHODS_TARGETING",
  InssInformation: "DOCTOR_TAXES_AND_PERMIT_INFORMATION_INSS",
  IssInformation: "DOCTOR_TAXES_AND_PERMIT_INFORMATION_ISS",
  PermitInformation: "DOCTOR_TAXES_AND_PERMIT_SEND",
  Portability: "PORTABILITY",
  Absence: "DOCTOR_ABSENCE_SEND",
  Benefactor: "DOCTOR_BENEFACTOR_SEND",
  Declaration : "DOCTOR_DECLARATION_SEND",
  AbsenceAnticipation: "DOCTOR_ABSENCE_ANTECIPATION_SEND",
  AbsenceDelay: "DOCTOR_ABSENCE_DELAY_SEND",
  TemporaryAssistance: "DOCTOR_TEMPORARY_ASSISTENCE",
  TemporaryAssistanceAnticipation: "DOCTOR_TEMPORARY_ASSISTENCE_ANTICIPATION",
  ItensGlossados: "FEATURE_GLOSS_SEND",
  GENERAL_REQUESTS: "GENERAL_REQUESTS",
};
