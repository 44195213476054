<template>
  <div>
    <VisualizationFormLayout
      modalId="list-boleto-modal"
      formTitle="Boletos disponíveis"
      cancelButtonText="Fechar"
      ref="alter-modal"
    >
      <template #content>
        <div class="principal">
          <p class="p4">
            <i class="fas fa-info-circle"></i>
            Clique no ícone para fazer o download do boleto.
          </p>
          <b-card class="custom-card my-4" border-variant="light">
            <b-table
              :items="items"
              v-show="!loading"
              responsive
              show-empty
              empty-text="Nenhuma informação para exibir!"
            >
              <template v-slot:head(Numero)="">
                Número
              </template>
              <template v-slot:head(DataVencimento)="">
                <div class="d-flex justify-content-center">
                  Data de vencimento
                </div>
              </template>
              <template v-slot:head(Valor)="">
                Valor
              </template>
              <template v-slot:head(CodigoCliente)="">
                <div class="d-flex justify-content-center">
                  Download
                </div>
              </template>
              <template #cell(DataVencimento)="data">
                <div class="d-flex justify-content-center">
                  {{ dateToLocaleDate(data.item.DataVencimento) }}
                </div>
              </template>
              <template #cell(Valor)="data">
                {{ formatMoney(data.item.Valor) }}
              </template>
              <template #cell(CodigoCliente)="data">
                <div class="d-flex justify-content-center">
                  <img
                    class="pointer"
                    src="@/assets/icons/file-download-outline.svg"
                    data-toggle="tooltip"
                    data-placement="top"
                    @click="downloadDocument(data.item)"
                  />
                </div>
              </template>
            </b-table>
          </b-card>
          <div v-show="loading">
            <b-skeleton-table :rows="12" :columns="3"></b-skeleton-table>
          </div>
        </div>
      </template>
    </VisualizationFormLayout>
  </div>
</template>

<script>
import VisualizationFormLayout from "../../../layouts/VisualizationFormLayout.vue";
import {
  getListRequested,
  downloadInvoice,
} from "../../../services/health-plan/health-plan";
import { formatMoney } from "../../../utils/format/formtUtils";
import { dateToLocaleDateString } from "../../../utils/date/date-utils";

export default {
  components: {
    VisualizationFormLayout,
  },
  mounted() {
    this.getList();
  },
  data() {
    return {
      fields: [],
      items: [],
      loading: false,
    };
  },
  methods: {
    dateToLocaleDate: dateToLocaleDateString,
    formatMoney: formatMoney,
    async downloadDocument({ Numero, CodigoCliente }) {
      try {
        this.loading = true;
        await downloadInvoice(Numero, CodigoCliente, this.$store.getters.isApp);
        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.error("downloadDocument", error);
      }
    },
    async getList() {
      try {
        this.loading = true;

        const crm = this.$store.getters.getUserName;
        this.items = await getListRequested(crm);
        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss">
.principal {
  margin-top: 3rem;
}
.pointer {
  cursor: pointer;
}
</style>
