<template>
  <div class="sustainability-fund">
    <SimpleLayout
      viewComponent="sustainability-fund-tab"
      :cardInfo="this.cardInfo"
      :leftAction="this.leftAction"
    />
  </div>
</template>

<script>
import Vue from "vue";
import SimpleLayout from "../../../layouts/SimpleLayout.vue";
import RoutesPaths from "../../../router/router-structure/routes-paths";

Vue.component("sustainability-fund-tab", () =>
  import("../components/visualization/SustainabilityFundTab.vue")
);

export default {
  components: {
    SimpleLayout,
  },
  data: function() {
    return {
      leftAction: {
        text: "Financeiro",
        onClick: this.leftActionClickHandler,
      },
      cardInfo: {
        icon: "sprout-outline.svg",
        title: "Fundo de Sustentabilidade",
        description: "Acesse o seu extrato de fundo de Sustentabilidade.",
      },
    };
  },
  methods: {
    leftActionClickHandler() {
      this.$router.push({
        path: RoutesPaths.financial.rootName(),
        replace: true,
      });
    },
  },
};
</script>
<style lang="scss">
.sustainability-fund .simple-layout--content {
  width: 85%;
}
</style>