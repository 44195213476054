<template>
  <b-modal
    class="modal"
    :id="$props.modalId || 'custom-modal'"
    :size="$props.landscape ? 'xl' : 'lg'"
    :no-close-on-backdrop="$props.noCloseOnBackdrop"
    :title="$props.title"
    body-class="p-0"
    hide-footer
    hide-header
  >
    <b-container>
      <slot name="content"> </slot>
    </b-container>
  </b-modal>
</template>

<script>
export default {
  props: [
    "modalId",
    "title",
    "noCloseOnBackdrop",
    "hideFotter",
    "landscape",
    "hideHeader",
  ],
};
</script>
<style lang="scss" scoped>
.container {
  padding: 0 !important;
}
.modal-header {
  padding: 0 !important;
}
</style>
