import store from "../../store";

class Occurrences {
  constructor(type, id, publishGuideEnd, message, publishGuideStart) {
    this.type = type;
    this.id = id.toString();
    this.publishGuideEnd = publishGuideEnd;
    this.message = message;
    this.publishGuideStart = publishGuideStart;
  }
}

export function createNewOcurrence(
  type,
  id,
  publishGuideEnd,
  message,
  publishGuideStart
) {
  return new Occurrences(type, id, publishGuideEnd, message, publishGuideStart);
}

export function addOccurrenceStorage(newOccurrence) {
  if (
    store.getters.getOccurrencesData === "" ||
    store.getters.getOccurrencesData === null
  ) {
    var newOccurrences = new Map();
    newOccurrences.set(getKey(newOccurrence), newOccurrence);
    const data = {
      data: newOccurrences,
    };

    store.dispatch("setStoreOccurrencesData", data);
  } else {
    var str = JSON.stringify(Array.from(store.getters.getOccurrencesData.data));
    var occurrences = new Map(JSON.parse(str));

    var occurrencesLast = occurrences.get(getKey(newOccurrence));
    if (occurrencesLast != null) {
      occurrencesLast.publishGuideEnd = newOccurrence.publishGuideEnd;
    } else {
      occurrences.set(getKey(newOccurrence), newOccurrence);
    }

    const data = {
      data: occurrences,
    };

    store.dispatch("setStoreOccurrencesData", data);
  }
}

export function getRequest() {
  if (
    store.getters.getOccurrencesData == "" ||
    store.getters.getOccurrencesData === null
  ) {
    return null;
  } else {
    var str = JSON.stringify(Array.from(store.getters.getOccurrencesData.data));
    var occurrences = new Map(JSON.parse(str));
    let metadata = "";

    if (occurrences.size == 0) {
      return null;
    } else {
      occurrences.forEach((value) => {
        if (value.publishGuideStart !== value.publishGuideEnd) {
          if (metadata !== "") metadata += ",";
          var published = getPublishGuideDescription(value.publishGuideEnd);
          metadata += `"${value.message}": "DIVULGAR NO GUIA MÉDICO? ${published}"`;
        }
      });

      const user = store.getters.getUserInformation;
      var json = `{
    "requester": {
      "crm": "${user.crm}",
      "name": "${user.name}"
    },
      "metadata": {${metadata}}
    }`;

      return JSON.parse(json);
    }
  }
}

function getPublishGuideDescription(publishGuide) {
  return publishGuide ? "SIM" : "NÃO";
}

function getKey(newOccurrence) {
  return newOccurrence.type + "_" + newOccurrence.id;
}
