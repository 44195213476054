<template>
  <div>
    <AlterFormLayout
      modalId="demonstrative-amounts-paid-modal"
      formTitle="Demonstrativo de valores pagos"
      cancelButtonText="Cancelar"
      submitButtonText="Download do Demonstrativo"
      :onSubmitEvent="onSubmit"
    >
      <template #content>
        <DemonstrativeAmountsPaidForm />
      </template>
    </AlterFormLayout>
  </div>
</template>

<script>
import { createToast } from "../../../components/toast/toast";
import AlterFormLayout from "../../../layouts/AlterFormLayout.vue";
import { downloadDocument } from "../../../services/DemonstrativeAmountsPaid/demonstrative-amounts-paid";
import { anchorBase64Download } from "../../../utils/anchor-base64-download/anchor-base64-download";
import DemonstrativeAmountsPaidForm from "../views/DemonstrativeAmountsPaidForm.vue";

export default {
  components: {
    AlterFormLayout,
    DemonstrativeAmountsPaidForm,
  },
  mounted() {},
  data() {
    return {};
  },
  methods: {
    async onSubmit(formData) {
      const response = await downloadDocument(
        formData.card.item,
        formData.year.item
      );

      if (!response.fileBase64) {
        this.msgErroInvalid = "Não existe documentos para serem baixados.";
        createToast(
          "Informação",
          "Não existe documentos para serem baixados.",
          "info"
        );
        this.loading = false;
        return;
      }

      const fileBase64 = response.fileBase64;
      const extension = response.extension;

      anchorBase64Download(
        this.$store.getters.isApp,
        "data:application/pdf",
        "file" + extension,
        fileBase64
      );
    },
  },
};
</script>
