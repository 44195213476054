import { RoleStructure } from "../../../static-data/RoleStructure";
import initializeJsonFromLocalStorage from "../../../utils/storage/initialize-storage-utils";

const initializeUserRole = () => {
  const storedReponse = initializeJsonFromLocalStorage("token-reponse");
  return getRoles(storedReponse);
};

const getRoles = (loginResponse) => {
  const roles = loginResponse?.additionalInformation?.authorities;
  return roles;
};

const buildPermissionsArray = (profileRoles) => {
  try {
    const roles = profileRoles || initializeUserRole();
    let permissions = {};

    if (!roles) {
      console.warn("No roles defined", roles);
      return;
    }

    roles.forEach((permArr) => {
      try {
        RoleStructure[permArr].forEach((role) => {
          permissions = { ...permissions, [role]: role };
        });
      } catch (error) {
        console.error(
          "Error on buildPermissionsArray: on obtain roles from ",
          permArr
        );
        return [];
      }
    });
    return permissions;
  } catch (error) {
    console.error("Error on buildPermissionsArray", error);
    return [];
  }
};
const initializeIsAdmin = () => { 
  const roles = initializeUserRole();
  return buildIsAdmin(roles);
};
const buildIsAdmin = (roles) => {
  if (roles) return roles.includes("AUTHORITY_ADMIN");
  return false;
}
const userRoles = {
  state: {
    permissions: buildPermissionsArray(),
    roles: initializeUserRole(),
    isAdmin: initializeIsAdmin(),
  },
  mutations: {
    setPermissions(state, permissions) {
      state.permissions = permissions;
    },
    setRoles(state, roles) {
      state.roles = roles;
    },
    setIsAdmin(state, isAdmin) {
      state.isAdmin = isAdmin;
    },
    clearPermissions(state) {
      state.permissions = [];
    },
    clearRoles(state) {
      state.roles = [];
    },
    clearIsAdmin(state) {
      state.isAdmin = false;
    },
  },
  actions: {
    initializePermissions({ commit }, loginReponse) {
      const roles = getRoles(loginReponse);
      const permissions = buildPermissionsArray(roles);
      const isAdmin = buildIsAdmin(roles);
      commit("setPermissions", permissions);
      commit("setRoles", roles);
      commit("setIsAdmin", isAdmin);
    },
    clearPermissions({ commit }) {
      commit("clearPermissions");
      commit("clearRoles");
      commit("clearIsAdmin");
    },
  },
  getters: {
    permissions: (state) => state.permissions,
    roles: (state) => state.roles,
    isAdmin: (state) => state.isAdmin,
  },
};

export default userRoles;
