<template>
  <div class="app-background">
    <Navbar>
      <template slot="leftItems">
        <b-navbar variant="faded" type="light">
          <b-navbar-brand href="#">
            <img
              src="@/assets/images/logo_verde.svg"
              class="d-inline-block align-top"
              alt="Kitten"
            />
          </b-navbar-brand>
        </b-navbar>
      </template>
    </Navbar>
    <div class="app-content">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import Navbar from "../components/base/NavBar.vue";
export default {
  components: { Navbar },
};
</script>

<style lang="scss" scoped>
.app-content {
  height: 89vh;
  width: 100vw;
  padding: 2rem;
  margin-top: 5rem;
}
</style>
