import Login from "../../modules/login/views/Login.vue";
import AdminLogin from "../../modules/login/views/AdminLogin.vue";
import InitialLayout from "../../layouts/InitialLayout.vue";

const LoginRoutes = [
  {
    path: "",
    redirect: "/login",
  },
  {
    path: "",
    component: InitialLayout,
    children: [{ path: "/login", component: Login }],
  },
  {
    path: "",
    component: InitialLayout,
    children: [{ path: "/admin-login", component: AdminLogin }],
  },
];

export default LoginRoutes;
