<template>
  <div class="container">
    <div class="row">
      <div class="col-sm">
        <p class="inicial-step-text">
          Aqui oferecemos uma ampla variedade de cursos e treinamentos abordando
          temas diversos, com o intuito de proporcionar aos cooperados um maior
          senso de pertencimento e compreensão de assuntos relevantes.
        </p>
      </div>
    </div>
    <div class="row">      
      <div class="col-sm">
        <p class="inicial-step-text mt-3">
          Nosso objetivo é capacitar e informar nossos cooperados, contribuindo
          para o desenvolvimento conjunto e aprimorando a experiência de todos.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},

  data() {
    return {};
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
.inicial-step-text {
  font-size: 18px;
  color: #0a5f55;
}
</style>
