import axios from "../http-client/axios";
import { AUTH_URL } from "../urls/base-urls";
import {
  APP_AUTORIZATION_USER,
  APP_AUTORIZATION,
} from "../urls/enviroment-variables";

export async function requestRefreshToken(resfreshToken) {
  const config = {
    auth: {
      username: APP_AUTORIZATION_USER,
      password: APP_AUTORIZATION,
    },
  };
  const resp = await axios.post(
    `${AUTH_URL}/oauth/token?grant_type=refresh_token&refresh_token=${resfreshToken}`,
    {},
    config
  );

  return resp.data;
}
