import { RoleList } from "../../static-data/RoleList";
import store from "../../store";
import isAbleRole from "../../utils/permissions/is-able-role";
import axios from "../http-client/axios";
import { SLIPKNOP_URL } from "../urls/base-urls";

export async function getNewness() {
  const resp = await axios({
    url: `${SLIPKNOP_URL}/newness`,
    method: "get",
    data: {},
    headers: { "content-type": "application/json" },
  });

  return resp.data;
}

export async function saveNewness(idNewness) {

  const checkValidateShowNewness = await validateShowNewness();
  
  if (checkValidateShowNewness) {
    const resp = await axios({
      url: `${SLIPKNOP_URL}/newness?idNewness=${idNewness}`,
      method: "post",
      data: {},
      headers: { "content-type": "application/json" },
    });

    return resp.data;
  }
}

export async function validateShowNewness() {
  if (store.getters.isAdmin) {
    return !isAbleRole(RoleList.AUTHORITY_USER_IS_CUSTOMER);
  }
  return true;
} 