<template>
  <div>
    <AlterFormLayout
      modalId="profile-image-modal"
      formTitle="Imagem do perfil"
      deleteButtonText="Remover foto"
      cancelButtonText="Cancelar"
      submitButtonText="Salvar"
      :onSubmitEvent="onSubmit"
      :onDeleteEvent="onDelete"
      attentionModalId="image-attention-modal"
      :disableEvent="this.$store.getters.getTokenAdmin"
    >
      <template #content>
        <ImageUploadForm />
      </template>
    </AlterFormLayout>
    <success-modal
      ref="success-modal"
      description="Foto do perfil atualizada com sucesso!"
      buttonText="Ok, entendi!"
    />
    <attention-modal
      modalId="image-attention-modal"
      title="Atenção!"
      submitButtonText="Sim, excluir a imagem"
      cancelButtonText="Não, cancelar a exclusão"
      :onAction="onDelete"
      ref="attetion-modal"
    >
      <template slot="description">
        <div class="justify-content-center mt-5">
          <div>
            <p class="p1 mb-5">
              Deseja mesmo a exclusão desta imagem?
            </p>
          </div>
        </div>
      </template>
    </attention-modal>
  </div>
</template>

<script>
import ImageUploadForm from "./ImageUploadForm.vue";
import AlterFormLayout from "../../../../layouts/AlterFormLayout.vue";
import SuccessModal from "../../../../components/base-modals/SuccessModal.vue";
import {
  updateProfileImage,
  deleteProfileImage,
} from "../../../../services/profile-image/profile-image-services.js";
import { inject } from "@vue/composition-api";
import { createToast } from "../../../../components/toast/toast";
import AttentionModal from "../../../../components/base-modals/AttentionModal.vue";

export default {
  components: {
    AlterFormLayout,
    ImageUploadForm,
    SuccessModal,
    AttentionModal,
  },
  setup() {
    const globalLoading = inject("globalLoading");
    return { globalLoading };
  },
  methods: {
    async onSubmit(formData) {
      try {
        this.globalLoading = true;
        const imageLink = await updateProfileImage(
          this.$store.getters.getUserName,
          formData.files[0]
        );
        await this.$store.dispatch("setImageProfile", imageLink);
        const modal = this.$refs["success-modal"].$children[0];
        modal.show();
        this.globalLoading = false;
      } catch (error) {
        this.globalLoading = false;
        console.error("Error OnSubmit", error);
        createToast("Atenção", "Ocorreu um erro ao realizar o upload.");
      }
    },

    async onDelete(onAction) {
      try {
        if (this.$store.getters.imageProfile) {
          createToast("Atenção", "Nenhum imagem para ser deletada.");
        } else if (onAction) {
          this.globalLoading = true;
          await deleteProfileImage(this.$store.getters.getUserName);
          await this.$store.dispatch("setImageProfile", "");
          this.globalLoading = false;
          this.$bvModal.hide("profile-image-modal");
        }
      } catch (error) {
        this.globalLoading = false;
        console.error("Error onDelete", error);
        createToast("Atenção", "Ocorreu um erro ao realizar o delete.");
      }
    },
  },
};
</script>
