export const APP_AUTORIZATION_USER = `${process.env.VUE_APP_AUTORIZATION_USER}`;
export const APP_AUTORIZATION = `${process.env.VUE_APP_AUTORIZATION}`;
export const APP_BLIP_CHAT_APP_KEY = `${process.env.VUE_APP_BLIP_CHAT_APP_KEY}`;
export const APP_BLIP_CHAT_URL = `${process.env.VUE_APP_BLIP_CHAT_URL}`;
export const APP_WHATSAPP_CHAT_URL = `${process.env.VUE_APP_WHATSAPP_CHAT_URL}`;
export const APP_USER_PASSWORD_BLIP_CHAT = `${process.env.VUE_APP_USER_PASSWORD_BLIP_CHAT}`;
export const APP_FIREBASE_APP_KEY = `${process.env.VUE_APP_FIREBASE_APP_KEY}`;
export const APP_FIREBASE_AUTH_DOMAIN = `${process.env.VUE_APP_FIREBASE_AUTH_DOMAIN}`;
export const APP_FIREBASE_DATABASE_URL = `${process.env.VUE_APP_FIREBASE_DATABASE_URL}`;
export const APP_FIREBASE_PROJECT_ID = `${process.env.VUE_APP_FIREBASE_PROJECT_ID}`;
export const APP_FIREBASE_STORAGE_BUCKET = `${process.env.VUE_APP_FIREBASE_STORAGE_BUCKET}`;
export const APP_FIREBASE_MESSAGING_SENDER_ID = `${process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID}`;
export const APP_FIREBASE_API_ID = `${process.env.VUE_APP_FIREBASE_API_ID}`;
export const APP_FIREBASE_MEASURE_MENT_ID = `${process.env.VUE_APP_FIREBASE_MEASURE_MENT_ID}`;
export const APP_CAPTCHA_SITE_KEY = `${process.env.VUE_APP_CAPTCHA_SITE_KEY}`;
