import axios from "../http-client/axios";
import { GORIJA_URL } from "../urls/base-urls";

export async function downloadFile(object) {
  const resp = await axios({
    url: `${GORIJA_URL}/download-document`,
    method: "post",
    data: object,
    headers: { "content-type": "application/json" },
  });
  return resp.data;
}
