import initializeJsonFromLocalStorage from "../../../utils/storage/initialize-storage-utils";
import { getNewness } from "../../../services/newness/newness-new.js";

const newnessData = {
  state: {
    newnessData: initializeJsonFromLocalStorage("configuration-newness"),
  },
  mutations: {
    removeNewness(state, value) {
      state.newnessData = state.newnessData.filter(n => n.idConfigurationNewness != value);
      localStorage.setItem("configuration-newness", JSON.stringify(state.newnessData));
    },
    setNewnessData(state, data) {
      state.newnessData = data; 
    },
    clear(state) {
      state.newnessData = null;
    },
  },
  actions: {
     removeNewness({ commit }, id) {
      commit("removeNewness", id);
    },
    requestNewnessData({ commit } , crm) {
      return new Promise((resolve, reject) => {
        getNewness(crm)
          .then((newness) => {
            localStorage.setItem("configuration-newness", JSON.stringify(newness));
            commit("setNewnessData", newness);
            resolve(newness);
          })
          .catch((err) => {
            console.error("requestLogin error: ", err);
            reject(err);
          });
      });
    },
    setStoreNewnessData({ commit }, data) {
      localStorage.setItem("configuration-newness", JSON.stringify(data));
      commit("setNewnessData", data);
    },
    clearNewnessData({ commit }) {
      return new Promise((resolve, reject) => {
        try {
          localStorage.setItem("configuration-newness", "");
          commit("clear");
          resolve();
        } catch (err) {
          reject(err);
        }
      });
    },
  },
  getters: {
    getNewnessData: (state) => state.newnessData,
  },
};

export default newnessData;
