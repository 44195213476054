import panelEconomicPage from "../../modules/panel-economic/views/panel-economic.vue";
import { PermissionList } from "../../static-data/PermissionList";
import RouterViewRender from "../../utils/routes/router-view-render";
import { IsAbleNavigation } from "../utils/is-able-navigation";

const PanelEconomicRoutes = {
  path: "panel-economic",
  component: RouterViewRender,
  beforeEnter: (to, from, next) =>
    IsAbleNavigation(to, from, next, PermissionList.INSTITUTIONAL_PAGE_MENU),
  children: [
    {
      path: "",
      component: panelEconomicPage,
      meta: {
        requiresAuth: true,
      },
    },
  ],
};

export default PanelEconomicRoutes;
