import Financial from "../../modules/financial/view/Financial.vue";
import RouterViewRender from "../../utils/routes/router-view-render";
import { PermissionList } from "../../static-data/PermissionList";
import { IsAbleNavigation } from "../utils/is-able-navigation";
import Analytics from "../../modules/analytics/views/Analytics.vue";
import ReportIncome from "../../modules/report-income/views/ReportIncome.vue";
import SustainabilityFund from "../../modules/sustainability-fund/views/SustainabilityFund.vue";
import QuotaStatement from "../../modules/quota-statement/views/QuotaStatement.vue";
import IncomeHistory from "../../modules/income-history/views/IncomeHistory.vue";
import ReportVoxis from "../../modules/voxis/view/ReportVoxis.vue";
import BackbeatView from "../../modules/backbeat/views/BackbeatView.vue";
import BackbeatKnowMore from "../../modules/backbeat/views/BackbeatKnowMoreView.vue";
import ReviewGlossesView from "../../modules/review-glosses/views/ReviewGlossesView.vue";


const FinancialRoutes = {
  path: "financial",
  component: RouterViewRender,
  beforeEnter: (to, from, next) =>
    IsAbleNavigation(to, from, next, PermissionList.FINANCIAL_MENU),
  children: [
    {
      path: "",
      component: Financial,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "analytics",
      component: Analytics,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "income-history",
      component: IncomeHistory,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "backbeat",
      component: BackbeatView,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "backbeat-know-more",
      component: BackbeatKnowMore,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "review-glosses",
      component: ReviewGlossesView,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "report-income",
      component: ReportIncome,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "sustainability-fund",
      component: SustainabilityFund,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "quota-statement",
      component: QuotaStatement,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "report-voxis",
      component: ReportVoxis,
      meta: {
        requiresAuth: true,
      },
    },
  ],
};

export default FinancialRoutes;
