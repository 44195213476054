import initializeJsonFromLocalStorage from "../../../utils/storage/initialize-storage-utils";
const setIsAppOnStore = (flag) => {
  try {
    localStorage.setItem("isApp", JSON.stringify(flag));
  } catch (error) {
    console.error("error on setIsAppOnStore", error);
    return false;
  }
};

const initializeIsAPPStore = () => {
  try {
    const accepted = initializeJsonFromLocalStorage("isApp");
    return accepted;
  } catch (error) {
    console.error("error on initializeUseTerm", error);
    return false;
  }
};

const isApp = {
  state: {
    isApp: initializeIsAPPStore(),
  },
  mutations: {
    setIsApp(state, value) {
      state.isApp = value;
    },
  },
  actions: {
    isApp({ commit }, flag) {
      setIsAppOnStore(flag);
      commit("setIsApp", flag);
    },
  },
  getters: {
    isApp: (state) => state.isApp,
  },
};

export default isApp;
