<template>
  <div class="password-container-form">
    <b-card border-variant="light" class="custom-card my-4">
      <b-form-row>
        <b-col>
          <p class="p3">SENHA ATUAL</p>
        </b-col>
        <b-col>
          <i
            class="fas fa-eye"
            v-show="showCurrentPassword"
            @click="toogleShowCurrentPassword()"
          ></i>
          <i
            class="fas fa-eye-slash"
            v-show="!showCurrentPassword"
            @click="toogleShowCurrentPassword()"
          ></i>
          <b-form-input
            placeHolder="Informe sua senha atual"
            id="currentPassword"
            v-model.lazy.trim="form.currentPassword"
            :type="getInputType(showCurrentPassword)"
            required
            :state="$props.invalidCurrentPassword"
          />
        </b-col>
      </b-form-row>
      <b-form-row>
        <b-col>
          <p class="p3">NOVA SENHA</p>
        </b-col>
        <b-col>
          <i
            class="fas fa-eye"
            v-show="showPassword"
            @click="toogleShowPassword()"
          ></i>
          <i
            class="fas fa-eye-slash"
            v-show="!showPassword"
            @click="toogleShowPassword()"
          ></i>
          <b-form-input
            placeHolder="Informe sua nova senha"
            id="password"
            v-model.lazy.trim="form.password"
            :state="validateFieldPassword('password')"
            :type="getInputType(showPassword)"
          />
        </b-col>
      </b-form-row>
      <b-form-row>
        <b-col>
          <p class="p3">CONFIRMAR NOVA SENHA</p>
        </b-col>
        <b-col>
          <i
            class="fas fa-eye"
            v-show="showNewPassword"
            @click="toogleShowNewPassword()"
          ></i>
          <i
            class="fas fa-eye-slash"
            v-show="!showNewPassword"
            @click="toogleShowNewPassword()"
          ></i>
          <b-form-input
            placeHolder="Confirme sua nova senha"
            id="newPassword"
            ref="newPassword"
            v-model.lazy.trim="form.newPassword"
            :state="validateFieldSamePassword('newPassword')"
            :type="getInputType(showNewPassword)"
          />
        </b-col>
      </b-form-row>
    </b-card>
    <RulePassword
      :password="form.password"
      :passwordConfirm="form.newPassword"
    />
  </div>
</template>

<script>
import { inject } from "@vue/composition-api";
import RulePassword from "../../../../components/password/RulePassword.vue";

export default {
  components: { RulePassword },
  setup() {
    const formData = inject("formData");
    const setForm = inject("setForm");
    const setSubmitHandler = inject("setSubmitHandler");
    return { formData, setForm, setSubmitHandler };
  },
  props: ["changePasswordForm", "invalidCurrentPassword"],
  mounted() {
    this.initForm();
  },
  data() {
    return {
      formSubmited: false,
      showPassword: false,
      showCurrentPassword: false,
      showNewPassword: false,
      form: {
        currentPassword: null,
        password: null,
        newPassword: null,
      },
      validations: {},
    };
  },

  methods: {
    initForm() {
      const changePasswordForm = this.$props.changePasswordForm;
      this.setSubmitHandler(this.onSubmit);
      if (changePasswordForm) {
        this.form = {
          currentPassword: changePasswordForm.currentPassword,
          password: changePasswordForm.password,
          newPassword: changePasswordForm.password,
        };
        this.formSubmited = true;
      }
      this.formData = {
        form: { ...this.form },
        onSubmitHandler: this.onSubmit,
        onDeleteHandler: this.onDelete,
      };
    },
    validateFieldPassword(name) {
      let value = this.form[name];

      const validation = !!value && value.length > 7;
      this.validations[name] = validation;
      if (!this.formSubmited) return null;

      return validation;
    },
    validateFieldSamePassword(name) {
      let value = this.form[name];

      const validation =
        !!value &&
        value.length > 7 &&
        this.form.password == this.form.newPassword;
      this.validations[name] = validation;
      if (!this.formSubmited) return null;

      return validation;
    },
    validateField(name) {
      let value = this.form[name];

      let validation = !!value;
      this.validations[name] = validation;
      if (!this.formSubmited) return null;

      return !!value;
    },
    requiredFieldsFilled() {
      return Object.values(this.validations).every(
        (fieldValue) => fieldValue == true
      );
    },
    toogleShowPassword() {
      this.showPassword = !this.showPassword;
    },
    toogleShowCurrentPassword() {
      this.showCurrentPassword = !this.showCurrentPassword;
    },
    toogleShowNewPassword() {
      this.showNewPassword = !this.showNewPassword;
    },
    getInputType(showPassword) {
      return showPassword ? "text" : "password";
    },
    onSubmit() {
      this.setForm(this.form);
      this.formSubmited = true;
      if (this.requiredFieldsFilled()) {
        return true;
      }
    },
    alert(message) {
      alert(message);
    },
  },
};
</script>

<style lang="scss">
//16px texto do upload
@import "../../../../../node_modules/cropperjs/dist/cropper.min.css";

div.form-row {
  padding: 0.5rem;
  border-bottom: 1px solid $gray-300;
  &:last-child {
    border-bottom: none;
  }
}
.password-container-form {
  i.fa-eye,
  i.fa-eye-slash {
    position: absolute;
    margin-left: 263px;
    margin-top: 10px;
    &:hover {
      cursor: pointer;
    }
  }
}
input.form-control {
  text-align: right;
  border: 1px solid transparent;
}
</style>
