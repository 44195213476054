var extToMimes = {
    'img': 'image/jpeg',
    'png': 'image/png',
    'csv': 'text/csv',
    'gif': 'image/gif',
    'jpeg': 'image/jpeg',
    'pdf': 'application/pdf',
    'xlsx': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'xls': 'application/vnd.ms-excel',
    'zip': 'application/zip',
 }

export function getMimeTypeByExtension(extension) {
  
    if (extToMimes[extension]) {
        return extToMimes[extension];
    }
}
  
  