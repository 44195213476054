<template>
  <b-modal
    :id="$props.modalId || 'custom-modal'"
    :size="$props.landscape ? 'xl' : 'lg'"
    :no-close-on-backdrop="$props.noCloseOnBackdrop"
    :no-close-on-esc="$props.noCloseOnEsc"
    :title="$props.title"
    :hide-footer="$props.hideFotter"
  >
    <template #modal-header>
      <slot name="header"> </slot>
    </template>
    <b-container>
      <slot name="content"> </slot>
    </b-container>

    <template #modal-footer>
      <slot name="footer"> </slot>
    </template>
  </b-modal>
</template>

<script>
export default {
  props: ["modalId", "title", "noCloseOnBackdrop", "noCloseOnEsc", "hideFotter", "landscape"],
};
</script>
<style lang="scss">
div.modal-content {
  background: var(--background);
}

header.modal-header,
footer.modal-footer {
  > h5 {
    margin: 0;
  }
  border: transparent;
}

footer.modal-footer {
  display: flex;
  flex-wrap: wrap;
  padding: 1rem 2.2rem;
}

div.modal-content {
  border-radius: 1rem;
  @include media-breakpoint-up(xs) {
    padding: 0;
  }

  @include media-breakpoint-up(sm) {
    padding: 0;
  }

  @include media-breakpoint-up(md) {
    padding: 2rem 4rem;
  }
}
</style>
