const InstitutionalPageLinkType = {
  TRAINING: "TRAINING",
  OUR_STORY: "OUR_STORY",
  COLLECTIONS: "COLLECTIONS",
  TRANSPARENCY_AND_COMMITMENT: "TRANSPARENCY_AND_COMMITMENT",
  OUR_WAY_OF_CARE: "OUR_WAY_OF_CARE",
  DOCUMENT_AND_MINUTES_OF_UGF: "DOCUMENT_AND_MINUTES_OF_UGF",
  ECONOMIC_PANEL: "ECONOMIC_PANEL",
  ANT_DUTS: "ANT_DUTS",
  NEW_TECNOLOGY:"NEW_TECNOLOGY",
};

export default InstitutionalPageLinkType;
