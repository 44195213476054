import VirtualOffice from "../../modules/virtual-office/views/VirtualOffice.vue";
import RouterViewRender from "../../utils/routes/router-view-render";

const VirtualOfficeRoutes = {
  path: "virtual-office",
  component: RouterViewRender,
  children: [
    {
      path: "",
      component: VirtualOffice,
      meta: {
        requiresAuth: true,
      },
    },
  ],
};

export default VirtualOfficeRoutes;
