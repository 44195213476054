<script>
import Cleave from "cleave.js";

export default {
  name: "custom-mask-label",
  render(h) {
    return h("input", {
      type: "text",
      class: "label " + this.getClass(),
      ref: "root",
      attrs: { disabled: true },
    });
  },
  props: {
    initialValue: { type: String },
    options: {
      type: Object,
      default: () => ({}),
    },
    raw: {
      type: Boolean,
      default: true,
    },
    state: {
      type: Boolean,
      default: null,
    },
    cssClass: { type: String },
  },
  data() {
    return {
      cleave: null,
      onValueChangedFn: null,
    };
  },
  mounted() {
    if (this.cleave) return;
    this.cleave = new Cleave(this.$refs.root, this.getOptions(this.options));
  },
  methods: {
    getClass() {
      let state = this.$props.cssClass;
      return state;
    },
    getOptions(options) {
      this.onValueChangedFn = options.onValueChanged;
      return Object.assign({}, options, {
        onValueChanged: this.onValueChanged,
      });
    },
    onValueChanged(event) {
      if (typeof this.onValueChangedFn === "function") {
        this.onValueChangedFn.call(this, event);
      }
    },
  },
  watch: {
    initialValue: {
      immediate: true,
      handler(newValue) {
        if (this.cleave && this.cleave.setRawValue)
          this.cleave.setRawValue(newValue);
      },
    },
    options: {
      deep: true,
      handler(newOptions) {
        this.cleave.destroy();
        this.cleave = new Cleave(this.$refs.root, this.getOptions(newOptions));
        this.cleave.setRawValue(this.modelValue);
      },
    },
  },
  beforeUnmount() {
    if (!this.cleave) return;
    this.cleave.destroy();
    this.cleave = null;
    this.onValueChangedFn = null;
  },
};
</script>
<style scoped lang="scss">
input.label {
  width: 100%;
  display: block;
  outline: none;
  border: none;
  background-color: transparent;
  text-align: right;
}
</style>
