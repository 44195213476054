import Documents from "../../modules/documents/views/Documents.vue";
import Benefactor from "../../modules/benefactor/views/Benefactor.vue";
import Absence from "../../modules/absence/views/Absence.vue";
import FuneralAssistance from "../../modules/funeral-assistance/views/FuneralAssistance.vue";
import Declaration from "../../modules/declarations/views/Declarations.vue";
import CooperativeDeclaration from "../../modules/cooperative-declaration/views/CooperativeDeclaration.vue";
import RouterViewRender from "../../utils/routes/router-view-render";
import TemporaryAssistance from "../../modules/temporary-assistance/views/TemporaryAssistance.vue";
import HealthPlan from "../../modules/health-plan/views/HealthPlan.vue";
import { PermissionList } from "../../static-data/PermissionList";
import { IsAbleNavigation } from "../utils/is-able-navigation";

const DocumentsRoutes = {
  path: "documents",
  component: RouterViewRender,
  beforeEnter: (to, from, next) =>
    IsAbleNavigation(to, from, next, PermissionList.DOCUMENTS_MENU),
  children: [
    {
      path: "",
      component: Documents,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "benefactor",
      component: Benefactor,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "funeralAssistance",
      component: FuneralAssistance,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "absence",
      component: Absence,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "temporary-assistance",
      component: TemporaryAssistance,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "declaration",
      component: Declaration,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "cooperative-declaration",
      component: CooperativeDeclaration,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "health-plan",
      component: HealthPlan,
      meta: {
        requiresAuth: true,
      },
    },
  ],
};

export default DocumentsRoutes;
