<template>
  <div>
    <FormLayout
      :tabs="this.tabs"
      :cardInfo="this.cardInfo"
      :leftAction="this.leftAction"
      :leftLinkNavigation="this.leftLinkNavigation"
      :onChangeTab="onChangeTabIndex"
    ></FormLayout>
  </div>
</template>

<script>
import Vue from "vue";
import FormLayout from "../../../layouts/FormLayout.vue";
import { ServiceType } from "../../../static-data/ServiceType";
import RoutesPaths from "../../../router/router-structure/routes-paths";

Vue.component("bank-information", () =>
  import("../components/visualization/BankInformation.vue")
);

Vue.component("payment-routing", () =>
  import("../components/visualization/PaymentRouting.vue")
);

export default {
  components: {
    FormLayout,
  },
  data: function() {
    return {
      currentTabIndex: 0,
      leftAction: {
        text: "Cadastro",
        onClick: this.leftActionClickHandler,
      },
      leftLinkNavigation: this.leftHistoryNavigation,
      cardInfo: {
        icon: "attach_money.svg",
        title: "Meios de pagamento",
        description: "Informe meios de pagamentos.",
      },
      tabs: [
        {
          title: "Dados Bancários",
          formComponent: "bank-information",
          serviceType: ServiceType.BankData,
        },
        {
          title: "Direcionamento de pagamentos",
          formComponent: "payment-routing",
          serviceType: ServiceType.PaymentRouting,
        },
      ],
    };
  },
  methods: {
    onChangeTabIndex(newValue) {
      this.currentTabIndex = newValue;
    },
    leftActionClickHandler() {
      this.$router.push(RoutesPaths.register.rootName());
    },
    leftHistoryNavigation() {
      this.$router.push({
        path: RoutesPaths.requestHistory.rootName(),
        params: {
          serviceType: this.tabs[this.currentTabIndex].serviceType,
        },
      });
    },
  },
};
</script>
