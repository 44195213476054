<template>
  <div>
    <CustomModalTemplate
      modalId="report-modal"
      :title="
        'Relatório de Glosas Voxis - Competência: ' +
          this.$props.month +
          '/' +
          this.$props.year
      "
      ref="form-modal"
      noCloseOnBackdrop
      classCustom="custom-header"
      landscape="false"
    >
      <template slot="content">
        <div>
          <p class="p4">
            <i class="fas fa-info-circle"></i>
            Clique no ícone para fazer o download da opção desejada.
          </p>

          <b-card class="custom-card my-4">
            <b-row class="custom-row">
              <b-col>
                <img
                  style="width: 34px;"
                  src="@/assets/icons/icon-doc-pdf.svg"
                />
                PDF
              </b-col>
              <b-col class="text-right">
                <img
                  @click="downloadPdf()"
                  src="@/assets/icons/download-outline.svg"
                />
              </b-col>
            </b-row>
            <b-row class="custom-row">
              <b-col>
                <img
                  style="width: 34px;"
                  src="@/assets/icons/microsoft-excel.svg"
                />
                EXCEL
              </b-col>
              <b-col class="text-right">
                <img
                  @click="downloadExcel()"
                  src="@/assets/icons/download-outline.svg"
                />
              </b-col>
            </b-row>
          </b-card>
        </div>
      </template>
      <template slot="footer">
        <b-button
          form="alter-form"
          type="submit"
          class="ml-4"
          variant="primary"
          @click="this.onClose"
        >
          Fechar
        </b-button>
      </template>
    </CustomModalTemplate>
  </div>
</template>

<script>
import { inject } from "@vue/composition-api";
import CustomModalTemplate from "../../components/custom-modal/CustomModalTemplate.vue";
import { createToast } from "../../components/toast/toast";

import { downloadFile } from "../../services/analytics/download-services";
import { anchorBase64Download } from "../../utils/anchor-base64-download/anchor-base64-download";

export default {
  props: ["year", "month"],
  components: { CustomModalTemplate },
  setup() {
    const globalLoading = inject("globalLoading");
    return { globalLoading };
  },
  methods: {
    onClose() {
      this.$refs["form-modal"].$children[0].hide();
    },
    async downloadPdf() {
      try {
        this.globalLoading = true;
        const crm = this.$store.getters.getUserName;
        const request = {
          downloadType: "PDF_GLOSS",
          crm: crm,
          year: this.$props.year,
          month: this.$props.month,
        };
        const response = await downloadFile(request);
        this.download(response);
        this.globalLoading = false;
      } catch (error) {
        this.globalLoading = false;
        console.error("Error downloadPdf", error);
      }
    },
    download(response) {
      if (!response.fileBase64) {
        this.msgErroInvalid = "Não existe documentos para serem baixados.";
        createToast(
          "Informação",
          "Não existe documentos para serem baixados.",
          "info"
        );
        this.loading = false;
        return;
      }

      let typeFile = "data:application/pdf";

      if (response.extension == ".xlsx") {
        typeFile = "data:application/vnd.ms-excel";
      }

      anchorBase64Download(
        this.$store.getters.isApp,
        typeFile,
        "file" + response.extension,
        response.fileBase64
      );
    },
    async downloadExcel() {
      try {
        this.globalLoading = true;
        const crm = this.$store.getters.getUserName;
        const request = {
          downloadType: "EXCEL_GLOSS",
          crm: crm,
          year: this.$props.year,
          month: this.$props.month,
        };
        const response = await downloadFile(request);
        this.download(response);
        this.globalLoading = false;
      } catch (error) {
        this.globalLoading = false;
        console.error("Error downloadExcel", error);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.custom-row {
  padding: 5px 5px 6px 5px;
}
</style>
