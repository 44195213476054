<template>
  <b-modal id="success-modal">
    <b-container>
      <template #modal-header> </template>

      <b-row class="justify-content-center">
        <div class="success-container">
          <div class="success-container--outter-circle">
            <div class="success-container--inner-circle">
              <i class="success-container--icon fas fa-check"></i>
            </div>
          </div>
        </div>
      </b-row>
      <b-row class="justify-content-center text-center my-5">
        <h2 class="font-weight-bold m-0">
          Sucesso!
        </h2>
      </b-row>
      <b-row class="justify-content-center text-center my-5">
        <p class="p1">
          {{ this.$props.description }}
        </p>
      </b-row>
      <b-row class="justify-content-center text-center my-5">
        <p class="p1" v-if="this.$props.subdescription">
          {{ this.$props.subdescription }}
        </p>
        <slot name="subdescription" v-else> </slot>
      </b-row>
      <b-row
        v-show="this.$props.alertPhysicalPerson"
        class="justify-content-center text-center my-5"
      >
        <p class="p1 font-weight-bold" v-if="this.$props.subdescription">
          <img
            class="img-exclamation"
            src="../../assets/images/MDI-information-green.svg"
          />
          {{ this.$props.alertPhysicalPerson }}
        </p>
      </b-row>
      <b-row
        v-show="this.$props.protocol"
        class="justify-content-center text-center my-5"
      >
        <p class="p1">
          Número do protocolo
        </p>
        <div class="font-size-custom">
          {{ this.$props.protocol }}
        </div>
      </b-row>
    </b-container>
    <template #modal-footer>
      <b-button
        @click="$bvModal.hide('success-modal')"
        class="ml-4 custom-align-button"
        variant="primary"
      >
        {{ $props.buttonText ? $props.buttonText : "Ok" }}
      </b-button>
    </template>
  </b-modal>
</template>

<script>
export default {
  props: ["description", "buttonText", "subdescription", "protocol", "alertPhysicalPerson"],
};
</script>

<style lang="scss" scoped>
.img-exclamation {
  width: 20px;
  height: 20px;
}
.success-container {
  &--icon {
    font-size: 4rem;
    color: white;
  }

  &--inner-circle {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 7rem;
    width: 7rem;
    background: var(--primary);
    border-radius: 100%;
  }

  &--outter-circle {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 9rem;
    width: 9rem;
    background: var(--green-1);
    border-radius: 100%;
  }
}

.text-center {
  text-align: center;
}
.font-size-custom {
  font-size: 1.6rem;
  margin-left: 1rem;
}
.custom-align-button {
  margin-left: auto !important;
  margin-right: auto;
}
</style>
