<script>

export const FormLayoutCustom = {
  props: [],
  components: {
  },

  render() {
    return (
      <b-container>
        <b-container class="cards">
          <b-container>
            <div class="card-one h-100">
              {this.$slots.periodOne}
            </div>
          </b-container>

          <b-container class="card-two">
            <div class="card-two h-100">
              {this.$slots.periodTwo} 
            </div>
          </b-container>

          <b-container class="card-tree">
            <div class="card-tree h-100">
              {this.$slots.buttonSearch}
            </div>
          </b-container>
        </b-container>
      </b-container>
    );
  },
};

export default FormLayoutCustom;
</script>

<style lang="scss" scoped>

.cards {
  max-width: 100%;
  display: grid;
  border-style: none;
  grid-column: 0;
}

.card-one {
  padding-bottom: 1rem;
}

.card-two {
  padding-left: 0rem;
  padding-bottom: 2rem;
}

.card-tree {
  padding-left: 0rem;
}

@media (min-width: 700px) {
  .cards {
    grid-template-columns: 225px 220px auto;
  }

  .card-one {
    padding-bottom: 0rem;
  }

  .card-two {
    padding-bottom: 0rem;
    padding-left: 2rem;
  }

  .card-tree {
    padding-left: 2rem;
  }
}

</style>
