<template>
  <div>
    <FormLayout
      :tabs="this.tabs"
      :cardInfo="this.cardInfo"
      :leftAction="this.leftAction"
      :leftLinkNavigation="this.leftLinkNavigation"
    ></FormLayout>
  </div>
</template>

<script>
import Vue from "vue";
import FormLayout from "../../../layouts/FormLayout.vue";
import RoutesPaths from "../../../router/router-structure/routes-paths";
import { ServiceType } from "../../../static-data/ServiceType";

Vue.component("DeclarationOthersInformations", () =>
  import("../components/visualization/DeclarationInformations.vue")
);

export default {
  components: {
    FormLayout,
  },
  data: function() {
    return {
      leftAction: {
        text: "Declarações",
        onClick: this.leftActionClickHandler,
      },
      leftLinkNavigation: this.leftHistoryNavigation,
      cardInfo: {
        headerIcon: "far fa-address-card",
        icon: "file-document-outline.svg",
        title: "Solicitar outras Declarações",
        description:
          "Caso não tenha encontrado a opção para solicitar a declaração desejada, acesse aqui.",
      },
      tabs: [
        {
          title: "",
          formComponent: "DeclarationOthersInformations",
          serviceType: ServiceType.Declaration,
        },
      ],
    };
  },
  methods: {
    leftActionClickHandler() {
      this.$router.push({
        path: RoutesPaths.declarations.rootName(),
        replace: true,
      });
    },
    leftHistoryNavigation() {
      this.$router.push({
        path: RoutesPaths.requestHistory.rootName(),
        params: {
          serviceType: ServiceType.Declaration,
        },
      });
    },
  },
};
</script>

<style lang="scss">
.declaration {
  .simple-layout--content {
    width: 100%;
  }
}
</style>
