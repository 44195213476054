<script>
import CustomCardInfo from "../components/custom-info-card/CustomInfoCard.vue";
import FormTemplate from "../templates/FormTemplate.vue";
import NavigationButton from "../components/navigation-button/NavigationButton.vue";

export const SimpleLayout = {
  props: [
    "cardInfo",
    "leftAction",
    "leftLinkNavigation",
    "navigation",
    "viewComponent",
  ],
  components: {
    FormTemplate,
    CustomCardInfo,
    NavigationButton,
  },
  render(createElement) {
    const invalidHandler = (e) => {
      console.warn("Invalid handler!", e);
    };

    const leftAction = {
      text: "Invalid Text!",
      onClick: invalidHandler,
      ...this.$props.leftAction,
    };

    const leftNavigation = this.$props.leftLinkNavigation;

    const cardInfo = {
      headerIcon: "",
      title: "Invalid Title!",
      description: "Invalid description!",
      ...this.$props.cardInfo,
      onCardClick: this.$props?.cardInfo?.onCardClick || invalidHandler,
    };

    const viewContent = createElement(this.$props.viewComponent);

    return (
      <FormTemplate>
        <template slot="leftAction">
          <NavigationButton actionClickHandler={leftAction.onClick}>
            <template slot="icon">
              <i class="fas fa-arrow-left"></i>
            </template>
            <template slot="text">{leftAction.text}</template>
          </NavigationButton>
        </template>

        <template slot="infoCard">
          <CustomCardInfo
            class="text-center mb-4"
            headerIcon={cardInfo.headerIcon}
            icon={cardInfo.icon}
            title={cardInfo.title}
            description={cardInfo.description}
            onCardClick={cardInfo.onCardClick}
          />

          {leftNavigation && (
            <b-card
              class="small-card-history mb-4"
              onClick={leftNavigation}
              border-variant="light"
            >
              <h5>Histórico de solicitações</h5>
            </b-card>
          )}
        </template>

        <template slot="navigation">
          <div class="simple-layout">
            <div class="simple-layout--content">
              <span></span>
              {viewContent}
            </div>
          </div>
        </template>
      </FormTemplate>
    );
  },
};

export default SimpleLayout;
</script>

<style scoped lang="scss">
.simple-layout {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2.8rem;
  span {
    display: flex;
    width: 100%;
    height: 0.15rem;
    border-radius: 20%;
    padding-right: 15px;
    padding-left: 15px;
    background-color: var(--secondary-green);
    background-clip: content-box;
    margin-bottom: 2rem;
  }

  &--content {
    margin-top: 2rem;
    padding: 0.5rem;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
}
.small-card-history.card {
  width: 19rem;
  justify-content: center;
  align-items: center;
  align-content: center;
  border-radius: 3rem;
  cursor: pointer;
  & > .card-body {
    text-align: center;
    font-weight: 600;
    padding: 0.7rem;
    margin: 0.5rem;
  }

  h5 {
    transition: color 0.65s ease;
  }

  &:hover,
  h5:hover {
    transition: color 0.25s ease;
    color: var(--primary);
  }
}
</style>
