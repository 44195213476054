<template>
    <div class="privacy-container">
        <div>
            <h3>DECLARAÇÃO DE PRIVACIDADE</h3>
        </div>
        <div class="separator-line separator-line--full separator-line--huge"></div>
        <p class="P4"></p>
        <p class="P9">
            <span>
                A Unimed Grande Florianópolis (UGF), alicerçada no propósito de cuidar das pessoas, está comprometida não
                só com a saúde, mas com a proteção dos dados pessoais. Para tal, implementamos um programa que visa a
                conformidade de nossas operações de tratamento de dados, com a legislação de proteção de dados pessoais.
            </span>

        </p>
        <p class="P9">
            <span>
                Desta forma, a UGF se compromete a garantir o direito à efetiva proteção, segurança e privacidade dos dados
                pessoais.
            </span>
        </p>
        <p class="P9">
            <span>
                Sinta o Nosso Jeito de Cuidar em cada detalhe da política de privacidade.
            </span>
        </p>
        <p class="P7"></p>
        <p class="P9"><span class="T1">1. DEFINIÇÕES</span></p>
        <p class="P7"></p>
        <p class="P9">
            <span>
                1.1. <span class="T1">DADOS PESSOAIS:</span> A LGPD define "dado pessoal" como todo aquele que se refira a
                uma
                pessoa física identificada ou identificável. Na prática, a expressão compreende todo dado que
                permite identificar uma pessoa, como por exemplo: nome, CPF, número de identidade etc.
                Além disso, os dados pessoais podem ser sensíveis ou não.</span>
        </p>
        <p class="P7"></p>
        <p class="P9">
            <span>
                1.2. <span class="T1">DADOS PESSOAIS SENSÍVEIS:</span> O dado pessoal sensível é aquele que se refere à
                origem
                racial ou étnica, convicção religiosa, opinião política, filiação a sindicato ou a organização de
                caráter religioso, filosófico ou político, dado referente à saúde ou à vida sexual, dado genético
                ou biométrico, quando vinculado a uma pessoa física.
            </span>
        </p>
        <p class="P7"></p>

        <p class="P9">
            <span>
                1.3. <span class="T1">TRATAMENTO:</span> É toda operação realizada com dados pessoais, como as que se
                referem a
                coleta, produção, recepção, classificação, utilização, acesso, reprodução, transmissão,
                distribuição, processamento, arquivamento, armazenamento, eliminação, avaliação ou
                controle da informação, modificação, comunicação, transferência, difusão ou extração.

            </span>
        </p>
        <p class="P7"></p>
        <p class="P9">
            <span>
                1.4. <span class="T1">TITULAR:</span> É a pessoa física a quem se referem os dados pessoais que são objeto de
                    tratamento. Nas relações com a Unimed, os Titulares, via de regra, serão os cooperados dos
                    serviços prestados.
            </span>
        </p>
        <p class="P7"></p>
        <p class="P9">
            <span>
                1.5. <span class="T1">CONSENTIMENTO:</span> É a manifestação livre, informada e inequívoca pela qual o Titular
                        concorda com o tratamento de seus dados pessoais para uma finalidade determinada.
            </span>
        </p>
        <p class="P7"></p>
        <p class="P9">
            <span>
                1.6. <span class="T1">USUÁRIOS:</span>  pessoa física que interaja com a UGF em situações em que tenha a possibilidade
                de disponibilizar seus dados pessoais (exemplos: pessoas que naveguem no Portal da UGF,
                nos aplicativos do cooperado e do cliente UGF, colaboradores, prestadores de serviços,
                beneficiários do plano de saúde, etc).
            </span>
        </p>        
        <p class="P7"></p>
        <p class="P9">
            <span>
                1.7. <span class="T1">COOKIES:</span> São arquivos de informação que são armazenados no seu computador ou
                    dispositivos móveis através do navegador de internet. Estes arquivos permitem que as
                    informações pessoais (por exemplo, nome e e-mail) sejam guardadas para uso posterior.
                    Assim, o site consegue lembrar das ações e preferências registradas em nome do usuário. O
                    uso de cookies existe para que o usuário, ao regressar a um site que já visitou, não tenha que
                    indicar novamente as suas preferências de navegação (por exemplo, idioma, fonte, forma de
                    visualização etc.). Os cookies podem ser de sessão ou persistentes.
            </span>
        </p>   
        <p class="P7"></p>
        <p class="P9">
            <span>
                1.8. <span class="T1">COOKIES DE SESSÃO:</span>  É apagado quando você fecha o navegador de internet. Ele é
                    armazenado na memória temporária do computador e não é retido depois que o navegador é
                    encerrado. Os cookies de sessão não coletam informações do seu computador, mas
                    armazenam informações na forma de uma identificação que não coleta dados pessoais do
                    usuário.
            </span>
        </p>     
        <p class="P7"></p>
        <p class="P9">
            <span>
                1.9. <span class="T1">COOKIES PERSISTENTES:</span> Permanecem no computador do usuário mesmo após fechar a
                    sessão ou até a sua exclusão.
            </span>
        </p>  
        <p class="P7"></p>
        <p class="P9">
            <span>
                1.10. <span class="T1">INFORMAÇÃO CONFIDENCIAL:</span> todo e qualquer dado ou informação relacionado à saúde de
                    um Titular, incluindo exames, laudos e prontuários.
            </span>
        </p>  
        <p class="P7"></p>
        <p class="P9"><span class="T1">2. COLETA DE DADOS PESSOAIS</span></p>
        <p class="P7"></p>
        <p class="P9">
            <span>
                2.1. A coleta de dados pessoais é necessária para a manutenção e aprimoramento dos serviços,
                    possibilitando a disponibilização de conteúdos por perfil e o desenvolvimento de soluções e
                    funcionalidades adequadas às necessidades dos usuários, além de facilitar o acesso aos
                    serviços de seu interesse.
            </span>
        </p>  
        <p class="P7"></p>
        <p class="P9">
            <span>
                2.2. Todas as informações são fornecidas voluntariamente pelo usuário e são coletadas em
                    diferentes áreas sob a forma de cadastro, identificação de acesso ao portal, aplicativos,
                    e-mails e formulários.
            </span>
        </p>  
        <p class="P7"></p>
        <p class="P9">
            <span>
                2.3. Para entender de forma detalhada como coletamos e utilizamos dados pessoais,
                    disponibilizamos estas informações no Anexo 01 desta política de privacidade. Além da forma
                    como os dados pessoais são coletados e tratados, o documento também enumera os serviços
                    que são oferecidos através do aplicativo.
            </span>
        </p>  
        <p class="P7"></p>
        <p class="P9"><span class="T1">3. UTILIZAÇÃO DOS DADOS PESSOAIS</span></p>
        <p class="P7"></p>
        <p class="P9">
            <span>
                3.1. Ao aceitar a Política de Privacidade você concorda que seus dados pessoais sejam utilizados
                    pela UGF para as seguintes finalidades:
            </span>
        </p>          
        <p class="P9">
            <span>
                3.1.1. Personalização de conteúdos e serviços;
            </span>
        </p>          
        <p class="P9">
            <span>
                3.1.2. Análise estatística dos hábitos de navegação e preferências dos usuários;
            </span>
        </p>  
        <p class="P9">
            <span>
                3.1.3. Análise e gerenciamento de saúde, em especial para medicina preventiva,
                    gerenciamento de crônicos e saúde ocupacional;
            </span>
        </p>   
        <p class="P9">
            <span>
                3.1.4. Desenvolvimento de novos produtos e serviços;
            </span>
        </p>   
        <p class="P9">
            <span>
                3.1.5. Aperfeiçoamento e desenvolvimento de seções;
            </span>
        </p>   
        <p class="P9">
            <span>
                3.1.6. Envio de e-mail com materiais e informações relacionadas à saúde;
            </span>
        </p>  
        <p class="P7"></p>
        <p class="P9">
            <span>
                3.2. O tratamento de dados pessoais sem consentimento poderá ocorrer nas hipóteses previstas
                    em lei.
            </span>
        </p>  
        <p class="P7"></p>
        <p class="P9">
            <span>
                3.3. A UGF poderá utilizar e divulgar dados que foram coletados dos usuários de forma
                    anonimizada e agrupada, onde essas informações deixarão de ser dados pessoais, e não
                    serão mais vinculadas aos Usuários. Esses dados não-pessoais serão utilizados para fins de
                    levantamentos estatísticos, com finalidade de exprimir de modo global as características
                    qualitativas e quantitativas gerais dos usuários que acessam as soluções da <span class="T1">UNIMED</span>.
            </span>
        </p>  
        <p class="P7"></p>
        <p class="P9"><span class="T1">4. ARMAZENAMENTO DE DADOS PESSOAIS</span></p>
        <p class="P7"></p>
        <p class="P9">
            <span>
                4.1. Os dados pessoais coletados pela UGF permanecem retidos por um prazo determinado que
                    dependerá da finalidade do tratamento e da base legal que o justifique.
            </span>
        </p>          
        <p class="P7"></p>
        <p class="P9">
            <span>
                4.2. Via de regra, em respeito ao seu direito à privacidade da informação, a UGF, periodicamente
                    revisa seu banco de dados, para saber se as informações registradas ainda são necessárias e
                    atualizadas, para o alcance da finalidade inicialmente pretendida.
            </span>
        </p>  
        <p class="P7"></p>
        <p class="P9">
            <span>
                4.3. Dentre as finalidades dos tratamentos da UGF, podemos citar os seguintes prazos:
            </span>
        </p>          
        <p class="P9">
            <span>
                4.3.1. 
                    <span class="T1">Tratamento baseado no consentimento do Usuário:</span>
                    <span>
                        Tratamento baseado no consentimento do Usuário: enquanto o consentimento for
                        válido e não for revogado;
                    </span>
            </span> 
        </p>         
        <p class="P9">
            <span>
                4.3.2. 
                    <span class="T1">Quando necessário para cumprimento de obrigação legal ou regulatória,</span>
                    <span>
                        em especial em face da ANS: dependerá do prazo previsto na norma legal ou regulamentar, podendo variar de poucos dias até 20 anos, dependendo do tipo de
                        informação e documento;
                    </span>
            </span> 
        </p>         
        <p class="P9">
            <span>
                4.3.3. 
                    <span class="T1">Para execução de contrato firmado entre a UGF e o Usuário/Titular:</span>
                    <span>
                        enquanto durar o contrato, e, após o seu encerramento, pelo prazo previsto legalmente;
                    </span>
            </span> 
        </p>         
        <p class="P9">
            <span>
                4.3.4. 
                    <span class="T1">Exercício regular de direitos em processos judiciais ou administrativos:</span>
                    <span>
                        pelo prazo previsto legalmente, ou enquanto durar o procedimento judicial/administrativo;
                    </span>
            </span> 
        </p>         
        <p class="P9">
            <span>
                4.3.5. 
                    <span class="T1">Tutela da Saúde:</span>
                    <span>
                        enquanto for necessário para a proteção da saúde do Usuário/Titular.
                    </span>
            </span> 
        </p>
        <p class="P7"></p>
        <p class="P9"><span class="T1">5. COMPARTILHAMENTO DE DADOS PESSOAIS</span></p>
        <p class="P7"></p>
        <p class="P9">
            <span>
                5.1. A UGF poderá compartilhar seus dados pessoais coletados em determinadas hipóteses que
                    são permitidas pela <span class="T1">LGPD</span>. Via de regra, esse compartilhamento pode ocorrer com:
            </span>
        </p>          
        <p class="P9">
            <span>
                5.1.1. 
                    <span class="T1">Sistema Unimed:</span>
                    <span>
                        para o melhor atendimento voltado ao Usuário;
                    </span>
            </span> 
        </p>  
        <p class="P9">
            <span>
                5.1.2. 
                    <span class="T1">Profissionais do Sistema Unimed:</span>
                    <span>
                        visando o tratamento de dados e o atendimento às solicitações do Usuários;
                    </span>
            </span> 
        </p>         
        <p class="P9">
            <span>
                5.1.3. 
                    <span class="T1">Organizações parceiras ou fornecedores:</span>
                    <span>
                        para a prestação dos nossos serviços voltados ao Usuário;
                    </span>
            </span> 
        </p>         
        <p class="P9">
            <span>
                5.1.4. 
                    <span class="T1">Autoridades ou entidades governamentais:</span>
                    <span>
                        para o cumprimento de obrigaçõeslegais;
                    </span>
            </span> 
        </p>         
        <p class="P9">
            <span>
                5.1.5. 
                    <span class="T1">Terceiros:</span>
                    <span>
                        nas hipóteses em que necessário para proteção dos interesses da UGF,
                        como é o caso de Escritórios de Advocacia;
                    </span>
            </span> 
        </p>  
        <p class="P7"></p>
        <p class="P9"><span class="T1">6. DIREITOS DOS USUÁRIOS</span></p>
        <p class="P7"></p>
        <p class="P9">
            <span>
                6.1. Nos termos da legislação vigente, o Titular do dado poderá a qualquer momento e mediante requisição:
            </span>
        </p>          
        <p class="P9">
            <span>
                6.1.1.                     
                    <span>
                        Confirmar a existência de tratamento;
                    </span>
            </span> 
        </p>  
        <p class="P9">
            <span>
                6.1.2. 
                    <span>
                        Acessar os dados;
                    </span>
            </span> 
        </p>         
        <p class="P9">
            <span>
                6.1.3. 
                    <span>
                        Corrigir dados incompletos, inexatos ou desatualizados;
                    </span>
            </span> 
        </p>         
        <p class="P9">
            <span>
                6.1.4.                     
                    <span>
                        Solicitar anonimização, bloqueio ou eliminação dos dados desnecessários,
                        excessivos ou tratados em desconformidade com o disposto com a legislação;
                    </span>
            </span> 
        </p>         
        <p class="P9">
            <span>
                6.1.5. 
                    <span>
                        Solicitar a portabilidade dos dados para outras operadoras de Plano de Saúde;
                    </span>
            </span> 
        </p>           
        <p class="P9">
            <span>
                6.1.6. 
                    <span>
                        Solicitar a eliminação dos dados, a qual será atendida caso não haja hipótese legal
                        para a retenção dos mesmos;
                    </span>
            </span> 
        </p>            
        <p class="P9">
            <span>
                6.1.7. 
                    <span>
                        Solicitar informações sobre a possibilidade de não fornecer consentimento e sobre as
                        consequências da negativa;
                    </span>
            </span> 
        </p>            
        <p class="P9">
            <span>
                6.1.8. 
                    <span>
                        Revogar o consentimento.
                    </span>
            </span> 
        </p>  
        <p class="P7"></p>
        <p class="P9">
            <span>
                6.2. 
                    <span>
                        O Titular de Dados Pessoais poderá entrar em contato conosco para solicitar o cumprimento
                        dos direitos previstos pela Lei Geral de Proteção de Dados (LGPD). Para tanto, basta que o
                        mesmo nos envie uma solicitação através do  <a href="https://www.unimed.coop.br/site/web/florianopolis/transparencia">
                            formulário</a> localizado em nosso site.
                    </span>
            </span> 
        </p>  
        <p class="P7"></p>
        <p class="P9"><span class="T1">7. ALTERAÇÕES DA POLÍTICA DE PRIVACIDADE</span></p>
        <p class="P7"></p>
        <p class="P9">
            <span>
                7.1. 
                    <span>
                        Como estamos sempre buscando melhorar os nossos serviços e a forma como operamos, a
                            UGF poderá atualizar a Política de Privacidade a qualquer momento, para refletir as melhorias
                            que foram realizadas. As alterações serão devidamente disponibilizadas aos usuários, e, por
                            isso, recomendamos a visita periódica desta página para que você, usuário, tenha
                            conhecimento sobre as modificações efetivadas.
                    </span>
            </span> 
        </p>  
         
        <p class="P7"></p>
        <p class="P9"><span class="T1">8. MEDIDAS DE SEGURANÇA DA INFORMAÇÃO</span></p>
        <p class="P7"></p>
        <p class="P9">
            <span>
                8.1. 
                    <span>
                        A UGF adota medidas tecnológicas adequadas para evitar a perda, má utilização, alteração,
acesso não autorizado e apropriação indevida dos dados pessoais dos usuários.
                    </span>
            </span> 
        </p>  
        <p class="P7"></p>
        <p class="P9">
            <span>
                8.2. 
                    <span>
                       Os procedimentos de segurança são reavaliados periodicamente, com o intuito de garantir a
                        proteção dos dados pessoais. As áreas dos aplicativos e serviços da <span class="T1">UNIMED</span> são
                        criptografadas (codificadas), sendo acessadas somente através de senha pessoal e, além
                        disso, possuem certificado digital de segurança.
                    </span>
            </span> 
        </p>   
        <p class="P7"></p>
        <p class="P9">
            <span>
                8.3. 
                    <span>
                        A UGF busca prevenir a ocorrência de incidentes de Segurança da Informação e garantir a
                        continuidade do seu negócio, preservando o nível de serviço padrão, inclusive, adotando
                        medidas disciplinares apropriadas em relação aos seus colaboradores e assegurando que
                        somente pessoas autorizadas possuem acesso às informações e dados pessoais tratados no
                        âmbito de nossas atividades
                    </span>
            </span> 
        </p>   
        <p class="P7"></p>
        <p class="P9">
            <span>
                8.4. 
                    <span>
                        O acesso aos dados pessoais dos Usuários/Titulares é restrito e limitado, sendo acessível
                        apenas para os profissionais do Sistema UNIMED que necessitam dessas informações para
                        melhor atendê-los.
                    </span>
            </span> 
        </p>    
        <p class="P7"></p>
        <p class="P9">
            <span>
                8.5. 
                    <span>
                        De qualquer modo, ainda que todas as medidas e boas práticas sejam adotadas, cientifica-se
                        que, circulando os dados em rede de internet aberta, não é possível eliminar 100% o risco de
                        acesso e utilização não autorizados, pelo que o usuário deverá adotar medidas de segurança
                        adequadas para utilização das soluções da <span class="T1">UNIMED</span>.

                    </span>
            </span> 
        </p>           
         <p class="P7"></p>
         <p class="P9"><span class="T1">9. TERMOS DE USO</span></p>        
         <p class="P7"></p>
        <p class="P9">
            <span>
                9.1. 
                    <span>
                        Ao acessar e utilizar os aplicativos e soluções da <span class="T1">UNIMED</span>, o Usuário concorda com as
                        condições abaixo descritas, manifestando sua expressa concordância e se comprometendo a
                        respeitar e cumprir todo o disposto na presente Política. Em caso de discordância com
                        qualquer das condições, a UGF solicita que o Usuário não acesse ou utilize seus serviços.
                    </span>
            </span> 
        </p>           
         <p class="P7"></p>
        <p class="P9">
            <span>
                9.2. 
                    <span>
                        O acesso aos serviços é individual e intransferível. O Usuário se compromete a não
                    compartilhar com terceiros as suas informações de acesso.
                    </span>
            </span> 
        </p>              
         <p class="P7"></p>
        <p class="P9">
            <span>
                9.3. 
                    <span>
                        Os usuários que apresentam incapacidade civil, incluindo os menores de 18 anos, devem
                        possuir autorização escrita e protocolada junto à UNIMED, pelo seu representante legal.
                    </span>
            </span> 
        </p>          
         <p class="P7"></p>
        <p class="P9">
            <span>
                9.4. 
                    <span>
                        Ainda, o usuário:
                    </span>
            </span> 
        </p>      
        <p class="P9">
            <span>
                9.4.1. 
                    <span>
                        Responsabiliza-se pelas ações e omissões praticadas com seu acesso, inclusive
                        pelas informações prestadas, as quais devem ser verdadeiras, exatas e atualizadas;
                    </span>
            </span> 
        </p>         
        <p class="P9">
            <span>
                9.4.2. 
                    <span>
                        Assegurará a confidencialidade das informações presentes nos aplicativos;
                    </span>
            </span> 
        </p>            
        <p class="P9">
            <span>
                9.4.3. 
                    <span>
                        Respeitará os direitos autorais e de propriedade intelectual de toda e qualquer
informação presente nos aplicativos e serviços da UNIMED, ou que venha a ter
conhecimento através dessas ferramentas, nos termos da Lei;

                    </span>
            </span> 
        </p>              
        <p class="P9">
            <span>
                9.4.4. 
                    <span>
                        Declara ciência que seu acesso aos serviços poderá ser encerrado, sem aviso prévio,
em caso de violação às regras estabelecidas na presente Política.
                    </span>
            </span> 
        </p>          
        <p class="P9">
            <span>
                9.4.5. 
                    <span>
                        Autoriza o download e a instalação de atualizações dos aplicativos e soluções,
visando manter sempre a versão mais recente;
                    </span>
            </span> 
        </p>           
        <p class="P9">
            <span>
                9.4.6. 
                    <span>
                        Está ciente de sua responsabilidade pelo consumo de internet que utilizar para o
acesso às plataformas, bem como pelas tarifas e impostos associados ao seu uso;
                    </span>
            </span> 
        </p>            
         <p class="P7"></p>
        <p class="P9">
            <span>
                9.5. 
                    <span>
                        O usuário se compromete a comunicar imediatamente à UGF em caso de ciência, ou mera
                    suspeita, de qualquer violação de segurança relacionada aos serviços e aplicações da
                    <span class="T1">UNIMED</span>.
                    </span>
            </span> 
        </p>            
         <p class="P7"></p>
        <p class="P9">
            <span>
                9.6. 
                    <span>
                        Os serviços disponibilizados poderão ser interrompidos por falhas de rede ou equipamento,
                        bem como para manutenção, reparos e atualizações.
                    </span>
            </span> 
        </p>    
        <p class="P7" />
        <p class="P9">
            <span class="T1">10. EXCLUSÃO DE CADASTRO</span>
        </p>           
         <p class="P7"></p>
        <p class="P9">
            <span>
                10.1. 
                    <span>
                        O usuário, a qualquer momento e de maneira facilitada, poderá solicitar a exclusão do seu
                        cadastro junto ao aplicativo, com a consequente exclusão de suas informações pessoais do
                        banco de dados da aplicação.

                    </span>
            </span> 
        </p>            
         <p class="P7"></p>
        <p class="P9">
            <span>
                10.2. 
                    <span>
                        Ressalta-se que as informações pessoais dos usuários utilizadas no momento do cadastro são
                        armazenadas no banco de dados da UNIMED, para a prestação de serviços de saúde, com
                        base no art. 7º, inciso VIII, da LGPD, as quais não serão eliminadas.
                    </span>
            </span> 
        </p>           
         <p class="P7"></p>
        <p class="P9">
            <span>
                10.3. 
                    <span>
                        Quando o usuário realizar um novo cadastro no aplicativo, os dados pessoais necessários
                        serão resgatados da base de dados da UNIMED.
                    </span>
            </span> 
        </p>    
        <p class="P7" />
        <p class="P9">
            <span class="T1">11. LIMITAÇÃO DE RESPONSABILIDADE</span>
        </p>           
         <p class="P7"></p>
        <p class="P9">
            <span>
                11.1. 
                    <span>
                        A <span class="T1">UNIMED</span> e seus aplicativos/soluções não serão responsáveis por danos diretos, indiretos,
                        acidentais, especiais e/ou decorrentes de relação mantida entre o Titular e terceiros ou com os
                        profissionais para quem o Titular tenha oferecido, por qualquer meio, acesso às informações
                        disponibilizadas através dos serviços da <span class="T1">UNIMED</span>.
                    </span>
            </span> 
        </p>             
         <p class="P7"></p>
        <p class="P9">
            <span>
                11.2. 
                    <span>
                        O uso das soluções da UNIMED, fora do contexto do atendimento à saúde proporcionado pelo
                        próprio Sistema UNIMED, serão por conta e risco do Titular
                    </span>
            </span> 
        </p> 
        <p class="P7" />
        <p class="P9">
            <span class="T1">12. DÚVIDAS E RECLAMAÇÕES</span>
        </p>           
         <p class="P7"></p>
        <p class="P9">
            <span>
                12.1. 
                    <span>
                        Para mais informações, solicitamos que o cooperado entre em contato com nosso
                        Encarregado pelo Tratamento de Dados Pessoais (DPO), por meio do <a href="https://www.unimed.coop.br/site/web/florianopolis/transparencia">formulário</a> localizado
                        em nosso site
                    </span>
            </span> 
        </p>  
        <p class="P7" />
        <p class="P9">
            <span class="T1">13. OUTRAS INFORMAÇÕES</span>
        </p>           
         <p class="P7"></p>
        <p class="P9">
            <span>
                13.1. 
                    <span>
                        Os dados pessoais coletados não são fornecidos a título gratuito ou oneroso a parceiros,
                        patrocinadores, anunciantes ou sites, sem consentimento do Titular
                    </span>
            </span> 
        </p>           
         <p class="P7"></p>
        <p class="P9">
            <span>
                13.2. 
                    <span>
                        A senha de acesso é de uso pessoal, e não deve ser fornecida a terceiros.
                    </span>
            </span> 
        </p>          
         <p class="P7"></p>
        <p class="P9">
            <span>
                13.3. 
                    <span>
                        Evite o uso de senhas que podem ser facilmente descobertas (por exemplo: data de
                        aniversário ou sequência de números.
                    </span>
            </span> 
        </p>   
        <p class="P3"></p>
        <p class="P7"></p>

        <p class="P3 external-link T2"><a target="_blank"
                href="https://firebasestorage.googleapis.com/v0/b/ugf-prod-plataforma-cooperado/o/others%2FAnexo%2001%20-%20Descritivo%20de%20Dados%20Coletados%2C%20Formas%20de%20Utiliza%C3%A7%C3%A3o%20e%20Servi%C3%A7os%20do%20Aplicativo.pdf?alt=media&token=5a553612-a86f-4659-b610-244b79524422&_gl=1*xm80qm*_ga*ODc1ODUwMi4xNjc0MjE0MjQ4*_ga_CW55HF8NVT*MTY4NjAwNDI1NC4xMC4xLjE2ODYwMDQzNjkuMC4wLjA.">
                ANEXO 1</a></p>

    </div>
</template>
  
<script>
export default {
   
};
</script>
  
<style lang="scss" scoped>
@page {}


.privacy-container {
    width: 1000px;
    margin: auto;
    margin-top: 100px;
}

.external-link {
    text-decoration: underline;
    text-decoration-color: var(--primary);
}

span,
a {
    font-size: 12px;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
    empty-cells: show;
}

td,
th {
    vertical-align: top;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    clear: both;
}

li {
    list-style: none;
}

li span {
    clear: both;
    line-height: 0;
    width: 0;
    height: 0;
}

.P3 {
    line-height: 100%;
    text-align: justify !important;
    writing-mode: horizontal-tb;
    direction: ltr;
    padding: 0in;
    border-style: none;
}

.P4 {
    line-height: 100%;
    text-align: justify !important;
    writing-mode: horizontal-tb;
    direction: ltr;
    padding: 0in;
    border-style: none;
    font-weight: bold;
}

.P7 {
    line-height: 115%;
    margin-top: 20px;
    text-align: justify !important;
    writing-mode: horizontal-tb;
    direction: ltr;
    padding: 0in;
    border-style: none;
}

.P9 {
    line-height: 115%;
    text-align: justify !important;
    writing-mode: horizontal-tb;
    direction: ltr;
    padding: 0in;
    border-style: none;
}

.T1 {
    font-weight: bold;
}

.T2 {
    margin-top: 40px;
    padding-bottom: 150px;
    width: 50px;
    margin: auto;
    font-size: 16px;
}

p {
    color: #23272b !important;
    margin-bottom: 1rem !important;
}

li>p {
    color: #23272b !important;
    margin-bottom: 0.2rem;
}

div.separator-line {
    margin: 3.5rem 0rem;
}
</style>
  