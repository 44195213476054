<template>
  <div :class="isMobile() ? 'is-mobile' : ''">
    <VisualizationBackbeatLayout :leftInfo="leftInfo" :leftAction="leftAction">
      <template slot="rightAction">
        <div class="d-flex align-content-end flex-wrap ml-auto">
          <div class="p-2 competence align-self-center">
            COMPETÊNCIA:
          </div>
          <div class="p-1 align-self-center">
            <vue-monthly-picker
              class="periodo"
              v-model="selectedDate"
              :monthLabels="this.allMonth"
              dateFormat="MM/YYYY"
            ></vue-monthly-picker>
          </div>
        </div>
      </template>
      <template slot="headerContent">
        <div class="div-principal">
          <div class="d-flex">
            <div
              class="d-flex align-content-center flex-wrap"
              v-if="this.dataBackBeat == undefined"
            >
              <h5>
                {{ this.dataBackBeat }}
                Não há dados para o período informado! Tente consultar outro
                período.
              </h5>
            </div>
          </div>
          <div
            class="resultado-coletivo"
            v-if="this.dataBackBeat !== undefined"
          >
            <div>
              <p class="p-accumulated">
                <img
                  class="img-Accumulated"
                  src="../../../assets/images/MDI-information-green.svg"
                />
                Acumulado até {{ this.dataBackBeat.competenceCalculation }}.
              </p>
            </div>
            <div class="div-value-pago mt-4">
              <div class="cards">
                <div
                  class="d-flex justify-content-between mb-3 custon-valor-pago align-self-center"
                >
                  <div class="t4 d-flex align-content-center flex-wrap">
                    Valor pago para o cooperado:
                  </div>
                  <div class="t10 d-flex align-content-center flex-wrap">
                    R$ {{ this.dataBackBeat.amountPaid }}
                  </div>
                </div>
                <div
                  class="d-flex mb-3 justify-content-end flex-wrap w-100"
                ></div>
              </div>
            </div>

            <h2 class="h2-resultado-coletivo">Resultado Coletivo</h2>
            <div class="span-new"></div>

            <div class="d-flex justify-content-between mt-5">
              <div class="custon-cards-resultado-coletivo">
                <div class="title">
                  <p class="p10">
                    Resultado Total
                  </p>
                </div>
                <div class="porcentage">
                  <p
                    v-text="this.dataBackBeat.resultCollective"
                    class="p19"
                  ></p>
                </div>
                <div class="footer">
                  <p
                    @click="onClickSaibaMais('RT')"
                    class="p custon-pointer saiba-mais"
                  >
                    <img
                      class="img-saiba-mais"
                      src="../../../assets/images/MDI-information.svg"
                    />
                    Saiba mais
                  </p>
                </div>
              </div>

              <div class="custon-cards-resultado-coletivo">
                <div class="title">
                  <p class="p6">Meta</p>
                </div>
                <div class="porcentage">
                  <div>
                    <p :class="isMobile() ? 'p-4-m' : 'p6'">menor que</p>
                    <p
                      v-text="this.dataBackBeat.goal"
                      class="d-flex align-items-start p-c-19"
                    ></p>
                  </div>
                </div>
                <div class="footer">
                  <p
                    @click="onClickSaibaMais('MT')"
                    class="p custon-pointer saiba-mais"
                  >
                    <img
                      class="img-saiba-mais"
                      src="../../../assets/images/MDI-information.svg"
                    />
                    Saiba mais
                  </p>
                </div>
              </div>

              <div
                v-show="!this.dataBackBeat.isLossRatio"
                class="custon-cards-resultado-coletivo"
              >
                <div class="title">
                  <p class="p-199">
                    Índice de Sinistralidade
                  </p>
                </div>
                <div class="porcentage">
                  <div>
                    <p v-text="this.dataBackBeat.lossRatio" class="p-c-199"></p>
                    <p class="p-199">
                      a meta foi alcançada!
                    </p>
                  </div>
                </div>
                <div class="footer">
                  <p
                    @click="onClickSaibaMais('IS')"
                    class="p custon-pointer saiba-mais"
                  >
                    <img
                      class="img-saiba-mais"
                      src="../../../assets/images/MDI-information.svg"
                    />
                    Saiba mais
                  </p>
                </div>
              </div>

              <div
                v-show="this.dataBackBeat.isLossRatio"
                class="custon-cards-resultado-coletivo"
              >
                <div class="title">
                  <p class="p-200">
                    Índice de Sinistralidade
                  </p>
                </div>
                <div class="porcentage">
                  <div>
                    <p v-text="this.dataBackBeat.lossRatio" class="p-c-200"></p>
                    <p class="p-200">
                      a meta não foi alcançada.
                    </p>
                  </div>
                </div>
                <div class="footer">
                  <p
                    @click="onClickSaibaMais('IS')"
                    class="p custon-pointer saiba-mais"
                  >
                    <img
                      class="img-saiba-mais"
                      src="../../../assets/images/MDI-information.svg"
                    />
                    Saiba mais
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div
            class="resultado-Individual"
            v-if="this.dataBackBeat !== undefined"
          >
            <h2 class="h2-resultado-Individual">Resultado Individual</h2>
            <div class="span-new"></div>
            <div class="d-flex justify-content-between mt-5">
              <div class="d-flex flex-column custon-cards-resultado-individual">
                <div>
                  <div class="title">
                    <p class="p-201">
                      Desempenho Administrativo
                    </p>
                    <div class="span-card mt-2"></div>
                  </div>
                </div>
                <div>
                  <div class="body-card">
                    <p class="desc-custon p-202">
                      <img
                        v-show="this.dataBackBeat.minimumProduction"
                        class="img-icons"
                        src="../../../assets/images/icon-check.svg"
                      />
                      <img
                        v-show="!this.dataBackBeat.minimumProduction"
                        class="img-icons"
                        src="../../../assets/images/icon-error.svg"
                      />
                      Cumprimento da produção mínima
                    </p>

                    <p class="desc-custon p-202">
                      <img
                        v-show="this.dataBackBeat.participationMeeting"
                        class="img-icons"
                        src="../../../assets/images/icon-check.svg"
                      />
                      <img
                        v-show="!this.dataBackBeat.participationMeeting"
                        class="img-icons"
                        src="../../../assets/images/icon-error.svg"
                      />
                      Participação assembleia
                    </p>

                    <p class="desc-custon p-202">
                      <img
                        v-show="this.dataBackBeat.nonPayment"
                        class="img-icons"
                        src="../../../assets/images/icon-check.svg"
                      />
                      <img
                        v-show="!this.dataBackBeat.nonPayment"
                        class="img-icons"
                        src="../../../assets/images/icon-error.svg"
                      />
                      Obrigações financeiras
                    </p>
                  </div>
                </div>
                <div class="mt-auto">
                  <div
                    class="d-flex"
                    :class="
                      isMobile() ? ' flex-column' : ' justify-content-between'
                    "
                  >
                    <p @click="onClickSaibaMais('IA')" class="p custon-pointer">
                      <img
                        class="img-saiba-mais"
                        src="../../../assets/images/MDI-information.svg"
                      />
                      Entenda este indicador
                    </p>
                    <p
                      v-text="
                        this.onGetAdicional(
                          this.dataBackBeat.additionalGainPerformance
                        )
                      "
                      :class="isMobile() ? 'p-201-gd' : 'p-201'"
                    ></p>
                  </div>
                </div>
              </div>

              <div class="d-flex flex-column custon-cards-resultado-individual">
                <div>
                  <div class="title">
                    <p class="p-201">
                      Pacote Tecnológico
                    </p>
                    <div class="span-card mt-2"></div>
                  </div>
                </div>
                <div>
                  <div class="body-card">
                    <p class="desc-custon p-202">
                      <img
                        v-show="
                          this.dataBackBeat.isAdditionalGainTechnologicalPackage
                        "
                        class="img-icons"
                        src="../../../assets/images/icon-check.svg"
                      />
                      <img
                        v-show="
                          !this.dataBackBeat
                            .isAdditionalGainTechnologicalPackage
                        "
                        class="img-icons"
                        src="../../../assets/images/icon-error.svg"
                      />
                      Disponilização de horários para agendamento pelo HUB
                    </p>
                  </div>
                </div>
                <div class="mt-auto">
                  <div
                    class="d-flex"
                    :class="
                      isMobile() ? ' flex-column' : ' justify-content-between'
                    "
                  >
                    <p @click="onClickSaibaMais('PT')" class="p custon-pointer">
                      <img
                        class="img-saiba-mais"
                        src="../../../assets/images/MDI-information.svg"
                      />
                      Entenda este indicador
                    </p>
                    <p
                      v-text="
                        this.onGetAdicional(
                          this.dataBackBeat.additionalGainTechnologicalPackage
                        )
                      "
                      :class="isMobile() ? 'p-201-gd' : 'p-201'"
                    ></p>
                  </div>
                </div>
              </div>
            </div>
            <div class="mt-5 eficiencia-tecnica">
              <div class="title">
                <div class="d-flex justify-content-between">
                  <p class="p-201">
                    Eficiência Técnica
                  </p>
                  <p
                    v-text="
                      this.onGetAdicional(
                        this.dataBackBeat.additionalGainTechnicalEfficiency
                      )
                    "
                    :class="isMobile() ? 'p-201-gd' : 'p-201'"
                  ></p>
                </div>
                <div class="span-card mt-2"></div>
              </div>
              <div class="custon-grid">
                <b-table
                  class="backbeat-table"
                  :items="items"
                  v-show="!this.globalLoading"
                  responsive
                  show-empty
                  empty-text="Nenhuma informação para exibir!"
                >
                  <template v-slot:head(indicator)="">
                    Nome do indicador
                  </template>
                  <template v-slot:head(reachedGoal)="">
                    Atingiu a meta?
                  </template>
                  <template v-slot:head(resultSpecialty)="">
                    Resultado da especialidade
                  </template>
                  <template v-slot:head(resultIndividual)="">
                    Resultado individual
                  </template>
                  <template #cell(indicator)="data">
                    {{ data.item.indicator }}
                  </template>
                  <template #cell(reachedGoal)="data">
                    {{ data.item.reachedGoal }}
                  </template>
                  <template #cell(resultSpecialty)="data">
                    {{ data.item.resultSpecialty }}
                  </template>
                  <template #cell(resultIndividual)="data">
                    {{ data.item.resultIndividual }}
                  </template>
                </b-table>
                <div v-show="this.globalLoading">
                  <b-skeleton-table :rows="12" :columns="4"></b-skeleton-table>
                </div>
              </div>
              <p
                @click="onClickSaibaMais('IT')"
                class="p custon-pointer custon-grid"
              >
                <img
                  class="img-saiba-mais"
                  src="../../../assets/images/MDI-information.svg"
                />
                Entenda este indicador
              </p>
            </div>

            <div
              v-show="true"
              class="help--internal mt-5"
              @click="onClickBackbeatSaibaMais()"
            >
              SAIBA MAIS SOBRE O BACKBEAT
              <img
                v-show="true"
                class="mdi-chevron-right"
                src="../../../assets/images/mdi-chevron-right.svg"
              />
            </div>
          </div>
        </div>
        <success-modal-custom
          title="Resultado Total"
          ref="success-modal-RT"
          buttonText="Ok, entendi!"
        >
          <template slot="body">
            <div class="">
              <div>
                <p class="p1 mb-5">
                  <strong>
                    Importante:
                  </strong>
                  Os resultados acima contemplam apenas beneficiários da Grande
                  Florianopólis que possuem planos privados de assistência à
                  saúde na modalidade de pré-pagamento.
                </p>
              </div>
            </div>
          </template>
        </success-modal-custom>
        <success-modal-custom
          title="Meta"
          ref="success-modal-MT"
          buttonText="Ok, entendi!"
        >
          <template slot="body">
            <div class="">
              <div>
                <p class="p1 mb-5">
                  A meta foi determinada levando em consideração os esforços
                  coletivos e qualidade assistencial dos médicos cooperados.
                </p>
              </div>
            </div>
          </template>
        </success-modal-custom>
        <success-modal-custom
          title="Índice de Sinistralidade"
          ref="success-modal-IS"
          buttonText="Ok, entendi!"
        >
          <template slot="body">
            <div class="">
              <div>
                <p class="p1 mb-5" v-text="onTextLossRatio()"></p>
              </div>
            </div>
          </template>
        </success-modal-custom>
        <success-modal-custom
          title="Saiba mais sobre os Indicadores Administrativos"
          ref="success-modal-IA"
          buttonText="Ok, entendi!"
        >
          <template slot="body">
            <div class="">
              <div>
                <p class="p1">
                  <strong>CUMPRIMENTO DA PRODUÇÃO MÍNIMA:</strong> o cooperado
                  deverá obter a produção mínima nas últimas 12 competências
                  (conforme o Regimento Interno Art. 138).
                </p>
                <p class="p1">
                  <strong>PARTICIPAÇÃO ASSEMBLEIA:</strong> o cooperado deverá
                  participar da última assembleia para receber o ganho adicional
                  até a próxima assembleia e assim sucessivamente.
                </p>
                <p class="p1 mb-1">
                  <strong>OBRIGAÇÕES FINANCEIRAS:</strong> o cooperado deverá
                  estar adimplente com todas as obrigações financeiras com a
                  cooperativa (mensalidade e coparticipação do plano de saúde,
                  seguros, alugueis, cota capital e etc).
                </p>
                <p class="p-16-custom mb-5"><strong>Ganho adicional máximo do indicador: 1,00% </strong></p>
              </div>
            </div>
          </template>
        </success-modal-custom>
        <success-modal-custom
          title="Saiba mais sobre o Pacote Tecnólogico"
          ref="success-modal-PT"
          buttonText="Ok, entendi!"
        >
          <template slot="body">
            <div class="">
              <div>
                <p class="p1 mb-1">
                  <strong
                    >"DISPONIBILIZAÇÃO DE HORÁRIOS PARA AGENDAMENTO PELO HUB" :
                  </strong>
                  o cooperado disponibilizará no mínimo vinte (20) agendas ao
                  mês, de segunda a sexta-feira, durante horário comercial.
                </p>
                <p class="p-16-custom mb-5"><strong>Ganho adicional máximo do indicador: 2,00% </strong></p>
              </div>
            </div>
          </template>
        </success-modal-custom>
        <success-modal-custom
          title="Saiba mais sobre os Indicadores Técnicos"
          ref="success-modal-IT"
          buttonText="Ok, entendi!"
        >
          <template slot="body">
            <div class="">
              <div>
                <p class="p1 mb-3">
                  No "RESULTADO DA ESPECIALIDADE" o cálculo é baseado no
                  resultado dos cooperados da mesma especialidade e aplicado o
                  percentil 80. Sendo assim o "RESULTADO DA ESPECIALIDADE"
                  servirá como META para as próximas competências.
                </p>
                <p class="p1 mb-1">
                  Para atingir a META, o "RESULTADO INDIVIDUAL" deverá ser menor
                  ou igual ao "RESULTADO DA ESPECIALIDADE". Neste formato, o
                  cooperado poderá comparar o seu "RESULTADO INDIVIDUAL" com a
                  sua especialidade.
                </p>
                <p class="p-16-custom mb-5"><strong>Ganho adicional máximo do indicador: 3,00% </strong></p>
              </div>
            </div>
          </template>
        </success-modal-custom>
      </template>
    </VisualizationBackbeatLayout>
  </div>
</template>

<script>
import SuccessModalCustom from "../components/visualization/SuccessModalCustom.vue";
import VisualizationBackbeatLayout from "../components/visualization/VisualizationBackbeatLayout.vue";
import { allMonth, getMonthNumber } from "../../../utils/date/date-utils";
import moment from "moment";
import { getDetailsBackbeatAPI } from "../../../services/backbeat/backbeat-services";
import { inject } from "@vue/composition-api";
import { isMobile } from "../../../utils/mobile/mobile";
import RoutesPaths from "../../../router/router-structure/routes-paths";

export default {
  components: {
    VisualizationBackbeatLayout,
    SuccessModalCustom,
  },
  mounted() {
    this.allMonth = allMonth();
    let { competence } = this.$store.getters.getAnalyticalData;

    if (competence === undefined || competence === null) {
      this.dataBackBeat = undefined;
    } else {
      this.selectedDate = moment(competence, "MM/YYYY");
    }
  },
  watch: {
    selectedDate: {
      handler() {
        this.year = this.getYear();
        this.month = this.getMonth();
        this.getDetailsBackbeatHandler();
      },
    },
  },
  computed: {
    yearsOptions: () => {
      let years = [];
      for (var n = 2017; n <= new Date().getFullYear(); n++) {
        years.push(n);
      }
      return years.reverse();
    },
  },
  setup() {
    const globalLoading = inject("globalLoading");
    return { globalLoading };
  },
  data() {
    return {
      dataBackBeat: {},
      allMonth: null,
      selectedDate: "",
      year: new Date().getFullYear(),
      leftAction: {
        text: "Backbeat",
        onClick: this.leftActionClickHandler,
      },
      leftInfo: "",
      fields: [],
      items: [],
      selectedViewRequestItem: null,
      requestTypeDescription: "",
      selectedAttachments: [],
    };
  },
  methods: {
    isMobile: isMobile,
    onClickBackbeatSaibaMais() {
      this.$router.push({
        path: RoutesPaths.financial.backbeatKnowMore(),
        replace: true,
      });
    },
    getYear() {
      if (this.selectedDate._f == "MM/YYYY")
        return this.selectedDate._i.substr(3, 4);
      else return this.selectedDate._i.substr(0, 4);
    },
    getMonth() {
      if (this.selectedDate._f == "MM/YYYY")
        return this.selectedDate._i.substr(0, 2);
      else return this.selectedDate._i.substr(5, 2);
    },
    async getDetailsBackbeatHandler() {
      try {
        this.globalLoading = true;
        const crm = this.$store.getters.getUserName;

        this.dataBackBeat = await getDetailsBackbeatAPI(
          crm,
          this.year,
          getMonthNumber(this.month)
        );
        this.items = this.dataBackBeat?.technicalEfficiency;
        this.globalLoading = false;
      } catch (error) {
        this.dataBackBeat = undefined;
        this.globalLoading = false;
        console.error("getDetailsBackbeatHandler", error);
      }
    },
    leftActionClickHandler() {
      this.$router.go(-1);
    },
    onGetAdicional(value) {
      return "Ganho adicional: " + value;
    },
    onClickSaibaMais(value) {
      let modal = null;
      if (value == "RT") {
        modal = this.$refs["success-modal-RT"].$children[0];
      } else if (value == "MT") {
        modal = this.$refs["success-modal-MT"].$children[0];
      } else if (value == "IS") {
        modal = this.$refs["success-modal-IS"].$children[0];
      } else if (value == "IA") {
        modal = this.$refs["success-modal-IA"].$children[0];
      } else if (value == "PT") {
        modal = this.$refs["success-modal-PT"].$children[0];
      } else if (value == "IT") {
        modal = this.$refs["success-modal-IT"].$children[0];
      }
      if (modal != null) modal.show();
    },
    onTextLossRatio() {
      if(this.dataBackBeat?.isLossRatio) 
        return `O índice de sinistralidade foi de ${this.dataBackBeat?.lossRatio}, ultrapassando 
                     o valor máximo de ${this.dataBackBeat?.goal} necessário para atingir a meta.`
      else
        return `O indice de sinistralidade foi de ${this.dataBackBeat?.lossRatio}, mantendo um valor
                      menor que ${this.dataBackBeat?.goal}, assim atingindo a meta.`
    },
  },
};
</script>
<style lang="scss" scoped>
.cards {
  width: 100%;
  display: grid;
  border-style: none;
  grid-column: 0;
  grid-template-columns: 65% 35%;
}

.div-value-pago {
  display: flex !important;
  justify-content: center !important;
  justify-items: center !important;
}

.div-principal {
  width: 97.6rem;
  max-width: 97.6rem;
  width: 100%;
  padding-left: 1.5rem;
}

.custon-valor-pago {
  border-radius: 10px;
  background-color: #ffffff;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  height: 5rem;
}

.t10 {
  font-size: multiplier-default-font-size(5); //20px
  color: #00995d;
}

.t4 {
  color: #23272b;
  font-size: multiplier-default-font-size(3); // 16
}

.span-new {
  width: 100%;
  height: 0.1rem;
  border-radius: 20%;
  background-color: var(--secondary-green);
}

.span-card {
  width: 100%;
  height: 0.05rem;
  border-radius: 20%;
  background-color: var(--grey-3);
}

.resultado-coletivo {
  width: 100%;
  padding-top: 0rem;
}

.custon-cards-resultado-coletivo {
  border-radius: 10px;
  background-color: #ffffff;
  padding-left: 1rem;
  padding-right: 1.5rem;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;

  width: 31%;
  height: 17.8rem;
}

.custon-cards-resultado-individual {
  border-radius: 10px;
  background-color: #ffffff;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;

  width: 48.3%;
  height: 19.8rem;

  @media screen and (max-width: 1112px) {
    height: 28rem;
  }
}

.eficiencia-tecnica {
  border-radius: 10px;
  background-color: #ffffff;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  width: 100%;
}

.title {
  height: 15%;
}

.footer {
  height: 10%;
}

.porcentage {
  height: 75%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.saiba-mais {
  width: 10rem;
}

.img-saiba-mais {
  height: 1rem;
  width: 1rem;
}

.img-Accumulated {
  height: 1.2rem;
  width: 1.2rem;
}

.custon-pointer {
  cursor: pointer;
}

.custon-heidth {
  height: 55px;
}

.vert-align {
  vertical-align: text-top;
}

.h2-resultado-coletivo {
  padding-top: 17px;
}

.T-11 {
  color: var(--orange-2);
  font-size: multiplier-default-font-size(5); // 16px
}

.body-card {
  padding-top: 1.5rem;
}

.desc-custon {
  padding-bottom: 0.5rem;
}

.img-icons {
  height: 2rem;
  width: 5%;
  @media screen and (max-width: 1112px) {
    width: 13%;
  }
}

.custon-grid {
  padding-bottom: 2rem;
}

.entenda-indicador {
  text-align: end;
}

.resultado-Individual {
  padding-top: 3.5rem;
  width: 100%;
}

.h2-resultado-Individual {
  padding-bottom: 0.7rem;
  padding-top: 1.7rem;
}

.custon-pointer:hover {
  color: #0a5f55 !important;
}
.is-mobile {
  .p-4-m {
    color: var(--green-3);
    font-size: 1rem;
  }
  .p-6 {
    color: var(--green-3);
    font-size: 1.4rem;
  }

  .p10 {
    color: var(--secondary-green);
    font-size: 1.4rem;
  }

  .p19 {
    color: var(--secondary-green);
    font-size: 2rem;
  }

  .p-c-19 {
    color: var(--green-3);
    font-size: 2rem;
  }

  .p-c-199 {
    color: var(--primary);
    font-size: 1.4rem;
  }
  .p-199-m {
    color: var(--primary);
    font-size: 1rem;
  }
  .p-199 {
    color: var(--primary);
    font-size: 1.2rem;
  }

  .p-c-200 {
    color: var(--orange-2);
    font-size: multiplier-default-font-size(19); // 48
  }

  .p-200 {
    color: var(--orange-2);
    font-size: multiplier-default-font-size(3); // 16px
  }

  .p-201 {
    color: var(--primary);
    font-size: 1.4rem;
  }
  .p-201-gd {
    color: var(--primary);
    font-size: 1.1rem;
  }
  .p-202 {
    color: var(--grey-2);
    font-size: 1rem;
    margin-bottom: 0rem;
  }
}
.p10 {
  color: var(--secondary-green);
  font-size: multiplier-default-font-size(3); // 16
}

.p19 {
  color: var(--secondary-green);
  font-size: multiplier-default-font-size(19); // 48
}

.p-c-19 {
  color: var(--green-3);
  font-size: multiplier-default-font-size(19); // 48
}

.p-c-199 {
  color: var(--primary);
  font-size: multiplier-default-font-size(19); // 48
}

.p-199 {
  color: var(--primary);
  font-size: multiplier-default-font-size(3); // 16px
}

.p-c-200 {
  color: var(--orange-2);
  font-size: multiplier-default-font-size(19); // 48
}

.p-200 {
  color: var(--orange-2);
  font-size: multiplier-default-font-size(3); // 16px
}

.p-201 {
  color: var(--primary);
  font-size: multiplier-default-font-size(5); // 20px
}

.p-accumulated {
  color: var(--green-2);
  font-size: multiplier-default-font-size(2); //14px
}

.p-202 {
  color: var(--grey-2);
  font-size: multiplier-default-font-size(2);
  margin-bottom: 0rem;
}

.p-16-custom {
  color: var(--green-2);
  font-size: multiplier-default-font-size(3); // 16
}

</style>
<style lang="scss">
.is-mobile .backbeat-table {
  table.b-table > thead {
    font-size: 0.95rem;
  }
  table.b-table > tbody {
    font-size: 1rem;
  }
}

.help {
  margin-top: 5.2rem;
  width: 100%;

  &--internal {
    display: flex;
    align-items: center;
    padding: 0 1rem 0 2rem;
    cursor: pointer;
    border-radius: 1rem;
    font-size: multiplier-default-font-size(3); // 16px

    background-color: white;
    color: #459b64;
    width: 100%;
    text-align: left;
    padding-left: 1.4rem;
    height: 5rem;
    margin-bottom: 2.5rem;

    img {
      margin-left: auto;
    }
  }
}
</style>
