<template>
  <div>
    <img
      class="float"
      src="@/assets/icons/whatsapp.svg"
      @click="openWhatsapp()"
    />
  </div>
</template>

<script>
import { APP_WHATSAPP_CHAT_URL } from "../../services/urls/enviroment-variables";
import { getNameUser } from "../../utils/user/user.js";

export default {
    
  methods: {
    openWhatsapp() {
      var element = document.createElement("a");
      element.setAttribute("href", `${APP_WHATSAPP_CHAT_URL + this.getText()}`);
      element.setAttribute("target", "_blank");
      document.body.appendChild(element);
      element.click();
    },
    getUser() {
      return getNameUser();
    },
    getText() {
      return `Olá, tudo bem? Eu sou o/a Dr(a) ${this.getUser()}`;
    },
  },
};
</script>

<style lang="scss" scoped>


.float {
  position: fixed;
  width: 70px;
  height: 70px;
  bottom: 20px;
  right: 20px;
  z-index: 100;
  cursor: pointer;
}

.my-float {
  margin-top: 16px;
}

@media only screen and (max-width: 600px) {
  .float {
    opacity: 0.7;
  }
}

</style>
