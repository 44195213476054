import axios from "../http-client/axios";
import { OPETH_URL } from "../urls/base-urls";

export async function saveDocumentsTypes(formData) {
     await axios.post(
        `${OPETH_URL}/document-type`,
          {type: formData.type}
      );
}

export async function saveDocuments(formData) {
  var bodyFormData = new FormData();
  bodyFormData.append('type_id', formData.type);
  if (formData.documentName) {
    const extension = getFileExtension(formData.file.name);
    formData.documentName = formData.documentName + "." + extension;
    
    bodyFormData.append("document_name", formData.documentName);
  }

  if (formData.year != null) {
    bodyFormData.append('year', formData.year);
  }
  bodyFormData.append('file', formData.file);
  await axios({
    method: "post",
    url: `${OPETH_URL}/document-record`,
    data: bodyFormData,
    headers: { "Content-Type": "multipart/form-data" },
  });
}


function getFileExtension(filename) {
  // get file extension
  const extension = filename.split(".").pop();
  return extension;
}

export async function getDocumentsTypes() {
  const resp = await axios.get(`${OPETH_URL}/document-type`);
  return resp.data;
}

export async function getListDocuments(typeId, competence) {
  if (typeId == null && competence == null) {
    return null;
  }
  let query = []
  if (typeId) {
    query.push(`typeId=${typeId}`)
  }
 if (competence) {
    let yearToRequest = competence;
    query.push(`year=${yearToRequest}`)
  }
  const params = query.join('&')
  const resp = await axios({
    url: `${OPETH_URL}/list-document-record?${params}`,
    method: "get",
    data: {},
    headers: { "content-type": "application/json" },
  });

  if (resp && resp.data.length == 0) return [];
  const documents = resp.data;

  return requestHistoryMapper(documents);
}

function requestHistoryMapper(documents) {
  let requests = [];
  documents.forEach((document) => {
    requests.push({
      name: document.name,
      year: document.year,
      download: document,
      excluir: document.id,
    });
  });

  return requests;
}

export async function deleteDocumentById(documentId) {
  const resp = await axios({
    url: `${OPETH_URL}/document-record?documentId=${documentId}`,
    method: "delete",
    data: {},
    headers: { "content-type": "application/json" },
  });

  return resp.data;
}

export async function getListYearsDocumentByType(typeId) {
  const resp = await axios({
    url: `${OPETH_URL}/list-years-document-by-type?typeId=${typeId}`,
    method: "get",
    data: {},
    headers: { "content-type": "application/json" },
  });

  return resp.data;
}

export async function updateDocumentType(documentType) {

  const request = {
    id: documentType.id,
    type: documentType.type,
    showDate: documentType.showDate
  } 

  const resp = await axios({
    url: `${OPETH_URL}/document-type`,
    method: "put",
    data: request,
  });
  return resp.data;
}

export async function saveDocumentType(documentType) {

  if (documentType.showDate == null || documentType.showDate == undefined) {
    documentType.showDate = false;
  }

  const request = {
    type: documentType.type,
    showDate: documentType.showDate
  } 

  const resp = await axios({
    url: `${OPETH_URL}/document-type`,
    method: "post",
    data: request,
    headers: { "content-type": "application/json" },
  });

  return resp.data;
}

export async function deleteDocumentTypeById(typeId) {
  const resp = await axios({
    url: `${OPETH_URL}/document-type?id=${typeId}`,
    method: "delete",
    data: {},
    headers: { "content-type": "application/json" },
  });

  return resp.data;
}
