import InitialLayout from "../../layouts/InitialLayout.vue";
import LoadApp from "../../modules/load-app/views/LoadApp.vue";

const BaseRoutes = {
  path: "/load-app",
  component: InitialLayout,
  children: [
    {
      path: "",
      component: LoadApp,
    },
  ],
};

export default BaseRoutes;
