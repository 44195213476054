<template>
  <div class="login-container">
    <transition name="fade">
      <div class="loading-block-container">
        <RotateSquare />
        <div>
          <p>Aguarde...</p>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import RotateSquare from "../../../components/rotate-square/RotateSquare.vue";
import RoutesPaths from "../../../router/router-structure/routes-paths";
export default {
  mounted() {
    this.buildApp();
  },
  components: { RotateSquare },
  methods: {
    async buildApp() {
      try {
        const isLogged = this.$store.getters.isLoggedIn;
        if (isLogged) {
          this.$router.push(RoutesPaths.home.rootName());
        }
      } catch (error) {
        console.error("buildApp", error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.login-container {
  display: flex;
  width: 100%;
  height: 100%;
  background-image: url("../../../assets/images/login/login-illustration.svg");
  background-repeat: no-repeat;

  @include media-breakpoint-up(xs) {
    justify-content: center;
    background-position: right bottom;
    background-size: 90% 28%;
  }

  @include media-breakpoint-up(sm) {
    justify-content: center;
    background-position: right bottom;
    background-size: 90% 28%;
  }

  @include media-breakpoint-up(md) {
    justify-content: center;
    background-position: right bottom;
    background-size: 50% 28%;
  }

  @include media-breakpoint-up(lg) {
    justify-content: initial;
    background-position: right top;
    background-size: 60% 100%;
  }

  @include media-breakpoint-up(xl) {
    justify-content: initial;
    background-position: right top;
    background-size: 60% 100%;
  }
}
</style>
